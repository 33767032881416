import $ from 'dom7';
import events from './events';
import API from './api';
import socket from './socket';

const Sync = {
  queue: localStorage.syncQueue ? JSON.parse(localStorage.syncQueue) : [],
  loggedIn() {
    const userRole = window.localStorage.userRole || null;
    const loggedIn = !!window.localStorage.accessToken && userRole;
    return loggedIn;
  },
  add({ url, data, base64FileProp, filesToUploadProp, socket: isSocket, eventName } = {}) {
    const d = { ...data };
    const needsFilesUpload = (filesToUploadProp && d.filesToUpload && d.filesToUpload.length);
    if (needsFilesUpload) {
      const filesToUpload = [];
      let filesRead = 0;
      d.filesToUpload.forEach((file) => {
        const reader = new FileReader();
        reader.onload = ((event) => {
          filesToUpload.push({
            bits: [event.target.result],
            name: file.name,
            options: {
              type: file.type,
              lastModified: file.lastModified,
            },
          });
          filesRead += 1;
          if (filesRead === d.filesToUpload.length) {
            d.filesToUpload = filesToUpload;
            Sync.queue.push({ url, data: d, base64FileProp, filesToUploadProp, socket: isSocket, eventName });
            localStorage.syncQueue = JSON.stringify(Sync.queue);
          }
        });
        reader.readAsText(file);
      });
    } else {
      Sync.queue.push({ url, data: d, base64FileProp, filesToUploadProp, socket: isSocket, eventName });
      localStorage.syncQueue = JSON.stringify(Sync.queue);
    }
  },
  clearQueue() {
    Sync.queue = [];
    localStorage.syncQueue = JSON.stringify([]);
  },
  processQueue() {
    if (!Sync.loggedIn()) return;
    const q = Sync.queue.shift();
    if (!q) return;
    const { url = '', data = {}, base64FileProp, filesToUploadProp, socket: isSocket, eventName } = q;
    if (isSocket) {
      socket.connection.send(eventName, data).then(() => {
        Sync.processQueue();
      }).catch(() => {
        Sync.processQueue();
      });
    } else {
      API.post({
        url,
        data,
        base64FileProp,
        filesToUploadProp,
        end() {
          Sync.processQueue();
        },
      });
    }
    localStorage.syncQueue = JSON.stringify(Sync.queue);
  },
  init() {
    events.$on('logedOut', () => {
      Sync.clearQueue();
    });
    events.$on('online', () => {
      Sync.processQueue();
    });
    if (window.cordova) {
      $(document).on('resume', () => {
        Sync.processQueue();
      });
    }
    if (!Sync.loggedIn()) {
      Sync.clearQueue();
      return;
    }
    if (navigator.onLine) Sync.processQueue();
  },
};

export default Sync;
