<template>
  <f7-app id="app" :params="$root.f7params">
    <f7-statusbar :class="{'login-statusbar': !$root.loggedIn}"></f7-statusbar>
    <template v-if="$root.loggedIn && !$root.userRoleChanging && !$root.userChanging">
      <f7-panel class="admin-menu-panel" left reveal v-if="$root.userRole === 'admin'" theme-dark>
        <f7-view :init="false">
          <admin-menu></admin-menu>
        </f7-view>
      </f7-panel>
      <f7-panel class="app-menu-panel" left reveal v-if="$root.userRole !== 'admin' && showAppMenu" theme-dark>
        <f7-view :init="false">
          <app-menu></app-menu>
        </f7-view>
      </f7-panel>
      <f7-view class="safe-areas" main :push-state="!$device.cordova" :push-state-separator="''" :url="initialUrl"></f7-view>
    </template>
    <f7-login-screen v-if="!$root.loggedIn" opened>
      <f7-view :main="false" name="login" :push-state="!$device.cordova" :push-state-separator="''" :url="loginUrl()" :animate="false"></f7-view>
    </f7-login-screen>
    <f7-popup ref="popup" :opened="$root.popupOpened" @popup:closed="onPopupClosed" @popup:open="onPopupOpen" :class="{'popup-delayed': $root.popupDelayed}">
      <f7-view :url="$root.popupUrl" v-if="$root.popupUrl"></f7-view>
      <f7-preloader v-else></f7-preloader>
    </f7-popup>
  </f7-app>
</template>
<script>
  import AdminMenu from './admin/menu.vue';
  import events from '../modules/events';

  export default {
    components: {
      AdminMenu,
    },
    data() {
      let initialUrl = '/';
      if (localStorage.lastCrowdId) {
        initialUrl = `/crowd/${localStorage.lastCrowdId}`;
      }
      return {
        showAppMenu: true,
        initialUrl,
      };
    },
    mounted() {
      const self = this;

      events.$on('hideAppMenu', () => {
        self.showAppMenu = false;
      });
      events.$on('showAppMenu', () => {
        self.showAppMenu = true;
      });

      let ignoreSignupRedirect = false;
      [
        'confirmation/',
        'reset-password/',
        'preview/',
        'assignment/',
        'assignment/my/',
        'recommendation/',
        'application/',
      ].forEach((u) => {
        if (window.location.href.indexOf(u) >= 0) ignoreSignupRedirect = true;
      });

      if (window.location.href.indexOf('fid') >= 0 && !localStorage.fid) {
        const fid = window.location.href.split('fid=')[1].split('&')[0];
        if (fid) localStorage.fid = fid;
      }


      function joinCrowdOnLoad(crowdGuid) {
        self.$root.joinCrowd(crowdGuid);
      }
      function joinCompanyOnLoad(companyGuid) {
        self.$root.joinCompany(companyGuid);
      }

      self.$f7ready((f7) => {
        if (window.location.href.indexOf('join=') >= 0) {
          const crowdGuid = window.location.href.split('join=')[1];
          if (self.$root.loggedIn) {
            joinCrowdOnLoad(crowdGuid);
          } else {
            events.$once('loggedIn', () => {
              joinCrowdOnLoad(crowdGuid);
            });
          }
        }
        if (window.location.href.indexOf('join_company=') >= 0) {
          const companyGuid = window.location.href.split('join_company=')[1];
          if (self.$root.loggedIn) {
            joinCompanyOnLoad(companyGuid);
          } else {
            events.$once('loggedIn', () => {
              joinCompanyOnLoad(companyGuid);
            });
          }
        }

        if (window.location.href.indexOf('/signup/') >= 0) return;
        if (window.location.href.indexOf('email=') >= 0) return;
        if (ignoreSignupRedirect) return;

        if (!self.$root.loggedIn && f7.views.login && !self.$device.cordova && self.loginUrl() !== '/') {
          f7.views.login.router.navigate(self.loginUrl(), { reloadCurrent: true });
        }
      });
    },
    created() {
      if (this.$root.loggedIn) {
        localStorage.visited = true;
        const isJoin = document.location.href.indexOf('?join') >= 0;
        if (!this.$device.cordova && document.location.pathname === '/' && localStorage.lastCrowdId && !isJoin) {
          window.history.replaceState({}, '', `/crowd/${localStorage.lastCrowdId}/`);
        }
      }
    },
    methods: {
      loginUrl() {
        const self = this;
        let url = '/';
        if (!self.$root.loggedIn && !localStorage.visited) {
          url = '/signup/';
        }
        if (document.location.href.indexOf('/company-join/') >= 0) {
          url = `/company-join/${document.location.href.split('join/')[1]}`;
        }
        if (!self.$root.loggedIn && document.location.href.indexOf('?join_company') >= 0) {
          url = `/company-join/${document.location.href.split('?join_company=')[1]}/`;
        }
        return url;
      },
      onPopupOpen(e) {
        const popup = e.detail;
        popup.params.closeByBackdropClick = this.$root.popupCloseByBackdropClick;
        if (this.$root.popupTabletFullScreen) {
          popup.$el.addClass('popup-tablet-fullscreen');
        }
      },
      onPopupClosed(e) {
        const popup = e.detail;
        this.$root.popupDelayed = false;
        this.$root.popupOpened = false;
        this.$root.popupUrl = null;
        popup.$el.removeClass('popup-tablet-fullscreen');
      },
    },
  };
</script>
