/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';
import PWA from '../../modules/pwa';

const state = {
  company: null,
  companyLoading: false,
  saving: false,
};

const getters = {
  companyLoading(state) {
    return state.companyLoading;
  },
  companySaving(state) {
    return state.saving;
  },
  company(state) {
    return state.company;
  },
};
const actions = {
  getCompany({ commit, state }, { id } = {}) {
    if (state.company) return;
    API.get({
      url: `admin/company/details?id=${id}`,
      loading: 'a/setCompanyLoading',
      ok(response) {
        commit('setCompany', response);
      },
    });
  },
  unsetCompany({ commit }) {
    commit('unsetCompany');
  },
  saveCompany({ dispatch }, { company, callbackOk, callbackError } = {}) {
    API.post({
      url: 'admin/company/save',
      loading: 'a/setCompanySaving',
      data: company,
      base64FileProp: 'logo',
      ok() {
        if (company && company.id && state.company && state.company.id === company.id) {
          const newCompany = {
            ...state.company,
            ...company,
          };
          PWA.updateCachedApi(`admin/company/details?id=${company.id}`, newCompany);
        }
        if (callbackOk) callbackOk();
      },
      error: callbackError,
    });
  },
};

const mutations = {
  setCompany(state, company) {
    state.company = company;
  },
  setCompanyLoading(state, status) {
    state.companyLoading = status;
  },
  unsetCompany(state) {
    state.company = null;
  },
  setCompanySaving(state, status) {
    state.saving = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
