/* eslint no-console: off */
import config from '../config';

const Google = {
  GoogleAuth: null,
  init(ok) {
    if (window.cordova) return;
    if (!window.gapi) return;
    window.gapi.load('auth2', () => {
      window.gapi.auth2
        .init({
          client_id: config.googleAuth.clientId,
          fetch_basic_profile: true,
        })
        .then(
          (GoogleAuth) => {
            Google.GoogleAuth = GoogleAuth;
            if (ok) ok();
          },
          (err) => {
            console.log(err);
          }
        );
    });
  },
  googleGetUser(ok, error) {
    if (window.cordova) return;
    const GoogleAuth = Google.GoogleAuth;
    if (!GoogleAuth) {
      Google.init(() => {
        Google.googleGetUser(ok, error);
      });
      return;
    }
    const GoogleUser = GoogleAuth.currentUser.get();
    const BasicProfile = GoogleUser.getBasicProfile();
    const Auth = GoogleUser.getAuthResponse(true);
    const id = BasicProfile.getId();
    const givenName = BasicProfile.getGivenName();
    const familyName = BasicProfile.getFamilyName();
    const imageUrl = BasicProfile.getImageUrl();
    const email = BasicProfile.getEmail();
    if (ok) {
      ok({
        id,
        email,
        first_name: givenName,
        last_name: familyName,
        picture: imageUrl,
        accessToken: Auth.access_token,
      });
    }
  },
  googleLogin(ok, error) {
    if (window.cordova) return;
    const GoogleAuth = Google.GoogleAuth;
    if (!GoogleAuth) {
      Google.init(() => {
        Google.googleLogin(ok, error);
      });
      return;
    }
    GoogleAuth.signIn().then(() => {
      const GoogleUser = GoogleAuth.currentUser.get();
      const BasicProfile = GoogleUser.getBasicProfile();
      const Auth = GoogleUser.getAuthResponse(true);
      const id = BasicProfile.getId();
      const givenName = BasicProfile.getGivenName();
      const familyName = BasicProfile.getFamilyName();
      const imageUrl = BasicProfile.getImageUrl();
      const email = BasicProfile.getEmail();
      if (ok) {
        ok({
          id,
          email,
          first_name: givenName,
          last_name: familyName,
          picture: imageUrl,
          accessToken: Auth.access_token,
        });
      }
    });
  },
  googleGetLoginStatus(ok, error) {
    if (window.cordova) return;
    const GoogleAuth = Google.GoogleAuth;
    if (!GoogleAuth) {
      Google.init(() => {
        Google.googleGetLoginStatus(ok, error);
      });
      return;
    }
    if (GoogleAuth.isSignedIn.get()) {
      if (ok) ok();
    } else if (error) {
      error();
    }
  },
  login(ok, error) {
    if (window.cordova) {
      if (!window.plugins.googleplus) return;
      window.plugins.googleplus.login(
        {},
        (obj) => {
          if (ok) {
            ok({
              id: obj.userId,
              email: obj.email,
              first_name: obj.givenName,
              last_name: obj.familyName,
              picture: obj.imageUrl,
              accessToken: obj.accessToken,
            });
          }
        },
        (msg) => {
          if (error) error(msg);
        }
      );
      return;
    }
    Google.googleLogin(() => {
      Google.googleGetUser((user) => {
        if (ok) ok({ ...user });
      }, error);
    }, (err) => {
      if (error) error(err);
    });
  },
};

export default Google;
