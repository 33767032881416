import Request from './request';
import events from './events';
import config from '../config';

const RequestSetup = {
  init() {
    Request.setup({
      beforeCreate(params) {
        const token = window.localStorage.accessToken;
        if (!token) return;
        if (params.headers) {
          params.headers.Authorization = `Bearer ${token}`;
        } else {
          params.headers = {
            Authorization: `Bearer ${token}`,
          };
        }
      },
      beforeSend() {
        events.$emit('requestBeforeSend');
      },
      complete() {
        events.$emit('requestComplete');
      },
      error(xhr, status) {
        if (status === 401 && xhr.requestUrl && xhr.requestUrl.indexOf('auth/refresh') < 0 && localStorage.refreshToken) {
          Request({
            url: `${config.host}/api/common/auth/refresh`,
            method: 'post',
            dataType: 'json',
            data: JSON.stringify({
              refreshToken: localStorage.refreshToken,
            }),
            processData: false,
            contentType: 'application/json',
            success(response) {
              if (response.accessToken && response.refreshToken) {
                localStorage.accessToken = response.accessToken;
                localStorage.refreshToken = response.refreshToken;
                Request(xhr.requestParameters);
              }
            },
          });
        } else {
          events.$emit('showError', xhr, status);
        }
      },
    });
  },
};

export default RequestSetup;
