<template>
  <div class="profile-form center-content" :class="{ 'profile-form-disabled': disabled }">
    <f7-block strong class="profile-form-logo-inputs">
      <logo class="profile-form-logo" :logo="logo" :placeholder="true" :size="150">
        <label v-if="!disabled">
          <input type="file" ref="logo" @change="proceedLogo">
          <f7-icon ios="f7:camera_fill" md="material:camera_alt"></f7-icon>
        </label>
      </logo>
      <div class="profile-form-inputs">
        <f7-block v-if="disabled" class="text-align-center no-safe-areas">
          <p>{{$t('company_details.change_info')}}: <a href="mailto:team@finderscrowd.com" class="external" target="_systembrowser">team@finderscrowd.com</a></p>
        </f7-block>
        <f7-list no-hairlines class="no-safe-areas">
          <f7-list-item v-if="$root.userRole === 'admin'" :title="$t('company_details.enterprise_label')">
            <f7-toggle color="brand-orange" :checked="isEnterprise" @change="isEnterprise = $event.target.checked"></f7-toggle>
          </f7-list-item>

          <li class="assignment-share-out-input" v-if="$root.userRole === 'admin'">
            <div class="item-content">
              <div class="item-inner item-cell">
                <shareout-slider
                  :value="shareOut"
                  @change="setTempShareout($event)"
                  @changed="changeShareOut($event)"
                ></shareout-slider>
              </div>
            </div>
          </li>

          <f7-list-input
            v-if="$root.userRole === 'admin'"
            :label="$t('company_details.assignment_solved_email')"
            type="email"
            :value="assignmentSolvedEmail"
            @input="assignmentSolvedEmail = $event.target.value"
            validate
            validateOnBlur
          ></f7-list-input>

          <f7-list-input
            :label="$t('form.company_name')"
            :disabled="disabled" required validate type="text" :value="companyName" @input="companyName = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            :label="$t('form.address')"
            :disabled="disabled" type="text" :value="address" @input="address = $event.target.value" required validate
          ></f7-list-input>
          <f7-list-input
            :label="$t('form.postcode')"
            :disabled="disabled" type="text" :value="postcode" @input="postcode = $event.target.value" required validate
          ></f7-list-input>
          <f7-list-input
            :label="$t('form.city')"
            :disabled="disabled" type="text" :value="city" @input="city = $event.target.value" required validate
          ></f7-list-input>
          <f7-list-input
            :label="$t('form.country')"
            :disabled="disabled" type="text" :value="country" @input="country = $event.target.value" required validate
          ></f7-list-input>
          <f7-list-input
            :label="$t('form.email')"
            :disabled="disabled" type="email" :value="email" @input="email = $event.target.value" required validate validateOnBlur
          ></f7-list-input>
          <f7-list-input
            :label="$t('form.phone')"
            :disabled="disabled" type="tel" :value="phone" @input="phone = $event.target.value" required validate
          ></f7-list-input>
          <f7-list-input
            :label="$t('form.orgnr')"
            :disabled="disabled" type="text" :value="orgnr" @input="orgnr = $event.target.value" required validate
          ></f7-list-input>
          <f7-list-input
            :label="$t('form.homepage')"
            :disabled="disabled" type="url" :value="homepage" @input="homepage = $event.target.value" required v-validate-url pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$" :error-message="$t('error.correct_url')"
          ></f7-list-input>
        </f7-list>
      </div>
    </f7-block>

    <f7-block-title>{{$t('company_details.invoice_address_title')}}</f7-block-title>
    <f7-list no-hairlines>
      <f7-list-item checkbox :disabled="disabled" :class="{ disabled: disabled }" :checked="differentInvoiceAddress" @change="differentInvoiceAddress = $event.target.checked" :title="$t('company_details.invoice_address_different')"></f7-list-item>
    </f7-list>
    <f7-list v-if="differentInvoiceAddress" style="margin-top: -15px">
      <f7-list-input
        :label="$t('form.address')"
        :disabled="disabled" type="text" :value="invoiceAddress" @input="invoiceAddress = $event.target.value" required validate
      ></f7-list-input>
      <f7-list-input
        :label="$t('form.postcode')"
        :disabled="disabled" type="text" :value="invoicePostcode" @input="invoicePostcode = $event.target.value" required validate
      ></f7-list-input>
      <f7-list-input
        :label="$t('form.city')"
        :disabled="disabled" type="text" :value="invoiceCity" @input="invoiceCity = $event.target.value" required validate
      ></f7-list-input>
      <f7-list-input
        :label="$t('form.country')"
        :disabled="disabled" type="text" :value="invoiceCountry" @input="invoiceCountry = $event.target.value" required validate
      ></f7-list-input>
    </f7-list>

    <template v-if="showSettings">
      <f7-block-title>{{$t('company_details.settings_title')}}</f7-block-title>
      <f7-list no-hairlines>
        <f7-list-input
          :label="$t('form.currency')"
          :disabled="disabled"
          type="select"
          :value="currency"
          @change="currency = parseInt($event.target.value, 10)"
        >
          <option
            v-for="(curr, id) in currencyList"
            :key="id"
            :value="id"
          >{{curr}}</option>
        </f7-list-input>
        <f7-list-input
          :label="$t('form.max_number_interest')"
          :disabled="disabled" type="number" :value="interestLimit" @input="interestLimit = $event.target.value"
        ></f7-list-input>
      </f7-list>
    </template>
    <slot></slot>
  </div>
</template>
<script>
  import Croppie from 'croppie';
  import extend from '../../utils/extend';
  import defaultCurrency from '../../utils/default-currency';
  import currencyList from '../../utils/currency-list';

  export default {
    props: {
      details: Object,
      firstEdit: Boolean,
      disabled: Boolean,
      showSettings: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      const details = this.details || {};
      return extend({
        companyName: '',
        address: '',
        postcode: '',
        city: '',
        country: '',
        email: '',
        phone: '',
        orgnr: '',
        homepage: '',
        interestLimit: 100,
        currency: defaultCurrency,
        logo: null,
        isEnterprise: false,
        differentInvoiceAddress: false,
        invoiceAddress: '',
        invoicePostcode: '',
        invoiceCity: '',
        invoiceCountry: '',
        shareOut: 50,
        assignmentSolvedEmail: '',
        users: [{
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        }],
      }, details);
    },
    watch: {
      details() {
        const self = this;
        if (!self.details) return;
        const {
          companyName,
          address,
          postcode,
          city,
          country,
          email,
          phone,
          orgnr,
          homepage,
          interestLimit,
          currency,
          logo,
          users,
          isEnterprise,
          invoiceAddress,
          invoicePostcode,
          invoiceCity,
          invoiceCountry,
          shareOut,
          assignmentSolvedEmail,
        } = self.details;

        let {
          differentInvoiceAddress,
        } = self.details;

        if (typeof differentInvoiceAddress === 'undefined') differentInvoiceAddress = false;

        extend(self, {
          companyName,
          address,
          postcode,
          city,
          country,
          email,
          phone,
          orgnr,
          homepage,
          interestLimit,
          currency,
          logo,
          users,
          isEnterprise,
          differentInvoiceAddress,
          invoiceAddress,
          invoicePostcode,
          invoiceCity,
          invoiceCountry,
          shareOut,
          assignmentSolvedEmail,
        });
      },
    },
    computed: {
      currencyList() {
        return currencyList;
      },
    },
    mounted() {
      const self = this;
      self.$$(self.$el).on('input change', () => {
        self.$emit('formChanged');
      });
      self.$nextTick(() => {
        self.$f7.input.resizeTextarea(self.$el.querySelector('textarea'));
      });
    },
    methods: {
      setTempShareout(value) {
        const self = this;
        if (self.originalShareout === null) {
          if (self.shareOut === null || self.shareOut === undefined) {
            self.originalShareout = 50;
          } else {
            self.originalShareout = self.shareOut;
          }
        }
        self.shareOut = value;
      },
      changeShareOut(value) {
        const self = this;
        if (value === self.originalShareout) return;
        if (self.originalShareout === null) return;
        self.$f7.dialog.confirm(self.$t('general.are_you_sure'), () => {
          self.shareOut = value;
          self.originalShareout = null;
        }, () => {
          if (self.originalShareout === null || self.originalShareout === undefined) {
            self.shareOut = 50;
          } else {
            self.shareOut = self.originalShareout;
          }
          self.originalShareout = null;
          const range = self.$f7.range.get(self.$el.querySelector('.range-slider'));
          if (range) range.setValue(self.shareOut);
        });
      },
      openCropper(src) {
        const self = this;
        let croppie;

        function onResize() {
          croppie.setZoom(croppie._currentZoom); // eslint-disable-line
        }

        const popup = self.$f7.popup.create({
          backdrop: self.$$(self.$el).parents('.popup').length === 0,
          content: `
            <div class="popup popup-image-crop popup-tablet-fullscreen">
              <div class="view view-init" data-dynamic-navbar="false">
                <div class="page">
                  <div class="image-crop"></div>
                  <div class="croppie-toolbar toolbar tabbar toolbar-bottom no-hairline no-shadow">
                    <div class="toolbar-inner">
                      <a href="#" class="link popup-close" data-popup=".popup-image-crop">${self.$t('general.cancel')}</a>
                      <a href="#" class="link crop-image">${self.$t('general.done')}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          `.trim(),
          on: {
            open() {
              let CroppieConstructor = Croppie;
              if (CroppieConstructor.Croppie) CroppieConstructor = CroppieConstructor.Croppie;
              croppie = new CroppieConstructor(popup.$el.find('.image-crop')[0], {
                enableZoom: true,
                enableResize: true,
                enforceBoundary: true,
                showZoomer: true,
                enableExif: true,
                viewport: {
                  width: 120,
                  height: 120,
                  type: 'square',
                },
              });
              croppie.bind({ url: src, zoom: 0 });
              popup.$el.find('.cr-overlay').on('click', () => {
                if (self.$$('.popup-image-crop .toolbar').hasClass('toolbar-hidden')) {
                  self.$f7.toolbar.show('.popup-image-crop .toolbar');
                } else {
                  self.$f7.toolbar.hide('.popup-image-crop .toolbar');
                }
              });
              popup.$el.find('a.crop-image').on('click', () => {
                croppie.result({
                  type: 'base64',
                  size: 'original',
                  // size: {
                  //   width: 800,
                  // },
                  format: 'png',
                }).then((result) => {
                  self.logo = result;
                });
                popup.close();
              });
              self.$f7.on('resize', onResize);
            },
            closed() {
              self.$f7.off('resize', onResize);
              setTimeout(() => {
                popup.destroy();
                croppie.destroy();
              }, 100);
            },
          },
        });
        popup.open();
      },
      proceedLogo(event) {
        const self = this;
        const input = event.target;
        const file = input.files[0];
        if (file.type.indexOf('image') === 0) {
          const reader = new window.FileReader();
          reader.onload = ((e) => {
            self.openCropper(e.target.result);
            input.value = null;
          });
          reader.readAsDataURL(file);
        }
      },
      validate() {
        const self = this;
        self.$$('input[required]').each((inputIndex, inputEl) => {
          self.$f7.input.validate(inputEl);
        });
        if (self.$el.querySelectorAll('.item-input-invalid').length > 0) {
          self.$f7.input.scrollIntoView(self.$el.querySelectorAll('.item-input-invalid')[0], 300);
          return false;
        }
        return true;
      },
      getData() {
        const self = this;
        const {
          companyName,
          address,
          postcode,
          city,
          country,
          email,
          phone,
          orgnr,
          currency,
          interestLimit,
          users,
          logo,
          isEnterprise,
          differentInvoiceAddress,
          invoiceAddress,
          invoicePostcode,
          invoiceCity,
          invoiceCountry,
          shareOut,
          assignmentSolvedEmail,
        } = self;
        let homepage = self.homepage;
        if (homepage.indexOf('//') < 0) homepage = `http://${homepage}`;
        return {
          companyName,
          address,
          postcode,
          city,
          country,
          email,
          phone,
          orgnr,
          homepage,
          currency,
          interestLimit,
          users,
          logo,
          isEnterprise,
          differentInvoiceAddress,
          invoiceAddress,
          invoicePostcode,
          invoiceCity,
          invoiceCountry,
          shareOut,
          assignmentSolvedEmail,
        };
      },
    },
  };
</script>
