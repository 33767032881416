<template>
  <f7-page login-screen theme-dark>
    <div class="middle-content">
      <f7-login-screen-title v-if="$device.cordova">
        <div class="finders-logo-img">
          <img src="i/logo-white.svg">
        </div>
        <div class="finders-logo-text">
          <img src="i/logo-text-white.svg">
        </div>
      </f7-login-screen-title>
      <f7-login-screen-title v-else>
        <div class="finders-logo-img">
          <a href="//finderscrowd.com" class="external" target="_blank">
            <img src="/i/logo-white.svg">
          </a>
        </div>
        <div class="finders-logo-text">
          <a href="//finderscrowd.com" class="external" target="_blank">
            <img src="/i/logo-text-white.svg">
          </a>
        </div>
      </f7-login-screen-title>

      <template v-if="isFacebookLanding">
        <f7-block v-if="noRoleOptions">
          <p class="text-align-center signup-terms-text" v-html="$t('auth.login_terms_link')"></p>
          <loading-button class="facebook-button" large fill round preloader-color="white" @click="loginFacebook" :loading="facebookLoginIn" :disabled="loginIn || googleLoginIn">
            <f7-icon f7="logo_facebook"></f7-icon>
            <span>{{$t('auth.login_facebook_button')}}</span>
          </loading-button>
        </f7-block>
        <template v-else>
          <f7-block-header class="roles-picker-title">{{$t('auth.login_as')}}</f7-block-header>
          <f7-list inset class="roles-picker" color-theme="white">
            <loading-list-button
              v-for="(role, index) in roleOptions"
              :key="index"
              @click="selectRole(role)"
              :text="rolesMap(role)"
              color="white"
              :loading="selectedRole === role && loginIn"
              :disabled="selectedRole !== role && loginIn"
            ></loading-list-button>
          </f7-list>
        </template>
      </template>
      <template v-else>
        <f7-block class="auth-error-block" v-if="errorMessage"><span>{{errorMessage}}</span></f7-block>
        <f7-list class="inputs-list" v-if="noRoleOptions">
          <f7-list-input type="email" :placeholder="$t('auth.email_placeholder')" :value="email" @input="email = $event.target.value" @keypress.enter.native="onEnter"></f7-list-input>
          <f7-list-input type="password" :placeholder="$t('auth.password_placeholder')" :value="password" @input="password = $event.target.value" @keypress.enter.native="onEnter"></f7-list-input>
          <li v-if="$theme.ios"></li>
        </f7-list>
        <template v-else>
          <f7-block-header class="roles-picker-title">{{$t('auth.login_as')}}</f7-block-header>
          <f7-list inset class="roles-picker" color-theme="white">
            <loading-list-button
              v-for="(role, index) in roleOptions"
              :key="index"
              @click="selectRole(role)"
              :text="rolesMap(role)"
              color="white"
              :loading="selectedRole === role && loginIn"
              :disabled="selectedRole !== role && loginIn"
            ></loading-list-button>
          </f7-list>
        </template>

        <f7-block v-if="noRoleOptions">
          <loading-button large fill round preloader-color="brand-orange" @click="login" :loading="loginIn" :text="$t('auth.login_button')" :disabled="facebookLoginIn || googleLoginIn"></loading-button>
          <div class="login-buttons-divider"><span>{{$t('general.or')}}</span></div>
          <loading-button class="facebook-button" large fill round preloader-color="white" @click="loginFacebook" :loading="facebookLoginIn" :disabled="loginIn || googleLoginIn">
            <f7-icon f7="logo_facebook"></f7-icon>
            <span>{{$t('auth.login_facebook_button')}}</span>
          </loading-button>
          <loading-button class="google-button" large fill round preloader-color="black" @click="loginGoogle" :loading="googleLoginIn" :disabled="loginIn || facebookLoginIn">
            <f7-icon icon="icon-google"></f7-icon>
            <span>{{$t('auth.login_google_button')}}</span>
          </loading-button>
        </f7-block>
        <f7-block v-if="noRoleOptions">
          <f7-block-footer><f7-link reload-current :href="`/signup/?email=${email}`">{{$t('auth.signup_link')}}</f7-link> &nbsp;/&nbsp; <f7-link reload-current href="/forgot-password/">{{$t('auth.forgot_password_link')}}</f7-link></f7-block-footer>
        </f7-block>
      </template>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  // import events from '../../modules/events';
  import Facebook from '../../modules/facebook';
  import Google from '../../modules/google';

  export default {
    props: {
      assignmentRedirectId: [String, Number],
      forceEmail: String,
      forceSelectedRole: String,
      forceRoleOptions: Array,
      forceFacebookId: [String, Number],
      forceFacebookAccessToken: [String, Number],
      forceGoogleId: [String, Number],
      forceGoogleAccessToken: [String, Number],
    },
    data() {
      const self = this;
      return {
        email: self.forceEmail || self.$f7route.query.email || window.localStorage.userEmail || '',
        password: '',
        roleOptions: self.forceRoleOptions || [],
        selectedRole: self.forceSelectedRole || null,
        facebookId: self.forceFacebookId || null,
        facebookAccessToken: self.forceFacebookAccessToken || null,
        facebookLoginIn: false,
        facebookUser: null,
        googleId: self.forceGoogleId || null,
        googleAccessToken: self.forceGoogleAccessToken || null,
        googleLoginIn: false,
        googleUser: null,
        errorMessage: null,
        isFacebookLanding: self.$f7route.query && ('fb' in self.$f7route.query),
      };
    },
    computed: {
      noRoleOptions() {
        return !this.roleOptions || !this.roleOptions.length;
      },
      ...mapGetters({
        loginIn: 'user/loginIn',
      }),
    },
    mounted() {
      const self = this;
      Google.googleGetLoginStatus(() => {
        Google.googleGetUser((user) => {
          self.googleUser = user;
        });
      });
      Facebook.fbGetLoginStatus((res) => {
        Facebook.fbGetUser(res.authResponse.userID, (user) => {
          self.facebookUser = {
            ...user,
            accessToken: res.authResponse.accessToken,
          };
        });
      });
    },
    methods: {
      showError(e) {
        let message = e;
        if (!message) return;
        if (message && typeof message !== 'string' && 'responseText' in message && !message.responseText) return;
        if (message.responseText) message = message.responseText;
        if (typeof message === 'string' && message.indexOf('{') === 0) {
          message = JSON.parse(message).message;
        }
        if (!message) return;
        this.errorMessage = message;
      },
      hideError() {
        this.errorMessage = null;
      },
      selectRole(role) {
        const self = this;
        self.selectedRole = role;
        if (self.facebookAccessToken) self.loginFacebook();
        else if (self.googleAccessToken) self.loginGoogle();
        else self.login();
      },
      rolesMap(role) {
        return this.$t(`roles.${['Finder', 'Company', 'Admin'][role]}`);
      },
      onEnter() {
        const self = this;
        self.login();
      },
      onLogin(response) {
        const self = this;
        localStorage.visited = true;
        self.$root.setLanguageOnProfileRequest = true;
        if (response.language) {
          self.$root.setLanguage(response.language);
        }
        if (response && response.accessToken) {
          window.localStorage.accessToken = response.accessToken;
          window.localStorage.userRole = response.claims.role;
          window.localStorage.refreshToken = response.refreshToken;
          const confirmed = response.claims.confirmed;
          if (confirmed && confirmed !== 'false') {
            window.localStorage.userConfirmed = 'true';
          }
          let redirectUrl = localStorage.lastCrowdId ? `/crowd/${localStorage.lastCrowdId}/` : undefined;
          let redirectProps;
          if (self.assignmentRedirectId) {
            redirectUrl = `/assignment/${self.assignmentRedirectId}/`;
            redirectProps = { scrollToRecommendations: true };
          } else if (response.redirect) {
            redirectUrl = response.redirect;
          }
          self.$root.login();
          if (redirectUrl) {
            setTimeout(() => {
              const needReloadAll = redirectUrl.match(/\/crowd\/[0-9]*[/]?/) && redirectUrl.match(/\/crowd\/[0-9]*[/]?/)[0] === redirectUrl;
              if (redirectProps) {
                self.$f7.views.main.router.navigate(redirectUrl, { props: redirectProps, reloadAll: needReloadAll });
              } else {
                self.$f7.views.main.router.navigate(redirectUrl, { reloadAll: needReloadAll });
              }
            }, 300);
          }
        }
      },
      loginFacebook() {
        const self = this;
        self.hideError();

        function sendData() {
          const { id: facebookId, email, first_name, last_name, picture, accessToken } = self.facebookUser;
          self.$store.dispatch('user/facebookLogin', {
            facebookId,
            email,
            first_name,
            last_name,
            picture,
            role: self.selectedRole,
            facebookAccessToken: accessToken,
            language: self.$root.language,
            callbackOk(response) {
              if (response && response.roleOptions && response.roleOptions.length) {
                if (response.accessToken) {
                  window.localStorage.accessToken = response.accessToken;
                }
                if (response.refreshToken) {
                  window.localStorage.refreshToken = response.refreshToken;
                }
                self.roleOptions = response.roleOptions;
                self.selectedRole = self.roleOptions[0];
                self.email = email;
                self.facebookId = facebookId;
                self.facebookAccessToken = accessToken;
                return;
              }
              self.facebookLoginIn = false;
              self.onLogin(response);
            },
            callbackError(e) {
              self.facebookLoginIn = false;
              self.showError(e);
            },
          });
        }
        self.facebookLoginIn = true;
        if (self.facebookUser) {
          sendData();
          return;
        }
        Facebook.login((user) => {
          self.facebookUser = user;
          sendData();
        }, () => {
          self.facebookLoginIn = false;
        });
      },
      loginGoogle() {
        const self = this;
        self.hideError();

        function sendData() {
          const { id: googleId, email, first_name, last_name, picture, accessToken } = self.googleUser;
          self.$store.dispatch('user/googleLogin', {
            googleId,
            email,
            first_name,
            last_name,
            picture,
            role: self.selectedRole,
            googleAccessToken: accessToken,
            language: self.$root.language,
            callbackOk(response) {
              if (response && response.roleOptions && response.roleOptions.length) {
                if (response.accessToken) {
                  window.localStorage.accessToken = response.accessToken;
                }
                if (response.refreshToken) {
                  window.localStorage.refreshToken = response.refreshToken;
                }
                self.roleOptions = response.roleOptions;
                self.selectedRole = self.roleOptions[0];
                self.email = email;
                self.googleId = googleId;
                self.googleAccessToken = accessToken;
                return;
              }
              self.googleLoginIn = false;
              self.onLogin(response);
            },
            callbackError(e) {
              self.googleLoginIn = false;
              self.showError(e);
            },
          });
        }
        self.googleLoginIn = true;
        if (self.googleUser) {
          sendData();
          return;
        }
        Google.login((user) => {
          self.googleUser = user;
          sendData();
        }, () => {
          self.googleLoginIn = false;
        });
      },
      login() {
        const self = this;
        const { email, password } = self;
        self.hideError();
        if (self.selectedRole === null && (!email.trim() || !password.trim())) {
          self.showError(self.$t('error.email_password_required'));
          return;
        }
        window.localStorage.userEmail = email;

        if (self.selectedRole !== null && self.roleOptions.length) {
          self.$store.dispatch('user/login', {
            email,
            password,
            role: self.selectedRole,
            language: self.$root.language,
            callbackOk(response) {
              self.onLogin(response);
            },
            callbackError(e) {
              self.showError(e);
              // error
            },
          });
          return;
        }

        self.$store.dispatch('user/login', {
          email,
          password,
          role: null,
          language: self.$root.language,
          callbackOk(response) {
            if (response && response.roleOptions) {
              self.roleOptions = response.roleOptions;
              self.selectedRole = self.roleOptions[0];
              return;
            }
            self.onLogin(response);
          },
          callbackError(e) {
            self.showError(e);
            // error
          },
        });
      },
    },
  };
</script>
