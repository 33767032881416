import events from '../modules/events';

export default {
  beforeCreate() {
    const self = this;
    self.dispatchWithRetry = function dispatchWithRetry(...args) {
      function dispatch() {
        self.$store.dispatch(...args);
      }
      if (self.$root.online) {
        dispatch();
        return;
      }
      events.$once('online', dispatch);
      self.$once('beforeDestroy', () => {
        events.$off('online', dispatch);
      });
      // call dispatch to get cached version
      dispatch();
    };
  },
  beforeDestroy() {
    const self = this;
    self.$emit('beforeDestroy');
  },
};
