<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-title>
        <div class="app-menu-navbar-logo"></div>
      </f7-nav-title>
    </f7-navbar>
    <f7-list>
      <f7-list-item
        :title="$t('app_menu.notifications_link')"
        link="/notifications/"
        :reload-all="true"
        panel-close
        :class="{'app-menu-active': activeMenu === 'notifications'}"
      >
        <f7-icon slot="media" ios="f7:bell_fill" md="material:notifications" />
        <f7-badge slot="after" v-if="$root.notificationsCount" color="brand-red">{{$root.notificationsCount}}</f7-badge>
      </f7-list-item>
      <f7-list-item
        :title="$t('app_menu.my_assignments_link_' + $root.userRole)"
        link="/my-assignments/"
        :reload-all="true"
        panel-close
        :class="{'app-menu-active': activeMenu === 'my-assignments'}"
      >
        <f7-icon slot="media" ios="f7:document_text_fill" md="material:assignment" />
      </f7-list-item>
    </f7-list>

    <f7-block-title>
      <span>{{$t('app_menu.crowds_title')}}</span>
      <f7-button
        v-if="$root.userRole === 'company'"
        :reload-all="true"
        href="/crowd/new/"
        panel-close
        small
        round
        fill
      >{{$t('app_menu.add_crowd_button')}}</f7-button>
      <f7-button
        v-if="$root.userRole === 'finder'"
        :reload-all="true"
        href="/crowd/join/"
        panel-close
        small
        round
        fill
      >{{$t('app_menu.join_crowd_button')}}</f7-button>
    </f7-block-title>
    <f7-list class="crowds-list">
      <f7-list-item
        link="/"
        :reload-all="true"
        :title="$t('app_menu.public_crowds_link')"
        panel-close
        :class="{'app-menu-active': activeMenu === 'public'}"
      >
        <div class="public-crowds-logo logo" slot="media"></div>
      </f7-list-item>
      <f7-list-item
        v-for="(crowd, index) in crowdList"
        :key="`${index}-${crowd.id}`"
        :link="`/crowd/${crowd.id}/`"
        :route-props="{
          crowdPassed: crowd,
        }"
        :reload-all="true"
        :title="crowd.name"
        :class="{'app-menu-active': activeMenu === crowd.id}"
        panel-close
      >
        <logo slot="media" :size="34" :logo="crowd.logo" />
        <f7-badge slot="after" v-if="crowd.isJoined === false" color="brand-orange">1</f7-badge>
      </f7-list-item>
    </f7-list>

  </f7-page>
</template>

<script>
  import mapRoleGetters from '../../utils/map-role-getters';
  import events from '../../modules/events';

  export default {
    data() {
      return {
        activeMenu: null,
      };
    },
    computed: {
      ...mapRoleGetters({
        profile: 'profile',
        crowdList: 'crowdList',
      }),
    },
    watch: {
      profile() {
        const self = this;
        if (self.profile && self.$root.setLanguageOnProfileRequest && self.profile.language) {
          self.$root.setLanguage(self.profile.language);
          self.$root.setLanguageOnProfileRequest = false;
        }
        self.$root.userProfile = self.profile;
      },
    },
    created() {
      const self = this;
      self.$store.dispatch(`${self.$root.storeRolePrefix}/getProfile`);
      self.$store.dispatch(`${self.$root.storeRolePrefix}/getMyCrowdList`);
    },
    mounted() {
      const self = this;
      events.$on('removedFromCrowd', self.removeFromCrowd);
      events.$on('refreshCrowdList', self.refreshCrowdList);
      events.$on('setActiveMenu', self.setActiveMenu);
    },
    methods: {
      setActiveMenu(activeMenu) {
        const self = this;
        self.activeMenu = activeMenu;
      },
      refreshCrowdList(callback) {
        const self = this;
        Promise.all([
          new Promise((resolve, reject) => {
            self.$store.dispatch(`${self.$root.storeRolePrefix}/getProfile`, {
              callbackOk() { resolve(); },
            });
          }),
          new Promise((resolve, reject) => {
            self.$store.dispatch(`${self.$root.storeRolePrefix}/getMyCrowdList`, {
              callbackOk() { resolve(); },
            });
          }),
        ]).then(() => {
          if (callback) callback();
        });
      },
      removeFromCrowd(crowdId) {
        const self = this;
        if (self.activeMenu === crowdId) {
          self.$f7.views.main.router.navigate('/', { reloadAll: true });
        }
      },
    },

  };
</script>
