<template>
  <f7-page>
    <f7-navbar :title="$t('about.title')" large>
      <f7-link slot="nav-left" back icon="icon-back" :text="$t('general.back')"></f7-link>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <div class="center-content">
      <f7-block strong class="about-text" v-html="$t('about.text')"></f7-block>
      <f7-list>
        <f7-list-item
          :title="$t('about.privacy_link')"
          link="https://finderscrowd.com/privacy/"
          external
          :target="$externalTarget"
        ></f7-list-item>
        <f7-list-item
          :title="$t('about.terms_link')"
          link="https://finderscrowd.com/terms/"
          external
          :target="$externalTarget"
        ></f7-list-item>
      </f7-list>
    </div>
  </f7-page>
</template>
<script>
  export default {};
</script>
