import linkify from './linkify';

export default function (text) {
  if (!text) return [];
  const { data = [] } = linkify(text, true);
  return data.map((item) => {
    let obj;
    if (item.image) {
      obj = {
        imageUrl: item.image,
        type: 'image',
        pb: {
          url: item.image,
        },
      };
    }
    if (item.yt) {
      obj = {
        yt: item.yt,
        imageUrl: `https://img.youtube.com/vi/${item.yt}/hqdefault.jpg`,
        type: 'video',
        pb: {
          html: `<iframe src="${window.cordova ? 'https:' : ''}//www.youtube.com/embed/${item.yt}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`,
        },
      };
    }
    if (item.vimeo) {
      obj = {
        vimeo: item.vimeo,
        type: 'video',
        pb: {
          html: `<iframe src="${window.cordova ? 'https:' : ''}//player.vimeo.com/video/${item.vimeo}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`,
        },
      };
    }
    if (item.dreamBroker) {
      obj = {
        dreamBroker: item.dreamBroker,
        type: 'video',
        pb: {
          html: `<iframe src="${window.cordova ? 'https:' : ''}//dreambroker.com/channel/${item.dreamBroker[0]}/iframe/${item.dreamBroker[1]}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`,
        },
      };
    }

    return obj;
  });
}
