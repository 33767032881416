/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../modules/api';

const clientMessages = {};

const state = {};
const getters = {};
const mutations = {};
const actions = {
  getMessages({ commit, state }, {
    assignmentId,
    companyId,
    finderId,
    guid,
    from,
    to,
    setLoading,
    cache,
    callbackOk,
    callbackError,
  }) {
    commit(`setSkip${from}${to}`, 0);
    const skip = state[`skip${from}${to}`];
    const take = state[`take${from}${to}`];

    API.get({
      url: `${from.toLowerCase()}/message/list`,
      data: { skip, take, assignmentId, companyId, finderId, guid, toType: to },
      loading: setLoading ? `m/setLoading${from}${to}` : null,
      cache,
      ok(response) {
        commit(`setMessages${from}${to}`, response.messages);
        if (callbackOk) callbackOk(response.messages);
      },
      error: callbackError,
    });
  },
  getMoreMessages({ commit, state }, {
    assignmentId,
    companyId,
    finderId,
    guid,
    from,
    to,
    setLoading,
    callbackOk,
    callbackError,
  }) {
    const skip = state[`skip${from}${to}`];
    const take = state[`take${from}${to}`];
    const newSkip = skip + take;
    commit(`setSkip${from}${to}`, newSkip);

    API.get({
      url: `${from.toLowerCase()}/message/list`,
      data: { skip: newSkip, take, assignmentId, companyId, finderId, guid, toType: to },
      loading: `m/setMoreLoading${from}${to}${setLoading ? ` m/setLoading${from}${to}` : ''}`,
      ok(response) {
        commit(`addToMessages${from}${to}`, response.messages);
        if (callbackOk) callbackOk(response.messages);
      },
      error: callbackError,
    });
  },
  unsetMessages({ commit }, { from, to }) {
    commit(`unsetMessages${from}${to}`);
  },
  sendMessage({ commit }, {
    assignmentId,
    companyId,
    finderId,
    guid,
    from,
    to,
    message,
    callbackOk,
    callbackError,
  }) {
    const { clientId } = message;
    commit(`addMessage${from}${to}`, message);
    API.post({
      url: `${from.toLowerCase()}/message/save`,
      data: {
        assignmentId,
        companyId,
        finderId,
        guid,
        from,
        toType: to,
        message,
      },
      loading: `m/setSending${from}${to}`,
      ok(response) {
        commit(`updateMessage${from}${to}`, { clientId, newMessage: response });
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  getMessagesParties({ commit }, { assignmentId, guid, from, callbackOk, callbackError }) {
    API.get({
      url: `${from.toLowerCase()}/message/parties/`,
      data: { assignmentId, guid },
      loading: `m/setPartiesLoading${from}`,
      ok(response) {
        commit(`setMessagesParties${from}`, response);
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
};

['Finder', 'Candidate', 'Company'].forEach((from) => {
  Object.assign(state, {
    [`messagesParties${from}`]: null,
    [`messagesPartiesLoading${from}`]: false,
  });
  Object.assign(getters, {
    [`messagesParties${from}`]: function getMessagesParties(state) {
      return state[`messagesParties${from}`];
    },
    [`messagesPartiesLoading${from}`]: function getMessagesParties(state) {
      return state[`messagesPartiesLoading${from}`];
    },
  });
  Object.assign(mutations, {
    [`setMessagesParties${from}`]: function setMessages(state, items) {
      state[`messagesParties${from}`] = items;
    },
    [`setPartiesLoading${from}`]: function setMessages(state, status) {
      state[`messagesPartiesLoading${from}`] = status;
    },
  });
});

['FinderCandidate', 'FinderCompany', 'CompanyCandidate', 'CompanyFinder', 'CandidateFinder', 'CandidateCompany'].forEach((fromTo) => {
  Object.assign(state, {
    [`messages${fromTo}`]: null,
    [`loading${fromTo}`]: false,
    [`sending${fromTo}`]: false,
    [`moreLoading${fromTo}`]: false,
    [`skip${fromTo}`]: 0,
    [`take${fromTo}`]: 20,
  });

  Object.assign(getters, {
    [`messages${fromTo}`]: function getMessages(state) {
      return state[`messages${fromTo}`];
    },
    [`loading${fromTo}`]: function getLoading(state) {
      return state[`loading${fromTo}`];
    },
    [`moreLoading${fromTo}`]: function getLoading(state) {
      return state[`moreLoading${fromTo}`];
    },
    [`sending${fromTo}`]: function getLoading(state) {
      return state[`sending${fromTo}`];
    },
  });

  function mergeToMessages(items) {
    const messages = state[`messages${fromTo}`];
    const allMessages = [...messages, ...items];
    const newMessages = [];
    const ids = [];
    allMessages.forEach((m) => {
      const { id, clientId } = m;
      if (id && ids.indexOf(id) >= 0) return;
      if (clientId && ids.indexOf(clientId) >= 0) return;
      if (id) ids.push(id);
      if (clientId) ids.push(clientId);
      newMessages.push(m);
    });

    state[`messages${fromTo}`] = newMessages;
  }

  Object.assign(mutations, {
    [`setMessages${fromTo}`]: function setMessages(state, items) {
      const messages = state[`messages${fromTo}`];
      items.forEach((item) => {
        if (clientMessages[item.id]) {
          item.clientId = clientMessages[item.id];
        }
      });
      if (!messages || messages && messages.length === 0) {
        state[`messages${fromTo}`] = items;
        return;
      }
      mergeToMessages(items);
    },
    [`addToMessages${fromTo}`]: function setMessages(state, items) {
      items.forEach((item) => {
        if (clientMessages[item.id]) {
          item.clientId = clientMessages[item.id];
        }
      });
      mergeToMessages(items);
    },
    [`unsetMessages${fromTo}`]: function unsetMessages(state) {
      state[`skip${fromTo}`] = 0;
      state[`messages${fromTo}`] = null;
    },
    [`setSkip${fromTo}`]: function setLoading(state, value) {
      state[`skip${fromTo}`] = value;
    },
    [`setLoading${fromTo}`]: function setLoading(state, status) {
      state[`loading${fromTo}`] = status;
    },
    [`setMoreLoading${fromTo}`]: function setLoading(state, status) {
      state[`moreLoading${fromTo}`] = status;
    },
    [`setSending${fromTo}`]: function setSaving(state, status) {
      state[`sending${fromTo}`] = status;
    },
    [`addMessage${fromTo}`]: function addMessage(state, message) {
      state[`messages${fromTo}`].push(message);
    },
    [`updateMessage${fromTo}`]: function updateMessage(state, { clientId, newMessage }) {
      clientMessages[newMessage.id] = clientId;
      state[`messages${fromTo}`].forEach((message, index) => {
        if (message.clientId && message.clientId === clientId) {
          state[`messages${fromTo}`][index] = {
            clientId,
            ...newMessage,
          };
        }
      });
    },
  });
});

export default {
  state,
  getters,
  actions,
  mutations,
};
