<template>
  <table>
    <thead>
      <tr>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('id')"
          @click="sort('id')"
        >
          <span>Id</span>
          <!-- <data-table-filter max-width="90" type="number" :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('name')"
          @click="sort('name')"
        >
          <span>{{$t('general.name')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('email')"
          @click="sort('email')"
        >
          <span>{{$t('general.email')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('finder')"
          @click="sort('finder')"
        >
          <span>{{$t('general.finder')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('company')"
          @click="sort('company')"
        >
          <span>{{$t('general.company')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('assignment')"
          @click="sort('assignment')"
        >
          <span>{{$t('general.assignment')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('status')"
          @click="sort('status')"
        >
          <span>{{$t('general.status')}}</span>
          <!-- <data-table-filter type="select" :clear-button="false">
            <option :value="null">{{$t(`candidate_status.All`)}}</option>
            <option
              v-for="(id, key) in statusList"
              :key="id"
              :value="id"
            >{{$t(`candidate_status.${key}`)}}</option>
          </data-table-filter> -->
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('createdDate')"
          @click="sort('createdDate')"
        >
          <span>{{$t('general.created')}}</span>
          <!-- <data-table-filter max-width="140" :placeholder="$t('general.filter')" calendar></data-table-filter> -->
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('openedDate')"
          @click="sort('openedDate')"
        >
          <span>{{$t('general.opened')}}</span>
          <!-- <data-table-filter max-width="140" :placeholder="$t('general.filter')" calendar></data-table-filter> -->
        </th>
        <th class="actions-cell"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="candidate in candidates" :key="candidate.key">
        <td>{{candidate.id}}</td>
        <td class="nowrap">{{candidate.firstName}} {{candidate.lastName}}</td>
        <td>{{candidate.email}}</td>
        <td class="nowrap"><f7-link :href="`/users/edit/${candidate.finderUserId}/`">{{candidate.finderFirstName}} {{candidate.finderLastName}}</f7-link></td>
        <td class="nowrap"><f7-link :href="`/companies/edit/${candidate.companyId}/`">{{candidate.companyName}}</f7-link></td>
        <td><f7-link :href="`/assignments/edit/${candidate.assignmentId}/`">{{candidate.assignmentTitle}}</f7-link></td>
        <td>{{$t(`candidate_status.${statusMap(candidate.status)}`)}}</td>
        <td class="nowrap">{{formatDate(candidate.createdDate)}}</td>
        <td class="nowrap">{{formatDate(candidate.openedDate)}} {{formatTime(candidate.openedDate)}}</td>
        <td class="actions-cell">
          <f7-link :href="`/candidates/edit/${candidate.id}/`" text-color="brand-green" icon-ios="f7:edit" icon-md="material:mode_edit"></f7-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import statusList from '../../utils/candidate-status-list';
  import statusMap from '../../utils/candidate-status-map';
  import formatDate from '../../utils/format-date';
  import formatTime from '../../utils/format-time';
  import tableMixin from './table-mixin';

  export default {
    mixins: [tableMixin],
    props: {
      candidates: Array,
      sortBy: String,
      sortOrder: String,
    },
    data() {
      return {
        statusList,
      };
    },
    methods: {
      sortableClasses(by) {
        const self = this;
        const { sortBy, sortOrder } = self;
        return {
          'sortable-cell-active': sortBy === by,
          'sortable-desc': sortBy === by && sortOrder === 'desc',
          'sortable-asc': sortBy === by && sortOrder === 'asc' || sortBy !== by,
        };
      },
      sort(by) {
        const self = this;
        let order = self.sortOrder;
        if (self.sortBy === by) {
          if (order === 'desc') order = 'asc';
          else order = 'desc';
        } else {
          order = 'asc';
        }
        self.$emit('sortChange', by, order);
      },
      formatDate(date) {
        if (!date) return '';
        return formatDate(date, this.$root.language);
      },
      formatTime(date) {
        if (!date) return '';
        return formatTime(date);
      },
      statusMap,
    },
  };
</script>
