// import events from './events';
import config from '../config';

const PWA = {
  skipWaitingSent: false,
  registration: null,
  serviceWorkerPendingUpdate: false,
  getCachedApi(url, callback) {
    if (!('caches' in window)) {
      if (callback) callback();
      return;
    }
    window.caches.match(url).then((response) => {
      if (response) {
        response.json().then((json) => {
          if (callback) callback(json);
        });
      } else if (callback) {
        callback();
      }
    });
  },
  removeCache(callback) {
    if (!('caches' in window)) {
      if (callback) callback();
      return;
    }
    window.caches.keys()
      .then((keys) => {
        return Promise.all(keys.map((key => window.caches.delete(key))))
      })
      .then(() => {
        if (callback) callback();
      });
  },
  removeCachedApi(callback) {
    if (!('caches' in window)) {
      if (callback) callback();
      return;
    }
    window.caches.keys()
      .then((keys) => {
        let apiKey;
        keys.forEach((key) => {
          if (key.indexOf('fc-api') === 0) apiKey = key;
        });
        return Promise.resolve(apiKey);
      })
      .then(key => window.caches.open(key))
      .then((cache) => {
        return new Promise((resolve, reject) => {
          cache.keys().then((keys) => {
            Promise.all(keys.map(key => cache.delete(key))).then(() => {
              resolve();
            });
          });
        });
      })
      .then(() => {
        if (callback) callback();
      });
  },
  updateCachedApi(apiUrl, newData = {}, callback) {
    if (!('caches' in window)) {
      if (callback) callback();
      return;
    }
    const fullApiUrl = `${config.host}/api${apiUrl[0] === '/' ? '' : '/'}${apiUrl}`;

    window.caches.keys()
      .then((keys) => {
        let apiKey;
        keys.forEach((key) => {
          if (key.indexOf('fc-api') === 0) apiKey = key;
        });
        return Promise.resolve(apiKey);
      })
      .then(key => window.caches.open(key))
      .then(cache => Promise.all([cache.match(fullApiUrl), cache]))
      .then(([response, cache]) => {
        if (response) {
          response.json().then((data) => {
            const { ok, redirected, status, statusText, type, url, headers } = response;
            const newResponse = new Response(
              JSON.stringify({ ...data, ...newData }),
              {
                ok,
                redirected,
                status,
                statusText,
                type,
                url,
                headers: {
                  ...headers,
                },
              }
            );
            cache.put(fullApiUrl, newResponse.clone()).then(() => {
              if (callback) callback();
            });
          });
        } else if (callback) {
          callback();
        }
      });
  },
  listenForWaitingServiceWorker(reg) {
    function awaitStateChange() {
      // eslint-disable-next-line
      reg.installing.addEventListener('statechange', function() {
        if (this.state === 'installed') PWA.promptUserToRefresh(reg);
      });
    }
    if (!reg) return;
    if (reg.waiting) {
      PWA.promptUserToRefresh(reg);
      return;
    }
    if (reg.installing) awaitStateChange();
    reg.addEventListener('updatefound', awaitStateChange);
  },
  promptUserToRefresh(registration) {
    if (!(registration && registration.waiting)) return;

    PWA.skipWaitingSent = true;
    registration.waiting.postMessage('skipWaiting');
    PWA.serviceWorkerPendingUpdate = true;

    // events.$emit('showAppUpdateMessage', () => {
    //   PWA.skipWaitingSent = true;
    //   if (!(registration && registration.waiting)) return;
    //   registration.waiting.postMessage('skipWaiting');
    // });
  },
  init(f7) {
    f7.serviceWorker.register('/service-worker.js').then((registration) => {
      PWA.registration = registration;
      PWA.listenForWaitingServiceWorker(registration);
    });
    if ('serviceWorker' in window.navigator) {
      let refreshing;
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (refreshing || !PWA.skipWaitingSent) return;
        refreshing = true;
        // window.location.reload();
      });
    }
  },
};

export default PWA;
