export default function (getters) {
  const r = {};
  Object.keys(getters).forEach((key) => {
    const getter = getters[key];
    r[key] = function get() {
      const self = this;
      const prefix = self.$root.storeRolePrefix;
      return self.$store.getters[`${prefix ? `${prefix}/` : ''}${getter}`];
    }
  });

  return r;
};
