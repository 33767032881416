import $ from 'dom7';
import Firebase from './modules/firebase';
import Push from './modules/push';
import briefWidget from './modules/brief-widget';
import Notifications from './modules/notifications';
import RequestSetup from './modules/request-setup';
import events from './modules/events';
import VueApp from './vue-app';
import routes from './pages/routes';
import Activity from './modules/activity';
import Sync from './modules/sync';
import progress from './modules/progress';

const App = {
  f7: {},
  notify(message) {
    if (!App.f7.toast) {
      events.$once('f7ready', () => {
        setTimeout(() => {
          App.notify(message);
        });
      });
      return;
    }
    const toast = App.f7.toast.create({
      text: message,
      closeTimeout: 4 * 1000, // 4 seconds
      closeButton: true,
      closeButtonText: 'Ok',
      once: {
        closed() {
          setTimeout(() => {
            toast.destroy();
          }, 0);
        },
      },
    });
    toast.open();
  },
  alert(message) {
    App.f7.dialog.alert(message);
  },
  showError(xhr, status) {
    if (status === 404) return;
    if (status === 500) return;
    let message;

    if (xhr && xhr.responseText) {
      try {
        message = JSON.parse(xhr.responseText).message;
      } catch (e) {
        // no json in response
      }
    } else if (typeof xhr === 'string') {
      message = xhr;
    }
    if (status === 401) {
      if (!message) {
        message = App.vue.$t('error.unauthorized');
      }
      if (xhr && xhr.requestUrl && xhr.requestUrl.indexOf('common/activity/track') > 0) message = null;
      else App.logout();
    }
    if (!message) return;
    if (xhr && xhr.requestUrl) {
      if (xhr.requestUrl.indexOf('common/auth/') >= 0) {
        return;
      }
      if (xhr.requestUrl.indexOf('candidate/application/details') >= 0 && status === 422) {
        App.f7.dialog.alert(message);
        return;
      }
    }

    if (status === 422) {
      let preventUnauthorizedAlert;
      events.$emit('unauthorized-error', () => {
        preventUnauthorizedAlert = true;
      });
      if (preventUnauthorizedAlert) return;
    }

    App.notify(message);
  },
  showOfflineError() {
    const message = App.vue.$t('network.api_offline_error');
    App.notify(message);
  },
  login(accessToken, userRole) {
    if (accessToken) {
      window.localStorage.accessToken = accessToken;
    }
    if (userRole) {
      window.localStorage.userRole = userRole;
    }
    App.vue.userRole = window.localStorage.userRole;
    App.vue.userConfirmed = window.localStorage.userConfirmed;
    App.vue.loggedIn = true;
    App.vue.justLoggedIn = true;
    App.f7.params.input.scrollIntoViewAlways = false;
    $('html').removeClass('with-modal-loginscreen');
    Activity.track();

    if (App.vue.userRole !== 'admin') {
      briefWidget();
    }

    if (!window.cordova) {
      Firebase.run();
    } else {
      Push.init();
    }
    events.$emit('loggedIn');
  },
  logout() {
    delete window.localStorage.accessToken;
    delete window.localStorage.refreshToken;
    delete window.localStorage.userRole;
    delete window.localStorage.userProfile;
    delete window.localStorage.userConfirmed;
    delete window.localStorage.lastCrowdId;

    events.$emit('logedOut');
    if (window.cordova) {
      App.vue.loggedIn = false;
      App.vue.userRole = null;
      if (App.f7.device.android) {
        App.f7.params.input.scrollIntoViewAlways = true;
      }
      $('html').removeClass('with-panel with-panel-left-reveal');
    } else {
      window.location = '/';
    }
  },
  changeRole(newRole, newRoutes, redirect) {
    window.localStorage.userRole = newRole;
    delete localStorage.lastCrowdId;
    if (newRoutes == null) {
      // eslint-disable-next-line
      newRoutes = routes(newRole);
    }
    if (window.cordova) {
      App.vue.userRoleChanging = true;
      App.vue.userRole = newRole;
      App.f7.routes = newRoutes || [];
      App.f7.params.routes = newRoutes || [];
      if (App.f7.views.main) {
        App.f7.views.main.routes = newRoutes || [];
        App.f7.views.main.router.routes = newRoutes || [];
      }
      $('html').removeClass('with-panel with-panel-left-reveal');
      App.vue.$nextTick(() => {
        App.vue.userRoleChanging = false;
        if (!redirect) return;
        App.vue.$nextTick(() => {
          App.f7.views.main.router.navigate(redirect);
        });
      });
      if (App.vue.userRole !== 'admin') {
        briefWidget();
      }
    } else {
      window.location = redirect || '/';
    }
  },
  changeUser(user) {
    if (user && user.accessToken) {
      window.localStorage.accessToken = user.accessToken;
      window.localStorage.refreshToken = user.refreshToken;
      window.localStorage.userRole = user.claims.role;
      const confirmed = user.claims.confirmed;
      if (confirmed && confirmed !== 'false') {
        window.localStorage.userConfirmed = 'true';
      }
    }
    if (window.cordova) {
      if (user.language) {
        App.vue.setLanguage(user.language);
      }
      const newRoutes = routes(window.localStorage.userRole);
      App.vue.userChanging = true;
      App.vue.userRole = window.localStorage.userRole;
      App.vue.userConfirmed = window.localStorage.userConfirmed;
      App.f7.routes = newRoutes || [];
      App.f7.params.routes = newRoutes || [];
      if (App.f7.views.main) {
        App.f7.views.main.routes = newRoutes || [];
        App.f7.views.main.router.routes = newRoutes || [];
      }
      $('html').removeClass('with-modal-loginscreen with-panel with-panel-left-reveal');
      App.vue.$nextTick(() => {
        App.vue.userChanging = false;
      });
    } else {
      window.location = '/';
    }
  },
};

events.$on('changeRole', (newRole, newRoutes, redirect) => {
  App.changeRole(newRole, newRoutes, redirect);
});
events.$on('changeUser', (user) => {
  App.changeUser(user);
});
events.$on('login', (...args) => {
  App.login(...args);
});
events.$on('logout', () => {
  App.logout();
});
events.$on('f7ready', (f7) => {
  App.f7 = f7;
});
events.$on('showOfflineError', () => {
  App.showOfflineError();
});
events.$on('showError', (...args) => {
  App.showError(...args);
});
events.$on('notify', (...args) => {
  App.notify(...args);
});
events.$on('alert', (...args) => {
  App.alert(...args);
});
events.$on('isLoggedIn', (callback) => {
  if (callback && App.vue) callback(App.vue.loggedIn);
});
events.$on('f7instance', (callback) => {
  if (callback) {
    if (App.f7) callback(App.f7);
    else {
      events.$once('f7ready', (f7) => {
        callback(f7);
      });
    }
  }
});
events.$on('requestBeforeSend', () => {
  progress.show(App.f7);
});
events.$on('requestComplete', () => {
  progress.hide(App.f7);
});

// Init Modules
Notifications.init();
RequestSetup.init();
Activity.init();
Sync.init();
App.vue = VueApp();

export default App;
