<template>
  <div class="data-table-footer">
    <div class="data-table-pagination">
      <span class="data-table-pagination-label">{{page * perPage + 1}}-{{Math.min(total, (page + 1) * perPage)}} of {{total}}</span>
      <a href="#" :class="{ disabled: page === 0 }" class="link" @click="prevPage">
        <i class="icon icon-prev color-gray"></i>
      </a>
      <a href="#" :class="{ disabled: total <  (page + 1) * perPage }" class="link" @click="nextPage">
        <i class="icon icon-next color-gray" />
      </a>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      total: Number,
      perPage: Number,
      page: Number,
    },
    methods: {
      prevPage() {
        this.$emit('prevPage');
      },
      nextPage() {
        this.$emit('nextPage');
      },
    },
  };
</script>
