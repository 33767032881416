<template>
  <div class="assignment-list">
    <a
      v-for="item in assignments"
      :key="item.id"
      :href="item.isMyAssignment ? `/assignment/my/${item.id}/` : `/assignment/${item.id}/`"
      class="assignment-list-item"
      :class="{'assignmet-list-item-closed': item.status === assignmentStatusList.Closed}"
    >
      <div class="item-media">
        <logo class="company-logo" :logo="item.logo || logo" :size="60"></logo>
        <span class="new-badge" v-if="item.isNew">{{$t('general.new')}}</span>
      </div>
      <div class="item-content">
        <div class="item-title">
          <span>{{item.title}}</span>
          <div class="item-closed-label" v-if="item.status === assignmentStatusList.Closed">{{$t('assignment_list.closed_label')}}</div>
        </div>
        <div class="item-meta">
          <div class="item-date" v-if="item.publishedDate">
            {{formatDate(item.publishedDate)}}
          </div>
          <div class="item-category" v-if="typeof item.category !== 'undefined'">
            {{$t(`assignment_category.${assignmentCategoryMap(item.category)}`)}}
          </div>
        </div>
        <div class="item-text">{{removeLinks(item.description)}}</div>
        <div class="item-footer">
          <div class="item-reward" v-if="!rewardDisabled(item)">{{$t('assignment.reward')}} {{rewardText(item)}}</div>
          <div class="item-gauges" v-if="$root.userRole === 'finder' && (showGauges || showRecommendedGauge || showEarningGauge) && hasGaugesData(item)">
            <gauge
              class="item-gauge-earnings"
              v-if="(showGauges || showEarningGauge) && !rewardDisabled(item)"
              :size="65"
              :progress="!isUnlimited(item) ? (item.earnings || 0)/(item.potentialEarnings || 1) : (item.earnings ? 1 : 0)"
              :numberText="`${formatNumber(item.earnings || 0, item.rewards[0].currency, true)}`"
              :numberFontSize="14"
              strokeFillColor="#ddd"
              strokeColor="#3FD77A"
              :strokeWidth="6"
              :visible="true"
            ></gauge>
            <gauge
              class="item-gauge-recommended"
              v-if="showGauges || showRecommendedGauge"
              :size="65"
              :progress="!isUnlimited(item) ? item.contacted / item.potentialRecommendations : (item.contacted ? 1 : 0)"
              :numberText="!isUnlimited(item) ? `${item.contacted}/${item.potentialRecommendations}` : `${item.contacted}`"
              :numberFontSize="14"
              strokeFillColor="#ddd"
              strokeColor="#f47321"
              :strokeWidth="6"
              :visible="true"
            ></gauge>
          </div>
        </div>
      </div>
    </a>
    <slot></slot>
  </div>
</template>
<script>
  import assignmentStatusList from '../../utils/assignment-status-list';
  import assignmentCategoryMap from '../../utils/assignment-category-map';
  import formatDate from '../../utils/format-date';
  import formatNumber from '../../utils/format-number';
  import removeLinks from '../../utils/remove-links';
  import assignmentHowToLimitList from '../../utils/assignment-how-to-limit-list';

  export default {
    props: {
      assignments: Array,
      showBadge: Boolean,
      logo: [String, Object],
      companyPreview: Boolean,
      showGauges: {
        type: Boolean,
        default: true,
      },
      showEarningGauge: Boolean,
      showRecommendedGauge: Boolean,
    },
    data() {
      return {
        assignmentStatusList,
      };
    },
    methods: {
      maxRecommendations(item) {
        if (item) {
          if (typeof item.howToLimitRecommendation === 'undefined') {
            return item.potentialRecommendations;
          }
          if (item.howToLimitRecommendation === assignmentHowToLimitList.OnRating) {
            return item.potentialRecommendations;
          }
          if (item.howToLimitRecommendation === assignmentHowToLimitList.Limited) {
            return item.howToLimitRecommendationAmount;
          }
          return null;
        }
        return 0;
      },
      isUnlimited(item) {
        return this.maxRecommendations(item) === null;
      },
      rewardDisabled(item) {
        return item.disableReward;
      },
      hasGaugesData(item) {
        return item.earnings > 0 || item.contacted > 0;
      },
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
      isCompany() {
        return this.$root.userRole === 'company' && !this.companyPreview;
      },
      formatNumber,
      assignmentCategoryMap,
      removeLinks,
      rewardText(item) {
        const { rewards = [], potentialEarningsMin, potentialEarningsMax, potentialReward } = item;
        const currency = rewards.length ? rewards[0].currency : 0;
        let amount = '';
        if (this.isCompany()) {
          amount = `${formatNumber(potentialReward, currency, 10000000)}`;
        } else {
          amount = potentialEarningsMin === potentialEarningsMax
            ? `${formatNumber(potentialEarningsMax, currency, 10000000)}`
            : `${formatNumber(potentialEarningsMin, undefined, 10000000)} - ${formatNumber(potentialEarningsMax, currency, 10000000)}`;
        }

        return amount;
      },
    },
  };
</script>
