<template>
  <f7-page>
    <f7-navbar large :title="$t('admin.crowds_title')">
      <f7-link slot="nav-left" class="navbar-left-panel-opener" panel-open="left" icon-ios="f7:menu" icon-md="material:menu"></f7-link>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <div class="data-table card card-outline">
      <div class="card-header no-padding-right">
        <div class="data-table-title">{{$t('admin.crowds_table_title')}}</div>
        <a-table-pagination
          v-if="crowds && total > perPage"
          :total="total"
          :page="page"
          :per-page="perPage"
          @prevPage="prevPage"
          @nextPage="nextPage"
        />
      </div>
      <div class="card-content">
        <a-crowd-list-table
          v-if="crowds && crowds.length"
          :crowds="crowds"
        ></a-crowd-list-table>
      </div>
      <a-table-pagination
        v-if="crowds && total > perPage"
        :total="total"
        :page="page"
        :per-page="perPage"
        @prevPage="prevPage"
        @nextPage="nextPage"
      />
    </div>
  </f7-page>
</template>
<script>
  import API from '../../modules/api';
  import events from '../../modules/events';

  let scrollTimeout;

  export default {
    data() {
      return {
        crowds: null,
        loading: false,
        total: null,
        page: 0,
        perPage: 30,
      };
    },
    created() {
      const self = this;
      self.getCrowdsList();
      events.$on('store:admin:getCrowdsList', self.getCrowdsList);
    },
    beforeDestroy() {
      const self = this;
      events.$off('store:admin:getCrowdsList', self.getCrowdsList);
    },
    methods: {
      getCrowdsList({ page } = {}) {
        const self = this;
        clearTimeout(scrollTimeout);

        let needScroll;
        if (typeof page !== 'undefined') {
          self.page = page;
          scrollTimeout = setTimeout(() => {
            needScroll = true;
          }, 300);
        }

        API.get({
          url: `admin/crowd/list?skip=${self.perPage * self.page}&take=${self.perPage}`,
          loading: [self, 'loading'],
          ok(response) {
            self.crowds = response.items;
            self.total = response.total;
            if (needScroll) {
              self.$$(self.$el).find('.page-content').scrollTop(0, 500);
            }
          },
        });
      },
      prevPage() {
        const self = this;
        self.getCrowdsList({ page: self.page - 1 });
      },
      nextPage() {
        const self = this;
        self.getCrowdsList({ page: self.page + 1 });
      },
    },
  };
</script>
