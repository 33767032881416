<template>
  <div class="center-content">
    <f7-block strong class="assignment-details-block">
      <div class="assignment-details-content-wrap">
        <div class="assignment-details-logo">
          <logo class="company-logo skeleton-effect-fade" :size="120"></logo>
        </div>
        <div class="assignment-details-content">
          <div class="assignment-title skeleton-text skeleton-effect-fade">____ ___</div>

          <div class="assignment-meta">
            <div class="company-name">
              <a href="#">
                <f7-icon material="business"></f7-icon>
                <span class="skeleton-text skeleton-effect-fade">_________</span>
                <f7-icon f7="chevron_right"></f7-icon>
              </a>
            </div>
          </div>

          <div class="assignment-description skeleton-text skeleton-effect-fade">
            _____ _____ _____ ___ _____ ___________ __________ _____ ______ __ _____ _______ _______ ____ ___ _________ ______ ________ ___ _____ __ _____ ________ _________ _____ ________ _____ ___ ____ _______ __________ ____ ____ ______ _______ ___ _______ ____ ___ ___ _______ _____ ______ _____ _____ __ _______ ____ _______ _______ ___ _______ ___ __ _______ ________ ____ _____ _____ _________ __ ____ ___ ________ _________ ____ _____ ___________ _____ ___ ______ ________ _____ ____ ____ _______ __ ____________ ______ _______ __ ______ ______ _______ ______ __ _______ _______ _____ _____ _______ _______ ___ _________ ____ ____ _______ _______
          </div>

          <div class="assignment-inner-block assignment-recommendations-info skeleton-text skeleton-effect-fade">
            __ ___ ____ _______ ___ _____ _____ __ ___________ ______ ____ ____ _______________ _____
          </div>
        </div>
      </div>
    </f7-block>
  </div>
</template>
<script>
  export default {};
</script>
