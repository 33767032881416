<template>
  <div class="gauge">
    <svg :width="`${size}px`" :height="`${semiCircle ? size / 2 : size}px`" :viewBox="`0 0 ${size} ${semiCircle ? size / 2 : size}`" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <template v-if="semiCircle">
        <path
          :d="`M${size - strokeWidth / 2},${size/2} a1,1 0 0,0 -${size - strokeWidth},0`"
          :stroke="strokeFillColor"
          :stroke-width="strokeWidth"
          :fill="fillColor || 'none'"
        />
        <path
          :d="`M${size - strokeWidth / 2},${size/2} a1,1 0 0,0 -${size - strokeWidth},0`"
          :stroke="strokeColor"
          :stroke-width="strokeWidth"
          :stroke-dasharray="length / 2"
          :stroke-dashoffset="(length / 2) * (Math.min(progressComputed, 1) - 1)"
          :fill="strokeFillColor ? 'none' : fillColor || 'none'"
        />
      </template>
      <template v-else>
        <circle
          v-if="strokeFillColor"
          :stroke="strokeFillColor"
          :stroke-width="strokeWidth"
          :fill="fillColor || 'none'"
          :cx="size / 2"
          :cy="size / 2"
          :r="radius"
        ></circle>
        <circle
          :transform="`rotate(-90 ${size/2} ${size/2})`"
          :stroke="strokeColor"
          :stroke-width="strokeWidth"
          :stroke-dasharray="length"
          :stroke-dashoffset="length * (1 - Math.min(progressComputed, 1))"
          :fill="strokeFillColor ? 'none' : fillColor || 'none'"
          :cx="size / 2"
          :cy="size / 2"
          :r="radius"
        ></circle>
      </template>

      <text
        v-if="labelBeforeText"
        x="50%"
        :y="semiCircle ? '100%' : '50%'"
        :font-weight="labelBeforeFontWeight"
        :font-size="labelBeforeFontSize"
        :fill="labelBeforeColor"
        :dy="semiCircle ? -5 : (numberText ? -(numberFontSize / 2 + 15) : 0)"
        text-anchor="middle"
        :dominant-baseline="!semiCircle && 'middle'"
      >{{labelBeforeText}}</text>
      <text
        v-if="numberText"
        x="50%"
        :y="semiCircle ? '100%' : '50%'"
        :font-weight="numberFontWeight"
        :font-size="numberFontSize"
        :fill="numberColor"
        :dy="semiCircle ? (labelText ? -labelFontSize - 15 : -5) : 0"
        text-anchor="middle"
        :dominant-baseline="!semiCircle && 'middle'"
      >{{numberText}}</text>
      <text
        v-if="labelText"
        x="50%"
        :y="semiCircle ? '100%' : '50%'"
        :font-weight="labelFontWeight"
        :font-size="labelFontSize"
        :fill="labelColor"
        :dy="semiCircle ? -5 : (numberText ? (numberFontSize / 2 + 10) : 0)"
        text-anchor="middle"
        :dominant-baseline="!semiCircle && 'middle'"
      >{{labelText}}</text>
      <text
        v-if="labelText"
        x="50%"
        :y="semiCircle ? '100%' : '60%'"
        :font-weight="labelFontWeight"
        :font-size="labelFontSize"
        :fill="labelColor"
        :dy="semiCircle ? -5 : (labelAfterText ? (numberFontSize / 2 + 10) : 0)"
        text-anchor="middle"
        :dominant-baseline="!semiCircle && 'middle'"
      >{{labelAfterText}}</text>
    </svg>
  </div>
</template>
<script>
  export default {
    props: {
      semiCircle: Boolean,
      size: {
        type: [Number, String],
        default: 100,
      },
      fillColor: String,
      strokeColor: {
        type: String,
        default: '#000000',
      },
      strokeFillColor: String,
      strokeWidth: {
        type: Number,
        default: 10,
      },
      progress: {
        type: Number,
        default: 50,
      },
      numberText: String,
      numberFontWeight: {
        type: Number,
        default: 500,
      },
      numberFontSize: {
        type: Number,
        default: 30,
      },
      numberColor: String,

      labelBeforeText: String,
      labelBeforeFontWeight: {
        type: Number,
        default: 400,
      },
      labelBeforeFontSize: {
        type: Number,
        default: 12,
      },
      labelBeforeColor: {
        type: String,
        default: '#555',
      },

      labelText: String,
      labelFontWeight: {
        type: Number,
        default: 400,
      },
      labelFontSize: {
        type: Number,
        default: 14,
      },
      labelColor: String,

      labelAfterText: String,
      labelAfterFontWeight: {
        type: Number,
        default: 400,
      },
      labelAfterFontSize: {
        type: Number,
        default: 12,
      },
      labelAfterColor: {
        type: String,
        default: '#555',
      },

      visible: Boolean,
    },
    data() {
      return {
        progressComputed: 0,
      };
    },
    watch: {
      progress() {
        const self = this;
        if (self.visible) self.progressComputed = self.progress;
      },
      visible() {
        const self = this;
        if (self.visible) {
          setTimeout(() => {
            self.progressComputed = self.progress;
          }, 100);
        }
      },
    },
    mounted() {
      const self = this;
      if (self.visible) {
        setTimeout(() => {
          self.progressComputed = self.progress;
        }, 0);
      }
    },
    computed: {
      length() {
        return 2 * Math.PI * this.radius;
      },
      radius() {
        const { size, strokeWidth } = this;
        return (size / 2) - (strokeWidth / 2);
      },
      numberClasses() {
        const { numberColor } = this;
        if (numberColor && (numberColor.indexOf('#') >= 0 || numberColor.indexOf('rgb') >= 0 || numberColor.indexOf('hsl') >= 0)) return null;
        return `text-color-${numberColor}`;
      },
      numberStyle() {
        const { numberColor, numberSize } = this;
        const style = {};
        if (numberColor && (numberColor.indexOf('#') >= 0 || numberColor.indexOf('rgb') >= 0 || numberColor.indexOf('hsl') >= 0)) {
          style.color = numberColor;
        }
        if (numberSize) {
          if (parseInt(numberSize, 10) === numberSize * 1) style.fontSize = `${numberSize}px`;
          style.fontSize = numberSize;
        }
        return style;
      },
      labelClasses() {
        const { labelColor } = this;
        if (labelColor && (labelColor.indexOf('#') >= 0 || labelColor.indexOf('rgb') >= 0 || labelColor.indexOf('hsl') >= 0)) return null;
        return `text-color-${labelColor}`;
      },
      labelStyle() {
        const { labelColor, labelSize } = this;
        const style = {};
        if (labelColor && (labelColor.indexOf('#') >= 0 || labelColor.indexOf('rgb') >= 0 || labelColor.indexOf('hsl') >= 0)) {
          style.color = labelColor;
        }
        if (labelSize) {
          if (parseInt(labelSize, 10) === labelSize * 1) style.fontSize = `${labelSize}px`;
          style.fontSize = labelSize;
        }
        return style;
      },
      contentStyle() {
        const { strokeWidth } = this;
        return {
          top: `${strokeWidth}px`,
          bottom: `${strokeWidth}px`,
          left: `${strokeWidth}px`,
          right: `${strokeWidth}px`,
        };
      },
    },
  }
</script>
