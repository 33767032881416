<template>
  <f7-page login-screen theme-dark>
    <div class="middle-content">
      <f7-login-screen-title>{{$t('auth.signup_title')}}</f7-login-screen-title>

      <f7-block v-if="showSegmented">
        <f7-segmented round>
          <f7-button small round outline :active="roleComputed === 'finder'" @click="roleComputed = 'finder'">{{$t('roles.Finder')}}</f7-button>
          <f7-button small round outline :active="roleComputed === 'company'" @click="roleComputed = 'company'">{{$t('roles.Company')}}</f7-button>
        </f7-segmented>
      </f7-block>

      <template v-if="roleComputed === 'finder'">
        <f7-block>
          <loading-button key="facebook-button" class="facebook-button" v-if="roleComputed === 'finder'" large fill round preloader-color="white" @click="loginFacebook" :loading="facebookLoginIn" :disabled="signingUp || googleLoginIn">
            <f7-icon f7="logo_facebook"></f7-icon>
            <span>{{$t('auth.signup_facebook_button')}}</span>
          </loading-button>
          <loading-button key="google-button" class="google-button" v-if="roleComputed === 'finder'" large fill round preloader-color="black" @click="loginGoogle" :loading="googleLoginIn" :disabled="signingUp || facebookLoginIn">
            <f7-icon icon="icon-google"></f7-icon>
            <span>{{$t('auth.signup_google_button')}}</span>
          </loading-button>
        </f7-block>
        <div class="login-buttons-divider" :style="{visibility: roleComputed === 'finder' ? 'visible' : 'hidden'}"><span>{{$t('general.or')}}</span></div>
      </template>
      <f7-block class="auth-error-block" v-if="errorMessage"><span>{{errorMessage}}</span></f7-block>
      <f7-list class="inputs-list">
        <f7-list-input @keypress.enter.native="onEnter" type="email" :placeholder="$t('auth.email_placeholder')" :value="email" @input="email = $event.target.value"></f7-list-input>
        <li v-if="$theme.ios"></li>
      </f7-list>
      <loading-button large fill round preloader-color="brand-orange" @click="signup" :loading="signingUp" :text="$t('auth.signup_button')" :disabled="facebookLoginIn || googleLoginIn"></loading-button>
      <f7-block>
        <f7-block-footer><f7-link :route-props="{ assignmentRedirectId }" reload-current href="/">{{$t('auth.login_link')}}</f7-link> &nbsp;/&nbsp; <f7-link reload-current href="/forgot-password/">{{$t('auth.forgot_password_link')}}</f7-link></f7-block-footer>
      </f7-block>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import api from '../../modules/api';
  import Facebook from '../../modules/facebook';
  import Google from '../../modules/google';

  export default {
    props: {
      role: String,
      assignmentRedirectId: [Number, String],
    },
    data() {
      return {
        email: this.$f7route.query.email || '',
        roleComputed: this.role === 'company' ? 'company' : 'finder',
        showSegmented: this.role !== 'company' && this.role !== 'finder',
        facebookLoginIn: false,
        facebookUser: false,
        googleLoginIn: false,
        googleUser: false,
        errorMessage: null,
        step: this.role === 'company' || this.role === 'finder' ? 'step-2' : 'step-1',
      };
    },
    computed: {
      ...mapGetters({
        signingUp: 'user/signingUp',
      }),
    },
    mounted() {
      const self = this;
      Google.googleGetLoginStatus(() => {
        Google.googleGetUser((user) => {
          self.googleUser = user;
        });
      });
      Facebook.fbGetLoginStatus((res) => {
        Facebook.fbGetUser(res.authResponse.userID, (user) => {
          self.facebookUser = {
            ...user,
            accessToken: res.authResponse.accessToken,
          };
        });
      });
    },
    methods: {
      sendFid() {
        if (!localStorage.fid) return;
        api.post({
          url: '/finder/affiliate/count',
          data: {
            fid: localStorage.fid,
          },
          ok() {
            delete localStorage.fid;
          },
        });
      },
      showError(e) {
        let message = e;
        if (!message) return;
        if (message.responseText) message = message.responseText;
        if (message.indexOf('{') === 0) {
          message = JSON.parse(message).message;
        }
        if (!message) return;
        this.errorMessage = message;
      },
      hideError() {
        this.errorMessage = null;
      },
      onEnter() {
        const self = this;
        self.signup();
      },
      onLogin(response) {
        const self = this;
        localStorage.visited = true;
        const { roleComputed } = self;
        if (response && (response.accessToken || response.token)) {
          window.localStorage.accessToken = response.accessToken || response.token;
          window.localStorage.refreshToken = response.refreshToken;
          window.localStorage.userRole = response.claims ? response.claims.role : roleComputed;
          const confirmed = response.claims.confirmed;
          if (confirmed && confirmed !== 'false') {
            window.localStorage.userConfirmed = 'true';
            // $root.userConfirmed = true;
          }
          let redirectUrl = localStorage.lastCrowdId ? `/crowd/${localStorage.lastCrowdId}/` : undefined;
          let redirectProps;
          if (self.assignmentRedirectId) {
            redirectUrl = `/assignment/${self.assignmentRedirectId}/`;
            redirectProps = { scrollToRecommendations: true };
          } else if (response.redirect) {
            redirectUrl = response.redirect;
          }
          const needReloadAll = redirectUrl && redirectUrl.match(/\/crowd\/[0-9]*[/]?/) && redirectUrl.match(/\/crowd\/[0-9]*[/]?/)[0] === redirectUrl;
          self.$f7router.navigate('/', { reloadCurrent: true });
          if (self.$device.cordova) {
            if (window.facebookConnectPlugin && window.facebookConnectPlugin.logEvent) {
              window.facebookConnectPlugin.logEvent('fb_mobile_complete_registration');
            }
            self.$root.login();
            if (redirectUrl) {
              setTimeout(() => {
                if (redirectProps) {
                  self.$f7.views.main.router.navigate(redirectUrl, { props: redirectProps, reloadAll: needReloadAll });
                } else {
                  self.$f7.views.main.router.navigate(redirectUrl, { reloadAll: needReloadAll });
                }
              }, 300);
            }
          } else {
            window.history.replaceState({}, '', '/');
            setTimeout(() => {
              self.$root.login();
              if (redirectUrl) {
                setTimeout(() => {
                  self.$f7.views.main.router.navigate(redirectUrl, { reloadAll: needReloadAll });
                }, 300);
              }
            }, 0);
          }
        }
      },
      loginGoogle() {
        const self = this;
        self.hideError();
        function sendData() {
          const { id: googleId, email, first_name, last_name, picture, accessToken } = self.googleUser;
          self.$store.dispatch('user/googleLogin', {
            googleId,
            email,
            first_name,
            last_name,
            picture,
            googleAccessToken: accessToken,
            language: self.$root.language,
            role: self.roleComputed,
            callbackOk(response) {
              self.sendFid();
              self.googleLoginIn = false;
              if (response && response.roleOptions && response.roleOptions.length) {
                if (response.accessToken) {
                  window.localStorage.accessToken = response.accessToken;
                }
                if (response.refreshToken) {
                  window.localStorage.refreshToken = response.refreshToken;
                }
                self.$f7router.navigate('/', {
                  reloadCurrent: true,
                  props: {
                    forceEmail: email,
                    forceSelectedRole: response.roleOptions[0],
                    forceRoleOptions: response.roleOptions,
                    forceGoogleId: googleId,
                    forceGoogleAccessToken: accessToken,
                  },
                });
                return;
              }
              self.onLogin(response);
            },
            callbackError(e) {
              self.googleLoginIn = false;
              self.showError(e);
            },
          });
        }
        self.$f7.dialog.confirm(self.$t('auth.signup_google_confirm'), () => {
          self.googleLoginIn = true;
          if (self.googleUser) {
            sendData();
            return;
          }
          Google.login((user) => {
            self.googleUser = user;
            sendData();
          }, () => {
            self.googleLoginIn = false;
          });
        });
      },
      loginFacebook() {
        const self = this;
        self.hideError();
        function sendData() {
          const { id: facebookId, email, first_name, last_name, picture, accessToken } = self.facebookUser;
          self.$store.dispatch('user/facebookLogin', {
            facebookId,
            email,
            first_name,
            last_name,
            picture,
            facebookAccessToken: accessToken,
            language: self.$root.language,
            role: self.roleComputed,
            callbackOk(response) {
              self.sendFid();
              self.facebookLoginIn = false;
              if (response && response.roleOptions && response.roleOptions.length) {
                if (response.accessToken) {
                  window.localStorage.accessToken = response.accessToken;
                }
                if (response.refreshToken) {
                  window.localStorage.refreshToken = response.refreshToken;
                }
                self.$f7router.navigate('/', {
                  reloadCurrent: true,
                  props: {
                    forceEmail: email,
                    forceSelectedRole: response.roleOptions[0],
                    forceRoleOptions: response.roleOptions,
                    forceFacebookId: facebookId,
                    forceFacebookAccessToken: accessToken,
                  },
                });
                return;
              }
              self.onLogin(response);
            },
            callbackError(e) {
              self.facebookLoginIn = false;
              self.showError(e);
            },
          });
        }
        self.$f7.dialog.confirm(self.$t('auth.signup_facebook_confirm'), () => {
          self.facebookLoginIn = true;
          if (self.facebookUser) {
            sendData();
            return;
          }
          Facebook.login((user) => {
            self.facebookUser = user;
            sendData();
          }, () => {
            self.facebookLoginIn = false;
          });
        });
      },
      signup() {
        const self = this;
        self.hideError();
        const { email, roleComputed } = self;
        if (!email.trim()) {
          self.showError(self.$t('error.email_required'));
          return;
        }
        self.$f7.dialog.confirm(self.$t('auth.signup_email_confirm', { email, role: self.$t(`general.${roleComputed.toLowerCase()}`) }), () => {
          window.localStorage.userEmail = email;
          self.$store.dispatch('user/signup', {
            email,
            role: roleComputed,
            language: self.$root.language,
            callbackOk(response) {
              self.sendFid();
              self.onLogin(response);
            },
            callbackError(e) {
              self.showError(e);
            },
          });
        });
      },
    },
  };
</script>
