<template>
  <f7-link href="#" @click="onClick" :popover-open="`#popover-${popoverId}`" class="navbar-profile-link">
    <template v-if="profile">
      <avatar v-if="$root.userRole === 'finder'" :file="profile.photo" :size="34" />
      <logo v-if="$root.userRole === 'company'" class="logo-avatar" :file="profile.companyLogo" :size="34" />
    </template>
    <template v-else>
      <f7-icon md="material:person" iso="f7:person_round" />
    </template>
    <span class="margin-left-half ">{{$t('general.settings')}}</span>
    <f7-popover class="profile-popover" :id="`popover-${popoverId}`" v-if="renderPopover && profile">
      <f7-list>
        <f7-list-item
          :title="$t('profile.user_settings_link')"
          link="/profile/"
          popover-close
        >
          <f7-icon v-if="!profile.photo" slot="media" f7="person" />
          <avatar v-else :file="profile.photo" :size="34" slot="media" />
        </f7-list-item>
        <f7-list-item
          v-if="$root.userRole === 'company'"
          :title="$t('profile.company_settings_link')"
          link="/profile/details/"
          popover-close
        >
          <f7-icon v-if="!profile.companyLogo" slot="media" f7="business" />
          <logo v-else :file="profile.companyLogo" :size="34" slot="media" style="width: 34px; height: 34px" />
        </f7-list-item>
      </f7-list>
      <f7-list v-if="profile && profile.roleOptions && profile.roleOptions.length >= 2">
        <f7-list-item
          v-if="currentRole !== role"
          v-for="(role, index) in profile.roleOptions"
          :key="index"
          :title="$t('profile.switch_account_to', { type: rolesMap(role) })"
          link="#"
          @click="changeRole(role)"
        ></f7-list-item>
      </f7-list>

      <f7-list>
        <f7-list-item
          v-if="$root.userRole === 'company'"
          :title="$t('profile.users_link')"
          link="/profile/users/"
          popover-close
        ></f7-list-item>
        <f7-list-item
          :title="$t('profile.payments_link')"
          link="/profile/payments/"
          popover-close
        ></f7-list-item>
        <f7-list-item
          :title="$t('profile.notifications_link')"
          link="/profile/notifications/"
          popover-close
        ></f7-list-item>
        <f7-list-item
          :title="$t('profile.about_link')"
          link="/profile/about/"
          popover-close
        ></f7-list-item>
      </f7-list>
      <f7-list>
        <f7-list-item
          :title="$t('profile.logout_link')"
          link
          @click="logout"
          text-color="brand-red"
        ></f7-list-item>
      </f7-list>
    </f7-popover>
  </f7-link>
</template>
<script>
  import mapRoleGetters from '../../utils/map-role-getters';
  import PWA from '../../modules/pwa';

  export default {
    props: {
      needConfirm: Boolean,
    },
    data() {
      const self = this;
      return {
        url: '/profile/',
        popoverId: self.$f7.utils.id(),
        renderPopover: true,
      };
    },
    computed: {
      currentRole() {
        return ['finder', 'company', 'admin'].indexOf(this.$root.userRole);
      },
      ...mapRoleGetters({
        profile: 'profile',
      }),
    },
    created() {
      const self = this;
      self.$store.dispatch(`${self.$root.storeRolePrefix}/getProfile`, { cached: true });
    },
    methods: {
      logout() {
        const self = this;
        this.$f7.popover.close();
        this.renderPopover = false;
        PWA.removeCachedApi(() => {
          this.$nextTick(() => {
            self.$root.logout();
          });
        });
      },
      onClick() {
        const self = this;
        self.$emit('click', self.url);
      },
      changeRole(role) {
        this.$f7.popover.close();
        this.renderPopover = false;
        this.$nextTick(() => {
          this.$root.changeRole(['finder', 'company', 'admin'][role]);
        });

      },
      rolesMap(role) {
        return this.$t(`roles.${['Finder', 'CompanyPossesionForm', 'Admin'][role]}`).toLowerCase();
      },
    },
  };
</script>
