/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';

const state = {};
const getters = {};
const mutations = {};

const actions = {
  setFinderTransactionStatus({ dispatch }, { id, status, updateLists }) {
    API.post({
      url: 'admin/finder/transactionstatussave',
      data: { id, status },
      ok() {
        if (updateLists) {
          dispatch('getFinderRewardList');
          dispatch('getFinderPaidRewardList');
        }
      },
    });
  },
  setCompanyTransactionStatus({ dispatch }, { id, status, updateLists }) {
    API.post({
      url: 'admin/company/transactionstatussave',
      data: { id, status },
      ok() {
        if (updateLists) {
          dispatch('getFinderRewardList');
          dispatch('getFinderPaidRewardList');
        }
      },
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
