<template>
  <f7-page
    infinite
    :infinite-preloader="false"
    @infinite="onInfinite"
  >
    <f7-navbar :title="$t('crowd_admins.title')">
      <f7-link slot="nav-left" back icon="icon-back" :text="$t('general.back')"></f7-link>
    </f7-navbar>

    <offline-indicator slot="fixed"></offline-indicator>

    <div class="center-content">

      <f7-list v-if="admins && admins.length" media-list class="crowd-admins-list">
        <f7-searchbar
          v-if="hasAdmins"
          class="searchbar-users searchbar-admins"
          inline
          :custom-search="true"
          :placeholder="$t('crowd_admins.search_placeholder')"
          :value="query"
          :disableButton="false"
          :disableButtonText="$t('general.cancel')"
          @searchbar:search="onSearch"
        />
        <f7-list-item
          v-for="admin in admins"
          :key="admin.id"
          :title="admin.firstName ? `${admin.firstName} ${admin.lastName}` : admin.email"
          checkbox
          :checked="admin.isAdmin || admin.isOwner"
          :disabled="admin.isOwner"
          :class="admin.isOwner ? 'disabled' : ''"
          @change="toggleAdmin(admin, $event.target.checked)"
        >
          <avatar :file="admin.photo" slot="media" :size="44"></avatar>
          <div slot="text">
            <div>{{admin.email}}</div>
          </div>
          <div class="text-color-gray" slot="header" v-if="admin.isOwner">{{$t('crowd_admins.isOwner')}}</div>
        </f7-list-item>
      </f7-list>
      <f7-block strong class="empty-block text-align-center" v-if="admins && !admins.length && query">{{$t('crowd_admins.search_not_found')}}</f7-block>
      <f7-block strong class="empty-block text-align-center" v-if="admins && !admins.length && !query">{{$t('crowd_admins.no_users')}}</f7-block>
      <f7-preloader v-if="allowInfinite && adminsHasMore" class="infinite-scroll-preloader"></f7-preloader>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import config from '../../config';

  export default {
    props: {
      crowdId: [String, Number],
    },
    data() {
      return {
        admins: null,
        allowInfinite: false,
        query: '',
        hasAdmins: false,
        host: config.host,
      };
    },
    computed: {
      ...mapGetters({
        currentAdmins: 'c/crowdAdmins',
        adminsHasMore: 'c/crowdAdminsHasMore',
        adminsLoadingMore: 'c/crowdAdminsLoadingMore',
      }),
    },
    watch: {
      currentAdmins() {
        const self = this;
        self.admins = [...self.currentAdmins];
        if (self.admins.length) self.hasAdmins = true;
      },
      'currentAdmins.length': function watchAdmins(length) {
        if (length && length % 20 === 0) {
          this.allowInfinite = true;
        } else {
          this.allowInfinite = false;
        }
      },
    },
    created() {
      const self = this;
      self.$store.dispatch('c/getCrowdAdmins', { crowdId: self.crowdId });
    },
    methods: {
      onSearch(ss, query) {
        const self = this;
        if (query.trim() === self.query) return;
        self.query = query.trim();
        clearTimeout(self.searchTimeout);
        self.searchTimeout = setTimeout(() => {
          self.$store.dispatch('c/getCrowdAdmins', { cache: false, query: query.trim(), crowdId: self.crowdId });
        }, 300);
      },
      onInfinite() {
        const self = this;
        if (!self.allowInfinite || self.adminsLoadingMore || !self.adminsHasMore) return;
        self.$store.dispatch('c/getMoreCrowdAdmins', { crowdId: self.crowdId });
      },
      toggleAdmin(admin, isAdmin) {
        const self = this;
        admin.isAdmin = true;
        self.$store.dispatch('c/updateCrowdAdmin', { crowdId: self.crowdId, userId: admin.id, isAdmin });
      },
    },
  };
</script>
