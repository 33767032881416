<template>
  <f7-page>
    <f7-navbar large :title="$t('admin.assignments_title')">
      <f7-link slot="nav-left" class="navbar-left-panel-opener" panel-open="left" icon-ios="f7:menu" icon-md="material:menu"></f7-link>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>

    <div class="data-table card card-outline">
      <div class="card-header no-padding-right">
        <div class="data-table-title">
          {{$t('admin.assignments_table_all_title')}}
          <div class="data-table-header-dropdown">
            <div class="input input-dropdown">
              <select :value="enterpriseFilter" @change="setEnterpriseFilter">
                <option value="all">{{$t('admin.enterprise_filter_all')}}</option>
                <option value="true">{{$t('admin.enterprise_filter_only_enterprise')}}</option>
                <option value="false">{{$t('admin.enterprise_filter_exclude_enterprise')}}</option>
              </select>
            </div>
          </div>
        </div>
        <a-table-pagination
          v-if="assignments && total > perPage"
          :total="total"
          :page="page"
          :per-page="perPage"
          @prevPage="prevPage"
          @nextPage="nextPage"
        />
      </div>
      <div class="card-content">
        <a-assignment-list-table
          v-if="assignments && assignments.length"
          :assignments="assignments"
          :show-order="true"
        ></a-assignment-list-table>
        <a-table-pagination
          v-if="assignments && total > perPage"
          :total="total"
          :page="page"
          :per-page="perPage"
          @prevPage="prevPage"
          @nextPage="nextPage"
        />
      </div>
    </div>
  </f7-page>
</template>
<script>
  import events from '../../modules/events';
  import API from '../../modules/api';

  let scrollTimeout;
  let enterpriseFilter = 'all';

  export default {
    data() {
      return {
        assignments: null,
        loading: false,
        total: null,
        page: 0,
        perPage: 30,
        enterpriseFilter,
      };
    },
    created() {
      const self = this;
      self.getAssignmentsList();
      events.$on('store:admin:getAssignmentsList', self.getAssignmentsList);
    },
    beforeDestroy() {
      const self = this;
      events.$off('store:admin:getAssignmentsList', self.getAssignmentsList);
    },
    methods: {
      getAssignmentsList({ filter, page } = {}) {
        const self = this;
        clearTimeout(scrollTimeout);

        if (filter) {
          enterpriseFilter = filter;
          Object.assign(self, { enterpriseFilter });
        }

        let needScroll;
        if (typeof page !== 'undefined') {
          self.page = page;
          scrollTimeout = setTimeout(() => {
            needScroll = true;
          }, 300);
        }

        API.get({
          url: `admin/assignment/list?skip=${self.perPage * self.page}&take=${self.perPage}${enterpriseFilter === 'all' ? '' : `&enterprise=${enterpriseFilter}`}`,
          loading: [self, 'loading'],
          ok(response) {
            self.assignments = response.items;
            self.total = response.total;
            if (needScroll) {
              self.$$(self.$el).find('.page-content').scrollTop(0, 500);
            }
          },
        });
      },
      prevPage() {
        const self = this;
        self.getAssignmentsList({ page: self.page - 1 });
      },
      nextPage() {
        const self = this;
        self.getAssignmentsList({ page: self.page + 1 });
      },
      setEnterpriseFilter(e) {
        const self = this;
        self.getAssignmentsList({ filter: e.target.value, page: 0 });
      },
    },
  };
</script>
