<template>
  <div class="offline-indicator" :class="{
    online: $root.online,
    offline: !$root.online,
  }">
    <span>{{$t(`network.indicator_${$root.online ? 'online' : 'offline'}`)}}</span>
  </div>
</template>
<script>
  export default {};
</script>
