<template>
  <f7-page
    infinite
    :infinite-preloader="false"
    @infinite="onInfinite"
  >
    <f7-navbar>
      <panel-link slot="nav-left" />
      <f7-nav-title>{{$t('crowd_join.title')}}</f7-nav-title>
      <f7-nav-right>
        <profile-link />
      </f7-nav-right>
    </f7-navbar>

    <offline-indicator slot="fixed"></offline-indicator>

    <div class="center-content center-content-blocks">
      <f7-list class="crowd-join-list" media-list v-if="crowdJoinList && crowdJoinList.length">
        <f7-list-item
          v-for="crowd in crowdJoinList"
          :key="crowd.id"
          :title="crowd.name"
          :text="crowd.description"
        >
          <logo slot="media" :size="34" :logo="crowd.logo" />
          <f7-button :disabled="joining" slot="after" small round outline @click="joinCrowd(crowd)">{{$t('crowd_join.join_button')}}</f7-button>
        </f7-list-item>
      </f7-list>
      <f7-block strong class="empty-block" v-if="crowdJoinList && !crowdJoinList.length">
        {{$t('crowd_join.empty')}}
      </f7-block>
      <f7-preloader v-if="allowInfinite && listHasMore" class="infinite-scroll-preloader"></f7-preloader>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import dispatchWithRetry from '../../utils/dispatch-with-retry';
  import events from '../../modules/events';

  export default {
    mixins: [dispatchWithRetry],
    data() {
      return {
        allowInfinite: false,
        hasCrowds: false,
      };
    },
    computed: {
      ...mapGetters({
        crowdJoinList: 'f/crowdJoinList',
        loading: 'f/crowdJoinListLoading',
        joining: 'f/crowdJoining',
        profile: 'f/profile',
        loadingMore: 'f/crowdJoinListMoreLoading',
        listHasMore: 'f/crowdJoinListHasMore',
      }),
    },
    watch: {
      'crowdJoinList.length': function watchAssignments(length) {
        const self = this;
        if (length) {
          self.hasCrowds = true;
        }
        if (length && length % 20 === 0) {
          self.allowInfinite = true;
        } else {
          self.allowInfinite = false;
        }
      },
      profile(profile, oldProfile) {
        const self = this;
        if (profile && !oldProfile) {
          self.dispatchWithRetry('f/getCrowdJoinList');
        }
      },
    },
    created() {
      const self = this;
      self.$store.dispatch('f/unsetCrowdJoinList');
      if (self.profile) {
        self.dispatchWithRetry('f/getCrowdJoinList');
      }
    },
    mounted() {
      events.$emit('setActiveMenu', null);
    },
    beforeDestroy() {
      const self = this;
      self.destroyed = true;
      clearTimeout(self.timeout);
    },
    methods: {
      onInfinite() {
        const self = this;
        if (!self.allowInfinite || self.loadingMore || !self.listHasMore) return;
        self.$store.dispatch('f/getMoreCrowdJoinList', { crowdId: self.crowdId });
      },
      joinCrowd(crowd) {
        const self = this;
        self.$store.dispatch('f/joinCrowd', {
          crowdId: crowd.id,
          callbackOk() {
            events.$emit('refreshCrowdList', () => {
              self.$f7router.navigate(`/crowd/${crowd.id}/`, {
                reloadAll: true,
                props: {
                  crowdPassed: crowd,
                },
              });
            });
          },
        });
      },
    },
  };
</script>
