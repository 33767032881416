import $ from 'dom7';
import events from './events';

export default {
  validate: {
    inserted(el, binding) {
      const { value } = binding;
      const domEvents = value || 'change paste blur';
      let inputEl = el;
      const tags = 'input textarea';
      if (tags.indexOf(inputEl.nodeName.toLowerCase()) < 0) {
        inputEl = inputEl.querySelector('input, textarea');
      }
      if (!inputEl) return;
      inputEl.required = true;
      const $inputEl = $(inputEl);
      $inputEl.on(domEvents, () => {
        events.$emit('f7instance', (f7) => {
          setTimeout(() => {
            f7.input.validate(inputEl);
          }, 0);
        });
      }, true);
    },
  },
  validateUrl: {
    inserted(el, binding) {
      const { value } = binding;
      const domEvents = value || 'change paste blur';
      let inputEl = el;
      const tags = 'input textarea';
      if (tags.indexOf(inputEl.nodeName.toLowerCase()) < 0) {
        inputEl = inputEl.querySelector('input, textarea');
      }
      if (!inputEl) return;
      inputEl.required = true;
      const $inputEl = $(inputEl);
      $inputEl.on(domEvents, () => {
        const value = $inputEl.val().trim();
        if (value.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/)) {
          if (value.indexOf('http://') !== 0 && value.indexOf('https://') !== 0) {
            $inputEl.val(`http://${value}`).trigger('input');
          }
        }
        setTimeout(() => {
          events.$emit('f7instance', (f7) => {
            f7.input.validate(inputEl);
          });
        }, 0);
      });
    },
  },
};
