<template>
  <table>
    <thead>
      <tr>
        <th class="sortable-cell sortable-cell-active sortable-asc no-ripple">
          <span>ID</span>
          <!-- <data-table-filter max-width="90" type="number" :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th>
          <span>{{$t('general.crowd_name')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th>
          <span>{{$t('general.company_name')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th>
          <span>{{$t('general.user')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th>
          <span>{{$t('admin.table_crowd_private')}}</span>
        </th>
        <th>
          <span>{{$t('general.created')}}</span>
          <!-- <data-table-filter max-width="140" :placeholder="$t('general.filter')" calendar></data-table-filter> -->
        </th>
        <th class="actions-cell"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="crowd in crowds"
        :key="crowd.id"
      >
        <td>{{crowd.id}}</td>
        <td class="nowrap">{{crowd.name}}</td>
        <td><f7-link :href="`/companies/edit/${crowd.companyId}/`">{{crowd.companyName}}</f7-link></td>
        <td class="nowrap">
          <f7-link v-if="crowd.user" :href="`/users/edit/${crowd.user.id}/`">{{crowd.user.firstName}} {{crowd.user.lastName}}</f7-link>
        </td>
        <td>
          <f7-icon v-if="crowd.isPrivate" material="check"></f7-icon>
        </td>
        <td class="nowrap">{{formatDate(crowd.createdDate)}}</td>
        <td class="actions-cell">
          <f7-link :href="`/crowds/edit/${crowd.id}/`" text-color="brand-green" icon-ios="f7:edit" icon-md="material:mode_edit"></f7-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
  import formatDate from '../../utils/format-date';
  import tableMixin from './table-mixin';

  export default {
    mixins: [tableMixin],
    props: {
      crowds: Array,
    },
    methods: {
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
    },
  };
</script>
