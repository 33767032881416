<template>
  <f7-popup tablet-fullscreen :opened="isGuidPreview">
    <f7-view :init="false">
      <f7-page class="assignment-details assignment-preview-page">
        <f7-navbar>
          <f7-nav-title>{{$t('assignment.preview_title')}}</f7-nav-title>
          <f7-nav-right v-if="!isGuidPreview">
            <f7-link popup-close icon-ios="f7:close" icon-md="material:close"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <offline-indicator slot="fixed"></offline-indicator>
        <div class="center-content" v-if="assignment">
          <f7-block strong class="assignment-details-block">
            <div class="assignment-details-content-wrap">
              <div class="assignment-details-logo">
                <logo v-if="assignment.anonymous" class="company-logo" :size="120"></logo>
                <a v-else href="#" style="cursor: auto">
                  <logo class="company-logo" :file="assignment.logo" :size="120"></logo>
                </a>
              </div>
              <div class="assignment-details-content">
                <div class="assignment-title">{{assignment.title}}</div>

                <image-gallery
                  v-if="mediaFilesFromText.length || assignment.files && assignment.files.length"
                  :files="assignment.files"
                  :extra-media-files="mediaFilesFromText"
                  :allow-edit="false"
                ></image-gallery>

                <div class="assignment-meta">
                  <div class="company-name" v-if="assignment.anonymous">
                    <f7-icon material="business"></f7-icon>
                    <span>{{$t('assignment.anonymous_name')}}</span>
                  </div>
                  <div class="company-name" v-else>
                    <a :href="`/company/${assignment.companyId}/`">
                      <f7-icon material="business"></f7-icon>
                      <span>{{assignment.companyName}}</span>
                      <f7-icon f7="chevron_right"></f7-icon>
                    </a>
                  </div>
                  <div class="assignment-category" v-if="typeof assignment.category !== 'undefined'">
                    <f7-icon material="local_offer"></f7-icon>
                    <span>{{$t(`assignment_category.${assignmentCategoryMap(assignment.category)}`)}}</span>
                  </div>
                  <div class="assignment-date" v-if="assignment.publishedDate">
                    <f7-icon material="date_range"></f7-icon>
                    <span>{{$t('assignment.published_date_range', { from: formatDate(assignment.publishedDate), to: formatDate(assignment.endDate) })}}</span>
                  </div>
                </div>

                <div class="assignment-reward" v-if="!rewardDisabled">
                  <div class="assignment-reward-finder-info-title">
                    <f7-icon material="person"></f7-icon>
                    <span>
                      <span>{{$t('assignment.accept_info_to_finder')}}:</span>
                    </span>
                  </div>
                  {{$t('assignment.reward_to_fc_label')}}
                  <span class="assignment-reward-number text-color-brand-green">{{rewardText}}</span>
                  <div v-if="assignment.multipleWinners" style="font-weight: 400; font-size: 14px">- {{$t('assignment.accept_many_reward_info', { level: null })}}</div>

                  <div class="assignment-reward-details" v-if="rewardDetails() && !assignment.multipleLevels">{{rewardDetails()}}</div>
                  <div class="assignment-reward-description" v-if="!assignment.multipleLevels && assignment.rewards[0].description" v-html="formatText(assignment.rewards[0].description)"></div>

                  <div class="assignment-reward-description" v-if="!assignment.multipleLevels && assignment.rewards[0] && assignment.rewards[0].description">
                    <f7-icon material="assignment"></f7-icon>
                    <span>{{assignment.rewards[0].description}}</span>
                  </div>

                  <div class="assignment-accept-many">
                    <f7-icon :material="assignment.multipleWinners ? 'supervisor_account' : 'person'"></f7-icon>
                    <span>
                      <span v-if="assignment.multipleWinners" v-html="$t('assignment.accept_many_info')"></span>
                      <span v-else v-html="$t('assignment.accept_one_info')"></span>
                    </span>
                  </div>
                </div>

                <div class="assignment-description" v-if="assignment.description">
                  <rich-text :text="assignment.description" :show-previews="false"></rich-text>
                </div>

                <div class="assignment-message-for-interested" v-if="assignment.messageForInterested && assignment.category !== assignmentCategoryList.Innovation">
                  <div class="assignment-message-for-interested-title">{{$t('assignment.interested_message_title')}}  ({{$t('assignment.interested_message_title_interested_only')}})</div>
                  <div class="assignment-message-for-interested-content">
                    <div v-html="formatText(assignment.messageForInterested)"></div>
                  </div>
                </div>

                <div class="assignment-message-for-finder" v-if="assignment.messageForFinder">
                  <div class="assignment-message-for-finder-title">{{$t('assignment.interested_message_title')}} ({{$t('assignment.interested_message_title_finder_only')}})</div>
                  <div class="assignment-message-for-finder-content">
                    <div v-html="formatText(assignment.messageForFinder)"></div>
                  </div>
                </div>

                <attachments
                  v-if="mediaFilesFromText.length || assignment.files && assignment.files.length"
                  :files="assignment.files || []"
                  :extra-media-files="mediaFilesFromText"
                  :allow-edit="false"
                ></attachments>
              </div>
            </div>
          </f7-block>

          <template v-if="assignment.multipleLevels && !rewardDisabled">
            <f7-block-title>{{$t('assignment.rewards_title')}}</f7-block-title>
            <div class="timeline rewards-timeline">
              <div class="timeline-item"
                v-for="(reward, index) in assignment.rewards"
                :key="reward.id"
                :class="{ active: reward.level === assignment.rewardLevel}"
              >
                <div class="timeline-item-divider">{{reward.level}}</div>
                <div class="timeline-item-content" style="width: 100%">
                  <f7-list media-list inset class="assignment-rewards-list no-safe-areas">
                    <f7-list-item
                      :subtitle="reward.description"
                      media-item
                    >
                      <span slot="inner-start">{{$t('assignment.reward_level_number', { level: reward.level || (index + 1) })}}:</span>
                      <span slot="title" v-html="$t('assignment.reward_to_fc_level_title', {
                        amount: `<span class=text-color-brand-green>${rewardLevelAmount(reward)}</span>`,
                      })"></span>
                      <div slot="title" v-if="assignment.multipleWinners" style="font-weight: 400; font-size: 14px; margin-bottom: 10px">- {{$t('assignment.accept_many_reward_info', { level: reward.level || (index + 1) })}}</div>
                      <span slot="text">{{rewardDetails(reward)}}</span>
                    </f7-list-item>
                  </f7-list>

                </div>
              </div>
            </div>
          </template>

          <template v-if="!isGuidPreview">
            <f7-block-title>{{$t('assignment.preview_link')}}</f7-block-title>
            <template v-if="assignment.guid">
              <f7-block strong><a :href="`${host}/preview/${assignment.guid}/`" target="_blank" class="clickable external">{{`${host}/preview/${assignment.guid}/`}}</a></f7-block>
            </template>
            <template v-else>
              <f7-block strong class="text-align-center">{{$t('assignment.preview_needs_save')}}</f7-block>
            </template>
          </template>

          <template v-if="!assignment.anonymous && moreAssignments && moreAssignments.length">
            <f7-block-title>{{$t('assignment.more_assignments_title', {companyName: assignment.companyName})}}</f7-block-title>
            <f-assignment-list :company-preview="true" :assignments="moreAssignments" :logo="assignment.logo" ></f-assignment-list>
          </template>
        </div>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
  import { mapGetters } from 'vuex';
  import striptags from 'striptags';
  import formatDate from '../../utils/format-date';
  import formatNumber from '../../utils/format-number';
  import currencyMap from '../../utils/currency-map';
  import assignmentCategoryMap from '../../utils/assignment-category-map';
  import rewardText from '../../utils/reward-text';
  import rewardDetails from '../../utils/reward-details';
  import rewardLevelAmount from '../../utils/reward-level-amount';
  import mediaFilesFromText from '../../utils/media-files-from-text';
  import config from '../../config';
  import assignmentCategoryList from '../../utils/assignment-category-list';

  export default {
    props: {
      id: [String, Number],
      guid: String,
    },
    data() {
      return {
        // assignment: this.$f7route.context.assignment,
        assignmentCategoryList,
        largeScreen: this.$f7.width >= 768,
        host: config.host,
      };
    },
    created() {
      const self = this;
      if (self.guid) {
        self.getPreviewByGuid();
      }
    },
    mounted() {
      const self = this;
      self.onResize = function onResize() {
        if (self.$f7.width >= 768) {
          self.$set(self, 'largeScreen', true);
        } else {
          self.$set(self, 'largeScreen', false);
        }
      };
      self.$f7.on('resize', self.onResize);
    },
    beforeDestroy() {
      const self = this;
      if (self.onResize) {
        self.$f7.off('resize', self.onResize);
      }
      self.destroyed = true;
    },
    computed: {
      ...mapGetters({
        assignment: 'a/assignmentPreview',
      }),
      rewardDisabled() {
        return this.assignment ? this.assignment.isInternal && this.assignment.disableReward : false;
      },
      mediaFilesFromText() {
        return this.assignment ? mediaFilesFromText(this.assignment.description) : [];
      },
      isGuidPreview() {
        return !!this.guid;
      },
      rewardText() {
        const self = this;
        const { assignment } = self;
        return rewardText(self, assignment);
      },
      moreAssignments() {
        const self = this;
        return self.assignment.companyAssignments ? self.assignment.companyAssignments.filter(({ id }) => id !== self.assignment.id) : [];
      },
    },
    methods: {
      formatNumber,
      assignmentCategoryMap,
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
      currencyMap,
      rewardLevelAmount(reward) {
        const self = this;
        return rewardLevelAmount(self, { shareOut: 100 }, reward);
      },
      rewardDetails(reward) {
        const self = this;
        const { assignment } = self;
        return rewardDetails(self, assignment, reward);
      },
      formatText(text) {
        return striptags(text || '').replace(/\n/g, '<br>');
      },
      getPreviewByGuid() {
        const self = this;
        if (!self.guid) return;
        self.$store.dispatch('a/getPreviewByGuid', {
          guid: self.guid,
          callbackOk(previewData) {
            self.$store.dispatch('a/setAssignmentPreview', previewData);
          },
        });
      },
    },
  };
</script>
