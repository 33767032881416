const progress = {
  loaders: 0,
  loadersTimeout: null,
  show(f7) {
    if (!f7 || !f7.progressbar) return;
    if (progress.loaders > 0) {
      progress.loaders += 1;
      return;
    }
    progress.loaders += 1;
    clearTimeout(progress.loadersTimeout);
    f7.progressbar.show();
  },
  hide(f7) {
    if (!f7 || !f7.progressbar) return;
    if (progress.loaders < 2) {
      progress.loaders = 0;
      progress.loadersTimeout = setTimeout(() => {
        f7.progressbar.hide();
      }, 100);
      return;
    }
    progress.loaders -= 1;
  },
};

export default progress;
