import assignmentStatusList from './assignment-status-list';

export default function (statusId) {
  let statusText;
  Object.keys(assignmentStatusList).forEach((key) => {
    if (assignmentStatusList[key] === statusId) statusText = key;
  });
  return statusText;
}

