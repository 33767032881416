<template>
  <f7-page class="assignment-details" @page:afterin="onPageAfterIn">
    <f7-navbar>
      <f7-nav-left>
        <back-link />
      </f7-nav-left>
      <f7-nav-title>{{$t('my_assignment.title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>

    <share-fab
      v-if="assignment && !assignment.isPrivate && assignment.status === assignmentStatusList.Published"
      :title="assignment.title"
      :message="assignment.description"
      :url="`${host}/assignment/${assignment.id}/${profile && profile.guid ? `?fid=${profile.guid}` : ''}`"
    />

    <div v-if="assignment" class="center-content">
      <f7-block-title class="display-flex justify-content-flex-end align-items-center">
        <f7-button fill round small class="assignment-edit-button" :href="`/assignment/${id}/edit/`">
          <f7-icon material="edit"></f7-icon>
          <span>{{$t('my_assignment.edit_link')}}</span>
        </f7-button>
        <f7-button fill round small class="assignment-edit-button margin-left" @click="previewAssignment">
          <f7-icon material="remove_red_eye"></f7-icon>
          <span>{{$t('my_assignment.preview_link')}}</span>
        </f7-button>
      </f7-block-title>
      <f7-block strong class="assignment-details-block">
        <div class="assignment-details-content-wrap">
          <div class="assignment-details-logo">
            <logo class="company-logo" :file="assignment.logo" :size="120"></logo>
          </div>
          <div class="assignment-details-content">
            <div class="assignment-title">{{assignment.title}}</div>

            <div class="assignment-status">
              <span class="assignment-status-badge" :data-status="assignmentStatusMap(assignment.status)">{{$t(`assignment_status.${assignmentStatusMap(assignment.status)}`)}}</span>
            </div>
            <div class="assignment-declined-message" v-if="assignment.status === assignmentStatusList.Declined" v-html="$t('my_assignment.decline_reason', {
              reason: `<i>${formatReason(assignment.declineReason)}</i>`
            })"></div>

            <div class="assignment-meta">
              <div class="assignment-category" v-if="typeof assignment.category !== 'undefined'">
                <f7-icon material="local_offer"></f7-icon>
                <span>{{$t(`assignment_category.${assignmentCategoryMap(assignment.category)}`)}}</span>
              </div>
              <div class="assignment-date" v-if="assignment.publishedDate">
                <f7-icon material="date_range"></f7-icon>
                <span>{{$t('assignment.published_date_range', { from: formatDate(assignment.publishedDate), to: formatDate(assignment.endDate) })}}</span>
              </div>
            </div>

            <div class="assignment-reward" v-if="!rewardDisabled">
              {{$t('assignment.reward_to_fc_label')}}
              <span class="assignment-reward-number text-color-brand-green">{{rewardText}}</span>
              <div v-if="assignment.multipleWinners" style="font-weight: 400; font-size: 14px">- {{$t('assignment.accept_many_reward_info', { level: null })}}</div>
            </div>
          </div>
        </div>
      </f7-block>

      <f7-block-title>{{$t('assignment.overview_title')}}</f7-block-title>
      <f7-block strong class="assignment-stats row gauges-row">
        <f7-col v-if="chartData">
          <pie-chart
            :size="200"
            :border-width="50"
            :data="chartData"
            :showLabels="true"
            :visible="true"
          ></pie-chart>
        </f7-col>
        <f7-col>
          <gauge
            :size="200"
            :progress="(assignment.interested.length || 0)/(assignment.contacted || 1)"
            :numberText="`${assignment.interested.length || 0} / ${assignment.contacted || 0}`"
            :numberFontSize="30"
            :labelText="`${$t('my_assignment.interested_label')} / ${$t('my_assignment.recommended_label')}`"
            :labelFontSize="12"
            labelColor="#555"
            strokeFillColor="#ddd"
            strokeColor="#f47321"
            :strokeWidth="12"
            :visible="true"
          ></gauge>
        </f7-col>
      </f7-block>
      <f7-block strong v-if="assignment.status === assignmentStatusList.Closed && assignment.needsCandidateRating" class="text-align-center">
        <rating :allow-rate="false" :value="5"></rating>
        <p>{{$t('my_assignment.rate_candidates')}}</p>
      </f7-block>
      <f7-block-title v-if="favorites && favorites.length" class="display-flex align-items-center" style="overflow: visible">
        <span style="height: 14px; margin-right: 5px" class="display-flex align-items-center">
          <f7-icon size="20" color="brand-orange" ios="f7:star_fill" md="material:star"></f7-icon>
        </span>
        <span style="line-height: 1">{{$t('my_assignment.favorite_title')}}</span>
      </f7-block-title>
      <f7-list media-list class="candidates-list" v-if="favorites && favorites.length">
        <f7-list-item
          v-for="interest in favorites"
          :key="interest.id"
          :link="`/candidate/${interest.id}/`"
          :title="interest.firstName && interest.lastName ? `${interest.firstName} ${interest.lastName}` : `${interest.email}`"
        >
          <span slot="after" class="badge color-brand-red" v-if="interest.unreadCount">{{interest.unreadCount}}</span>
          <span slot="after">{{formatDate(interest.createdDate)}}</span>
          <div class="candidate-status-badge" slot="inner-start" :data-status="candidateStatusMap(interest.status)">{{statusTextShort(interest.status)}}</div>

          <rating slot="subtitle" :allow-rate="false" :value="interest.rating || 0"></rating>
          <template v-if="assignment.multipleLevels">
            <div slot="text"
              class="candidate-status-text"
              v-for="(n, index) in interest.level"
              :key="index"
              v-if="n < interest.level"
            >
              <span class="candidate-status-badge" data-status="AgreementMade">{{n}}</span>
              <span>{{$t(`candidate_status.AgreementMade`)}}</span>
            </div>
            <div slot="text" class="candidate-status-text">
              <span class="candidate-status-badge" :data-status="candidateStatusMap(interest.status)">{{interest.level}}</span>
              <span>{{$t(`candidate_status.${candidateStatusMap(interest.status)}`)}}</span>
            </div>
          </template>
        </f7-list-item>
      </f7-list>
      <template v-if="assignment.interested && assignment.interested.length">
        <f7-block-title>{{$t('my_assignment.interested_title', {amount: assignment.interested.length})}}</f7-block-title>
        <f7-list media-list class="candidates-list">
          <f7-list-item
            v-for="interest in orderedInterests"
            :key="interest.id"
            :link="`/candidate/${interest.id}/`"
            :title="interest.firstName && interest.lastName ? `${interest.firstName} ${interest.lastName}` : `${interest.email}`"
            :class="{'candidate-unread' : interest.unread}"
            @click="interest.unread = false"
          >
            <div class="candidate-status-badge" slot="inner-start" :data-status="candidateStatusMap(interest.status)">{{statusTextShort(interest.status)}}</div>
            <span slot="after" class="badge color-brand-red" v-if="interest.unreadCount">{{interest.unreadCount}}</span>
            <span slot="after">{{formatDate(interest.createdDate)}}</span>
            <rating slot="subtitle" :allow-rate="false" :value="interest.rating || 0"></rating>
            <template v-if="assignment.multipleLevels">
              <div slot="text"
                class="candidate-status-text"
                v-for="(n, index) in interest.level"
                :key="index"
                v-if="n < interest.level"
              >
                <span class="candidate-status-badge" data-status="AgreementMade">{{n}}</span>
                <span>{{$t(`candidate_status.AgreementMade`)}}</span>
              </div>
              <div slot="text" class="candidate-status-text">
                <span class="candidate-status-badge" :data-status="candidateStatusMap(interest.status)">{{interest.level}}</span>
                <span>{{$t(`candidate_status.${candidateStatusMap(interest.status)}`)}}</span>
              </div>
            </template>
          </f7-list-item>
        </f7-list>
      </template>
      <template v-else>
        <f7-block-title>{{$t('my_assignment.interested_title_empty')}}</f7-block-title>
        <f7-block strong class="text-align-center">{{$t('my_assignment.no_interest')}}</f7-block>
      </template>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import striptags from 'striptags';
  import formatNumber from '../../utils/format-number';
  import formatDate from '../../utils/format-date';
  import currencyMap from '../../utils/currency-map';
  import assignmentStatusMap from '../../utils/assignment-status-map';
  import assignmentStatusList from '../../utils/assignment-status-list';
  import assignmentCategoryMap from '../../utils/assignment-category-map';
  import candidateStatusMap from '../../utils/candidate-status-map';
  import linkify from '../../utils/linkify';
  import rewardText from '../../utils/reward-text';
  import dispatchWithRetry from '../../utils/dispatch-with-retry';
  import config from '../../config';

  export default {
    mixins: [dispatchWithRetry],
    props: {
      id: [String, Number],
    },
    data() {
      return {
        assignmentStatusList,
        assignment: null,
        host: config.host,
      };
    },
    created() {
      const self = this;
      self.dispatchWithRetry('c/getMyAssignment', { id: parseInt(self.id, 10) });
      self.timeout = setTimeout(() => {
        self.update();
      }, 30000);
    },
    watch: {
      currentAssignment(newValue) {
        const self = this;
        if (!newValue) return;
        if (newValue && newValue.id !== parseInt(self.id, 10)) {
          return;
        }
        self.assignment = newValue;
      },
    },
    computed: {
      rewardDisabled() {
        return this.assignment ? this.assignment.isInternal && this.assignment.disableReward : false;
      },
      chartData() {
        const self = this;
        const data = [];
        const statusData = {
          Pending: 0,
          InterestReceived: 0,
          Qualified: 0,
          FinalStage: 0,
          NoFurtherProcess: 0,
          HadContactWithRecommendation: 0,
          AgreementMade: 0,
          Declined: 0,
          Maybe: 0,
          RecommendationReceived: 0,
        };
        self.assignment.interested.forEach((candidate) => {
          const key = candidateStatusMap(candidate.status);
          if (key in statusData) statusData[key] += 1;
        });
        Object.keys(statusData).forEach((key) => {
          const value = statusData[key];
          if (!value) return;
          let color = '#a0a0a0';
          if (key === 'Pending') {
            color = '#ffd400';
          }
          if (key === 'InterestReceived') {
            color = '#2196f3';
          }
          if (key === 'RecommendationReceived') {
            color = '#2196f3';
          }
          if (key === 'Qualified') {
            color = '#2196f3';
          }
          if (key === 'FinalStage') {
            color = '#f47321';
          }
          if (key === 'AgreementMade') {
            color = '#3FD77A';
          }
          if (key === 'NoFurtherProcess' || key === 'HadContactWithRecommendation') {
            color = '#FD2D4F';
          }
          if (key === 'Maybe') {
            color = '#ffd400';
          }
          data.push({
            color,
            value,
            label: self.$t(`candidate_status_short.${key}`, { fromFinder: key === 'RecommendationReceived' }),
          });
        });
        if (data.length === 0) {
          data.push({ color: '#dddddd', value: 0, label: self.$t('my_assignment.no_interest') });
        }
        return data;
      },
      rewardText() {
        const self = this;
        const { assignment } = self;
        return rewardText(self, assignment);
      },
      favorites() {
        const self = this;
        return self.assignment.interested.filter(candidate => candidate.favorite);
      },
      orderedInterests() {
        const self = this;
        return self.assignment.interested.sort((a, b) => {
          const dateA = new Date(a.createdDate).getTime();
          const dateB = new Date(b.createdDate).getTime();
          if (dateB > dateA) return 1;
          return -1;
        });
      },
      statusColorClass() {
        const self = this;
        if (self.assignment.status === assignmentStatusList.Published) return 'text-color-brand-green';
        if (self.assignment.status === assignmentStatusList.Declined) return 'text-color-brand-red';
        return 'text-color-brand-gray';
      },
      ...mapGetters({
        currentAssignment: 'c/myAssignment',
        loading: 'c/myAssignmentLoading',
        profile: 'c/profile',
      }),
    },
    beforeDestroy() {
      const self = this;
      self.$store.dispatch('c/unsetMyAssignment');
      self.destroyed = true;
      clearTimeout(self.timeout);
    },
    methods: {
      currencyMap,
      formatNumber,
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
      formatText(text) {
        return striptags(text || '').replace(/\n/g, '<br>');
      },
      assignmentStatusMap,
      candidateStatusMap,
      assignmentCategoryMap,
      formatReason(text) {
        return linkify(text, false).text.replace(/\n/g, '<br>');
      },
      statusTextShort(id) {
        return this.$t(`candidate_status_short.${candidateStatusMap(id)}`, { fromFinder: id === 'RecommendationReceived' });
      },
      scrollToReplies() {
        const self = this;
        const $ = self.$$;
        const $el = $(self.$el);
        const $candidatesListEl = $el.find('.candidates-list');
        if ($candidatesListEl.length === 0) return;
        const scrollTop = $candidatesListEl.prev('.block-title').offset().top - $el.offset().top - parseInt($el.find('.page-content').css('padding-top'), 10);
        $el.find('.page-content').scrollTop(scrollTop, 300);
      },
      update() {
        const self = this;
        if (self.destroyed) return;
        self.dispatchWithRetry('c/updateMyAssignment', {
          id: parseInt(self.id, 10),
          callbackOk() {
            if (self.destroyed) return;
            self.timeout = setTimeout(() => {
              if (self.destroyed) return;
              self.update();
            }, 30000);
          },
        });
      },
      previewAssignment() {
        const self = this;
        if (!self.assignment) return;
        self.$store.dispatch('c/getPreview', {
          id: self.assignment.id,
          callbackOk(company) {
            self.$store.dispatch('c/setAssignmentPreview', {
              ...self.assignment,
              companyName: company.companyName,
              logo: company.logo,
              companyId: company.id,
              guid: company.assignmentGuid,
            });
            self.$f7router.navigate('/assignment-preview/');
          },
        });
      },
      onPageAfterIn(e, page) {
        const self = this;
        if (page.direction === 'backward') {
          self.dispatchWithRetry('c/updateMyAssignment', { id: parseInt(self.id, 10) });
        }
      },
    },
  };
</script>
