/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';

const state = {
  company: null,
  companyLoading: false,
};

const getters = {
  companyLoading(state) {
    return state.companyLoading;
  },
  company(state) {
    return state.company;
  },
};
const actions = {
  getCompany({ commit, state }, { id }) {
    if (state.company) return;
    API.get({
      url: `company/company/details?id=${id}`,
      loading: 'c/setCompanyLoading',
      ok(res) {
        commit('setCompany', res);
      },
    });
  },
  unsetCompany({ commit }) {
    commit('unsetCompany');
  },
};

const mutations = {
  setCompany(state, company) {
    state.company = company;
  },
  setCompanyLoading(state, status) {
    state.companyLoading = status;
  },
  unsetCompany(state) {
    state.company = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
