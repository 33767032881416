const config = {
  host: '',
  version: process.env.APP_VERSION,
};
if (process.env.CONFIG_ENV === 'development') {
  Object.assign(config, {
    host: 'http://localhost:5000',
    appInsightsKey: '9bca89ea-3bfc-4df1-be86-9ffa427c4643',
    path: {
      files: 'https://findersmasterstorage.blob.core.windows.net/files/',
      images: 'https://finderscrowd-master.imgix.net/',
    },
    googleAuth: {
      clientId: '321024949769-qsq39hvnvi7jg7droo54bjt2oe0rbhtt.apps.googleusercontent.com',
    },
    firebase: {
      apiKey: 'AIzaSyBjpA1zmoSEkUSHiCqNG8kiDZTm7eYbgM4',
      authDomain: 'finder-master.firebaseapp.com',
      databaseURL: 'https://finder-master.firebaseio.com',
      projectId: 'finder-master',
      storageBucket: 'finder-master.appspot.com',
      messagingSenderId: '321024949769',
      publicVapidKey: 'BFBuZCo8w_6HM2kN4NBpUVgmQ9sN2VKywhd2-XRhiHsy-voR6ka7sqQxtvBQo2LtxVi0_MDFst3s4RtjC7U8YJU',
    },
  });
}
if (process.env.CONFIG_ENV === 'master') {
  Object.assign(config, {
    host: 'https://master.finderscrowd.com',
    appInsightsKey: '9bca89ea-3bfc-4df1-be86-9ffa427c4643',
    path: {
      files: 'https://findersmasterstorage.blob.core.windows.net/files/',
      images: 'https://finderscrowd-master.imgix.net/',
    },
    googleAuth: {
      clientId: '321024949769-qsq39hvnvi7jg7droo54bjt2oe0rbhtt.apps.googleusercontent.com',
    },
    firebase: {
      apiKey: 'AIzaSyBjpA1zmoSEkUSHiCqNG8kiDZTm7eYbgM4',
      authDomain: 'finder-master.firebaseapp.com',
      databaseURL: 'https://finder-master.firebaseio.com',
      projectId: 'finder-master',
      storageBucket: 'finder-master.appspot.com',
      messagingSenderId: '321024949769',
      publicVapidKey: 'BFBuZCo8w_6HM2kN4NBpUVgmQ9sN2VKywhd2-XRhiHsy-voR6ka7sqQxtvBQo2LtxVi0_MDFst3s4RtjC7U8YJU',
    },
  });
}
if (process.env.CONFIG_ENV === 'stage') {
  Object.assign(config, {
    host: 'https://finders-prod-staging.azurewebsites.net',
    appInsightsKey: '9bca89ea-3bfc-4df1-be86-9ffa427c4643',
    path: {
      files: 'https://findersmasterstorage.blob.core.windows.net/files/',
      images: 'https://finderscrowd-master.imgix.net/',
    },
    googleAuth: {
      clientId: '321024949769-qsq39hvnvi7jg7droo54bjt2oe0rbhtt.apps.googleusercontent.com',
    },
    firebase: {
      apiKey: 'AIzaSyBjpA1zmoSEkUSHiCqNG8kiDZTm7eYbgM4',
      authDomain: 'finder-master.firebaseapp.com',
      databaseURL: 'https://finder-master.firebaseio.com',
      projectId: 'finder-master',
      storageBucket: 'finder-master.appspot.com',
      messagingSenderId: '321024949769',
      publicVapidKey: 'BFBuZCo8w_6HM2kN4NBpUVgmQ9sN2VKywhd2-XRhiHsy-voR6ka7sqQxtvBQo2LtxVi0_MDFst3s4RtjC7U8YJU',
    },
  });
}
if (process.env.CONFIG_ENV === 'prod') {
  Object.assign(config, {
    host: 'https://app.finderscrowd.com',
    appInsightsKey: '4c5bdc81-3b95-488e-b92e-a59440e47a04',
    path: {
      files: 'https://findersprodstorage.blob.core.windows.net/files/',
      images: 'https://finderscrowd.imgix.net/',
    },
    googleAuth: {
      clientId: '246473296778-r0f175rkhgpmpqlc86hok9kr1vgvefaf.apps.googleusercontent.com',
    },
    firebase: {
      apiKey: 'AIzaSyBZRj9Qu_JAh-Zq7lNlWExdWGOvJ2Gn7M0',
      authDomain: 'finder-prod.firebaseapp.com',
      databaseURL: 'https://finder-prod.firebaseio.com',
      projectId: 'finder-prod',
      storageBucket: 'finder-prod.appspot.com',
      messagingSenderId: '246473296778',
      publicVapidKey: 'BB6MfU3HAZgGOU5GtKLwBBWFqji_hAD-KbQC2lhNJ_InNNVNUbNxF4krwzwC8mYw5-YT4EJuKaECOrSVfXRhf2w',
    },
  });
}
module.exports = config;