<template>
  <f7-page
    infinite
    :infinite-preloader="false"
    @infinite="onInfinite"
    @page:beforein="onPageBeforeIn"
    @page:afterin="onPageAfterIn"
  >
    <f7-navbar>
      <panel-link slot="nav-left" />
      <f7-nav-title>
        <f7-link popover-open=".crowd-popover" class="no-padding navbar-crowd-link" v-if="crowd || crowdPassed">
          <span>{{crowd ? crowd.name : (crowdPassed ? crowdPassed.name : '')}}</span>
          <f7-icon ios="f7:gear_fill" md="material:settings" />
        </f7-link>
      </f7-nav-title>
      <f7-nav-right v-if="crowd || crowdPassed">
        <profile-link />
      </f7-nav-right>
    </f7-navbar>

    <offline-indicator slot="fixed"></offline-indicator>

    <f7-popover slot="fixed" class="crowd-popover" v-if="crowd || crowdPassed">
      <f7-list>
        <f7-list-item
          :title="$t('assignments.leave_crowd')"
          popover-close
          link
          @click="leaveCrowd"
        >
          <f7-icon slot="media" f7="exit_fill" />
        </f7-list-item>
      </f7-list>
    </f7-popover>

    <chat-button
      slot="fixed"
      v-if="crowd && crowd.isJoined"
      :crowd-id="crowdId"
      :crowd-name="crowd.name"
    />

    <div class="center-content center-content-blocks">
      <f7-list class="pending-invite" v-if="crowd && !crowd.isJoined" media-list>
        <f7-list-item
          :title="$t('assignments.pending_crowd_invite_text', {companyName: crowd.companyName, crowdName: crowd.name})"
        >
          <logo class="company-logo" :logo="crowd.logo" :size="60" slot="media"></logo>
          <div slot="inner">{{crowd.description}}</div>
          <div slot="inner" class="pending-invite-buttons">
            <loading-button :loading="inviteInProcess === 'accept'" :disabled="inviteInProcess === 'decline'" small fill @click="acceptInvite" preloaderColor="white" :text="$t('assignments.pending_invite_accept')"></loading-button>
            <loading-button :loading="inviteInProcess === 'decline'" :disabled="inviteInProcess === 'accept'" small @click="declineInvite" preloaderColor="brand-orange" :text="$t('assignments.pending_invite_decline')"></loading-button>
          </div>
        </f7-list-item>
      </f7-list>
      <template v-if="(crowd && crowd.isJoined) || (crowdPassed && crowdPassed.isJoined)">
        <template v-if="!assignments">
          <f7-block strong class="assignment-list-filter">
            <div class="input input-dropdown skeleton-text skeleton-effect-fade">
              <select disabled readonly>
                <option>____ ____ ____</option>
              </select>
            </div>
          </f7-block>
          <skeleton-assignment-list></skeleton-assignment-list>
        </template>
        <f7-block strong v-if="hasAssignments || category !== null" class="assignment-list-filter">
          <div class="input input-dropdown">
            <select :value="category" @change="changeCategoryFilter($event.target.value)">
              <option :value="null">{{$t('assignment_category.All')}}</option>
              <option
                v-for="(category, key) in assignmentCategoryList"
                :value="category"
                :data-key="key"
                :key="key"
              >{{$t(`assignment_category.${key}`)}}</option>
            </select>
          </div>
        </f7-block>
        <f-assignment-list v-if="assignments && assignments.length" :assignments="assignments"></f-assignment-list>
        <f7-block strong v-if="assignments && !assignments.length" class="empty-block">
          <p>{{$t('assignments.no_published_assignments')}}</p>
        </f7-block>
        <f7-preloader v-if="allowInfinite && listHasMore" class="infinite-scroll-preloader"></f7-preloader>
      </template>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import events from '../../modules/events';
  import dispatchWithRetry from '../../utils/dispatch-with-retry';
  import assignmentCategoryList from '../../utils/assignment-category-list';

  export default {
    mixins: [dispatchWithRetry],
    props: {
      crowdId: [Number, String],
      crowdPassed: Object,
    },
    data() {
      return {
        crowd: null,
        assignments: null,
        assignmentCategoryList,
        allowInfinite: false,
        hasAssignments: false,
        inviteInProcess: false,
      };
    },
    created() {
      const self = this;

      localStorage.lastCrowdId = self.crowdId;

      self.$store.dispatch('f/unsetCrowd');
      self.$store.dispatch('f/unsetCrowdAssignmentList');
      self.$store.dispatch('f/unsetCrowdAssignmentListCategory');

      if (self.profile) {
        self.dispatchWithRetry('f/getCrowdAssignmentList', { crowdId: self.crowdId });
        self.dispatchWithRetry('f/getCrowd', { id: self.crowdId });
      }

      self.timeout = setTimeout(() => {
        self.update();
      }, 30000);
    },
    beforeDestroy() {
      const self = this;
      self.destroyed = true;
      clearTimeout(self.timeout);
    },
    computed: {
      isEnterprise() {
        return this.profile && this.profile.isEnterprise;
      },
      userConfirmed() {
        return this.$root.userConfirmed;
      },
      ...mapGetters({
        storeCrowd: 'f/crowd',
        storeAssignments: 'f/crowdAssignmentList',
        category: 'f/crowdAssignmentListCategory',
        loading: 'f/crowdAssignmentListLoading',
        loadingMore: 'f/crowdAssignmentListMoreLoading',
        profile: 'f/profile',
        listHasMore: 'f/crowdAssignmentListHasMore',
      }),
    },
    watch: {
      storeCrowd() {
        if (this.storeCrowd && parseInt(this.crowdId, 10) === this.storeCrowd.id) {
          this.crowd = this.storeCrowd;
        }
      },
      storeAssignments() {
        const crowdId = parseInt(this.crowdId, 10);
        if (this.storeAssignments && this.storeAssignments.crowdId === crowdId) {
          this.assignments = this.storeAssignments.items;
        }
      },
      profile(profile, oldProfile) {
        const self = this;
        if (profile && !oldProfile) {
          self.dispatchWithRetry('f/getCrowdAssignmentList', { crowdId: self.crowdId });
          self.dispatchWithRetry('f/getCrowd', { id: self.crowdId });
        }
      },
      'assignments.length': function watchAssignments(length) {
        const self = this;
        if (length) {
          self.hasAssignments = true;
        }
        if (length && length % 20 === 0) {
          self.allowInfinite = true;
        } else {
          self.allowInfinite = false;
        }
      },
    },

    mounted() {
      const self = this;
      self.setScrollOnUpdate = true;
      events.$emit('setActiveMenu', parseInt(self.crowdId, 10));
      events.$on('assignment-created', () => {
        self.onAssignmentCreated = true;
      });
    },
    methods: {
      leaveCrowd() {
        const self = this;
        self.$f7.dialog.confirm(self.$t('assignments.leave_crowd_confirm'), () => {
          self.$store.dispatch('f/leaveCrowd', {
            crowdId: parseInt(self.crowdId, 10),
            callbackOk() {
              events.$emit('refreshCrowdList');
              self.$f7router.navigate('/', { reloadAll: true });
            },
          });
        });
      },
      acceptInvite() {
        const self = this;
        self.inviteInProcess = 'accept';
        self.$store.dispatch('f/acceptInvite', {
          crowdId: self.crowd.id,
          callbackOk() {
            self.$store.dispatch('f/getCrowd', { id: self.crowdId });
            self.$store.dispatch('f/getCrowdAssignmentList', { crowdId: self.crowdId });
            events.$emit('refreshCrowdList');
          },
          callbackError() {
            self.inviteInProcess = false;
          },
        });
      },
      declineInvite() {
        const self = this;
        self.inviteInProcess = 'decline';
        self.$store.dispatch('f/declineInvite', {
          crowdId: self.crowd.id,
          callbackOk() {
            events.$emit('refreshCrowdList');
            self.$f7router.navigate('/', { reloadAll: true });
          },
          callbackError() {
            self.inviteInProcess = false;
          },
        });
      },
      restoreScroll() {
        const self = this;
        self.setScrollOnUpdate = false;
        if (!self.$device.cordova) return;
        const scrollTop = self.$f7router.scrollHistory[self.$f7route.url];
        if (scrollTop) {
          self.$nextTick(() => {
            self.$$(self.$el).find('.page-content').scrollTop(scrollTop);
          });
        }
      },
      changeCategoryFilter(categoryId) {
        const self = this;
        self.$store.dispatch('f/setCrowdAssignmentListCategory', { category: categoryId || null, crowdId: self.crowdId });
      },
      onInfinite() {
        const self = this;
        if (!self.allowInfinite || self.loadingMore || !self.listHasMore) return;
        self.$store.dispatch('f/getMoreCrowdAssignmentList', { crowdId: self.crowdId });
      },
      onPageBeforeIn(e, page) {
        const self = this;
        if (page.direction === 'backward') {
          clearTimeout(self.timeout);
          self.update();
        }
      },
      onPageAfterIn(e, page) {
        const self = this;
        if (page.direction === 'backward') {
          if (self.onAssignmentCreated) {
            self.onAssignmentCreated = false;
          }
          self.$store.dispatch('f/unsetAssignment');
        } else {
          setTimeout(() => {
            if (self.$root.justLoggedIn && self.$f7router.history.length === 1 && self.$f7router.currentRoute.name === 'assignment-list') {
              self.$f7router.navigate('/my-assignments/', { reloadAll: true });
            }
            self.$root.justLoggedIn = false;
          }, 100);
        }
      },
      update() {
        const self = this;
        if (self.destroyed) return;
      },
    },
  };
</script>
