<template>
  <f7-fab v-if="!$device.cordova">
    <f7-icon ios="f7:share" md="material:share"></f7-icon>
    <f7-icon f7="close"></f7-icon>
    <f7-fab-buttons>
      <f7-fab-button fab-close :label="$t('general.share_on_facebook')" @click="shareFacebook">
        <f7-icon f7="logo_facebook" :size="20"></f7-icon>
      </f7-fab-button>
      <f7-fab-button fab-close :label="$t('general.share_on_linkedin')" @click="shareLinkedIn">
        <f7-icon f7="logo_linkedin" :size="20"></f7-icon>
      </f7-fab-button>
    </f7-fab-buttons>
  </f7-fab>
  <f7-fab v-else @click="shareCordova">
    <f7-icon ios="f7:share" md="material:share"></f7-icon>
  </f7-fab>
</template>
<script>
  import share from '../../modules/share';

  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      message: {
        type: String,
        default: '',
      },
      url: {
        type: String,
        default: '',
      },
    },
    methods: {
      shareCordova() {
        const self = this;
        let rect;
        if (self.$device.ios) {
          rect = self.$el.getBoundingClientRect();
        }
        share.cordova({
          message: `FindersCrowd - ${self.title}`,
          subject: self.title,
          url: self.url,
          rect,
        });
      },
      shareFacebook() {
        const self = this;
        share.facebook({
          url: self.url,
        });
      },
      shareLinkedIn() {
        const self = this;
        const url = encodeURIComponent(self.url);
        const title = encodeURI(self.title.substr(0, 200));
        const summary = encodeURI((self.message).substr(0, 256));
        share.linkedin({
          url,
          title,
          summary,
        });
      },
    },
  };
</script>
