<template>
  <f7-page>
    <f7-navbar :title="$t('payments.title')" large>
      <f7-link slot="nav-left" back icon="icon-back" :text="$t('general.back')"></f7-link>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <div class="center-content" v-if="payments">
      <f7-block-title>{{$t('payments.payment_method_title')}}</f7-block-title>
      <f7-list>
        <f7-list-item
          v-for="(id, key) in finderPaymentMethodList"
          :key="key"
          radio
          :checked="payments.paymentMethod === id"
          @change="($event) => { if ($event.target.checked) payments.paymentMethod = id }"
        >
          <span slot="title">{{$t(`finder_payment_method.${key}`)}}</span>
          <template v-if="id === finderPaymentMethodList.CoolCompany">
            <f7-icon
              slot="title"
              v-if="$device.desktop"
              style="margin-left:5px"
              size="20"
              material="info"
              :tooltip="$t('finder_payment_info.CoolCompany')"
            ></f7-icon>
            <span slot="footer" v-else>{{$t('finder_payment_info.CoolCompany')}}</span>
          </template>

        </f7-list-item>
        <loading-list-button
          color="brand-orange"
          :text="$t('general.save')"
          @click="save"
          :loading="saving"
        ></loading-list-button>
      </f7-list>

      <div v-if="payments.paymentMethod === finderPaymentMethodList.Invoice && waitingForInvoice.length">
        <f7-block-title>{{$t('payments.invoice_title')}}</f7-block-title>
        <f7-block strong>
          <template>
            <p class="invoice-info-text" v-html="$t('payments.invoice_info')"></p>
            <div class="invoice">
              <p>
                <b>{{$t('payments.invoice_bill_to_email')}}:</b><br>
                <a href="mailto:invoice@finderscrowd.com" class="external" target="_systembrowser">invoice@finderscrowd.com</a><br>
                <br>
                <b>{{$t('payments.invoice_bill_to_mail')}}:</b><br>
                Elmén<br>
                Lindalsvägen 51 B<br>
                135 50 Tyresö<br>
                Stockholm Sweden<br>
                <br>
                <b>{{$t('payments.invoice_company_details')}}:</b><br>
                FindersCrowd AB<br>
                Org. nr: 559152-2692<br>
                <br>
              </p>
              <div class="invoice-table-wrap">
                <table>
                  <thead>
                    <tr>
                      <th>{{$t('payments.invoice_table_description')}}</th>
                      <th>{{$t('payments.invoice_table_verification')}}</th>
                      <th>{{$t('payments.invoice_table_amount')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(transaction, index) in waitingForInvoice"
                      :key="index"
                    >
                      <td>{{$t('payments.invoice_table_item', { candidate: transaction.candidate.firstName && transaction.candidate.lastName ? `${transaction.candidate.firstName} ${transaction.candidate.lastName}` : `${transaction.candidate.email}`, assignment: transaction.assignmentTitle })}}</td>
                      <td>{{transaction.verificationId}}</td>
                      <td>{{transaction.amount}} {{currencyMap(transaction.currency)}}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2">{{$t('payments.invoice_table_total')}}</td>
                      <td>{{waitingForInvoiceTotal}}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </template>
          <!--<template v-else>
            <p v-if="!pendingPayments.length" class="invoice-info-text">{{$t('payments.no_invoice_info')}}</p>
            <p v-if="pendingPayments.length">{{$t('payments.pending_info')}}</p>
            <template v-if="pendingPayments.length">
              <div class="invoice">
                <div class="invoice-table-wrap">
                  <table>
                    <thead>
                      <tr>
                        <th>{{$t('payments.invoice_table_description')}}</th>
                        <th>{{$t('payments.invoice_table_amount')}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(transaction, index) in pendingPayments"
                        :key="index"
                      >
                        <td>{{$t('payments.invoice_table_item', { candidate: transaction.candidate.firstName && transaction.candidate.lastName ? `${transaction.candidate.firstName} ${transaction.candidate.lastName}` : `${transaction.candidate.email}`, assignment: transaction.assignmentTitle })}}</td>
                        <td>{{transaction.amount}} {{currencyMap(transaction.currency)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
          </template>-->
        </f7-block>
      </div>

      <f7-block-title>{{$t('payments.earnings_title')}}</f7-block-title>
      <f7-block strong class="no-padding">
        <div class="data-table no-margin" v-if="payments.transactions && payments.transactions.length">
          <f-transaction-list-table
            :transactions="payments.transactions"
          ></f-transaction-list-table>
        </div>
        <div class="text-align-center padding" v-if="payments.transactions && !payments.transactions.length">
          <p>{{$t('payments.finder_no_payments')}}</p>
        </div>
      </f7-block>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import finderPaymentMethodList from '../../utils/finder-payment-method-list';
  import finderPaymentMethodMap from '../../utils/finder-payment-method-map';
  import transactionStatusList from '../../utils/finder-transaction-status-list';
  import currencyMap from '../../utils/currency-map';

  export default {
    created() {
      const self = this;
      self.$store.dispatch('f/getPayments');
    },
    data() {
      return {
        finderPaymentMethodList,
      };
    },
    computed: {
      waitingForInvoice() {
        return this.payments.transactions.filter(t => (t.status === transactionStatusList.WaitingForInvoice));
      },
      pendingPayments() {
        return this.payments.transactions.filter(t => (t.status === transactionStatusList.WaitingForCompany));
      },
      waitingForInvoiceTotal() {
        let total = 0;
        let curr;
        this.waitingForInvoice.forEach((t) => {
          total += t.amount;
          curr = t.currency;
        });
        return `${total} ${currencyMap(curr)}`;
      },
      pendingPaymentsTotal() {
        let total = 0;
        let curr;
        this.pendingPayments.forEach((t) => {
          total += t.amount;
          curr = t.currency;
        });
        return `${total} ${currencyMap(curr)}`;
      },
      ...mapGetters({
        loading: 'f/paymentsLoading',
        saving: 'f/paymentsSaving',
        payments: 'f/payments',
      }),
    },
    methods: {
      finderPaymentMethodMap,
      currencyMap,
      save() {
        const self = this;

        self.$store.dispatch('f/savePayments', {
          data: self.payments,
          callbackOk() {
            self.$f7router.back();
          },
        });
      },
    },
  };
</script>
