var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.wrap)?_c('f7-block',{ref:"el",staticClass:"attachments",attrs:{"strong":""}},[_c('div',{staticClass:"attachments-title-button"},[(_vm.showTitle)?_c('div',{staticClass:"attachments-title"},[_vm._v(_vm._s(_vm.$t('attachments.title'))+_vm._s(_vm.afterTitle ? (" " + _vm.afterTitle) : ''))]):_vm._e(),_vm._v(" "),(_vm.allowEdit && !_vm.disabled)?[_c('a',{staticClass:"attachments-button button button-small button-round button-outline display-inline-block popover-open",attrs:{"slot":"after","href":"#","data-popover":".attachments-popover"},slot:"after"},[_vm._v("\n        "+_vm._s(_vm.$t('attachments.add_files_button'))+"\n      ")]),_vm._v(" "),_c('f7-popover',{staticClass:"attachments-popover"},[_c('f7-list',[_c('li',[_c('label',{staticClass:"list-button popover-close",staticStyle:{"cursor":"pointer"}},[_c('input',{attrs:{"type":"file","multiple":""},on:{"change":_vm.onFilesChange}}),_vm._v("\n              "+_vm._s(_vm.$t('attachments.upload_button'))+"\n            ")])]),_vm._v(" "),_c('f7-list-button',{attrs:{"popover-close":"","text":_vm.$t('attachments.link_button')},on:{"click":_vm.openLinkDialog}})],1)],1)]:_vm._e()],2),_vm._v(" "),(_vm.allFiles && _vm.allFiles.length)?_c('div',{staticClass:"attachments-files"},_vm._l((_vm.allFiles),function(file,index){return _c('div',{key:index,staticClass:"attachments-file",class:{
        'attachments-image': _vm.isImage(file) || _vm.isVideoFrame(file),
        'photobrowser-link': _vm.isImage(file) || _vm.isVideoFrame(file),
        'video-preview': _vm.isVideoFrame(file),
        'attachments-document': !(_vm.isImage(file) || _vm.isVideoFrame(file) || _vm.isVideo(file)),
      },style:({backgroundImage: _vm.bgImage(file)}),attrs:{"data-index":_vm.isImage(file) || _vm.isVideoFrame(file) ? _vm.mediaFiles.indexOf(file) : undefined,"data-file-extension":_vm.fileExtension(file)},on:{"click":function($event){return _vm.onFileClick($event, file)}}},[(!_vm.isVideoFrame(file) && _vm.isVideo(file))?_c('f7-icon',{attrs:{"f7":"videocam_round_fill"}}):_vm._e(),_vm._v(" "),(_vm.allowEdit)?[(_vm.inMessagebar)?_c('span',{staticClass:"messagebar-attachment-delete",on:{"click":function($event){return _vm.onDeleteClick(file)}}}):_c('a',{staticClass:"attachment-delete",on:{"click":function($event){return _vm.onDeleteClick(file)}}},[_c('f7-icon',{attrs:{"ios":"f7:close","md":"material:close"}})],1)]:_vm._e(),_vm._v(" "),(_vm.fileName(file))?_c('span',{staticClass:"attachment-filename"},[_vm._v(_vm._s(_vm.fileName(file)))]):_vm._e()],2)}),0):_vm._e()]):(_vm.allFiles && _vm.allFiles.length)?_c('div',{ref:"el",staticClass:"attachments-files"},_vm._l((_vm.allFiles),function(file,index){return _c('div',{key:index,staticClass:"attachments-file",class:{
      'attachments-image': _vm.isImage(file) || _vm.isVideoFrame(file),
      'photobrowser-link': _vm.isImage(file) || _vm.isVideoFrame(file),
      'video-preview': _vm.isVideoFrame(file),
      'attachments-document': !(_vm.isImage(file) || _vm.isVideoFrame(file) || _vm.isVideoFrame(file)),
    },style:({backgroundImage: _vm.bgImage(file)}),attrs:{"data-index":_vm.isImage(file) || _vm.isVideoFrame(file) ? _vm.mediaFiles.indexOf(file) : undefined,"data-file-extension":_vm.fileExtension(file),"data-file-type":file.type},on:{"click":function($event){return _vm.onFileClick($event, file)}}},[(!_vm.isVideoFrame(file) && _vm.isVideo(file))?_c('f7-icon',{attrs:{"f7":"videocam_round_fill"}}):_vm._e(),_vm._v(" "),(_vm.allowEdit)?[(_vm.inMessagebar)?_c('span',{staticClass:"messagebar-attachment-delete",on:{"click":function($event){return _vm.onDeleteClick(file)}}}):_c('a',{staticClass:"attachment-delete",on:{"click":function($event){return _vm.onDeleteClick(file)}}},[_c('f7-icon',{attrs:{"ios":"f7:close","md":"material:close"}})],1)]:_vm._e(),_vm._v(" "),(_vm.fileName(file))?_c('span',{staticClass:"attachment-filename"},[_vm._v(_vm._s(_vm.fileName(file)))]):_vm._e()],2)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }