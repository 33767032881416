<script>
  import config from '../../config';

  export default {
    props: {
      logo: [String, Object],
      file: [String, Object],
      useImg: Boolean,
      size: {
        type: Number,
        required: true,
      },
      placeholder: {
        type: Boolean,
        default: true,
      },
    },
    render(c) {
      const self = this;
      if (self.useImg) {
        return c('img', {
          class: 'logo',
          attrs: {
            src: self.logoSrc,
          },
        });
      }

      return c('div', {
        class: {
          logo: true,
          'has-logo-image': self.logo || self.file,
        },
        attrs: {
          style: `background-image: ${self.logoUrl}`,
        },
      }, self.$slots.default);
    },
    computed: {
      logoUrl() {
        const src = this.logoSrc;
        if (!src) return 'none';
        return `url(${src})`;
      },
      logoSrc() {
        const logo = this.logo || this.file;
        if (typeof logo === 'string') return logo;
        if (logo && logo.downloadFileName) {
          if (logo.downloadFileName.indexOf('http') === 0) return logo.downloadFileName;
          return `${config.path.images}${logo.downloadFileName}?fit=clip&w=${this.size}&h=${this.size}&dpr=${this.$device.pixelRatio}`;
        }
        if (this.placeholder) return `${this.$device.cordova ? '' : '/'}i/logo-placeholder.svg`;
        return '';
      },
    },
  };
</script>
