<template>
  <f7-page @page:beforeremove="onPageBeforeRemove">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon="icon-back" :text="$t('general.back')" back></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{$t('admin.company_edit_title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <company-details-form ref="form" v-if="company" :details="company">
      <f7-list>
        <f7-list-button color="brand-blue" @click="save">{{$t('general.save')}}</f7-list-button>
      </f7-list>
    </company-details-form>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import events from '../../modules/events';

  export default {
    props: {
      id: [Number, String],
    },
    created() {
      const self = this;
      self.$store.dispatch('a/getCompany', { id: parseInt(self.id, 10) });
    },
    computed: {
      form() {
        return this.$refs.form;
      },
      ...mapGetters({
        saving: 'a/companySaving',
        loading: 'a/companyLoading',
        company: 'a/company',
      }),
    },
    methods: {
      save() {
        const self = this;

        const valid = self.form.validate();
        if (!valid) return;

        const company = self.company;

        const newCompany = {
          id: company.id,
          ...self.form.getData(),
        };

        self.$store.dispatch('a/saveCompany', {
          company: newCompany,
          callbackOk() {
            events.$emit('store:admin:getCompaniesList');
            self.$f7router.back();
          },
        });
      },
      onPageBeforeRemove() {
        const self = this;
        self.$store.dispatch('a/unsetCompany');
      },
    },
  };
</script>
