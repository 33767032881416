const symbols = {
  0: '$',
  1: 'kr',
  2: '€',
  3: 'kr',
  4: 'kr',
};

export default function (num, currId, trimThousands) {
  let number = parseInt(num.toString().replace(/ /g, ''), 10).toString();
  let moreThan1K;
  if (trimThousands) {
    const trimThousandsFrom = typeof trimThousands === 'number' ? trimThousands : 1000;
    if (number >= trimThousandsFrom) {
      moreThan1K = true;
      number = (Math.floor(number / 1000 * 10) / 10).toString();
    }
  }
  const float = number.split('.')[1];
  const decimal = number.split('.')[0];
  let formatted = decimal.split('').map((char, index, arr) => {
    const indexFromEnd = arr.length - index - 1;
    if (indexFromEnd > 1 && (indexFromEnd + 1) % 3 === 0 && index !== 0) return ` ${char}`;
    return char;
  }).join('');
  if (float) {
    formatted = `${formatted}.${float}`;
  }
  if (typeof currId !== 'undefined') {
    if (currId === 0 || currId === 2) formatted = `${symbols[currId]}${formatted}${moreThan1K ? 'K' : ''}`;
    else formatted = `${formatted} ${moreThan1K ? 't' : ''}${symbols[currId]}`;
  } else if (moreThan1K) {
    formatted = `${formatted}K`;
  }
  return formatted;
}
