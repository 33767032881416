<template>
  <f7-page
    infinite
    :infinite-preloader="false"
    @infinite="onInfinite"
    @page:beforein="onPageBeforeIn"
    @page:afterin="onPageAfterIn"
  >
    <f7-navbar>
      <panel-link slot="nav-left" />
      <f7-nav-title>
        <span class="navbar-logo"></span>
        <span>{{$t('assignments.public_crowd_title')}}</span>
      </f7-nav-title>
      <f7-nav-right>
        <profile-link />
      </f7-nav-right>
    </f7-navbar>

    <offline-indicator></offline-indicator>

    <div class="center-content center-content-blocks">
      <template v-if="!assignments">
        <f7-block strong class="assignment-list-filter">
          <div class="input input-dropdown skeleton-text skeleton-effect-fade">
            <select disabled readonly>
              <option>____ ____ ____</option>
            </select>
          </div>
        </f7-block>
        <skeleton-assignment-list></skeleton-assignment-list>
      </template>
      <f7-block strong v-if="hasAssignments || category !== null" class="assignment-list-filter">
        <div class="input input-dropdown">
          <select :value="category" @change="changeCategoryFilter($event.target.value)">
            <option :value="null">{{$t('assignment_category.All')}}</option>
            <option
              v-for="(category, key) in assignmentCategoryList"
              :value="category"
              :data-key="key"
              :key="key"
            >{{$t(`assignment_category.${key}`)}}</option>
          </select>
        </div>
      </f7-block>
      <f-assignment-list v-if="assignments && assignments.length" :assignments="assignments"></f-assignment-list>
      <f7-block strong v-if="assignments && !assignments.length" class="text-align-center">
        {{$t('assignments.no_published_assignments')}}
      </f7-block>
      <f7-preloader v-if="allowInfinite && listHasMore" class="infinite-scroll-preloader"></f7-preloader>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import dispatchWithRetry from '../../utils/dispatch-with-retry';
  import assignmentCategoryList from '../../utils/assignment-category-list';
  import events from '../../modules/events';

  export default {
    mixins: [dispatchWithRetry],
    data() {
      return {
        assignmentCategoryList,
        allowInfinite: false,
        hasAssignments: false,
      };
    },
    created() {
      const self = this;

      delete localStorage.lastCrowdId;

      self.$store.dispatch('f/unsetAssignmentList');

      if (self.profile) {
        self.dispatchWithRetry('f/getAssignmentList');
      }

      self.timeout = setTimeout(() => {
        self.update();
      }, 30000);
    },
    mounted() {
      const self = this;
      self.setScrollOnUpdate = true;
      events.$emit('setActiveMenu', 'public');
    },
    beforeDestroy() {
      const self = this;
      self.destroyed = true;
      clearTimeout(self.timeout);
    },
    watch: {
      profile(profile, oldProfile) {
        const self = this;
        if (profile && !oldProfile) {
          self.dispatchWithRetry('f/getAssignmentList');
        }
      },
      'assignments.length': function watchAssignments(length) {
        const self = this;
        if (length && self.setScrollOnUpdate && self.$f7route.url === '/') {
          self.restoreScroll();
        }
        if (length) {
          this.hasAssignments = true;
        }
        if (length && length % 20 === 0) {
          this.allowInfinite = true;
        } else {
          this.allowInfinite = false;
        }
      },
    },
    computed: {
      isEnterprise() {
        return this.profile && this.profile.isEnterprise;
      },
      ...mapGetters({
        category: 'f/assignmentListCategory',
        assignments: 'f/assignmentList',
        loading: 'f/assignmentListLoading',
        loadingMore: 'f/assignmentListMoreLoading',
        profile: 'f/profile',
        listHasMore: 'f/assignmentListHasMore',
      }),
    },
    methods: {
      restoreScroll() {
        const self = this;
        self.setScrollOnUpdate = false;
        if (!self.$device.cordova) return;
        const scrollTop = self.$f7router.scrollHistory[self.$f7route.url];
        if (scrollTop) {
          self.$nextTick(() => {
            self.$$('.tab-active').scrollTop(scrollTop);
          });
        }
      },
      changeCategoryFilter(categoryId) {
        const self = this;
        self.$store.dispatch('f/setAssignmentListCategory', { category: categoryId || null });
      },
      onPageBeforeIn(e, page) {
        const self = this;
        if (page.direction === 'backward') {
          clearTimeout(self.timeout);
          self.update();
        }
      },
      onPageAfterIn() {},
      onInfinite() {
        const self = this;
        if (!self.allowInfinite || self.loadingMore || !self.listHasMore) return;
        self.$store.dispatch('f/getMoreAssignmentList');
      },
      update() {
        const self = this;
        if (self.destroyed) return;
      },
    },
  };
</script>
