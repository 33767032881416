<template>
  <div class="assignment-list">
    <a
      v-for="item in assignments"
      :key="item.id"
      :href="`/assignment/my/${item.id}/`"
      class="assignment-list-item"
    >
      <div class="item-media">
        <logo class="company-logo" :logo="item.logo || logo" :size="120"></logo>
      </div>
      <div class="item-content">
        <div class="item-title">{{item.title}}</div>
        <div class="item-meta" v-if="item.publishedDate && item.status !== statusList.WaitingForConfirm && item.status !== statusList.Draft && item.status !== statusList.InReview">
          <div class="item-date">
            {{formatDate(item.publishedDate)}}
          </div>
          <div class="item-category" v-if="typeof item.category !== 'undefined'">
            {{$t(`assignment_category.${assignmentCategoryMap(item.category)}`)}}
          </div>
          <div class="item-user" v-if="typeof item.user !== 'undefined'">
            {{`${item.user.firstName} ${item.user.lastName}`}}
          </div>          
        </div>
        <div class="item-text">{{removeLinks(item.description)}}</div>
        <div class="item-footer">
          <div class="item-status" :class="`text-color-${statusColor(item.status)} border-color-${statusColor(item.status)}`">
            {{statusText(item.status)}}
          </div>
          <div class="item-gauges">
            <pie-chart
              :size="65"
              :border-width="20"
              :data="chartData(item)"
              :showLabels="false"
              :visible="true"
            ></pie-chart>
            <gauge
              class="item-gauge-recommended"
              :size="65"
              :progress="item.interested / (item.contacted || 1)"
              :numberText="`${item.interested}/${item.contacted}`"
              :numberFontSize="14"
              strokeFillColor="#ddd"
              strokeColor="#f47321"
              :strokeWidth="6"
              :visible="true"
            ></gauge>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>
<script>
  import formatDate from '../../utils/format-date';
  import removeLinks from '../../utils/remove-links';
  import assignmentCategoryMap from '../../utils/assignment-category-map';
  import assignmentStatusMap from '../../utils/assignment-status-map';
  import assignmentStatusList from '../../utils/assignment-status-list';
  import candidateStatusMap from '../../utils/candidate-status-map';

  export default {
    props: {
      assignments: Array,
      logo: [String, Object],
    },
    data() {
      return {
        statusList: assignmentStatusList,
      };
    },
    methods: {
      chartData(item) {
        const data = [];
        const statusData = {
          Pending: 0,
          InterestReceived: 0,
          Qualified: 0,
          FinalStage: 0,
          NoFurtherProcess: 0,
          HadContactWithRecommendation: 0,
          AgreementMade: 0,
          Declined: 0,
          Maybe: 0,
          RecommendationReceived: 0,
        };
        (item.candidates || []).forEach((candidate) => {
          const key = candidateStatusMap(candidate.status);
          if (key in statusData) statusData[key] += 1;
        });
        Object.keys(statusData).forEach((key) => {
          const value = statusData[key];
          if (!value) return;
          let color = '#a0a0a0';
          if (key === 'Pending') {
            color = '#ffd400';
          }
          if (key === 'InterestReceived') {
            color = '#2196f3';
          }
          if (key === 'RecommendationReceived') {
            color = '#2196f3';
          }
          if (key === 'Qualified') {
            color = '#2196f3';
          }
          if (key === 'FinalStage') {
            color = '#f47321';
          }
          if (key === 'AgreementMade') {
            color = '#3FD77A';
          }
          if (key === 'NoFurtherProcess' || key === 'HadContactWithRecommendation') {
            color = '#FD2D4F';
          }
          if (key === 'Maybe') {
            color = '#ffd400';
          }
          data.push({
            color,
            value,
          });
        });
        if (data.length === 0) {
          data.push({ color: '#dddddd', value: 0 });
        }
        return data;
      },
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
      removeLinks,
      assignmentCategoryMap,
      statusColor(status) {
        if (status === assignmentStatusList.Published) return 'brand-green';
        if (status === assignmentStatusList.Declined) return 'brand-red';
        return 'gray';
      },
      statusText(status) {
        return this.$t(`assignment_status.${assignmentStatusMap(status)}`);
      },
    },
  };
</script>
