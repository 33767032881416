/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../modules/api';
import deviceId from '../utils/device-id';

const state = {
  loginIn: false,
  signingUp: false,
  confirming: false,
  forgotSending: false,
  resettingPassword: false,
  loginOut: false,
  joinCompanySending: false,
};

const getters = {
  loginIn(state) {
    return state.loginIn;
  },
  signingUp(state) {
    return state.signingUp;
  },
  confirming(state) {
    return state.confirming;
  },
  forgotSending(state) {
    return state.forgotSending;
  },
  resettingPassword(state) {
    return state.resettingPassword;
  },
  loginOut(state) {
    return state.loginOut;
  },
  joinCompanySending(state) {
    return state.joinCompanySending;
  },
};
const actions = {
  login(store, { email, password, role, language, callbackOk, callbackError } = {}) {
    API.post({
      url: 'common/auth/login',
      loading: 'user/setLoginIn',
      data: {
        email, password, role, language, deviceId,
      },
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  facebookLogin(store, { email, facebookId, first_name, last_name, picture, language, facebookAccessToken, role, callbackOk, callbackError } = {}) {
    API.post({
      url: 'common/auth/facebooklogin',
      data: {
        email, facebookId, firstName: first_name, lastName: last_name, picture, language, deviceId, facebookAccessToken, role,
      },
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  googleLogin(store, { email, googleId, first_name, last_name, picture, language, googleAccessToken, role, callbackOk, callbackError } = {}) {
    API.post({
      url: 'common/auth/googlelogin',
      data: {
        email, googleId, firstName: first_name, lastName: last_name, picture, language, deviceId, googleAccessToken, role,
      },
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  signup(store, { email, role, language, callbackOk, callbackError } = {}) {
    API.post({
      url: 'common/auth/signup',
      loading: 'user/setSigningUp',
      data: {
        email, role, language, deviceId,
      },
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  getConfirmationDetails(store, { confirmationId, callbackOk, callbackError } = {}) {
    API.get({
      url: `common/auth/confirmationdetails?confirmationId=${confirmationId}&deviceId=${deviceId}`,
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  confirm(store, { data, callbackOk, callbackError } = {}) {
    API.post({
      url: 'common/auth/confirmation',
      loading: 'user/setConfirming',
      data: Object.assign(data, { deviceId }),
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  forgotPassword(store, { data, callbackOk, callbackError } = {}) {
    API.post({
      url: 'common/auth/forgot',
      loading: 'user/setForgotSending',
      data: Object.assign(data, { deviceId }),
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  resetPassword(store, { data, callbackOk, callbackError } = {}) {
    API.post({
      url: 'common/auth/reset',
      loading: 'user/setResettingPassword',
      data: Object.assign(data, { deviceId }),
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  logout(store, { callbackOk, callbackError } = {}) {
    API.post({
      url: 'common/auth/logout',
      loading: 'user/setLoginOut',
      data: { deviceId },
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  joinCompany(ctx, { email, language, companyGuid, callbackOk, callbackError }) {
    API.post({
      url: 'common/auth/companyjoin',
      loading: 'user/setJoinCompanySending',
      data: {
        companyGuid,
        guid: companyGuid,
        email,
        language,
      },
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
      error(...args) {
        if (callbackError) callbackError(...args);
      },
    });
  },
};

const mutations = {
  setConfirming(state, status) {
    state.confirming = status;
  },
  setLoginIn(state, status) {
    state.loginIn = status;
  },
  setSigningUp(state, status) {
    state.signingUp = status;
  },
  setForgotSending(state, status) {
    state.forgotSending = status;
  },
  setResettingPassword(state, status) {
    state.resettingPassword = status;
  },
  setLoginOut(state, status) {
    state.loginOut = status;
  },
  setJoinCompanySending(state, status) {
    state.joinCompanySending = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
