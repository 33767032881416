/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';
import Push from '../../modules/push';

const state = {
  list: null,
  listLoading: false,
  listMoreLoading: false,
  listSkip: 0,
  listTake: 20,
};

const getters = {
  notificationList(state) {
    return state.list;
  },
  notificationListLoading(state) {
    return state.listLoading;
  },
  notificationListMoreLoading(state) {
    return state.listMoreLoading;
  },
};
const actions = {
  getNotificationsCount(ctx, { callbackOk }) {
    API.get({
      url: 'finder/notification/count',
      cache: false,
      ok(res) {
        if (window.cordova && res && typeof res.count !== 'undefined') {
          Push.setBadge(res.count);
        }
        if (callbackOk) callbackOk(res.count);
      },
    });
  },
  getNotificationList({ commit, state }, { callbackOk } = {}) {
    commit('setNotificationsListSkip', 0);
    API.get({
      url: `finder/notification/list?skip=${state.listSkip}&take=${state.listTake}`,
      loading: 'f/setNotificationListLoading',
      ok(res) {
        commit('setNotificationList', res.items);
        if (callbackOk) callbackOk();
      },
    });
  },
  getMoreNotificationList({ commit, state }, { callbackOk } = {}) {
    commit('setNotificationsListSkip', state.listSkip + state.listTake);
    API.get({
      url: `finder/notification/list?skip=${state.listSkip}&take=${state.listTake}`,
      loading: 'f/setNotificationListLoading f/setNotificationListMoreLoading',
      ok(res) {
        commit('addToNotificationList', res.items);
        if (callbackOk) callbackOk();
      },
    });
  },
  unsetNotificationList({ commit }) {
    commit('unsetNotificationList');
  },
};

const mutations = {
  setNotificationList(state, items) {
    state.list = items;
  },
  addToNotificationList(state, items) {
    state.list.push(...items);
  },
  unsetNotificationList(state) {
    state.listSkip = 0;
    state.list = null;
  },
  setNotificationsListSkip(state, val) {
    state.listSkip = val;
  },
  setNotificationListLoading(state, status) {
    state.listLoading = status;
  },
  setNotificationListMoreLoading(state, status) {
    state.listMoreLoading = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
