<template>
  <f7-link
    icon-ios="f7:menu"
    icon-md="material:menu"
    panel-open="left"
    :icon-badge="$root.notificationsCount ? $root.notificationsCount : undefined"
    badge-color="brand-red"
  />
</template>
<script>
  export default {

  };
</script>
