/* eslint no-param-reassign: "off" */
import Vue from 'vue';
import Vuex from 'vuex';

import companyAssignment from './company-modules/assignment';
import companyCompany from './company-modules/company';
import companyCandidate from './company-modules/candidate';
import companyProfile from './company-modules/profile';
import companyNotification from './company-modules/notification';
import companyCrowd from './company-modules/crowd';

import finderAssignment from './finder-modules/assignment';
import finderCompany from './finder-modules/company';
import finderProfile from './finder-modules/profile';
import finderNotification from './finder-modules/notification';
import finderCrowd from './finder-modules/crowd';

import adminCrowd from './admin-modules/crowd';
import adminCompany from './admin-modules/company';
import adminAssignment from './admin-modules/assignment';
import adminFinder from './admin-modules/finder';
import adminCandidate from './admin-modules/candidate';
import adminTransaction from './admin-modules/transaction';
import adminUser from './admin-modules/user';
import adminProfile from './admin-modules/profile';

import application from './application';
import user from './user';
import messages from './messages';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    // a - admin
    a: {
      namespaced: true,
      modules: {
        crowd: adminCrowd,
        company: adminCompany,
        assignment: adminAssignment,
        finder: adminFinder,
        candidate: adminCandidate,
        transaction: adminTransaction,
        profile: adminProfile,
        adminUser,
      },
    },
    // c - company
    c: {
      namespaced: true,
      modules: {
        assignment: companyAssignment,
        company: companyCompany,
        candidate: companyCandidate,
        profile: companyProfile,
        notification: companyNotification,
        crowd: companyCrowd,
      },
    },
    // f - finder
    f: {
      namespaced: true,
      modules: {
        assignment: finderAssignment,
        company: finderCompany,
        profile: finderProfile,
        notification: finderNotification,
        crowd: finderCrowd,
      },
    },
    application,
    user,
    m: {
      namespaced: true,
      ...messages,
    },
  },
});

export default store;
