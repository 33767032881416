import currencyMap from './currency-map';
import formatNumber from './format-number';

export default function (self, assignment, reward) {
  const { shareOut } = assignment;
  const currency = reward.currency;
  let amountMin = 0;
  let amountMax = 0;

  const { type, fixed, percentage, expectedTo, expectedFrom } = reward;
  const rewardPart = self.$root.userRole === 'finder' ? shareOut : 100;
  if (type === 0) {
    amountMax = fixed * rewardPart / 100;
    amountMin = amountMax;
  } else {
    amountMax = expectedTo * (percentage * rewardPart / 10000);
    amountMin = expectedFrom * (percentage * rewardPart / 10000);
  }

  if (Number.isNaN(amountMin)) amountMin = 0;
  if (Number.isNaN(amountMax)) amountMax = 0;

  const currencyString = currencyMap(currency);
  let text = '';
  if (amountMin === amountMax) {
    text = `${formatNumber(amountMax)} ${currencyString}`;
  } else {
    text = self.$t('assignment.reward_amount_range', {
      min: formatNumber(amountMin),
      max: formatNumber(amountMax),
      currency: currencyString,
    });
  }
  return text;
}