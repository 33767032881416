<template>
  <f7-page login-screen theme-dark>
    <div class="middle-content">
      <f7-login-screen-title>{{$t('auth.company_join_title')}}</f7-login-screen-title>
      <f7-block class="auth-error-block" v-if="errorMessage"><span>{{errorMessage}}</span></f7-block>
      <f7-list class="inputs-list">
        <f7-list-input @keypress.enter.native="onEnter" type="email" :placeholder="$t('auth.email_placeholder')" :value="email" @input="email = $event.target.value"></f7-list-input>
        <li v-if="$theme.ios"></li>
      </f7-list>
      <f7-block v-if="!errored">
        <loading-button large fill round preloader-color="brand-orange" @click="send" :text="$t('auth.company_join_button')" :loading="sending"></loading-button>
      </f7-block>
      <f7-block>
        <f7-block-footer><f7-link reload-current href="/">{{$t('auth.login_link')}}</f7-link> &nbsp;/&nbsp; <f7-link reload-current href="/signup/">{{$t('auth.signup_link')}}</f7-link></f7-block-footer>
      </f7-block>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import events from '../../modules/events';

  export default {
    props: {
      companyGuid: String,
    },
    data() {
      return {
        email: window.localStorage.userEmail || '',
        errorMessage: null,
        errored: false,
      };
    },
    computed: {
      ...mapGetters({
        sending: 'user/joinCompanySending',
      }),
    },
    methods: {
      showError(e) {
        let message = e;
        if (!message) return;
        if (message && typeof message !== 'string' && 'responseText' in message && !message.responseText) return;
        if (message.responseText) message = message.responseText;
        if (typeof message === 'string' && message.indexOf('{') === 0) {
          message = JSON.parse(message).message;
        }
        if (!message) return;
        this.errorMessage = message;
      },
      hideError() {
        this.errorMessage = null;
      },
      onEnter() {
        const self = this;
        self.send();
      },
      onLogin(response) {
        const self = this;
        localStorage.visited = true;
        self.$root.setLanguageOnProfileRequest = true;
        if (response.language) {
          self.$root.setLanguage(response.language);
        }
        if (response && response.accessToken) {
          window.localStorage.accessToken = response.accessToken;
          window.localStorage.userRole = response.claims.role;
          window.localStorage.refreshToken = response.refreshToken;
          const confirmed = response.claims.confirmed;
          if (confirmed && confirmed !== 'false') {
            window.localStorage.userConfirmed = 'true';
          }
          let redirectUrl = localStorage.lastCrowdId ? `/crowd/${localStorage.lastCrowdId}/` : undefined;
          let redirectProps;
          if (self.assignmentRedirectId) {
            redirectUrl = `/assignment/${self.assignmentRedirectId}/`;
            redirectProps = { scrollToRecommendations: true };
          } else if (response.redirect) {
            redirectUrl = response.redirect;
          }
          self.$root.login();
          if (redirectUrl) {
            setTimeout(() => {
              const needReloadAll = redirectUrl.match(/\/crowd\/[0-9]*[/]?/) && redirectUrl.match(/\/crowd\/[0-9]*[/]?/)[0] === redirectUrl;
              if (redirectProps) {
                self.$f7.views.main.router.navigate(redirectUrl, { props: redirectProps, reloadAll: needReloadAll });
              } else {
                self.$f7.views.main.router.navigate(redirectUrl, { reloadAll: needReloadAll });
              }
            }, 300);
          }
        }
      },
      send() {
        const self = this;
        self.hideError();
        const { email } = self;
        if (!email.trim()) {
          self.showError(self.$t('error.all_fields_required'));
          return;
        }
        self.$f7.dialog.confirm(self.$t('auth.company_join_confirm', { email }), () => {
          self.$store.dispatch('user/joinCompany', {
            email,
            companyGuid: self.companyGuid,
            language: self.$root.language,
            callbackOk(response) {
              self.onLogin(response);
            },
            callbackError(e) {
              self.errored = true;
              self.showError(e);
            },
          });
        });
      },
    },
  };
</script>
