<template>
  <table style="min-width: 768px">
    <thead>
      <tr>
        <th>
          <span>{{$t('general.verification')}}</span>
        </th>
        <th>
          <span>{{$t('general.assignment')}}</span>
        </th>
        <th>
          <span>{{$t('general.company')}}</span>
        </th>
        <th>
          <span>{{$t('general.candidate')}}</span>
        </th>
        <th>
          <span>{{$t('general.amount')}}</span>
        </th>
        <th>
          <span>{{$t('general.date')}}</span>
        </th>
        <th>
          <span>{{$t('general.status')}}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="transaction in transactions" :key="transaction.id">
        <td>{{transaction.verificationId}}</td>
        <td style="min-width: 150px">
          <f7-link :href="`/assignment/${transaction.assignmentId}/`">{{transaction.assignmentTitle}}{{transaction.assignmentMultipleLevels ? ` (${$t('payments.assignment_level', {level: transaction.assignmentRewardLevel})})` : ''}}</f7-link>
        </td>
        <td>
          <template v-if="transaction.anonymous">
            {{transaction.companyName}}
          </template>
          <template v-else>
            <f7-link :href="`/company/${transaction.companyId}/`">{{transaction.companyName}}</f7-link>
          </template>
        </td>
        <td>{{transaction.candidate.firstName && transaction.candidate.lastName ? transaction.candidate.firstName + ' ' + transaction.candidate.lastName : transaction.candidate.email}}</td>
        <td class="nowrap">{{transaction.amount}} {{currencyMap(transaction.currency)}}</td>
        <td class="nowrap">{{formatDate(transaction.createdDate)}}</td>
        <td>
          {{typeof transaction.status !== 'undefined' ? removeHtml($t(`finder_transaction_status.${transactionStatusMap(transaction.status)}`)) : ''}}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import transactionStatusMap from '../../utils/finder-transaction-status-map';
  import formatDate from '../../utils/format-date';
  import currencyMap from '../../utils/currency-map';
  import removeHtml from '../../utils/remove-html';

  export default {
    props: {
      transactions: Array,
    },
    data() {
      return {
      };
    },
    methods: {
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
      currencyMap,
      transactionStatusMap,
      removeHtml,
    },
  };
</script>
