<template>
  <div class="no-safe-areas candidates-send-block">
    <template v-if="directCandidates(rewardLevel) && directCandidates(rewardLevel).length">
      <p class="candidates-send-block-title">
        <f7-icon f7="business_fill" />
        <span>{{$t('assignment.candidates_to_company_title', { companyName: assignment.companyName })}}</span>
      </p>
      <f-contacted-candidates-list
        :candidates="directCandidates(rewardLevel)"
        :rewardLevel="rewardLevel"
        :assignment="assignment"
        :isMulti="isMulti"
        @resendCandidate="resendCandidate"
      />
    </template>
    <template v-if="assignment.recommendedByEmail || emailCandidates(rewardLevel).length">
      <p class="candidates-send-block-title">
        <f7-icon f7="email_fill" />
        <span>{{$t('assignment.candidates_by_email_title')}}</span>
      </p>
      <f-contacted-candidates-list
        v-if="emailCandidates(rewardLevel).length"
        :candidates="emailCandidates(rewardLevel)"
        :rewardLevel="rewardLevel"
        :assignment="assignment"
        :isMulti="isMulti"
        @resendCandidate="resendCandidate"
      />
      <div v-else class="candidates-send-block-no-interest">{{$t('assignment.candidates_no_intereset_yet')}}</div>
    </template>
    <template v-if="assignment.recommendedByFacebook || facebookCandidates(rewardLevel).length">
      <p class="candidates-send-block-title">
        <f7-icon f7="logo_facebook" />
        <span>{{$t('assignment.candidates_by_facebook_title')}}</span>
      </p>
      <f-contacted-candidates-list
        v-if="facebookCandidates(rewardLevel).length"
        :candidates="facebookCandidates(rewardLevel)"
        :rewardLevel="rewardLevel"
        :assignment="assignment"
        :isMulti="isMulti"
        @resendCandidate="resendCandidate"
      />
      <div v-else class="candidates-send-block-no-interest">{{$t('assignment.candidates_no_intereset_yet')}}</div>
    </template>
    <template v-if="assignment.recommendedByLinkedIn || linkedinCandidates(rewardLevel).length">
      <p class="candidates-send-block-title">
        <f7-icon f7="logo_linkedin" />
        <span>{{$t('assignment.candidates_by_linkedin_title')}}</span>
      </p>
      <f-contacted-candidates-list
        v-if="linkedinCandidates(rewardLevel).length"
        :candidates="linkedinCandidates(rewardLevel)"
        :rewardLevel="rewardLevel"
        :assignment="assignment"
        :isMulti="isMulti"
        @resendCandidate="resendCandidate"
      />
      <div v-else class="candidates-send-block-no-interest">{{$t('assignment.candidates_no_intereset_yet')}}</div>
    </template>
  </div>
</template>
<script>
  import candidateRecommendationList from '../../utils/candidate-recommendation-list';

  export default {
    props: {
      rewardLevel: Number,
      assignment: Object,
      isMulti: Boolean,
    },
    methods: {
      candidatesForLevel(level = 0) {
        const self = this;
        return self.assignment.contactedCandidates.filter((candidate) => {
          let match = false;
          const candidateLevel = candidate.rewardLevel || 0;
          if (candidateLevel === level) match = true;
          if (!match) {
            candidate.rewards.forEach((reward) => {
              if (reward.level === level) match = true;
            });
          }
          return match;
        });
      },
      directCandidates(level) {
        if (!this.assignment) return [];
        return this.candidatesForLevel(level).filter(c => c.recommendation === candidateRecommendationList.Company);
      },
      emailCandidates(level) {
        if (!this.assignment) return [];
        return this.candidatesForLevel(level).filter(c => c.recommendation === candidateRecommendationList.Email);
      },
      facebookCandidates(level) {
        if (!this.assignment) return [];
        return this.candidatesForLevel(level).filter(c => c.recommendation === candidateRecommendationList.Facebook);
      },
      linkedinCandidates(level) {
        if (!this.assignment) return [];
        return this.candidatesForLevel(level).filter(c => c.recommendation === candidateRecommendationList.LinkedIn);
      },
      resendCandidate(...args) {
        this.$emit('resendCandidate', ...args);
      },
    },
  };
</script>
