<template>
  <f7-page login-screen theme-dark>
    <div class="middle-content">
      <f7-login-screen-title>{{$t('auth.forgot_password_title')}}</f7-login-screen-title>
      <f7-block class="auth-error-block" v-if="errorMessage"><span>{{errorMessage}}</span></f7-block>
      <f7-list class="inputs-list">
        <f7-list-input @keypress.enter.native="onEnter" type="email" :placeholder="$t('auth.email_placeholder')" :value="email" @input="email = $event.target.value"></f7-list-input>
        <li v-if="$theme.ios"></li>
      </f7-list>
      <f7-block>
        <loading-button large fill round preloader-color="brand-orange" @click="reset" :text="$t('auth.forgot_password_button')" :loading="sending"></loading-button>
      </f7-block>
      <f7-block>
        <f7-block-footer><f7-link reload-current href="/">{{$t('auth.login_link')}}</f7-link> &nbsp;/&nbsp; <f7-link reload-current href="/signup/">{{$t('auth.signup_link')}}</f7-link></f7-block-footer>
      </f7-block>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import events from '../../modules/events';

  export default {
    data() {
      return {
        email: window.localStorage.userEmail || '',
        errorMessage: null,
      };
    },
    computed: {
      ...mapGetters({
        sending: 'user/forgotSending',
      }),
    },
    methods: {
      showError(e) {
        let message = e;
        if (!message) return;
        if (message && typeof message !== 'string' && 'responseText' in message && !message.responseText) return;
        if (message.responseText) message = message.responseText;
        if (typeof message === 'string' && message.indexOf('{') === 0) {
          message = JSON.parse(message).message;
        }
        if (!message) return;
        this.errorMessage = message;
      },
      hideError() {
        this.errorMessage = null;
      },
      onEnter() {
        const self = this;
        self.reset();
      },
      reset() {
        const self = this;
        self.hideError();
        const { email } = self;
        if (!email.trim()) {
          self.showError(self.$t('error.all_fields_required'));
          return;
        }
        const data = {
          email,
          language: self.$root.language,
        };

        self.$store.dispatch('user/forgotPassword', {
          data,
          callbackOk() {
            self.$f7router.navigate('/', { reloadAll: true });
            if (!self.$device.cordova) {
              window.history.replaceState({}, '', '/');
            }
            setTimeout(() => {
              events.$emit('notify', self.$t('auth.forgot_success'));
            }, 300);
          },
          callbackError(e) {
            self.showError(e);
          },
        });
      },
    },
  };
</script>
