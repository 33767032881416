/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';

const state = {
  list: null,
  listLoading: false,
  finder: null,
  finderLoading: false,
  saving: false,
  rewardList: null,
  paidRewardList: null,
};

const getters = {
  finderList(state) {
    return state.list;
  },
  finderListLoading(state) {
    return state.listLoading;
  },
  finderLoading(state) {
    return state.finderLoading;
  },
  finderSaving(state) {
    return state.saving;
  },
  finder(state) {
    return state.finder;
  },
  finderRewardList(state) {
    return state.rewardList;
  },
  finderPaidRewardList(state) {
    return state.paidRewardList;
  },
};
const actions = {
  getFinderRewardList({ commit }) {
    API.get({
      url: 'admin/finder/rewardlist',
      ok(response) {
        commit('setFinderRewardList', response.items);
      },
    });
  },
  getFinderPaidRewardList({ commit }) {
    API.get({
      url: 'admin/finder/paidlist',
      ok(response) {
        commit('setFinderPaidRewardList', response.items);
      },
    });
  },
  getFinderList({ commit }) {
    API.get({
      url: 'admin/user/list',
      loading: 'a/setFinderListLoading',
      ok(response) {
        commit('setFinderList', response.items);
      },
    });
  },
  getFinder({ commit, state }, { id } = {}) {
    if (state.finder) return;
    API.get({
      url: `admin/user/details?id=${id}`,
      loading: 'a/setFinderLoading',
      ok(response) {
        commit('setFinder', response);
      },
    });
  },
  unsetFinder({ commit }) {
    commit('unsetFinder');
  },
  saveFinder({ dispatch }, { finder, callbackOk, callbackError }) {
    API.post({
      url: 'admin/user/save',
      loading: 'a/setFinderSaving',
      data: finder,
      base64FileProp: 'photo',
      ok() {
        dispatch('getFinderList');
        if (callbackOk) callbackOk();
      },
      error: callbackError,
    });
  },
};

const mutations = {
  setFinderList(state, finders) {
    state.list = finders;
  },
  setFinderListLoading(state, status) {
    state.listLoading = status;
  },
  setFinder(state, finder) {
    state.finder = finder;
  },
  setFinderLoading(state, status) {
    state.finderLoading = status;
  },
  unsetFinder(state) {
    state.finder = null;
  },
  setFinderSaving(state, status) {
    state.saving = status;
  },
  setFinderRewardList(state, items) {
    state.rewardList = items;
  },
  setFinderPaidRewardList(state, items) {
    state.paidRewardList = items;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
