<template>
  <f7-page
    infinite
    @infinite="onInfinite"
    :infinite-preloader="false"
  >
    <f7-navbar :title="$t('crowd_finders.title')">
      <back-link slot="nav-left" />
    </f7-navbar>

    <offline-indicator slot="fixed"></offline-indicator>

    <div class="center-content" v-if="finders">
      <f7-block strong class="finders-invite-block">
        <f7-block-title>{{$t('crowd_finders.finders_invite_title')}}</f7-block-title>
        <p>{{$t('crowd_finders.finders_invite_email_text')}}</p>
        <p class="text-align-center">
          <f7-button @click="inviteOpened = true" round fill style="width: 200px; display: inline-block">
            <f7-icon f7="email_fill"></f7-icon>
            <span>{{$t('crowd_finders.finders_invite_button')}}</span>
          </f7-button>
        </p>
        <div class="or-separator">
          <span>{{$t('crowd_finders.finders_invite_or')}}</span>
        </div>
        <p>{{$t('crowd_finders.finders_invite_link_text')}}</p>
        <f7-link
          no-link-class
          class="display-flex justify-content-center align-items-center"
          style="word-break: break-word;"
          :tooltip="$t('general.copy_to_clipboard')"
          @click="copyToClipboard(`${host}/?join=${crowdGuid}`)"
        >
          <span>{{`${host}/?join=${crowdGuid}`}}</span>
          <span class="display-flex margin-left flex-shrink-0">
            <f7-icon material="insert_drive_file"></f7-icon>
            <f7-icon material="subdirectory_arrow_left" :size="18" style="margin-left: -6px;"></f7-icon>
          </span>
        </f7-link>
      </f7-block>

      <f7-block-title class="display-flex justify-content-flex-end" v-if="finders && finders.length">
        <loading-button fill small round class="display-flex align-items-center" @click="resendAll()">
          <f7-icon material="send" class="no-margin" :size="20"></f7-icon>
          <span style="margin-left:4px">{{$t('crowd_finders.resend_all_button')}}</span>
        </loading-button>
      </f7-block-title>

      <f7-searchbar
        v-if="hasFinders"
        class="searchbar-users"
        inline
        :custom-search="true"
        :placeholder="$t('crowd_finders.search_placeholder')"
        :value="query"
        :disableButton="false"
        :disableButtonText="$t('general.cancel')"
        @searchbar:search="onSearch"
      />
      <f7-list v-if="finders && finders.length" media-list>
        <f7-list-item
          v-for="user in finders"
          :key="user.id"
          :title="user.firstName ? `${user.firstName} ${user.lastName}` : user.email"
          :subtitle="user.firstName ? user.email : undefined"
          :text="$t(`crowd_finders.status_${user.active ? 'active' : 'pending'}`)"
        >
          <avatar :file="user.photo" slot="media" :size="44"></avatar>
          <f7-link v-if="!user.active" @click="resendFinder(user)" color="gray" icon-material="send" slot="content" class="padding"></f7-link>
          <f7-link @click="deleteFinder(user)" color="gray" icon-material="delete" slot="content" class="padding"></f7-link>
        </f7-list-item>
      </f7-list>
      <f7-block strong class="empty-block text-align-center" v-if="finders && !finders.length && query">{{$t('crowd_finders.search_not_found')}}</f7-block>
      <f7-preloader v-if="allowInfinite && findersHasMore" class="infinite-scroll-preloader"></f7-preloader>
    </div>
    <f7-popup :opened="inviteOpened" @popup:closed="inviteOpened = false" ref="findersPopup" v-if="renderPopups">
      <f7-view :init="false">
        <f7-page>
          <f7-navbar :title="$t('crowd_finders.finders_invite_button')">
            <f7-nav-right>
              <f7-link icon-ios="f7:close" icon-md="material:close" @click="inviteOpened = false"></f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-list>
            <f7-block-header>{{$t('crowd_finders.invite_dialog')}}</f7-block-header>
            <f7-list-input
              :label="$t('crowd_finders.invite_dialog_info')"
              type="textarea"
              resizable
              :placeholder="$t('form.email')"
              defaultValue=""
            ></f7-list-input>
          </f7-list>
          <f7-list>
            <loading-list-button
              color="brand-orange"
              @click="inviteFinders"
              :loading="inviting"
            >{{$t('users.invite_dialog_button')}}</loading-list-button>
          </f7-list>
        </f7-page>
      </f7-view>
    </f7-popup>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import config from '../../config';
  import copyToClipboard from '../../utils/copy-to-clipboard';
  import events from '../../modules/events';

  export default {
    props: {
      crowdId: [String, Number],
    },
    data() {
      return {
        finders: null,
        allowInfinite: false,
        inviteOpened: false,
        renderPopups: true,
        host: config.host,
        query: '',
        hasFinders: false,
      };
    },
    computed: {
      ...mapGetters({
        inviting: 'c/crowdFindersInviting',
        findersLoading: 'c/crowdFindersLoading',
        currentFinders: 'c/crowdFinders',
        crowdGuid: 'c/crowdFindersGuid',
        finderDeleting: 'c/crowdFinderDeleting',
        findersHasMore: 'c/crowdFindersHasMore',
        findersLoadingMore: 'c/crowdFindersLoadingMore',
      }),
    },
    watch: {
      currentFinders() {
        const self = this;
        if (!self.currentFinders) return;
        self.finders = [...self.currentFinders];
        if (self.finders.length) self.hasFinders = true;
      },
      'currentFinders.length': function watchFinders(length) {
        if (length && length % 20 === 0) {
          this.allowInfinite = true;
        } else {
          this.allowInfinite = false;
        }
      },
    },
    created() {
      const self = this;
      self.$store.dispatch('c/unsetCrowdFinders');
      self.$store.dispatch('c/getCrowdFinders', { crowdId: self.crowdId });
    },
    mounted() {
      const self = this;
    },
    methods: {
      onSearch(ss, query) {
        const self = this;
        if (query.trim() === self.query) return;
        self.query = query.trim();
        clearTimeout(self.searchTimeout);
        self.searchTimeout = setTimeout(() => {
          self.$store.dispatch('c/getCrowdFinders', { crowdId: self.crowdId, cache: false, query: query.trim() });
        }, 300);
      },
      copyToClipboard(link) {
        const self = this;
        copyToClipboard(link, () => {
          events.$emit('notify', self.$t('general.copy_to_clipboard_success'));
        });
      },
      onInfinite() {
        const self = this;
        if (!self.allowInfinite || self.findersLoadingMore || !self.findersHasMore) return;
        self.$store.dispatch('c/getMoreCrowdFinders', { crowdId: self.crowdId });
      },
      resendAll() {
        const self = this;
        self.$f7.dialog.confirm(self.$t('crowd_finders.resend_all_confirm'), () => {
          self.$store.dispatch('c/resendAllCrowdFinder', { crowdId: self.crowdId });
        });
      },
      resendFinder(user) {
        const self = this;
        self.$f7.dialog.confirm(self.$t('users.resend_user_confirm'), () => {
          self.$store.dispatch('c/resendCrowdFinder', { userId: user.id, crowdId: self.crowdId });
        });
      },
      deleteFinder(user) {
        const self = this;
        self.$f7.dialog.confirm(self.$t('users.delete_user_confirm'), () => {
          self.$store.dispatch('c/deleteCrowdFinder', { userId: user.id, crowdId: self.crowdId });
        });
      },

      inviteFinders() {
        const self = this;
        function validate($inputEl) {
          self.$f7.input.validate($inputEl[0]);
          const emails = $inputEl.val().split('\n').map(e => e.trim()).filter(e => !!e);
          const hasInvalidEmail = emails.filter(e => e.indexOf('@') < 0).length > 0;
          if (!emails.length || hasInvalidEmail) {
            $inputEl.addClass('input-invalid').parents('.item-input').addClass('item-input-invalid item-input-with-error-message');
            let $errorEl = $inputEl.nextAll('.item-input-error-message');
            if ($errorEl.length === 0) {
              $inputEl.parent().append('<div class="item-input-error-message"></div>');
              $errorEl = $inputEl.nextAll('.item-input-error-message');
            }
            $errorEl.text(self.$t('users.invite_dialog_error'));
            return false;
          }
          if ($inputEl.hasClass('input-invalid')) return false;
          return true;
        }
        const $inputEl = self.$$(self.$refs.findersPopup.$el.querySelector('textarea'));
        const emails = $inputEl.val().split(/\n|\s/).map(e => e.trim()).filter(e => !!e);
        const valid = validate($inputEl);
        if (!valid) return;

        self.$store.dispatch('c/inviteCrowdFinder', {
          emails,
          crowdId: self.crowdId,
          callbackOk() {
            self.inviteOpened = false;
            setTimeout(() => {
              self.renderPopups = false;
              setTimeout(() => {
                self.renderPopups = true;
              });
            }, 600);
          },
        });
      },
    },
  };
</script>
