import exif from 'exif-js';
import Vue from 'vue';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle';
import Framework7, { Template7 } from './libs/framework7';

import store from './store/index';
import defaultLanguage from './utils/default-language';
import routes from './pages/routes';
import components from './components/components';
import directives from './modules/directives';
import extend from './utils/extend';

import i18n from './modules/i18n';
import Firebase from './modules/firebase';
import events from './modules/events';
import briefWidget from './modules/brief-widget';
import PWA from './modules/pwa';

import cordovaInit from './modules/cordova-init';

import Activity from './modules/activity';
import socket from './modules/socket';

window.EXIF = exif;

// T7 Globals
Template7.global = {
  externalTarget: window.cordova ? '_inappbrowser' : '_blank',
};

// i18n
Vue.prototype.$t = i18n;

// External target
if (window.cordova || Framework7.device.cordova) {
  Vue.prototype.$externalTarget = '_inappbrowser';
} else {
  Vue.prototype.$externalTarget = '_blank';
}
Framework7.use(Framework7Vue);
Vue.mixin({
  components,
  directives,
});

let userRole = window.localStorage.userRole || null;
if (window.location.href.indexOf('userRole=') >= 0) {
  userRole = window.location.href.split('userRole=')[1].split('&')[0];
  window.localStorage.userRole = userRole;
}
if (window.location.href.indexOf('join=') >= 0) {
  userRole = 'finder';
  window.localStorage.userRole = userRole;
}
const loggedIn = !!window.localStorage.accessToken && userRole;
const userConfirmed = !!window.localStorage.userConfirmed;

export default function () {
  const vueApp = new Vue({
    el: '#app',
    data() {
      const self = this;
      const ua = window.navigator.userAgent;
      const isIE = ua.indexOf('MSIE ') >= 0 || ua.indexOf('Trident/') >= 0 || ua.indexOf('Edge/') >= 0;

      return {
        f7params: {
          name: 'FindersCrowd',
          routes: routes(loggedIn ? userRole : null),
          panel: {
            leftBreakpoint: 900,
            swipe: self.$device.cordova || self.$device.ios || self.$device.android ? 'left' : undefined,
          },
          statusbar: {
            overlay: !!window.cordova,
            iosTextColor: 'white',
          },
          input: {
            scrollIntoViewOnFocus: true,
            scrollIntoViewCentered: true,
            scrollIntoViewAlways: self.$device.cordova && self.$device.android && !loggedIn,
          },
          touch: {
            materialRipple: !isIE,
            disableContextMenu: !self.$device.android,
          },
          navbar: {
            mdCenterTitle: true,
          },
          view: {
            iosSwipeBackActiveArea: window.screen.width,
            routesBeforeEnter(to, from, resolve, reject) {
              events.$emit('routesBeforeEnter', { to, from });
              if (window.cordova) {
                resolve();
                return;
              }
              if (PWA.serviceWorkerPendingUpdate && from && from.url && to && to.url) {
                if (from.url.indexOf('join=') >= 0 || to.url.indexOf('?joined') >= 0 || from.url.indexOf('join_company=') >= 0 || to.url.indexOf('?joined_company') >= 0) {
                  resolve();
                  return;
                }
                window.location.href = to.url;
                reject();
                return;
              }
              resolve();
            },
            routesBeforeLeave(to, from, resolve, reject) {
              let needPrevent = false;
              function prevent() {
                needPrevent = true;
              }
              events.$emit('routesBeforeLeave', { to, from, prevent, resolve, reject });
              if (!needPrevent) resolve();
            },
          },
        },
        backButtonTimestamp: new Date().getTime(),
        language: defaultLanguage,
        loggedIn,
        userRole,
        userConfirmed,
        userProfile: null,
        popupUrl: null,
        popupOpened: false,
        popupDelayed: false,
        popupTabletFullScreen: false,
        online: window.navigator.onLine,
        userRoleChanging: false,
        userChanging: false,
        notificationsCount: 0,
      };
    },
    computed: {
      storeRolePrefix() {
        const self = this;
        let prefix = '';
        if (self.userRole === 'finder') prefix = 'f';
        if (self.userRole === 'company') prefix = 'c';
        if (self.userRole === 'admin') prefix = 'a';
        return prefix;
      },
    },
    watch: {
      loggedIn() {
        const self = this;
        if (self.loggedIn && self.userRole) {
          self.$f7.routes = routes(self.userRole);
        } else {
          self.$f7.routes = routes();
        }
      },
    },
    mounted() {
      const self = this;
      self.$f7ready((f7) => {
        self.f7ready(f7);
      });
    },
    methods: {
      joinCompany(companyGuid) {
        const self = this;
        if (self.$root.userRole === 'company') {
          if (self.$root.userProfile && self.$root.userProfile.companyGuid === companyGuid) {
            events.$emit('notify', self.$t('company_join.already_joined_this_company'));
          } else {
            events.$emit('notify', self.$t('company_join.already_joined_other_company'));
          }
          return;
        }
        self.$store.dispatch('f/joinCompany', {
          companyGuid,
          callbackOk(result) {
            if (result === true) {
              self.$root.changeRole('company');
            }
          },
        });
      },
      joinCrowd(crowdGuid) {
        const self = this;
        self.$store.dispatch('f/joinCrowd', {
          crowdGuid,
          callbackOk(result) {
            events.$emit('refreshCrowdList', () => {
              self.$f7.views.main.router.navigate(`/crowd/${result.crowdId}/?joined`, {
                reloadAll: true,
              });
            });
          },
        });
      },
      showNotificationsBanner(success, cancel) {
        const self = this;
        const $ = self.$$;
        self.$f7.views.main.$el.find('.page-current .page-content').prepend(`
          <div class="fc-banner elevation-3">
            <div class="text">${self.$t('notifications_banner.text')}</div>
            <div class="buttons">
              <a href="#" class="fc-banner-cancel button button-round button-small color-gray button-fill">${self.$t('notifications_banner.cancel_button')}</a>
              <a href="#" class="fc-banner-success button button-raised-md button-round button-small button-fill">${self.$t('notifications_banner.enable_button')}</a>
            </div>
          </div>
        `);
        $('.fc-banner-cancel').on('click', () => {
          $('.fc-banner').remove();
          window.localStorage.notificationsBannerCanceled = true;
          window.localStorage.notificationsBannerShown = true;
          events.$emit('notificationsBannerCancel');
          if (cancel) cancel();
        });
        $('.fc-banner-success').on('click', () => {
          $('.fc-banner').remove();
          window.localStorage.notificationsBannerShown = true;
          events.$emit('notificationsBannerEnable');
          if (success) success();
        });
      },
      confirmDialog(...args) {
        const self = this;
        const defaultDialogTitle = self.$f7.params.dialog.title || self.$f7.name;
        let [text, title, callbackOk, callbackCancel] = args;
        if (typeof args[1] === 'function') {
          [text, callbackOk, callbackCancel, title] = args;
        }
        return self.$f7.dialog.create({
          title: typeof title === 'undefined' ? defaultDialogTitle : title,
          text,
          buttons: [
            {
              text: self.$t('general.cancel'),
              onClick: callbackCancel,
              keyCodes: [27],
            },
            {
              text: self.$t('general.yes'),
              bold: true,
              onClick: callbackOk,
              keyCodes: [13],
            },
          ],
          destroyOnClose: true,
        }).open();
      },
      setLanguage(language) {
        const self = this;
        self.$set(self, 'language', language);
        window.localStorage.finderscrowd_lang = language;
        extend(self.$f7.params, {
          dialog: {
            buttonOk: self.$t('general.ok'),
            buttonCancel: self.$t('general.cancel'),
          },
          calendar: {
            toolbarCloseText: self.$t('calendar.done'),
            monthNames: self.$t('calendar.month_names'),
            monthNamesShort: self.$t('calendar.month_names_short'),
            dayNames: self.$t('calendar.day_names'),
            dayNamesShort: self.$t('calendar.day_names_short'),
          },
        });
      },
      login() {
        events.$emit('login');
      },
      logout() {
        const self = this;
        function logout() {
          events.$emit('logout');
        }
        self.$store.dispatch('user/logout', { callbackOk: logout, callbackError: logout });
      },
      setRoleRoutes(newRole) {
        this.$f7.routes = routes(newRole);
        this.$f7.params.routes = routes(newRole);
        if (this.$f7.views.main) {
          this.$f7.views.main.routes = routes(newRole);
          this.$f7.views.main.router.routes = routes(newRole);
        }
      },
      changeRole(newRole) {
        events.$emit('changeRole', newRole, routes(newRole));
      },
      changeUser(user) {
        events.$emit('changeUser', user);
      },
      openPopup(url, delay, closeByBackdropClick = true, tabletFullScreen) {
        const self = this;
        self.popupTabletFullScreen = !!tabletFullScreen;
        self.popupCloseByBackdropClick = closeByBackdropClick;
        let pageUrl = url;
        if (pageUrl.indexOf('?') < 0) {
          pageUrl = `${pageUrl}?popup=true`;
        } else if (pageUrl.indexOf('popup=true') < 0) {
          pageUrl = `${pageUrl}&popup=true`;
        }
        if (!delay) {
          self.popupUrl = pageUrl;
        } else {
          self.popupDelayed = true;
          setTimeout(() => {
            self.popupUrl = pageUrl;
          }, 400);
        }
        self.popupOpened = true;
      },
      closePopup() {
        const self = this;
        self.popupOpened = false;
      },
      updateNotificationsCount() {
        const self = this;
        if (!self.loggedIn) return;
        if (self.userRole === 'finder') {
          self.$store.dispatch('f/getNotificationsCount', {
            callbackOk(count) {
              self.notificationsCount = count;
            },
          });
        } else if (self.userRole === 'company') {
          self.$store.dispatch('c/getNotificationsCount', {
            callbackOk(count) {
              self.notificationsCount = count;
            },
          });
        }
      },
      f7ready(f7) {
        const self = this;
        const $ = f7.$;

        events.$on('updateNotificationsCount', self.updateNotificationsCount);
        events.$on('pushForeground', self.updateNotificationsCount);
        events.$on('resume', self.updateNotificationsCount);
        setInterval(() => {
          if (!self.$root.loggedIn || self.$root.userRole === 'admin') return;
          self.updateNotificationsCount();
        }, 1000 * 60);

        // Check browser compatability
        (() => {
          if (window.cordova || !f7.device.desktop) return;
          if (window.CSS && window.CSS.supports('color', 'var(--f7-theme-color)')) {
            return;
          }

          $('body').append(`
            <div class="unsupported-browser-modal">
              <img src="/i/logo-white.svg" style="width: 60px">
              <img src="/i/logo-text-white.svg" style="width: 240px">
              ${self.$t('general.unsupported_browser_message', {
                chrome: '<a href="https://www.google.com/chrome/" class="external" target="_blank">Google Chrome</a>',
                firefox: '<a href="https://firefox.com" class="external" target="_blank">Firefox</a>',
                edge: '<a href="https://www.microsoft.com/EN-US/windows/microsoft-edge" class="external" target="_blank">Edge</a>',
              })}
            </div>
          `);
        })();

        // Extend F7 params
        extend(f7.params, {
          dialog: {
            buttonOk: self.$t('general.ok'),
            buttonCancel: self.$t('general.cancel'),
          },
          calendar: {
            toolbarCloseText: self.$t('calendar.done'),
            monthNames: self.$t('calendar.month_names'),
            monthNamesShort: self.$t('calendar.month_names_short'),
            dayNames: self.$t('calendar.day_names'),
            dayNamesShort: self.$t('calendar.day_names_short'),
          },
        });

        // Extend App
        events.$emit('f7ready', f7);

        // Add IE
        if (self.$device.ie) {
          $('html').addClass('device-ie');
        }

        // GA Track
        let gaTimeout;
        function trackPage(url, route) {
          if (!window.ga || !url) return;
          const role = localStorage.userRole;
          clearTimeout(gaTimeout);
          if ((role === 'finder' || role === 'company') && route.route.path === '/assignment/:id/') {
            return;
          }
          const trackUrl = url[0] === '/' ? url : `/${url}`;
          gaTimeout = setTimeout(() => {
            if (window.cordova) {
              window.ga('send', {
                hitType: 'screenview',
                screenName: trackUrl,
                appName: 'FindersCrowd',
                dimension1: localStorage.userRole,
              });
            } else {
              window.ga('send', 'pageview', {
                page: trackUrl,
                dimension1: localStorage.userRole,
              });
            }
          }, 300);
        }

        f7.on('routeChange', (routeTo) => {
          // Send GA
          if (window.ga) {
            trackPage(routeTo.url, routeTo);
          }

          // Close Toast on route change
          const toast = f7.toast.get();
          if (!toast) return;
          if (toast.$el && toast.$el.hasClass('toast-app-updater')) return;
          toast.close();
        });

        f7.on('pageAfterIn', () => {
          if (!f7.views.main) return;
          f7.views.main.$el.children('.page').each((index, pageEl) => {
            if (!pageEl.f7Page || !pageEl.f7Page.route) return;
            const $pageEl = $(pageEl);
            if (pageEl.f7Page.route.url !== f7.views.main.router.history[0] && $pageEl.prev('.page').length) {
              setTimeout(() => {
                $pageEl.addClass('no-swipe-panel');
              });
            }
          });
        });


        // Close Dialog
        $(document).on('click', 'a.dialog-close', () => {
          f7.dialog.close();
        });

        // Init FireBase & Brief Widget
        Activity.track();
        if (!window.cordova) {
          Firebase.init(f7);
          PWA.init(f7);

          if (self.$root.loggedIn) {
            self.$nextTick(() => {
              if (f7.views && f7.views.main && f7.views.main.router.url && f7.views.main.router.url.indexOf('/confirmation/') >= 0) {
                events.$once('confirmed', () => {
                  setTimeout(() => {
                    Firebase.run();
                  }, 300);
                });
              } else if (f7.views && f7.views.main && f7.views.main.router.url) {
                Firebase.run();
              }
            });
          }
        } else {
          cordovaInit.call(self, f7);
        }

        if (self.$root.loggedIn && self.$root.userRole !== 'admin') {
          briefWidget();
        }

        if (self.loggedIn) {
          socket.init();
          self.updateNotificationsCount();
        }
        events.$on('loggedIn', self.updateNotificationsCount);
        events.$on('loggedOut', socket.disconnect);
        events.$on('loggedIn', socket.init);
      },
    },
    render: h => h('App'),
    store,
  });

  events.$on('showNotificationsBanner', (...args) => {
    vueApp.showNotificationsBanner(...args);
  });

  events.$on('showAppUpdateMessage', (ok) => {
    const f7 = vueApp.$f7;
    function showToast() {
      const toast = f7.toast.create({
        render() {
          return `
            <div class="toast toast-bottom toast-app-updater">
              <div class="toast-content">
                <div class="toast-text">${vueApp.$t('app_updater.message')}</div>
                <a class="toast-button color-brand-orange ${f7.theme === 'md' ? 'button' : 'link'}">${vueApp.$t('app_updater.button')}</a>
              </div>
            </div>
          `.trim();
        },
      });
      toast.$el.find('.toast-button').on('click', () => {
        toast.close();
        ok();
      });
      toast.open();
    }
    if (vueApp.loggedIn) showToast();
    else {
      events.$once('login', () => {
        showToast();
      });
    }
  });
  events.$on('joinCompany', (companyGuid) => {
    vueApp.joinCompany(companyGuid);
  });
  events.$on('joinCrowd', (crowdGuid) => {
    vueApp.joinCrowd(crowdGuid);
  });

  (window.cordova ? document : window).addEventListener('online', () => {
    vueApp.online = true;
    events.$emit('online');
  }, false);
  (window.cordova ? document : window).addEventListener('offline', () => {
    vueApp.online = false;
    events.$emit('offline');
  }, false);

  return vueApp;
}
