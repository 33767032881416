/* eslint-disable */
let widgetLoaded = false;

export default function () {
  window.BriefWidgetId = 'd6a66bb6-f233-47ac-9334-e2737607aa2e';
  let user = {
    firstName: '',
    lastName: '',
    email: '',
  };
  try {
    user = JSON.parse(localStorage.userProfile);
  } catch (e) {
    // no user stored
  }
  window.BriefUser = {firstName: user.firstName, lastName: user.lastName, email: user.email, avatarUrl: ''};
  if (widgetLoaded) {
    return;
  }
  widgetLoaded = true;
  (function() {
    var w = window;
    var d = document;
    function l() {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.letsbrief.com/widget/widget.min.js';
      d.head.appendChild(s);
    }
    l();
  })();
}

