/* eslint no-console: off */

function openExternalUrl(url, target) {
  function openInAppBrowser(browserTarget = '_blank') {
    if (window.cordova.InAppBrowser) {
      window.cordova.InAppBrowser.open(url, browserTarget, 'toolbarcolor=#f47321,closebuttoncolor=#ffffff');
    }
  }
  if (target === '_systembrowser') {
    openInAppBrowser('_system');
    return;
  }
  if (!window.SafariViewController) {
    openInAppBrowser(target);
    return;
  }
  window.SafariViewController.isAvailable((available) => {
    if (available) {
      window.SafariViewController.show(
        {
          url,
          hidden: false, // default false. You can use this to load cookies etc in the background (see issue #1 for details).
          animated: true, // default true, note that 'hide' will reuse this preference (the 'Done' button will always animate though)
          transition: 'slide', // (this only works in iOS 9.1/9.2 and lower) unless animated is false you can choose from: curl, flip, fade, slide (default)
          enterReaderModeIfAvailable: false, // default false
          tintColor: '#ffffff', // default is ios blue
          barColor: '#f47321', // on iOS 10+ you can change the background color as well
          controlTintColor: '#ffffff', // on iOS 10+ you can override the default tintColor
          showDefaultShareMenuItem: true,
        },
        // this success handler will be invoked for the lifecycle events 'opened', 'loaded' and 'closed'
        (result) => {
          if (result.event === 'opened') {
            console.log('Safari ViewController opened');
          } else if (result.event === 'loaded') {
            console.log('Safari ViewController loaded');
          } else if (result.event === 'closed') {
            console.log('Safari ViewController closed');
          }
        },
        (err) => {
          console.log(`Safari ViewController Error: ${err}`);
        }
      );
    } else {
      // potentially powered by InAppBrowser because that (currently) clobbers window.open
      openInAppBrowser();
    }
  });
}

export default openExternalUrl;
