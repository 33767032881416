<template>
  <f7-link back prevent-router icon="icon-back" :text="$t('general.back')" @click="onClick"></f7-link>
</template>
<script>
  export default {
    methods: {
      onClick() {
        if (!this.$device.cordova && this.$f7route.url.indexOf('?ps=0') > 0) {
          const previousUrl = this.$f7router.history[this.$f7router.history.length - 2] || '/';
          this.$f7router.back(previousUrl, { pushState: false });
          window.history.replaceState({}, '', previousUrl);
          return;
        }
        this.$f7router.back();
      },
    },
  }
</script>

