<template>
  <f7-list :no-hairlines="noHairlines">
    <slot name="reward-start"></slot>
    <f7-link v-if="showDeleteButton" class="assignment-reward-delete" icon-color="brand-red" @click="onDeleteClick" icon-ios="f7:close" icon-md="material:close"></f7-link>
    <li v-if="level && level === 1" class="assignment-reward-level padding-left padding-top">{{$t('assignment.reward_level_number', { level })}}</li>
    <li v-if="level && level > 1" class="assignment-reward-level padding-left padding-top">{{$t('assignment.reward_level_number', { level })}} - {{$t('assignment.reward_after_level_reached', { level: level -1 })}}</li>
    <li class="assignment-reward-switch">
      <div class="assignment-reward-checkboxes" :class="{ disabled }">
        <f7-radio class="no-ripple" :checked="type === 0" @change="setRewardType($event, 0)"><span>{{$t('assignment_form.reward_fixed_label')}}</span></f7-radio>
        <f7-radio class="no-ripple" :checked="type === 1" @change="setRewardType($event, 1)"><span>{{$t('assignment_form.reward_percentage_label')}}</span></f7-radio>
      </div>
    </li>
    <li class="assignment-reward-fixed" v-if="type === 0">
      <div class="item-content item-input">
        <div class="item-inner">
          <div class="item-input-wrap">
            <input type="number" :class="{ disabled: disabled && !allowEditRewardAmount }" :placeholder="$t('assignment_form.reward_fixed_placeholder', { level })" validate required :value="fixed" min="1" @input="change('fixed', parseInt($event.target.value, 10))">
          </div>
        </div>
      </div>
      <div class="item-content item-input" :class="{ disabled: disableCurrency }">
        <div class="item-inner">
          <div class="item-input-wrap" :class="{ disabled, 'input-dropdown' : !disableCurrency  }">
            <select :value="currency" @change="change('currency', parseInt($event.target.value, 10))">
              <option
                v-for="(curr, id) in currencyList"
                :key="id"
                :value="id"
              >{{curr}}</option>
            </select>
          </div>
        </div>
      </div>
    </li>
    <f7-list-input
      v-if="type === 1"
      class="assignment-reward-percentage-value"
      inline-label
      :class="{ disabled }"
      type="select"
      :value="percentageType"
      @change="change('percentageType', parseInt($event.target.value, 10))"
    >
      <div slot="label">
        <span class="text-color-brand-orange">{{percentage || 1}}%</span> {{$t('assignment_form.reward_percentage_of_label')}}
      </div>
      <option
        v-for="(id, index) in percentageTypeList"
        :key="index"
        :selected="percentageType === id"
        :value="id"
      >{{$t(`reward_percentage_type.${percentageTypeMap(id)}`)}}</option>
    </f7-list-input>
    <f7-list-item v-if="type === 1">
      <slider
        :min="1"
        :max="100"
        :value="percentage || 1"
        :step="1"
        :label="true"
        :disabled="disabled && !allowEditRewardAmount"
        @change="change('percentage', $event)"
        @changed="changed('percentage', $event)"
      ></slider>
    </f7-list-item>

    <li class="assignment-reward-percentage-values" v-if="type === 1">
      <div class="item-title item-label">{{$t('assignment_form.reward_percentage_estimated_label', {
        type: $t(`reward_percentage_type.${percentageTypeMap(percentageType)}`),
        isSalary: percentageType === 0,
      })}}</div>
      <div class="item-inputs">
        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="number" :class="{ disabled: disabled && !allowEditRewardAmount }" :placeholder="$t('assignment_form.reward_percentage_from_placeholder')" validate required min="1" :value="expectedFrom" @input="change('expectedFrom', parseInt($event.target.value, 10))">
            </div>
          </div>
        </div>
        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-input-wrap">
              <input type="number" :class="{ disabled: disabled && !allowEditRewardAmount }" :placeholder="$t('assignment_form.reward_percentage_to_placeholder')" validate required min="1" :value="expectedTo" @input="change('expectedTo', parseInt($event.target.value, 10))">
            </div>
          </div>
        </div>
        <div class="item-content item-input" :class="{ disabled: disableCurrency }">
          <div class="item-inner">
            <div class="item-input-wrap" :class="{ disabled, 'input-dropdown' : !disableCurrency }" >
              <select :value="currency" @change="change('currency', parseInt($event.target.value, 10))">
                <option
                  v-for="(curr, id) in currencyList"
                  :key="id"
                  :value="id"
                >{{curr}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </li>
    <li class="assignment-reward-percentage-values assignment-reward-percentage-potential padding-bottom" v-if="type === 1 && expectedFrom && expectedTo">
      <div class="item-title item-label">
        <b>{{$t('assignment_form.reward_percentage_potential', {
          from: expectedFrom * percentage / 100,
          to: expectedTo * percentage / 100,
          currency: currencyList[currency],
        })}}</b>
      </div>
    </li>
    <li class="item-input" v-if="showDescription">
      <div class="item-content">
        <div class="item-inner">
          <div class="item-input-wrap">
            <textarea :class="{ disabled }" :required="descriptionRequired" :validate="descriptionRequired" :placeholder="$t('assignment_form.reward_description_placeholder')" :value="description" @input="change('description', $event.target.value)"></textarea>
          </div>
        </div>
      </div>
    </li>
  </f7-list>
</template>

<script>
  import currencyList from '../../utils/currency-list';
  import percentageTypeMap from '../../utils/reward-percentage-type-map';

  export default {
    props: {
      level: Number,
      noHairlines: Boolean,
      disabled: Boolean,
      allowEditRewardAmount: Boolean,
      percentageTypeList: Array,
      showDescription: Boolean,
      showDeleteButton: Boolean,
      disableCurrency: Boolean,
      descriptionRequired: Boolean,

      currency: [String, Number],
      type: Number,
      fixed: [Number, String],
      percentage: Number,
      percentageType: Number,
      expectedFrom: [String, Number],
      expectedTo: [String, Number],
      description: String,
    },
    data() {
      return {
        currencyList,
        originalPercentage: null,
      };
    },
    methods: {
      percentageTypeMap,
      setRewardType(e, type) {
        const self = this;
        if (e.target.checked) {
          self.change('type', type);
        }
      },
      onDeleteClick() {
        this.$emit('deleteClick');
      },
      setTempPercentage(value) {
        const self = this;
        if (self.originalPercentage === null) {
          self.originalPercentage = value;
        }
        this.$emit('change', 'percentage', value);
      },
      change(prop, value) {
        if (prop === 'percentage' && this.$root.userRole === 'admin') {
          this.setTempPercentage(value);
          return;
        }
        this.$emit('change', prop, value);
      },
      changed(prop, value) {
        const self = this;
        if (prop === 'percentage' && self.$root.userRole !== 'admin') {
          return;
        }
        if (value === self.originalPercentage) return;
        if (self.originalPercentage === null) return;
        self.$f7.dialog.confirm(self.$t('general.are_you_sure'), () => {
          this.$emit('change', prop, value);
          self.originalPercentage = null;
        }, () => {
          const previousValue = self.originalPercentage;
          this.$emit('change', prop, previousValue);
          self.originalPercentage = null;
          const range = self.$f7.range.get(self.$el.querySelector('.range-slider'));
          if (range) range.setValue(previousValue);
        });
      },
    },
  };
</script>