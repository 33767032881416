function generateUUIDSegment() {
  return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}
function generateUUID() {
  const s = generateUUIDSegment;
  return `${s() + s()}-${s()}-${s()}-${s()}-${s()}${s()}${s()}`;
}
let id = window.localStorage.getItem('device_id');
if (!id) {
  id = window.device && window.device.uuid ? window.device.uuid : generateUUID();
  window.localStorage.setItem('device_id', id);
}
const deviceId = id;
export default deviceId;
