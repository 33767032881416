export default {
  Pending: 9,
  Contacted: 0,
  InterestReceived: 1,
  RecommendationReceived: 8,
  Qualified: 2,
  FinalStage: 3,
  NoFurtherProcess: 4,
  HadContactWithRecommendation: 10,
  AgreementMade: 5,
  Declined: 6,
  Maybe: 7,
};