<template>
  <f7-input
    ref="input"
    class="table-filter"
    :style="`${maxWidth ? `max-width: ${maxWidth}px;` : ''}${minWidth ? `min-width: ${minWidth}px;` : ''}`"
    :type="type"
    :value="value"
    :placeholder="placeholder"
    :clear-button="clearButton"
    @input="onInput"
    @change="onChange"
  ><slot></slot></f7-input>
</template>
<script>
  export default {
    props: {
      type: {
        type: String,
        default: 'text',
      },
      clearButton: {
        type: Boolean,
        default: true,
      },
      placeholder: String,
      calendar: Boolean,
      value: [String, Number, Array],
      maxWidth: [String, Number],
      minWidth: [String, Number],
    },
    data() {
      return {};
    },
    beforeDestroy() {
      if (this.f7Calendar) this.f7Calendar.destroy();
    },
    mounted() {
      const self = this;
      self.$f7ready((f7) => {
        if (!self.calendar) return;

        self.f7Calendar = f7.calendar.create({
          inputEl: self.$refs.input.$el.querySelector('input'),
          value: self.value ? [new Date(self.value)] : [],
          closeOnSelect: true,
          routableModals: false,
        });
      });
    },
    methods: {
      onInput(e) {
        this.$emit('input', e);
      },
      onChange(e) {
        this.$emit('change', e);
      },
    },
  };
</script>
