<script>
import linkify from '../../utils/linkify';

export default {
  props: {
    text: String,
    urlPreview: {
      type: Boolean,
      default: false,
    },
    parsePhones: {
      type: Boolean,
      default: false,
    },
    photoBrowser: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: String,
      default: 'div',
    },
    showPreviews: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      data: [],
      photos: [],
      formattedText: null,
    };
  },
  render(c) {
    const self = this;
    return c(self.tag, {
      class: 'rich-text',
    }, [
      c('div', {
        domProps: {
          innerHTML: self.formattedText,
        },
      }),
      [
        (self.preview && self.urlPreview && c('url-preview', {
          props: {
            url: self.preview,
          },
        })),
        (self.showPreviews && self.data.length > 0 && c('div', {
          staticClass: 'previews',
        }, self.data.map((el, index) => {
          return c('div', {
            staticClass: 'preview-item photobrowser-link',
            class: {
              'image-preview': el.image,
              'video-preview': el.yt || el.vimeo || el.dreamBroker,
            },
            key: index,
            style: (() => {
              if (el.image) return { backgroundImage: `url(${el.image})` };
              if (el.yt) return { backgroundImage: `url(https://img.youtube.com/vi/${el.yt}/hqdefault.jpg)` };
              return {};
            })(),
            attrs: {
              'data-index': index,
            },
          });
        }))),
      ],
    ]);
  },
  watch: {
    text() {
      const self = this;
      self.getData();
    },
    photoBrowser() {
      const self = this;
      self.getData();
    },
  },
  methods: {
    getData() {
      const self = this;
      const { text, data, preview } = linkify(self.text, self.photoBrowser, self.parsePhones);
      self.data = data;
      self.preview = preview;
      self.formattedText = text.replace(/\n/g, '<br />');
    },
    updatePhotos() {
      const self = this;
      const photos = [];
      self.data.forEach((obj) => {
        if (obj.yt) {
          photos.push({
            html: `<iframe src="${window.cordova ? 'https:' : ''}//www.youtube.com/embed/${obj.yt}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`,
          });
        }
        if (obj.vimeo) {
          photos.push({
            html: `<iframe src="${window.cordova ? 'https:' : ''}//player.vimeo.com/video/${obj.vimeo}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`,
          });
        }
        if (obj.dreamBroker) {
          photos.push({
            html: `<iframe src="${window.cordova ? 'https:' : ''}//dreambroker.com/channel/${obj.dreamBroker[0]}/iframe/${obj.dreamBroker[1]}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`,
          });
        }
        if (obj.image) {
          photos.push({
            url: obj.image,
          });
        }
      });
      self.photos = photos;
      if (self.pb) self.pb.params.photos = photos;
    },
  },
  beforeDestroy() {
    const self = this;
    if (!self.photoBrowser) return;
    if (self.pb) self.pb.destroy();
  },
  created() {
    const self = this;
    self.getData();
  },
  mounted() {
    const self = this;

    if (!self.photoBrowser) return;
    const $ = self.$$;

    self.updatePhotos();

    self.$f7ready(() => {
      const pbView = self.$f7.views.main || self.$f7.views[0];
      self.pb = self.$f7.photoBrowser.create({
        theme: 'dark',
        photos: self.photos,
        routableModals: !!pbView,
        view: pbView,
        backLinkText: self.$t('general.close'),
        renderNavbar() {
          const pb = this;

          let iconsColor = pb.params.iconsColor;
          if (!pb.params.iconsColor && pb.params.theme === 'dark') iconsColor = 'white';

          const backLinkText = pb.params.backLinkText;

          const isPopup = pb.params.type !== 'page';
          const navbarHtml = `
            <div class="navbar">
              <div class="navbar-inner sliding">
                <div class="left">
                  <a href="#" class="link ${isPopup ? 'popup-close' : ''} ${!backLinkText ? 'icon-only' : ''} ${!isPopup ? 'back' : ''}" ${isPopup ? 'data-popup=".photo-browser-popup"' : ''}>
                    <i class="icon icon-back ${iconsColor ? `color-${iconsColor}` : ''}"></i>
                    ${backLinkText ? `<span>${backLinkText}</span>` : ''}
                  </a>
                </div>
                <div class="title">
                  <span class="photo-browser-current"></span>
                  <span class="photo-browser-of">${pb.params.navbarOfText}</span>
                  <span class="photo-browser-total"></span>
                </div>
                <div class="right"></div>
              </div>
            </div>
          `.trim();
          return navbarHtml;
        },
        on: {
          transitionEnd() {
            self.pb.$el.find('.swiper-slide-prev iframe, .swiper-slide-next iframe').each((index, el) => {
              el.src = el.src;
            });
          },
        },
      });
    });

    $(self.$el).on('click', '.photobrowser-link', function onClick(e) {
      e.preventDefault();
      const clickedIndex = $(this).attr('data-index');
      self.pb.open(parseInt(clickedIndex, 10));
    });
  },
};
</script>
