<template>
  <f7-page
    infinite
    :infinite-preloader="false"
    @infinite="onInfinite"
    @page:beforein="onPageBeforeIn"
    @page:afterin="onPageAfterIn"
  >
    <f7-navbar>
      <panel-link slot="nav-left" />
      <f7-nav-title>{{crowd ? crowd.name : (crowdPassed ? crowdPassed.name : '')}}</f7-nav-title>
      <f7-nav-right v-if="crowd || crowdPassed">
        <profile-link />
      </f7-nav-right>
    </f7-navbar>

    <offline-indicator slot="fixed"></offline-indicator>

    <f7-popover slot="fixed" class="crowd-popover" v-if="crowd || crowdPassed">
      <f7-list>
        <f7-list-item
          popover-close
          :link="`/crowd/${crowdId}/finders/`"
        >
          <div slot="title" style="white-space: normal">{{$t('assignments.manage_finders')}}</div>
          <f7-icon slot="media" ios="f7:persons" md="material:person" />
        </f7-list-item>
        <f7-list-item
          popover-close
          :link="`/crowd/${crowdId}/administrators/`"
        >
          <div slot="title" style="white-space: normal">{{$t('assignments.manage_admins')}}</div>
          <f7-icon slot="media" ios="f7:briefcase_fill" md="material:business_center" />
        </f7-list-item>
        <f7-list-item
          popover-close
          :link="`/crowd/${crowdId}/edit/`"
        >
          <div slot="title" style="white-space: normal">{{$t('assignments.edit_crowd')}}</div>
          <f7-icon slot="media" ios="f7:edit" md="material:edit" />
        </f7-list-item>
      </f7-list>
    </f7-popover>

    <chat-button
      slot="fixed"
      v-if="crowd && crowd.isJoined"
      :crowd-id="crowdId"
      :crowd-name="crowd.name"
    />

    <div class="center-content center-content-blocks">
      <f7-block-title class="display-flex justify-content-flex-end align-items-center">
        <f7-button small round fill popover-open=".crowd-popover" class="margin-right-half">{{$t('assignments.crowd_settings')}}</f7-button>
        <f7-button small round fill :href="`/assignment/new/?crowdId=${crowdId}`" v-if="assignments && (assignments.length || hasAssignments)">{{$t('assignments.new_assignment_button')}}</f7-button>
      </f7-block-title>
      <template v-if="!assignments">
        <f7-block strong class="assignment-list-filter">
          <div class="input input-dropdown skeleton-text skeleton-effect-fade">
            <select disabled readonly>
              <option>____ ____ ____</option>
            </select>
          </div>
        </f7-block>
        <skeleton-assignment-list></skeleton-assignment-list>
      </template>
      <f7-block strong v-if="hasAssignments || category !== null" class="assignment-list-filter">
        <div class="input input-dropdown">
          <select :value="category" @change="changeCategoryFilter($event.target.value)">
            <option :value="null">{{$t('assignment_category.All')}}</option>
            <option
              v-for="(category, key) in assignmentCategoryList"
              :value="category"
              :data-key="key"
              :key="key"
            >{{$t(`assignment_category.${key}`)}}</option>
          </select>
        </div>
      </f7-block>
      <c-assignment-list v-if="assignments && assignments.length" :assignments="assignments"></c-assignment-list>
      <f7-block strong v-if="assignments && !assignments.length && !hasAssignments" class="text-align-center crowd-assignments-intro">
        <h3>{{$t('assignments.crowd_no_published_assignments')}}</h3>
        <p>
          <span>{{$t('assignments.crowd_no_published_invite_text')}}</span>
          <f7-button
            large
            fill
            round
            href="finders/"
          >
            <f7-icon ios="f7:persons" md="material:person" />
            <span>{{$t('assignments.crowd_no_published_invite_button')}}</span>
          </f7-button>
        </p>
        <p>
          <span>{{$t('assignments.crowd_no_published_assignment_text')}}</span>
          <f7-button
            large
            fill
            round
            :href="`/assignment/new/?crowdId=${crowdId}`"
          >
            <f7-icon ios="f7:document_text_fill" md="material:assignment" />
            <span>{{$t('assignments.crowd_no_published_assignment_button')}}</span>
          </f7-button>
        </p>
      </f7-block>
      <f7-block strong v-if="assignments && !assignments.length && hasAssignments" class="empty-block">
        <p>{{$t('assignments.no_published_assignments')}}</p>
      </f7-block>
      <f7-preloader v-if="allowInfinite && listHasMore" class="infinite-scroll-preloader"></f7-preloader>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import events from '../../modules/events';
  import dispatchWithRetry from '../../utils/dispatch-with-retry';
  import assignmentCategoryList from '../../utils/assignment-category-list';

  export default {
    mixins: [dispatchWithRetry],
    props: {
      crowdId: [Number, String],
      crowdPassed: Object,
    },
    data() {
      return {
        crowd: null,
        assignments: null,
        assignmentCategoryList,
        allowInfinite: false,
        hasAssignments: false,
      };
    },
    created() {
      const self = this;

      localStorage.lastCrowdId = self.crowdId;

      self.$store.dispatch('c/unsetCrowd');
      self.$store.dispatch('c/unsetCrowdAssignmentList');
      self.$store.dispatch('c/unsetCrowdAssignmentListCategory');

      if (self.profile) {
        self.dispatchWithRetry('c/getCrowdAssignmentList', { crowdId: self.crowdId });
        self.dispatchWithRetry('c/getCrowd', { id: self.crowdId });
      }

      self.timeout = setTimeout(() => {
        self.update();
      }, 30000);
    },
    beforeDestroy() {
      const self = this;
      self.destroyed = true;
      clearTimeout(self.timeout);
    },
    computed: {
      isEnterprise() {
        return this.profile && this.profile.isEnterprise;
      },
      userConfirmed() {
        return this.$root.userConfirmed;
      },
      ...mapGetters({
        storeCrowd: 'c/crowd',
        storeAssignments: 'c/crowdAssignmentList',
        category: 'c/crowdAssignmentListCategory',
        loading: 'c/crowdAssignmentListLoading',
        loadingMore: 'c/crowdAssignmentListMoreLoading',
        profile: 'c/profile',
        listHasMore: 'c/crowdAssignmentListHasMore',
      }),
    },
    watch: {
      storeCrowd() {
        if (this.storeCrowd && parseInt(this.crowdId, 10) === this.storeCrowd.id) {
          this.crowd = this.storeCrowd;
        }
      },
      storeAssignments() {
        const crowdId = parseInt(this.crowdId, 10);
        if (this.storeAssignments && this.storeAssignments.crowdId === crowdId) {
          this.assignments = this.storeAssignments.items;
        }
      },
      profile(profile, oldProfile) {
        const self = this;
        if (profile && !oldProfile) {
          self.dispatchWithRetry('c/getCrowdAssignmentList', { crowdId: self.crowdId });
          self.dispatchWithRetry('c/getCrowd', { id: self.crowdId });
        }
      },
      'assignments.length': function watchAssignments(length) {
        const self = this;
        if (length) {
          self.hasAssignments = true;
        }
        if (length && length % 20 === 0) {
          self.allowInfinite = true;
        } else {
          self.allowInfinite = false;
        }
      },
    },

    mounted() {
      const self = this;
      self.setScrollOnUpdate = true;
      events.$emit('setActiveMenu', parseInt(self.crowdId, 10));
      events.$on('assignment-created', () => {
        self.onAssignmentCreated = true;
      });
    },
    methods: {
      restoreScroll() {
        const self = this;
        self.setScrollOnUpdate = false;
        if (!self.$device.cordova) return;
        const scrollTop = self.$f7router.scrollHistory[self.$f7route.url];
        if (scrollTop) {
          self.$nextTick(() => {
            self.$$(self.$el).find('.page-content').scrollTop(scrollTop);
          });
        }
      },
      changeCategoryFilter(categoryId) {
        const self = this;
        self.$store.dispatch('c/setCrowdAssignmentListCategory', { category: categoryId || null, crowdId: self.crowdId });
      },
      onInfinite() {
        const self = this;
        if (!self.allowInfinite || self.loadingMore || !self.listHasMore) return;
        self.$store.dispatch('c/getMoreCrowdAssignmentList', { crowdId: self.crowdId });
      },
      onPageBeforeIn(e, page) {
        const self = this;
        if (page.direction === 'backward') {
          clearTimeout(self.timeout);
          self.update();
        }
      },
      onPageAfterIn(e, page) {
        const self = this;
        if (page.direction === 'backward') {
          if (self.onAssignmentCreated) {
            self.onAssignmentCreated = false;
          }
          self.$store.dispatch('c/unsetAssignment');
        } else {
          setTimeout(() => {
            if (self.$root.justLoggedIn && self.$f7router.history.length === 1 && self.$f7router.currentRoute.name === 'assignment-list') {
              self.$f7router.navigate('/my-assignments/', { reloadAll: true });
            }
            self.$root.justLoggedIn = false;
          }, 100);
        }
      },
      update() {
        const self = this;
        if (self.destroyed) return;
      },
    },
  };
</script>
