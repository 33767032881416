<template>
  <f7-page login-screen theme-dark>
    <div class="middle-content">
      <f7-login-screen-title>{{$t('auth.confirm_title')}}</f7-login-screen-title>
      <f7-block class="auth-error-block" v-if="errorMessage"><span>{{errorMessage}}</span></f7-block>
      <f7-list class="inputs-list">
        <f7-list-input type="text" :placeholder="$t('auth.firstname_placeholder')" :value="firstName" @input="firstName = $event.target.value" @keypress.enter.native="onEnter" required validate></f7-list-input>
        <f7-list-input type="text" :placeholder="$t('auth.lastname_placeholder')" :value="lastName" @input="lastName = $event.target.value" @keypress.enter.native="onEnter" required validate></f7-list-input>
        <f7-list-input type="password" :placeholder="$t('auth.password_placeholder')" :value="password" @input="password = $event.target.value" @keypress.enter.native="onEnter" required validate></f7-list-input>
        <f7-list-input type="password" :placeholder="$t('auth.password_confirm_placeholder')" :value="passwordConfirm" @input="passwordConfirm = $event.target.value" @keypress.enter.native="onEnter" required validate></f7-list-input>
        <li v-if="$theme.ios"></li>
      </f7-list>
      <f7-block>
        <loading-button :disabled="facebookLoginIn" large fill round preloader-color="brand-orange" @click="login" :loading="confirming" :text="$t('auth.login_button')"></loading-button>
        <!--<div v-if="role === 'finder' || role === 0" class="login-buttons-divider"><span>{{$t('general.or')}}</span></div>
        <loading-button :disabled="confirming" v-if="role === 'finder' || role === 0" class="facebook-button" large fill round preloader-color="white" @click="loginFacebook" :loading="facebookLoginIn">
          <f7-icon f7="logo_facebook"></f7-icon>
          <span>{{$t('auth.confirm_facebook_button')}}</span>
        </loading-button>
        <loading-button :disabled="confirming" v-if="role === 'finder' || role === 0" class="google-button" large fill round preloader-color="black" @click="loginGoogle" :loading="googleLoginIn">
          <f7-icon f7="logo_facebook"></f7-icon>
          <span>{{$t('auth.confirm_facebook_button')}}</span>
        </loading-button>
        -->
      </f7-block>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import events from '../../modules/events';
  import Facebook from '../../modules/facebook';
  import Google from '../../modules/google';

  export default {
    props: {
      confirmationId: [Number, String],
      assignmentId: String,
    },
    data() {
      return {
        firstName: '',
        lastName: '',
        password: '',
        passwordConfirm: '',
        role: null,
        facebookLoginIn: false,
        facebookId: null,
        facebookAccessToken: null,
        facebookUser: null,
        googleLoginIn: false,
        googleId: null,
        googleAccessToken: null,
        googleUser: null,
        errorMessage: null,
      };
    },
    created() {
      const self = this;
      self.$store.dispatch('user/getConfirmationDetails', {
        confirmationId: self.confirmationId,
        callbackOk(details) {
          if (details.language) {
            self.$root.setLanguage(details.language);
          }
          if (details.firstName && !self.firstName) self.firstName = details.firstName;
          if (details.lastName && !self.lastName) self.lastName = details.lastName;
          if ((details.role || details.role === 0) && !self.role) self.role = details.role;
        },
      });
    },
    computed: {
      ...mapGetters({
        confirming: 'user/confirming',
      }),
    },
    mounted() {
      const self = this;
      if (self.$root.loggedIn) {
        events.$emit('hideAppMenu');
      }
      Google.googleGetLoginStatus(() => {
        Google.googleGetUser((user) => {
          self.googleUser = user;
        });
      });
      Facebook.fbGetLoginStatus((res) => {
        Facebook.fbGetUser(res.authResponse.userID, (user) => {
          self.facebookUser = {
            ...user,
            accessToken: res.authResponse.accessToken,
          };
        });
      });
    },
    methods: {
      showError(e) {
        let message = e;
        if (!message) return;
        if (message && typeof message !== 'string' && 'responseText' in message && !message.responseText) return;
        if (message.responseText) message = message.responseText;
        if (typeof message === 'string' && message.indexOf('{') === 0) {
          message = JSON.parse(message).message;
        }
        if (!message) return;
        this.errorMessage = message;
      },
      hideError() {
        this.errorMessage = null;
      },
      onEnter() {
        const self = this;
        self.login();
      },
      onLogin(response) {
        const self = this;
        const { assignmentId } = self;
        if (!response || !response.accessToken) return;
        window.localStorage.visited = true;
        window.localStorage.accessToken = response.accessToken;
        window.localStorage.userRole = response.claims.role;
        window.localStorage.userConfirmed = 'true';
        self.$root.userRole = response.claims.role;
        self.$root.userConfirmed = true;

        let redirectUrl = localStorage.lastCrowdId ? `/crowd/${localStorage.lastCrowdId}/` : undefined;
        if (response.redirect) {
          redirectUrl = response.redirect;
        } else if (self.$f7route.query && self.$f7route.query.redirect) {
          redirectUrl = self.$f7route.query.redirect;
        } else if (assignmentId) {
          redirectUrl = `/assignment/${assignmentId}/edit/`;
        }

        self.$root.setLanguageOnProfileRequest = true;
        if (response.language) {
          self.$root.setLanguage(response.language);
        }

        const needReloadAll = redirectUrl && redirectUrl.match(/\/crowd\/[0-9]*[/]?/) && redirectUrl.match(/\/crowd\/[0-9]*[/]?/)[0] === redirectUrl;

        if (self.$root.loggedIn) {
          self.$root.setRoleRoutes(self.$root.userRole);
          self.$store.dispatch(`${self.$root.storeRolePrefix}/getProfile`, {
            cached: false,
            callbackOk() {
              events.$emit('showAppMenu');
            },
          });
          self.$store.dispatch(`${self.$root.storeRolePrefix}/getMyCrowdList`);
          self.$f7router.navigate('/', { reloadAll: true });


          if (redirectUrl) {
            setTimeout(() => {
              self.$f7.views.main.router.navigate(redirectUrl, { reloadAll: needReloadAll });
              events.$emit('confirmed');
            }, 300);
          } else {
            events.$emit('confirmed');
          }
        } else if (self.$device.cordova) {
          self.$root.login();

          if (redirectUrl) {
            setTimeout(() => {
              self.$f7.views.main.router.navigate(redirectUrl, { reloadAll: needReloadAll });
            }, 300);
          }
        } else {
          window.history.replaceState({}, '', '/');
          setTimeout(() => {
            self.$root.login();
            if (redirectUrl) {
              setTimeout(() => {
                self.$f7.views.main.router.navigate(redirectUrl, { reloadAll: needReloadAll });
              }, 300);
            }
          }, 0);
        }
        if (response.alert) {
          setTimeout(() => {
            self.$f7.dialog.alert(response.alert);
          }, 300);
        }
      },
      loginGoogle() {
        const self = this;
        self.hideError();
        function sendData() {
          const { id: googleId, email, first_name, last_name, picture, accessToken } = self.googleUser;
          self.$store.dispatch('user/googleLogin', {
            googleId,
            email,
            first_name,
            last_name,
            picture,
            role: 0,
            googleAccessToken: accessToken,
            language: self.$root.language,
            callbackOk(response) {
              self.googleLoginIn = false;
              self.onLogin(response);
            },
            callbackError(e) {
              self.googleLoginIn = false;
              self.showError(e);
            },
          });
        }
        self.googleLoginIn = true;
        if (self.googleUser) {
          sendData();
          return;
        }
        Google.login((user) => {
          self.googleUser = user;
          sendData();
        }, () => {
          self.googleLoginIn = false;
        });
      },
      loginFacebook() {
        const self = this;
        self.hideError();
        function sendData() {
          const { id: facebookId, email, first_name, last_name, picture, accessToken } = self.facebookUser;
          self.$store.dispatch('user/facebookLogin', {
            facebookId,
            email,
            first_name,
            last_name,
            picture,
            role: 0,
            facebookAccessToken: accessToken,
            language: self.$root.language,
            callbackOk(response) {
              self.facebookLoginIn = false;
              self.onLogin(response);
            },
            callbackError(e) {
              self.facebookLoginIn = false;
              self.showError(e);
            },
          });
        }
        self.facebookLoginIn = true;
        if (self.facebookUser) {
          sendData();
          return;
        }
        Facebook.login((user) => {
          self.facebookUser = user;
          sendData();
        }, () => {
          self.facebookLoginIn = false;
        });
      },
      login() {
        const self = this;
        self.hideError();
        const { firstName, lastName, password, passwordConfirm, confirmationId } = self;
        if (!firstName.trim() || !lastName.trim() || !password.trim() || !passwordConfirm.trim()) {
          self.showError(self.$t('error.all_fields_required'));
          return;
        }
        if (password !== passwordConfirm) {
          self.showError(self.$t('error.passwords_dont_match'));
          return;
        }
        const data = {
          confirmationId,
          firstName,
          lastName,
          password,
          language: self.$root.language,
        };
        self.$store.dispatch('user/confirm', {
          data,
          callbackOk(response) {
            self.onLogin(response);
          },
          callbackError(e) {
            self.showError(e);
          },
        });
      },
    },
  };
</script>
