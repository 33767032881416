<template>
  <div class="shareout-slider" :class="{ 'shareout-slider-disabled': disabled }">
    <div class="shareout-slider-cell shareout-slider-label text-color-brand-blue">
      <div class="number">{{value}}%</div>
      <div class="label">{{$t('general.finder')}}</div>
    </div>
    <div class="shareout-slider-cell shareout-slider-range">
      <f7-range :min="0" :max="100" :value="value" :step="10" @range:change="onChange" @range:changed="onChanged"></f7-range>
    </div>
    <div class="shareout-slider-cell shareout-slider-label text-color-brand-orange">
      <div class="number">{{100 - value}}%</div>
      <div class="label">{{$t('general.finderscrowd')}}</div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      value: Number,
      disabled: Boolean,
    },
    methods: {
      onChange(e) {
        this.$emit('change', parseInt(e, 10));
      },
      onChanged(e) {
        this.$emit('changed', parseInt(e, 10));
      },
    },
  };
</script>
