<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <back-link />
      </f7-nav-left>
      <f7-nav-title>{{$t('crowd_form.edit_title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <crowd-form v-if="crowd" :disabled="disabled" ref="form" class="center-content" :crowd="crowd" @formChanged="onFormChanged">
      <f7-list v-if="!disabled">
        <loading-list-button :loading="saving" :disabled="deleting" @click="save">{{$t('general.save')}}</loading-list-button>
      </f7-list>
      <f7-list v-if="!disabled && crowd.isOwner">
        <loading-list-button color="brand-red" :loading="deleting" :disabled="saving" @click="deleteCrowd">{{$t('crowd_form.delete_crowd')}}</loading-list-button>
      </f7-list>
    </crowd-form>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import mapRoleGetters from '../../utils/map-role-getters';
  import events from '../../modules/events';

  export default {
    props: {
      crowdId: [Number, String],
    },
    data() {
      return {};
    },
    computed: {
      disabled() {
        return false;
      },
      form() {
        return this.$refs.form;
      },
      ...mapGetters({
        saving: 'c/crowdSaving',
        deleting: 'c/crowdDeleting',
        crowd: 'c/crowd',
      }),
      ...mapRoleGetters({
        profile: 'profile',
      }),
    },
    mounted() {
      const self = this;
      self.$store.dispatch('c/getCrowd', { id: self.crowdId });
    },
    methods: {
      onFormChanged() {
        const self = this;
        self.formChanged = true;
      },
      confirmBack() {
        const self = this;
        if (self.formChanged) {
          self.$root.confirmDialog(self.$t('form.back_confirm'), () => {
            self.$f7router.back();
          });
        } else {
          self.$f7router.back();
        }
      },
      save() {
        const self = this;
        const valid = self.form.validate();
        if (!valid) return;

        const newData = self.form.getData();

        const newCrowd = {
          id: self.crowdId,
          ...newData,
        };

        self.$store.dispatch('c/saveCrowd', {
          crowd: newCrowd,
          callbackOk() {
            self.$f7router.back();
            self.$store.dispatch('c/getCrowd', { id: self.crowdId });
            events.$emit('crowd-created');
          },
          callbackError() {
            self.action = null;
          },
        });
      },
      deleteCrowd() {
        const self = this;
        self.$f7.dialog.confirm(self.$t('crowd_form.delete_crowd_confirm'), () => {
          self.$store.dispatch('c/deleteCrowd', {
            id: self.crowdId,
            callbackOk() {
              self.$f7router.navigate('/', { reloadAll: true });
            },
          });
        });
      },
    },
  };
</script>
