import currencies from './currency-list';

const lang = window.navigator.language;

let curr;

if (lang.indexOf('sv') === 0) {
  curr = 'SEK';
} else if (lang.indexOf('nb') === 0) {
  curr = 'NOK';
} else {
  curr = 'USD';
}

const defaultCurrencyId = Object.keys(currencies).filter((currId) => {
  return (currencies[currId] === curr);
})[0];

export default defaultCurrencyId;
