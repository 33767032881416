import striptags from 'striptags';
import urlRegex from './url-regex';
import getVideoDataFromUrl from './get-video-data-from-url';

export default function (text, photoBrowser, parsePhones) {
  let linkTarget = window.cordova ? '_inappbrowser' : '_blank';
  const img = ('svg jpg jpeg png gif').split(' ');
  const data = [];
  let preview = null;
  let linkedText = striptags(text || '').replace(urlRegex, (url) => {
    const { yt, vimeo, dreamBroker } = getVideoDataFromUrl(url);
    let pic;
    if (photoBrowser) {
      if (yt) {
        data.push({ yt });
      }
      if (vimeo) {
        data.push({ vimeo });
      }
      if (dreamBroker) {
        data.push({ dreamBroker });
      }

      // Images
      img.forEach((ext) => {
        const urlExt = url.substr(url.length - ext.length - 1);
        if (urlExt === `.${ext}`) {
          data.push({ image: url });
          pic = url;
        }
      });
    }

    // MarkDown
    if (url.indexOf('[') === 0) {
      return url;
    }
    // EMAIL
    if (url.indexOf('@') >= 0 && url.indexOf('//') < 0 && url.indexOf('mailto:') < 0) {
      linkTarget = '_systembrowser';
      // eslint-disable-next-line
      url = `mailto:${url}`;
    }
    if (url.indexOf('mailto:') === 0) {
      const mailTo = url.replace('mailto:', '');
      return `<a href="${url}" class="external" ${window.cordova ? 'target="_systembrowser"' : ''}>${mailTo}</a>`;
    }
    let href = url;
    // Add http
    if (!href.match(/(http:|ftp:|file:|https:)/) && href.match(/[a-z0-9]/).index === 0) {
      href = `http://${href}`;
    }
    const mediaLink = yt || pic || vimeo || dreamBroker;
    const classAttr = mediaLink ? `photobrowser-link ${(yt || vimeo || dreamBroker) ? 'video-link' : 'image-link'}` : 'external';
    const hrefAttr = mediaLink ? '' : href;
    if (!mediaLink && !preview) preview = href;

    return `<a ${mediaLink ? `data-index="${data.length - 1}"` : ''} class="${classAttr}" href="${hrefAttr}" target="${linkTarget}">${mediaLink ? '' : url}</a>`;
  });

  // Parse Images
  linkedText = linkedText.replace(/!\(([^)]*)\)\[([^]*)]/gi, '<img alt="$1" src="$2">');

  // Parse Manual Links
  linkedText = linkedText.replace(/\(([^)]*)\)\[([^]*)]/gi, `<a href="$2" title="$1" target="${linkTarget}" class="external">$1</a>`);

  // Tel
  if (parsePhones) {
    linkedText = linkedText.replace(/([0-9][0-9-()+ ]{5,}[0-9])/gi, `<a href="tel:$1" class="external" ${window.cordova ? 'target="_systembrowser"' : ''}>$1</a>`);
  }

  return {
    text: linkedText,
    data,
    preview,
  };
}
