<template>
  <table style="min-width: 640px">
    <thead>
      <tr>
        <th>
          <span>{{$t('general.verification')}}</span>
        </th>
        <th>
          <span>{{$t('general.assignment')}}</span>
        </th>
        <th>
          <span>{{$t('general.finder')}}</span>
        </th>
        <th>
          <span>{{$t('general.candidate')}}</span>
        </th>
        <th>
          <span>{{$t('general.amount')}}</span>
        </th>
        <th>
          <span>{{$t('general.date')}}</span>
        </th>
        <th>
          <span>{{$t('general.status')}}</span>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="transaction in transactions" :key="transaction.id">
        <td>{{transaction.verificationId}}</td>
        <td style="min-width: 150px">
          <f7-link :href="`/assignment/my/${transaction.assignmentId}/`">{{transaction.assignmentTitle}}{{transaction.assignmentMultipleLevels ? ` (${$t('payments.assignment_level', {level: transaction.assignmentRewardLevel})})` : ''}}</f7-link>
        </td>
        <td>{{transaction.finder.firstName + ' ' + transaction.finder.lastName}}</td>
        <td>
          <a v-if="transaction.candidate.id" :href="`/candidate/${transaction.candidate.id}/`">{{transaction.candidate.firstName && transaction.candidate.lastName ? transaction.candidate.firstName + ' ' + transaction.candidate.lastName : transaction.candidate.email}}</a>
          <span v-else>{{transaction.candidate.firstName && transaction.candidate.lastName ? transaction.candidate.firstName + ' ' + transaction.candidate.lastName : transaction.candidate.email}}</span>
        </td>
        <td class="nowrap">{{transaction.amount}} {{currencyMap(transaction.currency)}}</td>
        <td class="nowrap">{{formatDate(transaction.createdDate)}}</td>
        <td>
          {{typeof transaction.status !== 'undefined' ? $t(`company_transaction_status.${transactionStatusMap(transaction.status)}`) : ''}}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import transactionStatusMap from '../../utils/company-transaction-status-map';
  import formatDate from '../../utils/format-date';
  import currencyMap from '../../utils/currency-map';

  export default {
    props: {
      transactions: Array,
    },
    data() {
      return {
      };
    },
    methods: {
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
      currencyMap,
      transactionStatusMap,
    },
  };
</script>
