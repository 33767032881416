<template>
  <f7-page>
    <f7-navbar>
      <panel-link slot="nav-left" />
      <f7-nav-title>{{$t('crowd_form.create_title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <crowd-form v-if="crowd && profile && profile.isEnterprise" :disabled="disabled" ref="form" class="center-content" :crowd="crowd" @formChanged="onFormChanged">
      <f7-list v-if="!disabled">
        <loading-list-button :loading="saving" @click="save">{{$t('general.create')}}</loading-list-button>
      </f7-list>
    </crowd-form>
    <f7-block strong v-if="profile && !profile.isEnterprise" class="text-align-center">
      <p>{{$t('crowd_form.upgrade_text')}}</p>
      <p v-if="!upgradeRequestSent">
        <f7-button
          :text="$t('crowd_form.upgrade_button')"
          fill
          round
          class="display-inline-flex"
          @click="sendUpgradeRequest"
        />
      </p>
      <p v-else><b>{{$t('crowd_form.upgrade_success', { email: profile.email })}}</b></p>
    </f7-block>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import mapRoleGetters from '../../utils/map-role-getters';
  import events from '../../modules/events';

  export default {
    data() {
      return {
        crowd: {
          name: '',
          isPrivate: true,
          description: '',
        },
        upgradeRequestSent: false,
      };
    },
    computed: {
      disabled() {
        return false;
      },
      form() {
        return this.$refs.form;
      },
      ...mapGetters({
        saving: 'c/crowdSaving',
      }),
      ...mapRoleGetters({
        profile: 'profile',
      }),
    },
    mounted() {
      events.$emit('setActiveMenu', null);
    },
    methods: {
      onFormChanged() {
        const self = this;
        self.formChanged = true;
      },
      confirmBack() {
        const self = this;
        if (self.formChanged) {
          self.$root.confirmDialog(self.$t('form.back_confirm'), () => {
            self.$f7router.back();
          });
        } else {
          self.$f7router.back();
        }
      },
      sendUpgradeRequest() {
        const self = this;
        self.$store.dispatch('c/sendEnterpriseUpgradeRequest', {
          callbackOk() {
            self.upgradeRequestSent = true;
          },
        });
      },
      save() {
        const self = this;
        const valid = self.form.validate();
        if (!valid) return;

        const newData = self.form.getData();

        const newCrowd = {
          id: 0,
          ...newData,
        };

        self.$store.dispatch('c/saveCrowd', {
          crowd: newCrowd,
          callbackOk(c) {
            if (c && c.id) {
              self.$f7router.navigate(`/crowd/${c.id}/`, {
                reloadAll: true,
                props: {
                  crowdPassed: c,
                },
              });
            }

            events.$emit('crowd-created');
          },
          callbackError() {
            self.action = null;
          },
        });
      },
    },
  };
</script>
