/* eslint no-console: off */
import facebookBrowser from './facebook-browser';

if (process.env.TARGET === 'web') {
  window.facebookConnectPlugin = facebookBrowser;
  facebookBrowser.browserInit();
}

const Facebook = {
  fbGetUser(userId, ok, error) {
    window.facebookConnectPlugin
      .api(
        `${userId}/?fields=id,email,first_name,last_name,picture`,
        ['email', 'public_profile'],
        (res) => {
          if (ok) ok(res);
        },
        (err) => {
          if (error) error(err);
          console.log(err);
        }
      );
  },
  fbLogin(ok, error) {
    window.facebookConnectPlugin.login(
      ['email', 'public_profile'],
      (res) => {
        if (!res.status || res.status !== 'connected') {
          if (error) error(res);
          return;
        }
        if (ok) ok(res);
      },
      (err) => {
        if (error) error(err);
        console.log(err);
      }
    );
  },
  fbGetLoginStatus(ok, error) {
    window.facebookConnectPlugin.getLoginStatus((res) => {
      if (res.status && res.status === 'connected') {
        if (ok) ok(res);
      } else if (error) error();
    }, (err) => {
      if (error) error();
    });
  },
  login(ok, error) {
    Facebook.fbLogin((res) => {
      Facebook.fbGetUser(res.authResponse.userID, (user) => {
        if (ok) ok({ ...user, accessToken: res.authResponse.accessToken });
      }, error);
    }, (err) => {
      if (error) error(err);
    });
  },
};

export default Facebook;
