<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon="icon-back" :text="$t('general.back')"></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{$t('assignment_form.create_title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <assignment-form v-if="assignment" ref="form" class="center-content" :assignment="assignment" :crowdId="crowdId" :crowd="crowd" @formChanged="onFormChanged">
      <f7-block strong v-if="assignmentCreateData.needsCompanyDetails" class="text-align-center">
        <p>{{$t('assignment_form.need_company_details')}}</p>
        <p>
          <f7-button small round fill class="display-inline-block" @click="editCompany">{{$t('assignment_form.edit_company_details_button')}}</f7-button>
        </p>
      </f7-block>

      <div class="buttons-row mobile-buttons-row">
        <f7-button
          :class="{ disabled: saving }"
          class="button-left"
          large
          fill
          round
          fluid
          color="gray"
          :text="$t('assignment_form.preview_link')"
          @click="previewAssignment()"
        ></f7-button>
        <loading-button
          :class="{ disabled: saving}"
          large
          fill
          round
          fluid
          color="brand-blue"
          :text="$t('general.save')"
          @click="save(assignmentStatusList.Draft)"
          :loading="action === 'save'"
        ></loading-button>
        <loading-button
          :class="{ disabled: saving || assignmentCreateData.needsCompanyDetails }"
          large
          fill
          round
          fluid
          color="brand-green"
          :text="$t('assignment_form.save_publish_button')"
          @click="save(assignmentStatusList.Published)"
          :loading="action === 'publish'"
        ></loading-button>
      </div>
      <f7-block-footer class="desktop-text-align-right mobile-text-align-center" v-html="$t('assignment_form.terms_link')"></f7-block-footer>
    </assignment-form>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import currencyMap from '../../utils/currency-map';
  import assignmentStatusList from '../../utils/assignment-status-list';
  import formatNumber from '../../utils/format-number';
  import events from '../../modules/events';

  export default {
    data() {
      const crowdId = this.$f7route.query.crowdId;
      return {
        action: null,
        company: {},
        crowdId: crowdId ? parseInt(crowdId, 10) : null,
        assignmentStatusList,
      };
    },
    computed: {
      form() {
        return this.$refs.form;
      },
      assignment() {
        const self = this;
        if (!self.assignmentCreateData) return null;
        const { interestLimit, currency, users, needsCompanyDetails, isEnterprise, crowdName } = self.assignmentCreateData;
        return { interestLimit, currency, users, needsCompanyDetails, isEnterprise, crowdId: self.crowdId, crowdName };
      },
      ...mapGetters({
        loading: 'c/assignmentCreateDataLoading',
        assignmentCreateData: 'c/assignmentCreateData',
        saving: 'c/assignmentSaving',
        crowd: 'c/crowd',
      }),
    },
    created() {
      const self = this;
      self.$store.dispatch('c/getAssignmentCreateData', { crowdId: self.crowdId });
      if (self.crowdId) {
        self.$store.dispatch('c/getCrowd', { id: self.crowdId });
      }
    },
    mounted() {
      const self = this;
      events.$on('routesBeforeLeave', self.onRouteLeave);
    },
    beforeDestroy() {
      const self = this;
      self.$store.dispatch('c/unsetAssignmentCreateData');
      events.$off('routesBeforeLeave', self.onRouteLeave);
    },
    methods: {
      currencyMap,
      onFormChanged() {
        const self = this;
        self.formChanged = true;
      },
      onRouteLeave({ to, from, prevent, resolve, reject }) {
        const self = this;
        if (self.formChanged && from && from.url === '/assignment/new/') {
          if (to && (to.url === '/how-to-write-assignment/' || to.url.indexOf('/data/') >= 0 || to.url.indexOf('/photos/') >= 0)) {
            return;
          }
          prevent();
          self.$root.confirmDialog(self.$t('form.back_confirm'), () => {
            resolve();
          }, () => {
            reject();
          });
        }
      },
      editCompany() {
        const self = this;
        self.$root.openPopup('/profile/details/?firstedit=true&popup=true&updateAssignmentCreateData=true', 0, false);
      },
      getInvoiceAmount() {
        const self = this;
        const assignment = self.form.getData();

        const { rewards = [], multipleLevels, shareOut } = assignment;
        const currency = rewards.length ? rewards[0].currency : 0;
        let amountMin = 0;
        let amountMax = 0;
        rewards.forEach((reward, index) => {
          if (!multipleLevels && index > 0) return;
          const { type, fixed, percentage, expectedTo, expectedFrom } = reward;
          const rewardPart = this.$root.userRole === 'finder' ? shareOut : 100;
          if (type === 0) {
            amountMax += fixed * rewardPart / 100;
            if (index === 0) amountMin += fixed * rewardPart / 100;
          } else {
            amountMax += expectedTo * (percentage * rewardPart / 10000);
            if (index === 0) amountMin += expectedFrom * (percentage * rewardPart / 10000);
          }
        });

        const currencyString = currencyMap(currency);
        let text = '';
        if (amountMin === amountMax) {
          text = `${formatNumber(amountMax)} ${currencyString}`;
        } else {
          text = self.$t('assignment.reward_amount_range', {
            min: formatNumber(amountMin),
            max: formatNumber(amountMax),
            currency: currencyString,
          });
        }

        return text;
      },
      save(status) {
        const self = this;
        const valid = self.form.validate();
        if (!valid) return;

        const newData = self.form.getData();
        delete newData.status;

        const newAssignment = {
          id: 0,
          replies: 0,
          status,
          crowdId: self.crowdId,
          ...newData,
        };
        self.formChanged = false;
        function proceed() {
          self.action = status === assignmentStatusList.Draft ? 'save' : 'publish';
          self.$store.dispatch('c/saveAssignment', {
            assignment: newAssignment,
            callbackOk() {
              if (self.action === 'publish' && !self.$root.userConfirmed) {
                self.$f7.dialog.alert(self.$t('assignment_form.need_confirm_dialog'));
              } else if (self.action === 'publish') {
                self.$f7.dialog.alert(self.$t('assignment_form.need_review_dialog'));
              }
              self.action = null;
              self.$f7router.back();
              events.$emit('assignment-created');
              if (self.crowdId) {
                self.$store.dispatch('c/getCrowdAssignmentList', { crowdId: self.crowdId });
              }
            },
            callbackError() {
              self.action = null;
            },
          });
        }

        if (!newAssignment.disableReward && status === assignmentStatusList.Published && !(newAssignment.isInternal && self.assignmentCreateData.rewardHandledByCompany)) {
          const amount = self.getInvoiceAmount();
          const crowdName = self.assignment.crowdName;
          self.$f7.dialog.confirm(self.$t('assignment_form.publish_confirm', { amount, crowdName }), () => {
            proceed();
          });
        } else {
          proceed();
        }
      },
      previewAssignment() {
        const self = this;
        if (!self.assignment || self.previewLoading) return;
        self.previewLoading = true;
        const preview = self.form.getData();
        self.formChanged = false;
        self.$store.dispatch('c/getPreview', {
          id: self.assignment.id,
          crowdId: self.crowdId,
          callbackOk(company) {
            self.$store.dispatch('c/setAssignmentPreview', {
              companyName: company.companyName,
              logo: company.logo,
              companyId: company.id,
              guid: company.assignmentGuid,
              crowdId: self.crowdId,
              ...preview,
              files: [...preview.files, ...preview.filesToUpload],
            });
            self.$f7router.navigate('/assignment-preview/');
            self.previewLoading = false;
          },
        });
      },
    },
  };
</script>
