/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';
import PWA from '../../modules/pwa';

const state = {
  reviewList: null,
  reviewListLoading: false,
  reviewListMoreLoading: false,
  reviewListSkip: 0,
  reviewListTake: 20,
  assignment: null,
  assignmentLoading: false,
  saving: false,
  assignmentPreview: null,
};

const getters = {
  assignmentPreview(state) {
    return state.assignmentPreview;
  },
  assignmentReviewList(state) {
    return state.reviewList;
  },
  assignmentReviewListLoading(state) {
    return state.reviewListLoading;
  },
  assignmentReviewListMoreLoading(state) {
    return state.reviewListMoreLoading;
  },
  assignmentLoading(state) {
    return state.assignmentLoading;
  },
  assignmentSaving(state) {
    return state.saving;
  },
  assignment(state) {
    return state.assignment;
  },
};
const actions = {
  getPreview(store, { id, callbackOk, callbackError }) {
    API.get({
      url: `admin/info/preview?assignmentId=${id}`,
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  setAssignmentPreview({ commit }, assignment) {
    commit('setAssignmentPreview', assignment);
  },
  getAssignmentReviewList({ commit }) {
    commit('setReviewListSkip', 0);
    API.get({
      url: `admin/assignment/reviewlist?skip=${state.reviewListSkip}&take=${state.reviewListTake}`,
      loading: 'a/setAssignmentReviewListLoading',
      ok(response) {
        commit('setAssignmentReviewList', response.items);
      },
    });
  },
  getMoreAssignmentReviewList({ commit, state }) {
    commit('setReviewListSkip', state.reviewListSkip + state.reviewListTake);
    API.get({
      url: `admin/assignment/reviewlist?skip=${state.reviewListSkip}&take=${state.reviewListTake}`,
      loading: 'a/setAssignmentReviewListLoading a/setAssignmentReviewListMoreLoading',
      ok(response) {
        commit('addToAssignmentReviewList', response.items);
      },
    });
  },
  unsetAssignmentReviewList({ commit }) {
    commit('unsetAssignmentReviewList');
  },
  getAssignment({ commit }, { id } = {}) {
    if (state.assignment) return;

    API.get({
      url: `admin/assignment/details?id=${id}`,
      loading: 'a/setAssignmentLoading',
      ok(response) {
        commit('setAssignment', response);
      },
    });
  },

  unsetAssignment({ commit }) {
    commit('unsetAssignment');
  },
  saveAssignment({ dispatch }, { assignment, callbackOk, callbackError } = {}) {
    API.post({
      url: 'admin/assignment/save',
      loading: 'a/setAssignmentSaving',
      data: assignment,
      filesToUploadProp: 'files',
      ok() {
        if (assignment && assignment.id && state.assignment && state.assignment.id === assignment.id) {
          const newAssignment = {
            ...state.assignment,
            ...assignment,
          };
          PWA.updateCachedApi(`admin/assignment/details?id=${assignment.id}`, newAssignment);
        }
        dispatch('getAssignmentReviewList');
        if (callbackOk) callbackOk();
      },
      error: callbackError,
    });
  },
};

const mutations = {
  setAssignmentPreview(state, assignment) {
    state.assignmentPreview = assignment;
  },
  setAssignmentReviewList(state, assignments) {
    state.reviewList = assignments;
  },
  unsetAssignmentReviewList(state) {
    state.reviewListSkip = 0;
    state.reviewList = null;
  },
  setAssignmentReviewListLoading(state, status) {
    state.reviewListLoading = status;
  },

  setAssignment(state, item) {
    state.assignment = item;
  },
  setAssignmentLoading(state, status) {
    state.assignmentLoading = status;
  },
  unsetAssignment(state) {
    state.assignment = null;
  },

  setAssignmentSaving(state, status) {
    state.saving = status;
  },

  setReviewListSkip(state, val) {
    state.reviewListSkip = val;
  },
  addToAssignmentReviewList(state, assignments) {
    state.reviewList.push(...assignments);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
