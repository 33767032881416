<template>
  <f7-page>
    <f7-navbar :title="$t('profile.title')" large>
      <f7-link back prevent-router slot="nav-left" @click="confirmBack" icon="icon-back" :text="$t('general.back')"></f7-link>
      <f7-link slot="nav-right" @click="logout" icon-ios="f7:exit" icon-md="material:exit_to_app" :text="$t('profile.logout_link')"></f7-link>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <div class="profile-form center-content">
      <user-profile-form ref="form" v-if="user" :user="user" @formChanged="onFormChanged">
        <f7-list>
          <loading-list-button
            color="brand-orange"
            :text="$t('general.save')"
            @click="save"
            :loading="saving"
          ></loading-list-button>
        </f7-list>
      </user-profile-form>
      <user-profile-form v-else skeleton></user-profile-form>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import mapRoleGetters from '../../utils/map-role-getters';
  import PWA from '../../modules/pwa';

  export default {
    data() {
      return {
        formChanged: false,
      };
    },
    computed: {
      form() {
        return this.$refs.form;
      },
      currentRole() {
        return ['finder', 'company', 'admin'].indexOf(this.$root.userRole);
      },
      ...mapRoleGetters({
        saving: 'profileSaving',
        loading: 'profileLoading',
        user: 'profile',
      }),
      ...mapGetters({
        loginOut: 'user/loginOut',
      }),
    },
    created() {
      const self = this;
      self.$store.dispatch(`${self.$root.storeRolePrefix}/getProfile`);
    },
    methods: {
      logout() {
        const self = this;
        PWA.removeCachedApi(() => {
          self.$root.logout();
        });
      },
      onFormChanged() {
        const self = this;
        self.formChanged = true;
      },
      confirmBack() {
        const self = this;
        if (self.formChanged) {
          self.$root.confirmDialog(self.$t('form.back_confirm'), () => {
            self.$f7router.back();
          });
        } else {
          self.$f7router.back();
        }
      },
      save() {
        const self = this;

        const valid = self.form.validate();
        if (!valid) return;

        const user = self.user;

        const newUser = {
          id: user.id,
          ...self.form.getData(),
        };

        self.$store.dispatch(`${self.$root.storeRolePrefix}/saveProfile`, {
          data: newUser,
          callbackOk() {
            self.formChanged = false;
            if (newUser.language !== self.$root.language) {
              self.$root.setLanguage(newUser.language);
            }
            self.$f7router.back();
          },
        });
      },
    },
  };
</script>
