<template>
  <f7-page login-screen theme-dark>
    <div class="middle-content">
      <f7-login-screen-title>{{$t('auth.reset_password_title')}}</f7-login-screen-title>
      <f7-block class="auth-error-block" v-if="errorMessage"><span>{{errorMessage}}</span></f7-block>
      <f7-list class="inputs-list">
        <f7-list-input @keypress.enter.native="onEnter" type="password" :placeholder="$t('auth.password_placeholder')" :value="password" @input="password = $event.target.value" validate required></f7-list-input>
        <f7-list-input @keypress.enter.native="onEnter" type="password" :placeholder="$t('auth.password_confirm_placeholder')" :value="passwordConfirm" @input="passwordConfirm = $event.target.value" validate required></f7-list-input>
        <li v-if="$theme.ios"></li>
      </f7-list>
      <f7-block>
        <loading-button large fill round preloader-color="brand-orange" @click="reset" :text="$t('auth.reset_password_button')" :loading="sending"></loading-button>
      </f7-block>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import events from '../../modules/events';

  export default {
    props: {
      guid: [String, Number],
    },
    data() {
      return {
        password: '',
        passwordConfirm: '',
        errorMessage: null,
      };
    },
    computed: {
      ...mapGetters({
        sending: 'user/resettingPassword',
      }),
    },
    methods: {
      showError(e) {
        let message = e;
        if (!message) return;
        if (message && typeof message !== 'string' && 'responseText' in message && !message.responseText) return;
        if (message.responseText) message = message.responseText;
        if (typeof message === 'string' && message.indexOf('{') === 0) {
          message = JSON.parse(message).message;
        }
        if (!message) return;
        this.errorMessage = message;
      },
      hideError() {
        this.errorMessage = null;
      },
      onEnter() {
        const self = this;
        self.reset();
      },
      reset() {
        const self = this;
        self.hideError();
        const { password, passwordConfirm, guid } = self;
        if (!password.trim() || !passwordConfirm.trim()) {
          self.showError(self.$t('error.all_fields_required'));
          return;
        }
        if (password !== passwordConfirm) {
          self.showError(self.$t('error.passwords_dont_match'));
          return;
        }
        const data = {
          guid,
          password,
          language: self.$root.language,
        };
        self.$store.dispatch('user/resetPassword', {
          data,
          callbackOk() {
            self.$f7router.navigate('/', { reloadAll: true });
            if (!self.$device.cordova) {
              window.history.replaceState({}, '', '/');
            }
            setTimeout(() => {
              events.$emit('notify', self.$t('auth.reset_password_success'));
            }, 300);
          },
          callbackError(e) {
            self.showError(e);
          },
        });
      },
    },
  };
</script>
