<template>
  <div class="chat">
    <f7-messagebar ref="messagebar" :placeholder="$t('chat.placeholder')" :value="text" @input="text = $event.target.value">
      <f7-link
        icon-ios="f7:arrow_up_round_fill"
        icon-md="material:send"
        slot="inner-end"
        @click="sendMessage"
      ></f7-link>
    </f7-messagebar>
    <div class="page-content infinite-scroll-content infinite-scroll-top" ref="pageContentEl" @infinite="onInfinite">
      <f7-messages ref="messages" :init="false">
        <template v-for="(message, index) in messagesSorted">
          <f7-messages-title
            v-if="isDifferentDate(message)"
            :key="`${message.id}_date`"
          >{{getMessagesDate(message.createdDate)}}</f7-messages-title>
          <f7-message
            :type="message.myMessage ? 'sent' : 'received'"
            :key="message.clientId || message.id"
            :header="`${message.user.firstName || ''} ${message.user.lastName || ''} ${messageTime(message.createdDate)}`"
            :avatar="messageAvatar(message.user.avatar)"
            :last="true"
            :first="true"
            :tail="true"
            :class="{ 'message-sending': message.sending, 'message-appear-from-bottom': index >= animateMessageIndex && (animatedIds.indexOf(message.id) < 0 || animatedIds.indexOf(message.clientId) < 0) }"
            @animationend.native="addAnimatedId(message)"
          >
            <div slot="text" v-html="formatText(message.text)"></div>
          </f7-message>
        </template>
        <f7-message
          v-if="messagesSorted && !messagesSorted.length && partyName"
          type="sent"
          :last="true"
          :first="true"
          :tail="true"
          class="intro-message"
        >
          <div slot="text" v-html="$t('chat.intro_message', {name: partyName, role: $t(`general.${to.toLowerCase()}`).toLowerCase()})"></div>
        </f7-message>
      </f7-messages>
    </div>
  </div>
</template>

<script>
  import striptags from 'striptags';
  import { mapGetters } from 'vuex';
  import events from '../../modules/events';
  import formatDate from '../../utils/format-date';
  import config from '../../config';
  import dispatchWithRetry from '../../utils/dispatch-with-retry';
  import mapRoleGetters from '../../utils/map-role-getters';

  export default {
    mixins: [dispatchWithRetry],
    props: {
      partyName: String,
      assignmentId: [Number, String],
      companyId: [Number, String],
      finderId: [Number, String],
      guid: [Number, String],
      from: String,
      to: String,
    },
    data() {
      return {
        interval: null,
        animateMessageIndex: 100,
        text: '',
        animatedIds: [],
        allowScrollOnKeyboardOpen: true,
        allowInfinite: false,
      };
    },
    created() {
      const self = this;
      const { from, to } = self;
      self.$store.dispatch('m/unsetMessages', { from, to });
      self.getMessages();
    },
    mounted() {
      const self = this;
      self.f7Messages = self.$f7.messages.create({
        el: self.$refs.messages.$el,
        autoLayout: false,
      });
      self.$refs.messagebar.f7Messagebar.$textareaEl.on('keypress', self.onKeyPress);
      if (self.$device.desktop) {
        self.$nextTick(() => {
          self.$refs.messagebar.focus();
        });
      }
      events.$on('keyboardWillShow', self.onKeyboardWillShow);
    },
    beforeDestroy() {
      const self = this;
      self.$refs.messagebar.f7Messagebar.$textareaEl.off('keypress', self.onKeyPress);
      self.f7Messages.destroy();
      self.destroyed = true;
      events.$off('keyboardWillShow', self.onKeyboardWillShow);
      clearTimeout(self.timeout);
    },
    computed: {
      ...mapRoleGetters({
        user: 'profile',
      }),
      ...mapGetters({
        messagesFinderCandidate: 'm/messagesFinderCandidate',
        messagesFinderCompany: 'm/messagesFinderCompany',
        messagesCompanyCandidate: 'm/messagesCompanyCandidate',
        messagesCompanyFinder: 'm/messagesCompanyFinder',
        messagesCandidateFinder: 'm/messagesCandidateFinder',
        messagesCandidateCompany: 'm/messagesCandidateCompany',

        loadingFinderCandidate: 'm/loadingFinderCandidate',
        loadingFinderCompany: 'm/loadingFinderCompany',
        loadingCompanyCandidate: 'm/loadingCompanyCandidate',
        loadingCompanyFinder: 'm/loadingCompanyFinder',
        loadingCandidateFinder: 'm/loadingCandidateFinder',
        loadingCandidateCompany: 'm/loadingCandidateCompany',

        moreLoadingFinderCandidate: 'm/moreLoadingFinderCandidate',
        moreLoadingFinderCompany: 'm/moreLoadingFinderCompany',
        moreLoadingCompanyCandidate: 'm/moreLoadingCompanyCandidate',
        moreLoadingCompanyFinder: 'm/moreLoadingCompanyFinder',
        moreLoadingCandidateFinder: 'm/moreLoadingCandidateFinder',
        moreLoadingCandidateCompany: 'm/moreLoadingCandidateCompany',

        sendingFinderCandidate: 'm/sendingFinderCandidate',
        sendingFinderCompany: 'm/sendingFinderCompany',
        sendingCompanyCandidate: 'm/sendingCompanyCandidate',
        sendingCompanyFinder: 'm/sendingCompanyFinder',
        sendingCandidateFinder: 'm/sendingCandidateFinder',
        sendingCandidateCompany: 'm/sendingCandidateCompany',
      }),
      messagesSorted() {
        const self = this;
        if (!self.messages) return null;
        return self.messages.sort((a, b) => {
          return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
        });
      },
      messages() {
        const self = this;
        return self[`messages${self.from}${self.to}`];
      },
      moreLoading() {
        const self = this;
        return self[`moreLoading${self.from}${self.to}`]
      },
      loading() {
        const self = this;
        return self[`loading${self.from}${self.to}`];
      },
      sending() {
        const self = this;
        return self[`sending${self.from}${self.to}`];
      },
    },
    watch: {
      'messages.length': function onMessagesChange(newLength, oldLength) {
        const self = this;
        if (!oldLength) {
          if (newLength >= 20) {
            self.allowInfinite = true;
          }
          self.$nextTick(() => {
            self.f7Messages.scroll(0);
          });
          self.animateMessageIndex = newLength;
          if (self.messages) {
            self.messages.forEach((m) => {
              self.addAnimatedId(m);
            });
          }
          return;
        }
        if (newLength > oldLength) {
          const pageContentEl = self.$refs.pageContentEl;
          const scrollHeightBefore = pageContentEl.scrollHeight;
          const heightBefore = pageContentEl.offsetHeight;
          const scrollBefore = pageContentEl.scrollTop;
          self.$nextTick(() => {
            let onEdge = false;
            if (scrollBefore - (scrollHeightBefore - heightBefore) >= -10) {
              onEdge = true;
            }
            if (onEdge) self.f7Messages.scroll();
          });
        }
      },
    },
    methods: {
      needToScroll() {
        const self = this;
        const pageContentEl = self.$refs.pageContentEl;
        const onBottom = pageContentEl.scrollTop >= (pageContentEl.scrollHeight - pageContentEl.offsetHeight) - pageContentEl.offsetHeight;
        return onBottom;
      },
      onKeyboardWillShow() {
        const self = this;
        if (!self.allowScrollOnKeyboardOpen) return;
        const needToScroll = self.needToScroll();
        if (needToScroll) {
          self.f7Messages.scroll(0);
        }
      },
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
      formatText(text) {
        return striptags(text || '').replace(/\n/g, '<br>');
      },
      addAnimatedId(message) {
        if (message.id && this.animatedIds.indexOf(message.id) < 0) {
          this.animatedIds.push(message.id);
        }
        if (message.clientId && this.animatedIds.indexOf(message.clientId) < 0) {
          this.animatedIds.push(message.clientId);
        }
      },
      isDifferentDate(message) {
        const self = this;
        const previousMessage = self.messagesSorted[self.messagesSorted.indexOf(message) - 1];
        if (previousMessage) {
          const previousDate = new Date(previousMessage.createdDate || new Date());
          const currentDate = new Date(message.createdDate || new Date());
          if (previousDate.getFullYear() !== currentDate.getFullYear() || previousDate.getMonth() !== currentDate.getMonth() || previousDate.getDate() !== currentDate.getDate()) {
            return true;
          }
          return false;
        }
        return true;
      },
      messageAvatar(avatar) {
        if (avatar && avatar.indexOf('http') === 0) return avatar;
        if (avatar) return `${config.path.images}${avatar}?fit=crop&w=32&h=32&dpr=${this.$device.pixelRatio}`;
        return `${this.$device.cordova ? '' : '/'}i/avatar-placeholder.svg`;
      },
      getMessagesDate(date) {
        return this.formatDate(date);
      },
      messageTime(date) {
        const d = new Date(date);
        let hours = d.getHours();
        let minutes = d.getMinutes();
        if (hours < 10) hours = `0${hours}`;
        if (minutes < 10) minutes = `0${minutes}`;
        return `${hours}:${minutes}`;
      },
      getMessages(setLoading = true, cache = true) {
        const self = this;
        if (self.destroyed) return;
        const {
          assignmentId, companyId, finderId, guid, from, to,
        } = self;
        clearTimeout(self.timeout);
        if (self.moreLoading) {
          self.timeout = setTimeout(() => {
            if (self.destroyed) return;
            self.getMessages(false);
          }, 3000);
          return;
        }
        self.dispatchWithRetry('m/getMessages', {
          assignmentId,
          companyId,
          finderId,
          guid,
          from,
          to,
          setLoading,
          cache,
          callbackOk() {
            if (self.destroyed) return;
            self.timeout = setTimeout(() => {
              if (self.destroyed) return;
              self.getMessages(false, false);
            }, 3000);
          },
        });
      },
      onKeyPress(e) {
        const self = this;
        if (!self.$device.desktop) return;
        if (e.keyCode === 13 && !e.shiftKey) {
          e.preventDefault();
          self.sendMessage();
        }
      },
      onInfinite() {
        const self = this;
        if (!self.allowInfinite || self.moreLoading) return;
        const {
          assignmentId, companyId, finderId, guid, from, to,
        } = self;
        const pageContentEl = self.$refs.pageContentEl;
        const scrollHeightBefore = pageContentEl.scrollHeight;
        const messagesBefore = self.messages.length;
        self.$store.dispatch('m/getMoreMessages', {
          assignmentId,
          companyId,
          finderId,
          guid,
          from,
          to,
          callbackOk() {
            self.animateMessageIndex = self.messages.length;
            if (self.messages.length - messagesBefore < 20) {
              self.allowInfinite = false;
            }
            self.$nextTick(() => {
              const { scrollHeight } = pageContentEl;
              pageContentEl.scrollTop = (scrollHeight - scrollHeightBefore);
            });
          },
        });
      },
      sendMessage() {
        const self = this;
        const {
          assignmentId, companyId, finderId, guid, from, to,
        } = self;

        const text = self.text.trim();
        if (!text) return;

        self.text = '';
        clearTimeout(self.timeout);
        setTimeout(() => {
          self.allowScrollOnKeyboardOpen = false;
          setTimeout(() => {
            self.allowScrollOnKeyboardOpen = true;
          }, 300);
          self.$refs.messagebar.focus();
        }, 0);

        const newMessage = {
          clientId: new Date().getTime(),
          text,
          sending: true,
          createdDate: new Date(),
          myMessage: true,
        };
        if (self.user) {
          newMessage.user = {
            firstName: self.user.firstName ? self.user.firstName : self.user.email,
            lastName: self.user.firstName ? self.user.lastName : '',
            avatar: self.user.photo && self.user.photo.downloadFileName ? self.user.photo.downloadFileName : undefined,
          };
        } else {
          newMessage.user = {};
        }

        self.$store.dispatch('m/sendMessage', {
          assignmentId,
          companyId,
          finderId,
          guid,
          from,
          to,
          message: newMessage,
          callbackOk() {
            self.getMessages(false);
          },
        });
      },
    },
  };
</script>