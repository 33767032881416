/* eslint "no-console": "off" */
import * as signalR from '../libs/@aspnet/signalr/dist/esm/index';
import events from './events';
import config from '../config';

const socket = {
  disconnected: false,
  connectTimeout: null,
  disconnect() {
    if (!socket.connection) return;
    socket.disconnected = true;
    clearTimeout(socket.connectTimeout);
    socket.connection.stop();
  },

  init() {
    if (socket.connection) return;
    socket.disconnected = false;
    socket.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${config.host}/chat`, {
        accessTokenFactory: () => window.localStorage.accessToken,
      })
      .build();

    function isConnected() {
      return socket.connection.state !== signalR.HubConnectionState.Disconnected;
    }

    function handleMessage(message) {
      events.$emit('chat:message', message);
    }
    function handleCrowdAdded() {
      events.$emit('refreshCrowdList');
    }
    function handleCrowdRemoved({ crowdId } = {}) {
      events.$emit('refreshCrowdList');
      events.$emit('removedFromCrowd', crowdId);
    }

    function onConnectionError(error) {
      if (socket.disconnected) return;
      if (error && error.message) console.error(error.message);
      connect(); // eslint-disable-line
    }

    socket.connection.on('newMessage', handleMessage);
    socket.connection.on('crowdAdded', handleCrowdAdded);
    socket.connection.on('crowdRemoved', handleCrowdRemoved);
    socket.connection.onclose(onConnectionError);

    function connect() {
      if (socket.disconnected) return;
      if (isConnected()) return;
      socket.connection.start()
        .then(() => {
          clearTimeout(socket.connectTimeout);
        })
        .catch((error) => {
          console.error(error.message);
          clearTimeout(socket.connectTimeout);
          socket.connectTimeout = setTimeout(() => {
            connect();
          }, 5000);
        });
    }

    connect();
  },
};
export default socket;
