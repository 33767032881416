/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';
import PWA from '../../modules/pwa';

const state = {
  crowd: null,
  crowdLoading: false,
  crowdSaving: false,
  crowdDeleting: false,
  crowdList: null,
  crowdListLoading: false,

  crowdAssignmentList: null,
  crowdAssignmentListSkip: 0,
  crowdAssignmentListTake: 20,
  crowdAssignmentListLoading: false,
  crowdAssignmentListMoreLoading: false,
  crowdAssignmentListHasMore: true,
  crowdAssignmentListCategory: null,

  crowdFindersInviting: false,
  crowdFindersLoading: false,
  crowdFinders: null,
  crowdFindersGuid: null,
  crowdFinderDeleting: false,
  crowdFindersHasMore: true,
  crowdFindersLoadingMore: false,
  crowdFindersSkip: 0,
  crowdFindersTake: 20,

  crowdAdmins: null,
  crowdAdminsUpdating: false,
  crowdAdminsLoading: null,
  crowdAdminsLoadingMore: null,
  crowdAdminsHasMore: true,
  crowdAdminsSkip: 0,
  crowdAdminsTake: 20,
};
const getters = {};
Object.keys(state).forEach((key) => {
  getters[key] = state => state[key];
});

const actions = {
  getCrowdAssignmentList({ commit, state }, { crowdId, cache = true } = {}) {
    commit('setCrowdAssignmentListSkip', 0);
    commit('setCrowdAssignmentListHasMore', true);
    API.get({
      url: `company/crowd/assignmentlist?crowdId=${crowdId}&category=${state.crowdAssignmentListCategory}&skip=${state.crowdAssignmentListSkip}&take=${state.crowdAssignmentListTake}`,
      loading: 'c/setCrowdAssignmentListLoading',
      cache,
      ok(response) {
        commit('setCrowdAssignmentList', { crowdId: parseInt(crowdId, 10), items: response.items });
      },
    });
  },
  updateCrowdAssignmentList({ dispatch }, { crowdId }) {
    dispatch('getCrowdAssignmentList', { cache: false, crowdId });
  },
  getMoreCrowdAssignmentList({ commit, state }, { crowdId }) {
    commit('setCrowdAssignmentListSkip', state.crowdAssignmentListSkip + state.crowdAssignmentListTake);
    API.get({
      url: `company/crowd/assignmentlist?crowdId=${crowdId}&category=${state.crowdAssignmentListCategory}&skip=${state.crowdAssignmentListSkip}&take=${state.crowdAssignmentListTake}`,
      loading: 'c/setCrowdAssignmentListLoading c/setCrowdAssignmentListMoreLoading',
      cache: false,
      ok(response) {
        if (!response || (response.items && response.items.length < 20)) {
          commit('setCrowdAssignmentListHasMore', false);
          if (!response.items.length) return;
        }
        commit('addToCrowdAssignmentList', response.items);
      },
    });
  },
  unsetCrowdAssignmentList({ commit }) {
    commit('unsetCrowdAssignmentList');
  },
  getMyCrowdList({ commit }, { cache = false, callbackOk } = {}) {
    API.get({
      url: 'company/crowd/mylist',
      loading: 'c/setCrowdListLoading',
      cache,
      ok(response) {
        commit('setCrowdList', response.items);
        if (callbackOk) callbackOk();
      },
    });
  },
  getCrowd({ commit }, { id }) {
    API.get({
      url: `company/crowd/details?id=${id}`,
      loading: 'c/setCrowdLoading',
      ok(response) {
        commit('setCrowd', response);
      },
    });
  },
  unsetCrowd({ commit }) {
    commit('unsetCrowd');
  },
  saveCrowd({ dispatch, commit }, { crowd, callbackOk, callbackError } = {}) {
    API.post({
      url: 'company/crowd/save',
      loading: 'c/setCrowdSaving',
      data: crowd,
      base64FileProp: 'logo',
      ok(c) {
        if (crowd && crowd.id) {
          if (state.crowd && state.crowd.id === crowd.id) {
            const newCrowd = {
              ...state.crowd,
              ...crowd,
            };
            PWA.updateCachedApi(`company/crowd/details?id=${crowd.id}`, newCrowd);
            commit('setCrowd', newCrowd);
          }
        }
        dispatch('getMyCrowdList');

        if (callbackOk) callbackOk(c);
      },
      error: callbackError,
    });
  },
  deleteCrowd({ dispatch }, { id, callbackOk, callbackError } = {}) {
    API.post({
      url: 'company/crowd/delete',
      loading: 'c/setCrowdDeleting',
      data: { id },
      ok() {
        dispatch('getMyCrowdList');
        if (callbackOk) callbackOk();
      },
      error: callbackError,
    });
  },
  setCrowdAssignmentListCategory({ commit, dispatch }, { category, crowdId }) {
    commit('setCrowdAssignmentListCategory', category === null ? null : parseInt(category, 10));
    dispatch('updateCrowdAssignmentList', { crowdId });
  },
  unsetCrowdAssignmentListCategory({ commit, dispatch }) {
    commit('unsetCrowdAssignmentListCategory');
  },

  getCrowdFinders({ commit }, { cache = true, crowdId, query } = {}) {
    commit('setCrowdFindersSkip', 0);
    commit('setCrowdFindersHasMore', true);
    API.get({
      url: `company/finder/list?crowdId=${crowdId}${query ? `&search=${query}` : ''}`,
      loading: 'c/setCrowdFindersLoading',
      cache,
      ok(response) {
        commit('setCrowdFinders', response.users);
        commit('setCrowdFindersGuid', response.crowdGuid);
      },
    });
  },
  getMoreCrowdFinders({ commit, state }, { crowdId }) {
    commit('setCrowdFindersSkip', state.crowdFindersSkip + state.crowdFindersTake);
    API.get({
      url: `company/finder/list?crowdId=${crowdId}&skip=${state.crowdFindersSkip}&take=${state.crowdFindersTake}`,
      loading: 'c/setCrowdFindersLoading c/setCrowdFindersLoadingMore',
      cache: false,
      ok(response) {
        if (!response || (response.users && response.users.length < 20)) {
          commit('setCrowdFindersHasMore', false);
          if (!response.users.length) return;
        }
        const { users } = response;
        commit('addToCrowdFinders', users);
      },
    });
  },
  resendAllCrowdFinder(ctx, { crowdId }) {
    API.post({
      url: 'company/finder/resendall',
      loading: 'c/setCrowdFindersInviting',
      data: {
        crowdId: parseInt(crowdId, 10),
      },
    });
  },
  resendCrowdFinder({ dispatch }, { userId, crowdId }) {
    API.post({
      url: 'company/finder/resend',
      loading: 'c/setCrowdFindersInviting',
      data: { userId, crowdId: parseInt(crowdId, 10) },
      ok() {
        dispatch('getCrowdFinders', { cache: false, crowdId });
      },
    });
  },
  deleteCrowdFinder({ commit, dispatch, state }, { userId, crowdId }) {
    const userToDelete = state.crowdFinders.filter(user => user.id === userId)[0];
    if (userToDelete) {
      state.crowdFinders.splice(state.crowdFinders.indexOf(userToDelete), 1);
      commit('setCrowdFinders', state.crowdFinders);
    }
    API.post({
      url: 'company/finder/delete',
      loading: 'c/setCrowdFinderDeleting',
      data: { userId, crowdId: parseInt(crowdId, 10) },
      ok() {
        dispatch('getCrowdFinders', { cache: false, crowdId });
      },
    });
  },
  inviteCrowdFinder({ dispatch }, { emails, crowdId, callbackOk } = {}) {
    API.post({
      url: 'company/finder/invite',
      loading: 'c/setCrowdFindersInviting',
      data: { emails, crowdId: parseInt(crowdId, 10) },
      ok() {
        if (callbackOk) callbackOk();
        dispatch('getCrowdFinders', { crowdId });
      },
    });
  },
  unsetCrowdFinders({ commit }) {
    commit('unsetCrowdFinders');
  },

  // company/user/crowdlist
  getCrowdAdmins({ commit }, { cache = true, query, crowdId } = {}) {
    commit('setCrowdAdminsSkip', 0);
    commit('setCrowdAdminsHasMore', true);
    API.get({
      url: `company/user/crowdlist?crowdId=${crowdId}${query ? `&search=${query}` : ''}`,
      loading: 'c/setCrowdAdminsLoading',
      cache,
      ok(response) {
        commit('setCrowdAdmins', response.users);
        commit('setCrowdFindersGuid', response.crowdGuid);
      },
    });
  },
  getMoreCrowdAdmins({ commit, state }, { crowdId }) {
    commit('setCrowdAdminsSkip', state.crowdAdminsSkip + state.crowdAdminsTake);
    API.get({
      url: `company/user/crowdlist?crowdId=${crowdId}&skip=${state.crowdAdminsSkip}&take=${state.crowdAdminsTake}`,
      loading: 'c/setCrowdAdminsLoading c/setCrowdAdminsLoadingMore',
      cache: false,
      ok(response) {
        if (!response || (response.users && response.users.length < 20)) {
          commit('setCrowdAdminsHasMore', false);
          if (!response.users.length) return;
        }
        const { users } = response;
        commit('addToCrowdAdmins', users);
      },
    });
  },
  updateCrowdAdmin({ commit }, { crowdId, userId, isAdmin } = {}) {
    API.post({
      url: 'company/user/crowdupdate',
      loading: 'c/setCrowdAdminsUpdating',
      data: {
        crowdId: parseInt(crowdId, 10),
        userId,
        isAdmin,
      },
    });
  },

};

const mutations = {};
Object.keys(state).forEach((key) => {
  const upperKey = `${key[0].toUpperCase()}${key.substr(1)}`;
  mutations[`set${upperKey}`] = (state, data) => {
    state[key] = data;
  };
  mutations[`unset${upperKey}`] = (state) => {
    state[key] = null;
  };
});
Object.assign(mutations, {
  addToCrowdAssignmentList(state, assignments) {
    state.crowdAssignmentList.items.push(...assignments);
  },
  addToCrowdFinders(state, items) {
    state.crowdFinders.push(...items);
  },
  addToCrowdAdmins(state, items) {
    state.crowdAdmins.push(...items);
  },
});

export default {
  state,
  getters,
  actions,
  mutations,
};
