<template>
  <f7-page>
    <f7-navbar :title="$t('notifications_settings.title')" large>
      <f7-link slot="nav-left" back icon="icon-back" :text="$t('general.back')"></f7-link>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <div class="center-content">
      <div class="fc-banner fc-banner-inner" v-if="showNotificationsBanner && !hideNotificationBanner">
        <div class="text">{{$t('notifications_banner.text')}}</div>
        <div class="buttons">
          <a href="#" class="fc-banner-success button button-round button-small button-fill" @click="bannerOk()">{{$t('notifications_banner.enable_button')}}</a>
        </div>
      </div>
      <f7-block-title>{{$t('notifications_settings.labels_title')}}</f7-block-title>
      <f7-list v-if="notifications">
        <f7-list-item
          :title="$t('notifications_settings.push_label')"
        >
          <f7-toggle
            color="brand-orange"
            slot="after"
            :checked="notifications.enabledPush"
            @change="notifications.enabledPush = $event.target.checked"
          ></f7-toggle>
          <ul v-if="profile && profile.isEnterprise && notifications.enabledPush" slot="root">
            <f7-list-item :title="$t('notifications_settings.internal_label')">
              <f7-toggle
                color="brand-orange"
                slot="after"
                :checked="notifications.enabledPushInternal || notifications.enabledPushInternal === undefined || notifications.enabledPushInternal === null"
                @change="notifications.enabledPushInternal = $event.target.checked"
              ></f7-toggle>
            </f7-list-item>
            <f7-list-item :title="$t('notifications_settings.external_label')">
              <f7-toggle
                color="brand-orange"
                slot="after"
                :checked="notifications.enabledPushExternal || notifications.enabledPushExternal === undefined || notifications.enabledPushExternal === null"
                @change="notifications.enabledPushExternal = $event.target.checked"
              ></f7-toggle>
            </f7-list-item>
          </ul>
        </f7-list-item>
      </f7-list>
      <f7-list v-if="notifications">
        <f7-list-item
          :title="$t('notifications_settings.email_label')"
        >
          <f7-toggle
            color="brand-orange"
            slot="after"
            :checked="notifications.enabledEmail"
            @change="notifications.enabledEmail = $event.target.checked"
          ></f7-toggle>
          <ul v-if="profile && profile.isEnterprise && notifications.enabledEmail" slot="root">
            <f7-list-item :title="$t('notifications_settings.internal_label')">
              <f7-toggle
                color="brand-orange"
                slot="after"
                :checked="notifications.enabledEmailInternal || notifications.enabledEmailInternal === undefined || notifications.enabledEmailInternal === null"
                @change="notifications.enabledEmailInternal = $event.target.checked"
              ></f7-toggle>
            </f7-list-item>
            <f7-list-item :title="$t('notifications_settings.external_label')">
              <f7-toggle
                color="brand-orange"
                slot="after"
                :checked="notifications.enabledEmailExternal || notifications.enabledEmailExternal === undefined || notifications.enabledEmailExternal === null"
                @change="notifications.enabledEmailExternal = $event.target.checked"
              ></f7-toggle>
            </f7-list-item>
          </ul>
        </f7-list-item>
      </f7-list>
      <f7-list v-if="notifications">
        <loading-list-button
          color="brand-orange"
          :text="$t('general.save')"
          @click="save"
          :loading="saving"
        ></loading-list-button>
      </f7-list>
    </div>
  </f7-page>
</template>
<script>
  import mapRoleGetters from '../../utils/map-role-getters';
  import events from '../../modules/events';

  export default {
    data() {
      return {
        hideNotificationBanner: false,
      };
    },
    created() {
      const self = this;
      self.$store.dispatch(`${self.$root.storeRolePrefix}/getNotificationsSettings`);
      if (!self.profile) {
        self.$store.dispatch(`${self.$root.storeRolePrefix}/getProfile`);
      }
    },
    computed: {
      ...mapRoleGetters({
        saving: 'notificationsSettingsSaving',
        loading: 'notificationsSettingsLoading',
        notifications: 'notificationsSettings',
        profile: 'profile',
      }),
      showNotificationsBanner() {
        return window.localStorage.notificationsBannerCanceled === 'true';
      },
    },
    methods: {
      bannerOk() {
        const self = this;
        self.hideNotificationBanner = true;
        events.$emit('notificationsBannerEnable');
        delete window.localStorage.notificationsBannerCanceled;
      },
      save() {
        const self = this;

        self.$store.dispatch(`${self.$root.storeRolePrefix}/saveNotificationsSettings`, {
          data: self.notifications,
          callbackOk() {
            self.$f7router.back();
          },
        });
      },
    },
  };
</script>
