export default {
  mounted() {
    const self = this;
    const el = self.$el;
    const $cardEl = self.$$(el).parents('.card-content');

    function handleTouchStart() {
      if ($cardEl[0].scrollLeft > 0) {
        $cardEl.addClass('no-swipe-panel');
      }
    }
    function handleTouchEnd() {
      $cardEl.removeClass('no-swipe-panel');
    }
    $cardEl.on('touchstart', handleTouchStart);
    $cardEl.on('touchend', handleTouchEnd);

    self.detachEvents = () => {
      $cardEl.off('touchstart', handleTouchStart);
      $cardEl.off('touchend', handleTouchEnd);
    };
  },
  beforeDestroy() {
    this.detachEvents();
  },
};
