<template>
  <div class="crowd-form">
    <f7-block strong class="profile-form-logo-inputs">
      <logo class="profile-form-logo" :logo="logo" :placeholder="true" :size="150">
        <label v-if="!disabled">
          <input type="file" ref="logo" @change="proceedLogo">
          <f7-icon ios="f7:camera_fill" md="material:camera_alt"></f7-icon>
        </label>
      </logo>
      <div class="profile-form-inputs">
        <f7-list no-hairlines class="no-safe-areas no-margin">
          <f7-list-item
            :title="isPrivate ? $t('crowd_form.private_label') : $t('crowd_form.public_label')"
            :footer="isPrivate ? $t('crowd_form.private_info') : $t('crowd_form.public_info')"
          >
            <f7-toggle color="brand-orange" :class="{ disabled: disabled }" slot="after" :checked="isPrivate" @change="isPrivate = $event.target.checked"></f7-toggle>
          </f7-list-item>
          <f7-list-input
            :label="$t('crowd_form.name_label')"
            :class="{ disabled: disabled }"
            type="text"
            :placeholder="$t('crowd_form.name_placeholder')"
            validate
            required
            :value="name"
            @input="name = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            :label="$t('crowd_form.description_label')"
            :class="{ disabled: disabled }"
            type="textarea"
            resizable
            :placeholder="$t('crowd_form.description_placeholder')"
            validate
            required
            :value="description"
            @input="description = $event.target.value"
          ></f7-list-input>

        </f7-list>
      </div>
    </f7-block>

    <slot></slot>
  </div>
</template>
<script>
  import Croppie from 'croppie';

  export default {
    props: {
      crowd: Object,
      disabled: Boolean,
    },
    data() {
      const crowd = this.crowd || {};
      const {
        name = '',
        description = '',
        isPrivate = true,
        logo = null,
      } = crowd;
      const data = {
        name,
        description,
        isPrivate,
        logo,
      };
      return data;
    },
    watch: {
      crowd() {
        const self = this;
        const { crowd } = self;
        Object.assign(self, crowd);
      },
    },
    mounted() {
      const self = this;
      self.$$(self.$el).on('input change', () => {
        self.$emit('formChanged');
      });
      self.$nextTick(() => {
        self.$$(self.$el).find('textarea').each((index, textareaEl) => {
          self.$f7.input.resizeTextarea(textareaEl);
        });
      });
    },
    methods: {
      openCropper(src) {
        const self = this;
        let croppie;

        function onResize() {
          croppie.setZoom(croppie._currentZoom); // eslint-disable-line
        }

        const popup = self.$f7.popup.create({
          backdrop: self.$$(self.$el).parents('.popup').length === 0,
          content: `
            <div class="popup popup-image-crop popup-tablet-fullscreen">
              <div class="view view-init" data-dynamic-navbar="false">
                <div class="page">
                  <div class="image-crop"></div>
                  <div class="croppie-toolbar toolbar tabbar toolbar-bottom no-hairline no-shadow">
                    <div class="toolbar-inner">
                      <a href="#" class="link popup-close" data-popup=".popup-image-crop">${self.$t('general.cancel')}</a>
                      <a href="#" class="link crop-image">${self.$t('general.done')}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          `.trim(),
          on: {
            open() {
              let CroppieConstructor = Croppie;
              if (CroppieConstructor.Croppie) CroppieConstructor = CroppieConstructor.Croppie;
              croppie = new CroppieConstructor(popup.$el.find('.image-crop')[0], {
                enableZoom: true,
                enableResize: true,
                enforceBoundary: true,
                showZoomer: true,
                enableExif: true,
                viewport: {
                  width: 120,
                  height: 120,
                  type: 'square',
                },
              });
              croppie.bind({ url: src, zoom: 0 });
              popup.$el.find('.cr-overlay').on('click', () => {
                if (self.$$('.popup-image-crop .toolbar').hasClass('toolbar-hidden')) {
                  self.$f7.toolbar.show('.popup-image-crop .toolbar');
                } else {
                  self.$f7.toolbar.hide('.popup-image-crop .toolbar');
                }
              });
              popup.$el.find('a.crop-image').on('click', () => {
                croppie.result({
                  type: 'base64',
                  size: 'original',
                  // size: {
                  //   width: 800,
                  // },
                  format: 'png',
                }).then((result) => {
                  self.logo = result;
                });
                popup.close();
              });
              self.$f7.on('resize', onResize);
            },
            closed() {
              self.$f7.off('resize', onResize);
              setTimeout(() => {
                popup.destroy();
                croppie.destroy();
              }, 100);
            },
          },
        });
        popup.open();
      },
      proceedLogo(event) {
        const self = this;
        const input = event.target;
        const file = input.files[0];
        if (file.type.indexOf('image') === 0) {
          const reader = new window.FileReader();
          reader.onload = ((e) => {
            self.openCropper(e.target.result);
            input.value = null;
          });
          reader.readAsDataURL(file);
        }
      },
      validate() {
        const self = this;
        const {
          name, description,
        } = self;

        self.$$(self.$el).find('input[required], textarea[required]').each((inputIndex, inputEl) => {
          self.$f7.input.validate(inputEl);
        });
        if (self.$el.querySelectorAll('.item-input-invalid').length > 0) {
          self.$f7.input.scrollIntoView(self.$el.querySelectorAll('.item-input-invalid')[0], 300);
          return false;
        }

        if (!name || !description) return false;

        return true;
      },
      getData() {
        const self = this;
        const {
          name, description, isPrivate, logo,
        } = self;

        return {
          name,
          description,
          isPrivate,
          logo,
        };
      },
    },
  };
</script>
