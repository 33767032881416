<template>
  <div class="pie-chart">
    <svg class="pie-svg"
      :width="`${size}px`"
      :height="`${size}px`"
      :viewBox="`0 0 ${size} ${size}`"
    >
      <circle
        v-for="(item, index) in data"
        :key="index"
        :stroke="item.color"
        :stroke-width="borderWidth"
        :stroke-dasharray="length"
        :stroke-dashoffset="length * (1 - value(item))"
        :r="radius"
        :transform="`rotate(${rotations[index]} ${size / 2} ${size / 2})`"
        fill="none"
        cx="50%"
        cy="50%"
      ></circle>
    </svg>
    <div class="pie-chart-labels">
      <div class="pie-chart-label"
        v-for="(item, index) in data"
        :key="index"
      >
        <div class="pie-chart-label-color" v-if="item.label" :style="{backgroundColor: item.color}">{{item.value > 0 ? item.value : ''}}</div>
        <div class="pie-chart-label-text" v-if="item.label">{{item.label}}</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      size: Number,
      data: Array,
      borderWidth: Number,
      showLabels: Boolean,
      visible: Boolean,
    },
    data() {
      return {
        isVisible: false,
      };
    },
    computed: {
      radius() {
        const self = this;
        return self.size / 2 - (self.borderWidth / 2);
      },
      length() {
        const self = this;
        return 2 * Math.PI * self.radius;
      },
      rotations() {
        const self = this;
        let rotate = -90;
        const rotations = [];
        self.data.forEach((item) => {
          rotations.push(rotate);
          const itemLength = (self.length * self.value(item));
          rotate += (itemLength / self.length) * 360;
        });
        return rotations;
      },
    },
    watch: {
      visible() {
        const self = this;
        if (self.visible) {
          setTimeout(() => {
            self.isVisible = true;
          }, 100);
        }
      },
    },
    mounted() {
      const self = this;
      if (self.visible) {
        setTimeout(() => {
          self.isVisible = true;
        }, 0);
      }
    },
    methods: {
      value(item) {
        const self = this;
        if (!self.isVisible) return 0;
        let total = 0;
        self.data.forEach((el) => {
          total += el.value;
        });
        return item.value / total;
      },
    },
  }
</script>
