<template>
  <table>
    <thead>
      <tr>
        <th>
          <span>{{$t('general.verification')}}</span>
          <data-table-filter :placeholder="$t('general.filter')"></data-table-filter>
        </th>
        <th>
          <span>{{$t('general.finder')}}</span>
          <data-table-filter :placeholder="$t('general.filter')"></data-table-filter>
        </th>
        <th>
          <span>{{$t('general.candidate')}}</span>
          <data-table-filter :placeholder="$t('general.filter')"></data-table-filter>
        </th>
        <th>
          <span>{{$t('general.assignment')}}</span>
          <data-table-filter :placeholder="$t('general.filter')"></data-table-filter>
        </th>
        <th>
          <span>{{$t('general.company')}}</span>
          <data-table-filter :placeholder="$t('general.filter')"></data-table-filter>
        </th>
        <th class="sortable-cell sortable-desc no-ripple">
          <span>{{$t('general.created')}}</span>
          <data-table-filter max-width="140" :placeholder="$t('general.filter')" calendar></data-table-filter>
        </th>
        <th>
          <span>{{$t('admin.table_finder_reward')}}</span>
        </th>
        <th>
          <span>{{$t('admin.table_reward')}}</span>
        </th>
        <th>{{$t('admin.table_payment_method')}}</th>
        <th>{{$t('admin.table_finder_status')}}</th>
        <th>{{$t('admin.table_company_status')}}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, index) in items"
        :key="index"
      >
        <td>{{item.verificationId}}</td>
        <td class="nowrap">
          <f7-link :href="`/users/edit/${item.finderId}/`">{{item.finderFirstName}} {{item.finderLastName}}</f7-link>
        </td>
        <td class="nowrap">
          <f7-link :href="`/candidates/edit/${item.candidateId}/`">{{item.candidateFirstName}} {{item.candidateLastName}}</f7-link>
        </td>
        <td class="nowrap">
          <f7-link :href="`/assignments/edit/${item.assignmentId}/`">{{item.assignmentTitle}}</f7-link>
        </td>
        <td class="nowrap">
          <f7-link :href="`/companies/edit/${item.companyId}/`">{{item.companyName}}</f7-link>
        </td>
        <td class="nowrap">{{formatDate(item.createdDate)}}</td>
        <td class="nowrap">{{formatNumber(item.finderReward)}} {{currencyMap(item.currency)}}</td>
        <td class="nowrap">{{formatNumber(item.reward)}} {{currencyMap(item.currency)}}</td>
        <td class="nowrap">{{$t(`finder_payment_method.${finderPaymentMethodMap(item.finderPaymentMethod)}`)}}</td>
        <template v-if="changeStatus">
          <td>
            <div class="input input-dropdown" :class="{disabled: item.companyTransactionStatus !== companyTransactionStatusList.InvoicePaid}">
              <select style="height: 28px; width: 200px" :value="item.finderTransactionStatus" @change="setFinderTransactionStatus(item, $event.target.value)">
                <option
                  v-for="(status, index) in finderTransactionStatuses(item)"
                  :key="index"
                  :value="status"
                >{{removeHtml($t(`finder_transaction_status.${finderTransactionStatusMap(status)}`))}}</option>
              </select>
            </div>
          </td>
          <td>
            <div class="input input-dropdown">
              <select style="height: 28px; width: 200px" :value="item.companyTransactionStatus" @change="setCompanyTransactionStatus(item, $event.target.value)">
                <option
                  v-for="(status, key) in companyTransactionStatuses()"
                  :key="key"
                  :value="status"
                >{{$t(`company_transaction_status.${companyTransactionStatusMap(status)}`)}}</option>
              </select>
            </div>
          </td>
        </template>
        <template v-else>
          <td class="nowrap">{{removeHtml($t(`finder_transaction_status.${finderTransactionStatusMap(item.finderTransactionStatus)}`))}}</td>
          <td class="nowrap">{{$t(`company_transaction_status.${companyTransactionStatusMap(item.companyTransactionStatus)}`)}}</td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import formatDate from '../../utils/format-date';
  import formatNumber from '../../utils/format-number';
  import currencyMap from '../../utils/currency-map';
  import finderPaymentMethodList from '../../utils/finder-payment-method-list';
  import finderPaymentMethodMap from '../../utils/finder-payment-method-map';
  import finderTransactionStatusList from '../../utils/finder-transaction-status-list';
  import finderTransactionStatusMap from '../../utils/finder-transaction-status-map';
  import companyTransactionStatusList from '../../utils/company-transaction-status-list';
  import companyTransactionStatusMap from '../../utils/company-transaction-status-map';
  import removeHtml from '../../utils/remove-html';
  import tableMixin from './table-mixin';

  export default {
    mixins: [tableMixin],
    props: {
      items: Array,
      updateLists: Boolean,
      changeStatus: Boolean,
    },
    data() {
      return {
        finderTransactionStatusList,
        companyTransactionStatusList,
      };
    },
    methods: {
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
      currencyMap,
      formatNumber,
      finderPaymentMethodMap,
      finderTransactionStatusMap,
      companyTransactionStatusMap,
      finderTransactionStatuses(item) {
        if (item.finderPaymentMethod === finderPaymentMethodList.CoolCompany) {
          return [
            finderTransactionStatusList.WaitingForCompany,
            finderTransactionStatusList.SentToCoolCompany,
          ];
        }
        return [
          finderTransactionStatusList.WaitingForCompany,
          finderTransactionStatusList.WaitingForInvoice,
          finderTransactionStatusList.InvoicePaid,
        ];
      },
      companyTransactionStatuses() {
        return [
          companyTransactionStatusList.PreparingInvoice,
          companyTransactionStatusList.InvoiceSent,
          companyTransactionStatusList.InvoicePaid,
        ];
      },
      setFinderTransactionStatus(item, status) {
        const self = this;
        self.$set(item, 'finderTransactionStatus', parseInt(status, 10));
        self.$store.dispatch('a/setFinderTransactionStatus', {
          id: item.id,
          status,
          updateLists: self.updateLists,
        });
      },
      setCompanyTransactionStatus(item, status) {
        const self = this;
        self.$set(item, 'companyTransactionStatus', parseInt(status, 10));
        self.$store.dispatch('a/setCompanyTransactionStatus', {
          id: item.id,
          status,
          updateLists: self.updateLists,
        });
      },
      removeHtml,
    },
  };
</script>
