module.exports = {
  general: {
    ok: 'Ok',
    cancel: 'Cancel',
    save: 'Save',
    create: 'Create',
    close: 'Close',
    send: 'Send',
    resend: 'Resend',
    update: 'Update',
    back: 'Back',
    done: 'Done',
    yes: 'Yes',
    page_not_found: 'Requested content not found',
    title: 'Title',
    created: 'Created',
    opened: 'Opened',
    active: 'Active',
    company_name: 'Company name',
    crowd_name: 'Crowd name',
    crowd: 'Crowd',
    name: 'Name',
    email: 'Email',
    filter: 'Filter',
    verification: 'Verification',
    assignment: 'Assignment',
    company: 'Company',
    finder: 'Finder',
    candidate: 'Candidate',
    date: 'Date',
    status: 'Status',
    amount: 'Amount',
    finderscrowd: 'FindersCrowd',
    copy_to_clipboard_success: 'Link copied to clipboard',
    user: 'User',
    are_you_sure: 'Are you sure?',
    or: 'or',
    share_finderscrowd: 'Invite your friends to become a Finder',
    share_finderscrowd_summary: 'Earn money on referrals! Solve sales, recruitment, rental assignments and much more ...',
    share_on_facebook: 'Share assignment on Facebook',
    share_on_linkedin: 'Share assignment on LinkedIn',
    new: 'New',
    unsupported_browser_message: '<p>Your browser is not supported.</p><p>Please use {{chrome}}, {{firefox}} or {{edge}}.</p>',
    copy_to_clipboard: 'Copy to clipboard',
    settings: 'Settings',
  },
  app_menu: {
    my_assignments_link_finder: 'My Assignments',
    my_assignments_link_company: 'My Assignments',
    notifications_link: 'Notifications',
    crowds_title: 'Crowds',
    add_crowd_button: 'New Crowd',
    join_crowd_button: 'Join Crowd',
    public_crowds_link: 'Public',
  },
  notifications_banner: {
    text: 'FindersCrowd requires your permission to enable notifications in order to notify you when something happens',
    enable_button: 'Enable',
    cancel_button: 'Cancel',
  },
  admin: {
    menu_dashboard: 'Dashboard',
    menu_crowds: 'Crowds',
    menu_companies: 'Companies',
    menu_assignments: 'Assignments',
    menu_finders: 'Finders',
    menu_users: 'Users',
    menu_candidates: 'Candidates',
    menu_logout: 'Logout',
    dashboard_title: 'Dashboard',
    dashboard_table_needs_review_title: 'Assignments Needs Review',
    dashboard_table_rewards_title: 'Finder Rewards',
    dashboard_table_paid_rewards_title: 'Finder Paid Rewards',
    assignments_title: 'Assignments',
    enterprise_filter_all: 'All',
    enterprise_filter_only_enterprise: 'Only Enterprise',
    enterprise_filter_exclude_enterprise: 'Exclude Enterprise',
    assignments_table_need_review_title: 'Needs Review',
    assignments_table_all_title: 'All Assignments',
    candidate_edit_title: 'Edit candidate',
    candidates_title: 'Candidates',
    candidates_table_title: 'Candidates',
    company_create_title: 'New company',
    company_edit_title: 'Edit company',
    companies_title: 'Companies',
    companies_table_title: 'Companies',
    companies_new_company_button: 'New company',
    crowd_edit_title: 'Edit crowd',
    crowds_title: 'Crowds',
    crowds_table_title: 'Crowds',
    user_edit_title: 'Edit User',
    user_email_exists: 'Email is already used by other user',
    user_disabled: 'Turn off the option to send recommendations',
    users_title: 'Users',
    users_table_title: 'Users',
    table_role: 'Role',
    table_user_name: 'User name',
    table_finder_reward: 'Finder reward',
    table_reward: 'Reward',
    table_payment_method: 'Payment method',
    table_finder_status: 'Finder status',
    table_company_status: 'Company status',
    table_user_confirmed: 'Confirmed',
    table_user_platform: 'Platform',
    table_user_facebook: 'Facebook',
    table_user_google: 'Google',
    table_user_affiliates: 'Affiliates',
    table_assignment_public: 'Public',
    table_crowd_private: 'Private'
  },
  attachments: {
    title: 'Attachments',
    add_files_button: 'Attach',
    upload_button: 'Upload file',
    link_button: 'Link to file',
    link_dialog_text: 'Add link to file. It can also be a link to YouTube or Vimeo video',
  },
  form: {
    name: 'Name',
    first_name: 'First name',
    first_name_placeholder: 'Your first name',
    last_name: 'Last name',
    last_name_placeholder: 'Your last name',
    phone: 'Phone',
    phone_placeholder: 'Your phone number',
    email: 'Email',
    email_placeholder: 'Your Email',
    company_name: 'Company name',
    company_name_placeholder: 'Company name',
    message: 'Message',
    message_placeholder: 'Additional comments',
    linkedin: 'LinkedIn profile link',
    linkedin_placeholder: 'Your LinkedIn profile url',
    language: 'Language',
    address: 'Address',
    postcode: 'Postcode',
    city: 'City',
    country: 'Country',
    orgnr: 'Company ID',
    homepage: 'Homepage',
    about: 'About',
    currency: 'Currency',
    max_number_interest: 'Maximum number of submitted applications',
    back_confirm: 'Are you sure you want to leave this page without saving?',
    optional: 'optional',
    required: 'required',
  },
  chat: {
    title: 'Chat',
    placeholder: 'Message',
    intro_message: 'Chat with {{role}} {{name}}',
    chat_button: 'Crowd Chat',
  },
  company: {
    title: 'Company',
    published_assignments_title: 'Published Assignments',
  },
  application: {
    intro: '{{name}} has recommended you. Does following sound interesting? Give your feedback at the bottom of the page.',
    about_company_title: 'About {{company}}',
    interested_message_title: 'Additional information',
    assignment_details_title: 'Ad details',
    status_title: 'Status of your application',
    interested_title: 'Is this interesting?',
    intereset_received_title: 'Thank you! Your notice of interest has been received!',
    intereset_received_subtitle: 'Would you like to add more information?',
    edit_title: 'Edit application details',
    chat_block_title: 'Communication',
    chat_with_link: 'Chat with {{name}}',
    represent_private: 'I am private person',
    represent_company: 'I represent a company',
    saved: 'Thank you! Your notice of interest has been received!',
    updated: 'Thank you! Your notice of interest has been updated!',
    invalid_link: 'Could not open link. Please copy and paste link into your web browser.',
    not_available: 'Application is no longer available',
    reached_limit_finder: 'The number of submitted applications for the person who shared the link has reached a limit.',
    reached_limit_assignment: 'The number of submitted applications in this assignment has reached a limit. If the company decides to change this limit the assignment will be re-opened. Please come back later and check it out.',
    already_applied: 'You have already applied to this assignment',
    interested_button: 'Yes, I\'m interested',
    maybe_button: 'Maybe, want more info',
    not_interested_button: 'No, not interested',
    open_application_button: 'Open application',
    declined_message: 'You have declined this assignment',
    decline_confirm: 'Are you sure?',
    privacy_link: 'By sending this notice of interest, you accept our <a href="http://finderscrowd.com/privacy/" class="external" target="{{@global.externalTarget}}">privacy policy</a>',
    closed_title: 'Assignment is closed',
    email_notifications_label: 'Notify me via email about new messages and status changes',
    assignment_level: 'Step {{level}}',
    after_decline_message: 'Do you know someone else you think would be interested in this? If your recommendation solves the assignment, you will receive {{reward}} in reward. Sign up for free as Finder at FindersCrowd to send recommendations on this assignment and many more.<br><a href="/signup/?email={{email}}" data-view=".login-screen .view" class="dialog-close" data-reload-current="true">Sign up here!</a>',
    redirecting_to_application: 'Redirecting to application in {{n}} seconds',
    email_placeholder: 'Enter your email',
    email_title_interested: 'We are glad that you are interested!',
    email_title_maybe: 'We are glad that you want to know more!',
    email_title_declined: 'Your email',
  },
  company_details: {
    assignment_solved_email: 'Assignment Solved Email',
    enterprise_label: 'Enterprise',
    settings_title: 'Settings',
    change_info: 'In order to change your company details, please contact us',
    title: 'Details',
    no_logo_confirm: 'Are you sure you want to continue without a logo?',
    invoice_address_title: 'Invoice address',
    invoice_address_different: 'Use different invoice address',
  },
  users: {
    title: 'Users',
    tab_users_title: 'Users',
    tab_finders_title: 'Finders',
    users_invite_intro: 'Invite fellow co-workers to this account',
    users_invite_button: 'Invite Users',
    finders_invite_intro: 'Invite Finders to this crowd',
    finders_invite_button: 'Invite Finders',
    invite_dialog: 'Enter email addresses to invite:',
    invite_dialog_info: 'Each email address on new line',
    invite_dialog_error: 'At least one of the email addresses is invalid',
    invite_dialog_button: 'Invite',
    status_active: 'Active',
    status_pending: 'Pending',
    delete_user_confirm: 'Are you sure you want to remove this user from your account?',
    resend_user_confirm: 'Are you sure you want to send invite to this user again?',
    resend_all_button: 'Resend to all pending users',
    resend_all_confirm: 'Are you sure you want to send invite to all pending users again?',
    search_placeholder: 'Search users',
    search_not_found: 'No users found',

    invite_title: 'How do you want to invite fellow co-workers to this account?',
    invite_email_text: 'Send invite by email',
    invite_button: 'Invite Users',
    invite_or: 'Or',
    invite_link_text: 'Share invite link',
  },
  notifications_page: {
    title: 'Notifications',
    no_notifications: 'No notifications yet',
  },
  notifications_settings: {
    title: 'Notifications',
    labels_title: 'Notify me with',
    push_label: 'Push notifications',
    email_label: 'Email notifications',
    internal_label: 'for private crowd',
    external_label: 'for public crowd',
  },
  notifications: {
    assignment_approved_title: 'Assignment approved',
    assignment_approved_body: 'Your assignment "{{title}}" is approved and published.',
    assignment_declined_title: 'Assignment declined',
    assignment_declined_body: 'Your assignment "{{title}}" is declined. Read more in assignment details.',
    new_assignment_title_reward: 'New assignment: {{title}}',
    new_assignment_title: 'New assignment',
    new_assignment_body: 'Reward: {{reward}}',
    new_assignment_anonymous_body: 'Reward: {{reward}}',
    increased_reward_assignment_title: '{{companyName}} has increased reward',
    increased_reward_assignment_title_anonymous: 'Reward was increased',
    increased_reward_assignment_body: 'Reward is now {{reward}} for assignment {{title}}',
    new_recommendation_title: 'New recommendation',
    new_recommendation_body: 'You\'ve got a new recommendation for "{{title}}"',
    updated_recommendation_title: 'Updated recommendation',
    updated_recommendation_body: 'Recommendation has been updated for "{{title}}"',
    new_application_title: 'New application',
    new_application_body: 'You\'ve got a new application for "{{title}}"',
    new_application_maybe_title: 'New application',
    new_application_maybe_body: 'Interested wants more info on "{{title}}"',
    interested_application_title: 'Interested',
    interested_application_body: 'Your recommendation has applied for "{{title}}"',
    not_interested_application_title: 'Not interested',
    not_interested_application_body: '{{email}} is not interested in "{{title}}"',
    updated_application_title: 'Updated application',
    updated_application_body: 'Application has been updated for "{{title}}"',
    new_message_title: '{{firstName}} {{lastName}}',
    new_message_title_candidate: '{{email}}',
    new_message_body: '{{text}}',
    finder_changed_title: 'Recommendation status changed',
    finder_changed_body: 'The status of your recommendation in assigment "{{assignmentTitle}}" has changed',
    finder_agreement_made_title: 'Agreement made',
    finder_agreement_made_body: 'Congratulations! One of your recommendations has solved the assignment "{{assignmentTitle}}"',
  },
  payments: {
    title: 'Payments',
    company_no_payments: 'You don\'t have any payments yet.',
    assignment_level: 'Step {{level}}',
    payment_method_title: 'Payment method',
    earnings_title: 'Earnings',
    finder_no_payments: 'You don\'t have any transactions yet.',
    invoice_title: 'Invoice details',
    invoice_info: 'Compose the invoice according to the details below:',
    no_invoice_info: 'At the moment you don\'t have any earnings where payment is due',
    invoice_bill_to_email: 'Send invoice as email to',
    invoice_bill_to_mail: 'Or as regular mail to',
    invoice_company_details: 'Company details',
    invoice_table_item: 'Reward for successful recommendation of {{candidate}} for the "{{assignment}}" assignment',
    invoice_table_description: 'Description',
    invoice_table_verification: 'Verification',
    invoice_table_amount: 'Amount',
    invoice_table_total: 'Total',
    pending_info: 'Waiting for payment from client.',
  },
  profile: {
    title: 'Profile',
    edit_title: 'Edit',
    edit_profile_link: 'Edit profile',
    payments_link: 'Payments',
    notifications_link: 'Notifications',
    logout_link: 'Logout',
    users_link: 'Users',
    company_details_link: 'Company details',
    about_link: 'About',
    switch_account_to: 'Switch to {{type}} account',
    user_settings_link: 'User settings',
    company_settings_link: 'Company settings',
  },
  about: {
    title: 'About',
    text: `
<p>Please click the links below to read our Terms and conditions and our Privacy policy</p>
    `,
    privacy_link: 'Privacy policy',
    terms_link: 'Terms and conditions',
  },
  assignments: {
    public_crowd_title: 'Public',
    notifications_title: 'Notifications',
    company_intro_title: 'Welcome!',
    company_intro_text: `
<p>Here is where you handle everything that is related to your assignments. To get started, click on New assignment and fill out the requested information.  If you need help, you´ll find it on the assignment page.</p>

<p>More information, terms of use and privacy policy can be found at <a href="http://finderscrowd.com" target="{{@global.externalTarget}}" class="external">finderscrowd.com</a></p>

<p>Good luck!</p>
    `,
    finder_intro_title: 'Welcome!',
    finder_intro_text: `
<p>Here is where you solve various assignments by using your network and your knowledge. You will find all your submitted recommendations on this page.</p>

<p>More information, terms of use and privacy policy can be found at <a href="http://finderscrowd.com" target="{{@global.externalTarget}}" class="external"> finderscrowd.com</a></p>

<p>Good luck!</p>
    `,
    public_assignments: 'All Assignments',
    my_assignments_company: 'My Assignments',
    my_assignments_finder: 'My Assignments',
    no_internal_assignments: 'No internal assignments yet',
    crowd_no_published_assignments: 'What do you want to do next?',
    crowd_no_published_invite_text: 'Invite Finders to this crowd',
    crowd_no_published_invite_button: 'Invite Finders',
    crowd_no_published_assignment_text: 'Create new assignment in this crowd',
    crowd_no_published_assignment_button: 'New assignment',
    no_published_assignments: 'No published assignments yet',
    no_created_assignments: 'You haven\'t created any assignments yet. <a href="{{url}}">Create new assignment</a>',
    no_recommended_assignments: 'No referrals in any assignment made yet',
    new_assignment_button: 'New assignment',
    rating_title: 'Your rating',
    rating_info_finder: 'We calculate ratings automatically based on the quality of recommendations. You start with 3 stars',
    rating_info_company: 'We calculate ratings automatically based on the number of successful assignments as well as the number of rated recommendations. You start with 3 stars',
    gauge_earnings_before_label: 'You\'ve earned',
    gauge_earnings_label: 'of {{earnings}} in potential',
    gauge_earnings_after_label: 'earnings',
    gauge_recommendations_before_label: 'You\'ve made',
    gauge_recommendations_label: 'recommendations',
    gauge_recommendations_label_after: 'in these assignments',
    gauge_recommendations_label_after_single: 'in this assignment',
    internal_tab_title: 'Internal',
    public_tab_title: 'External',
    pending_crowd_invite_text: '{{companyName}} has invited you to join {{crowdName}}',
    pending_invite_text: '{{companyName}} has invited you to join their account',
    pending_invite_accept: 'Accept',
    pending_invite_decline: 'Decline',
    edit_crowd: 'Edit Crowd',
    manage_finders: 'Manage Finders',
    manage_admins: 'Manage administrators',
    leave_crowd: 'Leave Crowd',
    leave_crowd_confirm: 'Are you sure you want to leave this crowd?',
    crowd_settings: 'Settings',
  },
  assignment_list: {
    closed_label: 'Closed',
    reward_label: 'Total reward to FindersCrowd',
    interested_label: 'interested',
  },
  assignment: {
    title: 'Assignment',
    preview_title: 'Preview',
    preview_link: 'Preview share link',
    preview_needs_save: 'Save assignment to get access to preview share link',
    earn_label: 'Earn',
    reward_to_fc_label: 'Total reward to FindersCrowd',
    reward_amount_range: 'from {{min}} to {{max}} {{currency}}',
    reward_amount_end: 'per recommendation that solves the assignment',
    reward_details: 'Earning is based on {{percentage}}% of estimated {{percentageType}} ranging from {{min}} to {{max}} {{currency}}',
    reward_details_salary: 'Earning is based on a percentage of estimated annual salary',
    published_date_range: 'Published from {{from}} to {{to}}',
    interested_message_title: 'Additional information',
    interested_message_title_finder_only: 'only visible to Finder',
    interested_message_title_interested_only: 'only visible to interested',
    about_company_link: 'Learn more about {{companyName}}',
    earnings_title: 'Earnings',
    earning_details: 'You have earned <b>{{amount}}</b>{{#if multipleLevels}} in step {{level}}{{/if}} for successful recommendation {{#if isNotInnovation}}of <b>{{candidateName}}</b> {{/if}}on <b>{{date}}</b>',
    payment_status: 'Payment status: <b>{{status}}</b>',
    payment_info_company: 'Reward payment is handled by {{companyName}}',
    payment_info: 'Payment transaction will be done as soon as the client has payed the reward. Your payment method is currently {{paymentMethod}}.{{#if isPayroll}} On payroll, statutory social security contributions and taxes are deducted from the gross amount indicated before the net amount is paid.{{/if}} If you like to change payment options <a href="{{url}}">click here</a>.',
    recommendation_email_title: 'Enter email address',
    recommendation_email_info: 'Only one email address is allowed',
    recommendation_email_placeholder: 'Email address to recommended',
    recommendation_email_closed: 'Closed',
    rewards_title: 'Rewards',
    overview_title: 'Overview',
    recommendations_title: 'Your recommendations',
    recommendations_intro: 'In case of successful signing with a recommendation, you will receive a reward of: {{amount}}',
    recommendations_maximum: 'You already have submitted {{max}} recommendations.',
    recommendations_amount: 'Your current rating allows you to send up to {{amount}} recommendations/assignment.',
    chat_with_link: 'Chat with {{name}}',
    recommendation_edit_link: 'Edit recommendation',
    feedback_label: 'Feedback from {{companyName}}',
    no_recommendation_sent: 'Recommendation hasn\'t been sent yet',
    recommendation_index_label: 'Recommendation {{index}}',
    reward_title: 'Rewards',
    reward_level_title: 'Earn {{amount}}{{#if multipleWinners}} per recommendation{{/if}}',
    reward_to_fc_level_title: 'Reward to FindersCrowd: {{amount}}{{#if multipleWinners}} per recommendation that solves the assignment{{/if}}',
    more_assignments_title: 'More assignments from {{companyName}}',
    recommendation_email_subject: 'Interested?',
    recommendation_email_body: `Hi

Hope all is well.

I thought of you when I saw this. Something you\'re interested in?

{{url}}

Best regards`,
    recommendation_need_confirm: 'We need to confirm your email address before you can send recommendations, meanwhile we have safely saved your recommended email address. A confirmation email has just been sent to you.',
    already_recommended: 'Already recommended',
    not_allowed_recommend_internally: 'In this assignment it is not allowed to recommend internal Finders',
    not_allowed_internal_finder: 'In this assignment it is not allowed to show interest as internal Finder',
    anonymous_name: 'Company wants to be anonymous',
    agreed_transaction_value: 'Agreed transaction value: {{transactionValue}}',
    closed_title: 'Assignment is closed',
    accept_many_reward_info: 'for each recommendation that solves {{#if level}}step {{level}}{{else}}the assignment{{/if}}',
    accept_info_to_finder: 'Information to Finder',
    accept_many_info: 'In this assignment, you can get reward for several of your recommendations',
    accept_one_info: 'In this assignment, you can get reward for one of your recommendation',
    recommendations_info: 'Send your recommendations below.',
    recommendations_landing_info: 'Do you know someone you think would be interested? Sign up below to send your recommendations.',
    company_anonymous: 'company',
    show_more_assignments_button: 'Show all',
    is_not_deletable: 'Recommendations have already been sent, the assignment can\'t be deleted',
    deleted: 'Assignment is deleted',
    reward: 'Reward',
    reward_per_recommendation: 'Reward/recommendation',
    how_to_recommend_link: 'How to recommend',
    reward_level_number: 'Step {{level}}',
    reward_after_level_reached: 'reward after step {{level}} is reached',
    in_review_title: 'Waiting for review',
    in_review_body: 'Assignment: {{title}}',
    create_email_button: 'Create email',
    resend_recommendation_button: 'Resend',
    recommendation_sent_to: 'Recommendation sent to {{to}}',
    recommend_button: 'Make a recommendation',
    is_closed: 'Assignment is closed',
    recommendation_not_exist: 'Recommendation does not exist',
    finder_anonymous_label: 'Anonymous',
    finder_anonymous_info: 'Your name and contact information will be hidden to company',
    recommendation_info_ToUs: 'In this assignment, your recommendations will be submitted directly to {{company}}.',
    recommendation_info_ForwardAd: 'In this assignment, a link to the ad will be sent to your recommendations.',
    recommendation_info_FindersChoice: 'In this assignment, you can choose to submit recommendations directly to {{company}} or send a link to the ad to your recommendations.',
    user_disabled_info: 'You have been disabled to send more recommendations',
    recommendation_sync_message: 'We have saved your recommendation and will send it when you will be online',
    signup_button: 'Sign up',
    recommendation_dialog_title: 'How do you want to recommend?',
    recommendation_dialog_title_email_only: 'Make a recommendation',
    recommendation_dialog_text_to_company: 'Directly to company',
    recommendation_dialog_text_to_candidates: 'Send the ad to people in my network',
    recommendation_dialog_company_button: 'Make a recommendation',
    recommendation_dialog_email_button: 'By email',
    recommendation_dialog_facebook_button: 'By Facebook',
    recommendation_dialog_linkedin_button: 'By LinkedIn',
    unauthorized_text: 'You do not have permission to display this assignment',
    candidates_to_company_title: 'Directly to {{companyName}}',
    candidates_by_email_title: 'Email',
    candidates_by_facebook_title: 'Facebook',
    candidates_by_linkedin_title: 'LinkedIn',
    candidates_no_intereset_yet: 'No interest yet',
    reached_limit_finder: 'You have reached the limit of how many recommendation you can make for this assignment',
    reached_limit_assignment: 'The number of submitted applications in this assignment has reached a limit. If the company decides to change this limit the assignment will be re-opened. Please come back later and check it out.',
  },
  assignment_recommend: {
    title: 'Recommend',
    info: 'Enter information about your recommendation below. Email or phone is required.',
    info_innovation: 'Enter information about your recommendation below.',
    email_placeholder: 'Email address to recommended',
    first_name_placeholder: 'First name to recommended',
    last_name_placeholder: 'Last name to recommended',
    phone_placeholder: 'Phone to recommended',
    linkedin_placeholder: 'LinkedIn profile url to recommended',
    message_placeholder: 'Message to {{companyName}}',
    send_button: 'Send recommendation to {{companyName}}',
    update_button: 'Update recommendation',
    message_attachments_required: 'Message or attachments required',
    send_to_company: 'Your recommendation will be sent to {{companyName}}',
  },
  my_assignment: {
    title: 'Details',
    edit_link: 'Edit',
    preview_link: 'Preview',
    decline_reason: 'Decline reason: {{reason}}',
    recommended_label: 'recommended',
    interested_label: 'received',
    favorite_title: 'Favorite',
    interested_title: '{{amount}} received',
    interested_title_empty: 'Received',
    no_interest: 'No submitted applications yet',
    rate_candidates: 'Assignment is closed. Please rate and set current status on all your received recommendations to get better rating.',
    level_status: 'Step {{level}} status',
  },
  candidate: {
    title: 'Incoming',
    notes_title: 'Internal notes',
    notes_placeholder: 'Add your internal notes here',
    status_title: 'Select current status:',
    details_title: 'Details',
    recommended_directly: 'Directly from Finder',
    recommended_email: 'Interested via Email',
    recommended_facebook: 'Interested via Facebook',
    recommended_linkedin: 'Interested via LinkedIn',
    feedback_finder_title: 'Communication with Finder',
    feedback_candidate_title: 'Communication with Recommended',
    mark_favorite_button: 'Mark as favorite',
    rate_label: 'Rate recommendation:',
    chat_label: 'Chat with:',
    reward_level_number: 'Step {{level}}',
    reward_level_title: 'Reward: {{amount}}',
    reward_amount_range: 'from {{min}} to {{max}} {{currency}}',
    confirm_dialog_invoiced: 'Reward of {{amount}} will be invoiced',
    confirm_dialog_handled: 'Finder will be rewarded {{amount}}',
    confirm_dialog_expected: 'Your expected {{percentageType}} was {{amount}}. Please enter the final agreement?{{#if isSalary}} (only visible to you, used for calculation){{/if}}',
    confirm_dialog_more_than_expected: 'Are you sure transaction value is more than you expected?',
    confirm_dialog_less_than_expected_error: 'Transaction value can not be less than expected value',
    confirm_dialog_no_accept_many: 'This will close the assignment for further recommendations and all other applications will be set to "<b>{{status}}</b>"',
    confirm_dialog_no_accept_many_closed: 'All other applications will be set to "<b>{{status}}</b>"',
    confirm_dialog_title: 'Assignment solved?',
    confirm_terms_link: 'according to our <a href="http://finderscrowd.com/terms/" class="external" target="{{@global.externalTarget}}">terms and conditions</a>',
    assignment_closed: 'Assignment is closed',
    agreement_made_dialog: 'Congratulations on your solved assignment!',
    rate_required_dialog_text: 'Don’t forget to set current status and rate Finder recommendation',
    rate_required_dialog_dont_remind: 'Don\'t remind me',
    rate_required_dialog_cancel: 'Stay on page',
    rate_required_dialog_continue: 'Continue',
    status_reminder: 'Don\'t forget to set current status',
  },
  crowd_join: {
    title: 'Join Crowd',
    join_button: 'Join Crowd',
    empty: 'No Crowds available yet',
  },
  company_join: {
    already_joined_this_company: 'You have already joined this company',
    already_joined_other_company: 'You have already joined other company',
  },
  crowd_form: {
    create_title: 'New Crowd',
    edit_title: 'Edit Crowd',
    private_label: 'Private',
    public_label: 'Public',
    private_info: 'Private Crowds are invite-only.',
    public_info: 'Public Crowds is open for everybody to join.',
    name_label: 'Crowd name',
    name_placeholder: 'Enter crowd name',
    description_label: 'Crowd description',
    description_placeholder: 'Enter crowd description',
    upgrade_text: 'Upgrade to Enterprise account in order to create public and private Crowds',
    upgrade_button: 'Upgrade',
    upgrade_success: 'Thank you. We will contact you shortly on {{email}}',
    name_required: 'Please enter crowd name',
    not_exist: 'Crowd doesn\'t exist',
    not_allowed: 'Not allowed',
    only_enterprise: 'Upgrade to Enterprise account in order to create public and private Crowds',
    delete_crowd: 'Delete crowd',
    delete_crowd_confirm: 'Are you sure you want to delete this crowd?',
  },
  crowd_finders: {
    title: 'Manage Finders',
    finders_invite_title: 'How do you want to invite Finders?',
    finders_invite_email_text: 'Send invite by email',
    finders_invite_button: 'Invite by email',
    finders_invite_or: 'Or',
    finders_invite_link_text: 'Share invite link',
    invite_dialog: 'Enter email addresses to invite:',
    invite_dialog_info: 'Each email address on new line',
    invite_dialog_error: 'At least one of the email addresses is invalid',
    invite_dialog_button: 'Invite',
    status_active: 'Active',
    status_pending: 'Pending',
    delete_user_confirm: 'Are you sure you want to remove this user from your account?',
    resend_user_confirm: 'Are you sure you want to send invite to this user again?',
    resend_all_button: 'Resend to all pending users',
    resend_all_confirm: 'Are you sure you want to send invite to all pending users again?',
    search_placeholder: 'Search finders',
    search_not_found: 'No finders found',
  },
  crowd_admins: {
    title: 'Administrators',
    search_placeholder: 'Search users',
    search_not_found: 'No users found',
    no_users: 'No users to assign as admins',
    isOwner: 'Owner',
  },
  crowd_chat: {
    title: 'Chat',
    intro_message: 'Chat with crowd',
    presence: '{{#if finders}}{{finders}} finders{{/if}} {{#if admins}}{{#if finders}}and{{/if}}{{/if}} {{#if admins}}{{admins}} administrators{{/if}}',
  },
  assignment_form: {
    want_interested_title: 'The Finder may recommend by',
    want_interested_finder: 'Recommendations submitted directly from Finder',
    want_interested_candidate: 'Confirmed interest from Finder recommendations',
    want_interested_ToUs: 'Submitting contact information to us',
    want_interested_ForwardAd: 'Forwarding the ad for confirmation from interested',
    want_interested_FindersChoice: 'Finders own choice',
    limit_title: 'The Finder may recommend',
    limit_OnRating: 'Up to 5 times (based on rating)',
    limit_Unlimited: 'Unlimited times',
    limit_Limited: 'Limited to <b>{{value}}</b> times',
    allow_social_media_label: 'Allow social media recommendations',
    how_to_write_link: 'How to create your assignment',
    advertisement_order: 'Advertisement order',
    create_title: 'New Assignment',
    edit_title: 'Edit',
    description_block_title: '1. Ad section',
    status_label: 'Status',
    category_label: 'Category',
    title_label: 'Title',
    title_placeholder: 'Enter ad title',
    description_label: 'Description',
    description_placeholder: 'Enter ad description',
    interested_message_label: 'Message for interested (optional)',
    interested_message_placeholder: 'Additional information only visible to interested',
    finder_message_label: 'Message for Finder (optional)',
    finder_message_placeholder: 'Additional instructions only visible to Finder',
    end_date_label: 'End date',
    end_date_placeholder: 'Enter the end date for publishing',
    more_options_link: 'More options',
    internal_label: 'Publish internally',
    internal_info: 'Assignment will be visible for internal Finders only',
    internal_finders_label: 'Finders is allowed to recommend each other',
    anonymous_label: 'Anonymous',
    anonymous_info: 'Company name and contact information will be hidden',
    max_number_interest_label: 'Maximum number of submitted applications',
    reward_info: 'Includes rewards to Finder and FindersCrowd AB, cost of third parties and transactions.',
    add_reward_button: 'Add reward step',
    reward_block_title: '2. Reward section',
    reward_title: 'Reward if assignment is solved',
    multiple_levels_label: 'Multiple steps',
    reward_fixed_label: 'Fixed',
    reward_percentage_label: 'Percentage',
    reward_fixed_placeholder: 'Amount to pay if {{#if level}}step {{level}}{{else}}assignment{{/if}} is solved',
    reward_description_placeholder: 'Describe the conditions that must be fulfilled to get this reward',
    reward_percentage_of_label: 'of',
    reward_percentage_estimated_label: 'Estimated {{type}}{{#if isSalary}} (only visible to you, used for calculation){{/if}}',
    reward_percentage_from_placeholder: 'From',
    reward_percentage_to_placeholder: 'To',
    reward_percentage_potential: 'Potential reward: {{from}} - {{to}} {{currency}}',
    need_company_details: 'You need to complete your company details in order to publish your first assignment.',
    edit_company_details_button: 'Edit company details',
    save_publish_button: 'Save and Publish',
    publish_button: 'Publish',
    close_button: 'Close',
    delete_button: 'Delete',
    close_confirm: 'Are you sure you want to close this assignment?',
    delete_confirm: 'Are you sure you want to delete this assignment?',
    need_confirm_dialog: 'We need to confirm your email address before you can publish, meanwhile we have safely saved your assignment. A confirmation email has been sent to your inbox.',
    need_review_dialog: 'Great assignment! We are reviewing and will let you know when the assignment is published.',
    approve_label: 'Approve',
    decline_label: 'Decline',
    decline_reason_label: 'Decline reason',
    need_review_title: 'Assignment needs review',
    needs_company_details: 'Before you can publish your assignment, please provide all the company details in order to make it even better.',
    preview_link: 'Preview',
    accept_many_label: 'Multiple recommendations can solve the assignment',
    accept_many_info: 'Used when you want to make agreements with several of those who submit their interest notifications (eg in sales assignments or in the recruitment of several people with the same role)',
    disable_reward_label: 'Enable reward',
    disable_reward_info: 'Reward is disabled for this assignment',
    terms_link: 'By publishing the assignment, you accept our <a class="external" target="{{@global.externalTarget}}" href="http://finderscrowd.com/terms/">terms and conditions</a>',
    publish_confirm: '<p>Assignment will be published in {{crowdName}} crowd</p><p>If assignment is solved {{amount}} will be invoiced</p><p>Read our <a target="{{@global.externalTarget}}" href="http://finderscrowd.com/terms/" class="external">terms and conditions</a></p>',
    reward_increase_confirm: 'Are you sure you want to increase reward?',
    multiplewinners_manycandidates: 'Can not turn off \'Multiple recommendations can solve the assignment\' since Finders already have sent recommendations',
    multiplewinners_manyagreements: 'Can not turn off \'Multiple recommendations can solve the assignment\' since multiple Finders already have won',
    published_reward_deleted: 'Not allowed to delete reward in published assignment',
    published_reward_created: 'Not allowed to create more reward steps in published assignment',
    published_reward_type_change: 'Not allowed to change reward type in published assignment',
    published_reward_currency_change: 'Not allowed to change reward currency in published assignment',
    published_reward_fixed_lower: 'Not allowed to decrease reward in published assignment',
    published_reward_percentage_lower: 'Not allowed to decrease reward in published assignment',
    public_crowd_name: 'Public',
  },
  auth: {
    email_placeholder: 'Enter your email',
    password_placeholder: 'Enter your password',
    firstname_placeholder: 'First name',
    lastname_placeholder: 'Last name',
    password_confirm_placeholder: 'Confirm password',

    login_as: 'Login as',
    login_button: 'Login',
    login_facebook_button: 'Login with Facebook',
    login_google_button: 'Login with Google',
    login_link: 'Login',

    signup_title: 'Sign up',
    signup_link: 'Sign up',
    signup_button: 'Sign up',
    signup_facebook_button: 'Sign up with Facebook',
    signup_google_button: 'Sign up with Google',
    signup_subtitle: 'What do you want to do?',
    signup_button_finder: 'I want to recommend',
    signup_button_company: 'I want to publish assignment',

    forgot_password_title: 'Password recovery',
    forgot_password_link: 'Forgot password',
    forgot_password_button: 'Reset password',
    forgot_password_sent: 'We just sent you an email with a password reset link',

    confirm_title: 'Complete Account',
    confirm_facebook_button: 'Confirm with Facebook',

    reset_password_title: 'Reset password',
    reset_password_button: 'Let me in',
    reset_password_ok: 'All set! Now you can login with your new password',

    invite_company_user_exists: '{{email}} already has Company account',
    invite_finder_user_exists: '{{email}} is already invited',
    invite_invalid_email: '{{email}} is invalid',

    company_join_title: 'Company member signup',
    company_join_button: 'Join',
    already_joined_this_company: 'You have already joined this company. Login as usual.',
    already_joined_other_company: 'You have already joined other company',

    invalid_credentials: 'Invalid credentials',
    missing_email: 'Please provide an email address',
    invalid_email: 'Please provide a valid email address',
    account_exists: 'Account already exists. Forgot password?',
    account_notexists: 'Account does not exist. Is it the right email address?',
    signup_success: 'Signup was successful. Use the same email address to login.',
    signup_company_success: 'We need to confirm your email address. A confirmation email has been sent to your inbox.',
    user_exists: 'User already exists',
    forgot_success: 'We have sent you an email with instructions to reset your password',
    reset_password_success: 'Your password has been reset, please login as usual',
    not_facebook_user: 'Not a Facebook account. Try login with email and password.',
    login_terms_link: 'By logging in, you accept our <a href="http://finderscrowd.com/terms/" class="external" target="{{@global.externalTarget}}">terms and conditions</a> and <a href="http://finderscrowd.com/privacy/" class="external" target="{{@global.externalTarget}}">privacy policy</a>',
    company_join_confirm: 'You will signup with email: <b>{{email}}</b>. By signing up, you accept our <a href="http://finderscrowd.com/terms/" class="external" target="{{@global.externalTarget}}">terms and conditions</a> and <a href="http://finderscrowd.com/privacy/" class="external" target="{{@global.externalTarget}}">privacy policy</a>.',
    signup_email_confirm: 'You will signup as <b>{{role}}</b> with email: <b>{{email}}</b>. By signing up, you accept our <a href="http://finderscrowd.com/terms/" class="external" target="{{@global.externalTarget}}">terms and conditions</a> and <a href="http://finderscrowd.com/privacy/" class="external" target="{{@global.externalTarget}}">privacy policy</a>.',
    signup_facebook_confirm: 'You will signup as <b>Finder</b>. By signing up, you accept our <a href="http://finderscrowd.com/terms/" class="external" target="{{@global.externalTarget}}">terms and conditions</a> and <a href="http://finderscrowd.com/privacy/" class="external" target="{{@global.externalTarget}}">privacy policy</a>.',
    signup_google_confirm: 'You will signup as <b>Finder</b>. By signing up, you accept our <a href="http://finderscrowd.com/terms/" class="external" target="{{@global.externalTarget}}">terms and conditions</a> and <a href="http://finderscrowd.com/privacy/" class="external" target="{{@global.externalTarget}}">privacy policy</a>.',
  },
  error: {
    general: 'Something went wrong. Please try again later',
    unauthorized: 'Unauthorized access',
    email_password_required: 'Email and password are required',
    all_fields_required: 'All fields are required',
    passwords_dont_match: 'Password doesn\'t match',
    email_required: 'Email is required',
    email_or_phone_required: 'Email or phone is required',
    email_invalid: 'Email is invalid',
    email_correct: 'You will now sign up as <b>{{role}}</b> with email: <b>{{email}}</b>, correct?',
    correct_url: 'Enter correct URL',
  },
  finder_payment_info: {
    CoolCompany: 'On payroll, statutory social security contributions and taxes are deducted from the gross amount indicated before the net amount is paid',
  },
  finder_payment_method: {
    CoolCompany: 'Payroll, handled via Cool Company',
    Invoice: 'Invoice',
  },
  finder_transaction_status: {
    WaitingForCompany: 'Waiting for payment from client',
    SentToCoolCompany: 'The basis for payroll payment has been sent to Cool Company. Please contact <a href="https://coolcompany.com" class="external" target="{{@global.externalTarget}}">Cool Company</a> if you have questions.',
    WaitingForInvoice: 'Waiting for your invoice',
    InvoicePaid: 'Invoice received and paid',
    HandledByCompany: 'Reward handled by company',
  },
  company_transaction_status: {
    PreparingInvoice: 'Preparing invoice',
    InvoiceSent: 'Invoice sent to client',
    InvoicePaid: 'Invoice paid by client',
    HandledByCompany: 'Reward handled by company',
  },
  candidate_status: {
    All: 'All',
    Pending: 'Pending',
    Contacted: 'Contacted',
    InterestReceived: 'Received',
    RecommendationReceived: 'Received',
    Qualified: 'In process',
    FinalStage: 'Final stage of the process',
    NoFurtherProcess: 'Unfortunately, no longer in the process',
    HadContactWithRecommendation: 'Unfortunately, previously had contact with recommendation',
    AgreementMade: 'Assignment solved',
    Declined: 'Not interested',
    Maybe: 'Maybe, need more info',
  },
  candidate_status_short: {
    All: 'All',
    Pending: 'Pending',
    Contacted: 'Contacted',
    InterestReceived: 'Interested',
    RecommendationReceived: '{{#if fromFinder}}Recommendation from Finder{{else}}Recommended{{/if}}',
    Qualified: 'In process',
    FinalStage: 'Final stage',
    NoFurtherProcess: 'Declined',
    HadContactWithRecommendation: 'Previously known',
    AgreementMade: 'Agreement made',
    Declined: 'Not interested',
    Maybe: 'Need more info',
  },
  assignment_status: {
    All: 'All',
    WaitingForConfirm: 'Waiting for email confirmation',
    Draft: 'Draft',
    InReview: 'In review',
    Declined: 'Declined',
    Published: 'Published',
    Closed: 'Closed',
  },
  assignment_category: {
    All: 'All categories',
    Recruitment: 'Recruitment / Headhunting',
    Sales: 'Sales and marketing',
    Rent: 'Rental of premises',
    Consultant: 'Assignments for consultants',
    BusinessEducation: 'Business education',
    Innovation: 'Innovation projects',
    Interim: 'Interim management',
    Finance: 'Finance / Investments',
    Other: 'Other',
  },
  reward_percentage_type: {
    AnnualSalary: 'annual salary',
    AnnualRent: 'annual rent',
    TransactionValue: 'transaction value',
    AnnualSalaryDefinite: 'annual salary',
    AnnualRentDefinite: 'annual rent',
    TransactionValueDefinite: 'transaction value',
  },
  language: {
    en: 'English',
    sv: 'Swedish',
  },
  confirmation: {
    not_completed: 'Hey! Looks like you haven\'t completed your account setup. We have sent you an email with instructions.',
    already_confirmed: 'Hey! Looks like you have already confirmed your email. Try login as usual.',
    firstname_required: 'First name is required',
    lastname_required: 'Last name is required',
    password_required: 'Password is required',
    password_length: 'Use at least 6 character password to make it secure',
    need_confirm: 'We need to confirm your email address before you can publish. A confirmation email has been sent to your inbox.',
    before_send: 'We need to confirm your email address before you can send recommendation. A confirmation email has just been sent to you.',
    start_first_assignment: 'Great! Start off by creating your first assignment.',
    in_review: 'Great! We are reviewing and will let you know when the assignment is published.',
    ready_to_publish: 'Great! Now you are ready to publish your assignment.',
    start_sending_recommendations: 'Great! Start earning money by sending recommendations.',
    dont_forget_recommendations: 'Great! Don\'t forget to send your recommendations.',
    sent_recommendations: 'Great! Your recommendations has been sent to company.',
  },
  calendar: {
    month_names: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    month_names_short: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    day_names: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    day_names_short: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    done: 'Done',
  },
  roles: {
    Finder: 'Finder',
    Company: 'Company',
    CompanyPossesionForm: 'Company',
    Admin: 'Admin',
  },
  email: {
    confirmation_subject: 'Welcome to FindersCrowd',
    confirmation_title: 'You\'re on your way',
    confirmation_text: 'By clicking on the following confirm button, you are confirming your email address',
    confirmation_buttontext: 'Confirm',
    confirmation_pending_subject: 'Recommendations are pending',
    confirmation_pending_title: 'Your recommendations are pending',
    confirmation_pending_text: 'Please confirm your email address in order to send pending recommendations',
    confirmation_pending_buttontext: 'Confirm',
    reset_password_subject: 'Reset password',
    reset_password_title: 'Reset password',
    reset_password_text: 'Please use the following button to reset your password',
    reset_password_buttontext: 'Reset',
    got_message_subject: 'You\'ve got a new message',
    got_messages_subject: 'You\'ve got {{unreadCount}} new messages',
    got_message_title: 'You\'ve got a new message',
    got_messages_title: 'You\'ve got {{unreadCount}} new messages',
    got_message_text: 'Please visit FindersCrowd to view your message',
    got_messages_text: 'Please visit FindersCrowd to view your messages',
    got_message_buttontext: 'Open app',
    got_message_text_candidate: 'Open your application to view your message',
    got_messages_text_candidate: 'Open your application to view your messages',
    got_message_buttontext_candidate: 'Open application',
    candidate_changed_subject: 'Status changed',
    candidate_changed_title: 'The status of your application has changed',
    candidate_changed_text: 'Open your application to view the changes',
    candidate_changed_buttontext: 'Open application',
    candidate_assignment_closed_subject: 'Assignment is closed',
    candidate_assignment_closed_title: 'Assignment "{{assignmentTitle}}" is now closed',
    candidate_assignment_closed_text: 'You can still access your application',
    candidate_assignment_closed_buttontext: 'Open application',
    finder_changed_subject: 'Recommendation status changed',
    finder_changed_title: 'The status of your recommendation in assigment "{{assignmentTitle}}" has changed',
    finder_changed_text: 'Open assignment to view the changes',
    finder_changed_buttontext: 'Open assignment',
    finder_agreement_made_subject: 'Agreement made',
    finder_agreement_made_title: 'Congratulations! One of your recommendations has solved the assignment "{{assignmentTitle}}"',
    finder_agreement_made_text: 'Open assignment to see your reward',
    finder_agreement_made_buttontext: 'Open assignment',
    finder_assignment_closed_subject: 'Assignment is closed',
    finder_assignment_closed_title: 'Assignment "{{assignmentTitle}}" is now closed',
    finder_assignment_closed_text: 'You can still access the assignment details',
    finder_assignment_closed_buttontext: 'Open app',
    finder_new_assignments_subject: 'New assignments',
    finder_new_assignments_title: 'New assignments',
    company_transaction_status_changed_subject: 'Payment status has changed',
    company_transaction_status_changed_title: 'Payment status has changed for assignment "{{assignmentTitle}}"',
    company_transaction_status_changed_text: 'Please visit FindersCrowd to check the new payment status',
    company_transaction_status_changed_buttontext: 'Open app',
    finder_transaction_status_changed_subject: 'Payment status has changed',
    finder_transaction_status_changed_title: 'Payment status has changed for assignment "{{assignmentTitle}}"',
    finder_transaction_status_changed_text: 'Please visit FindersCrowd to check the new payment status',
    finder_transaction_status_changed_buttontext: 'Open app',
    company_unread_candidate_subject: 'You\'ve received a new application',
    company_unread_candidates_subject: 'You\'ve received {{unreadCount}} new applications',
    company_unread_candidate_title: 'You\'ve received a new application',
    company_unread_candidates_title: 'You\'ve received {{unreadCount}} new applications',
    company_unread_candidate_text: 'Please visit FindersCrowd to view the new application',
    company_unread_candidates_text: 'Please visit FindersCrowd to view the new applications',
    company_unread_candidate_buttontext: 'Open app',
    company_missing_status_or_rating_candidate_subject: 'Missing status or rating',
    company_missing_status_or_rating_candidates_subject: 'Missing status or rating',
    company_missing_status_or_rating_candidate_title: 'Set status or rating',
    company_missing_status_or_rating_candidates_title: 'Set status or ratings',
    company_missing_status_or_rating_candidate_text: 'Don\'t forget to set current status and rate Finder recommendation',
    company_missing_status_or_rating_candidates_text: 'Don\'t forget to set current status and rate {{candidatesCount}} Finder recommendations',
    company_missing_status_or_rating_candidate_buttontext: 'Open app',
    company_invite_subject: '{{fromName}} has invited you to {{companyName}}\'s referral network',
    company_invite_title: '{{fromName}} has invited you to {{companyName}}\'s referral network',
    company_invite_text: '{{companyName}}\'s referral network is powered by FindersCrowd, a platform for managing referrals and recommendations.<br><br>By clicking on the below confirm button, you confirm your email address and accept FindersCrowd\'s <a href="https://finderscrowd.com/terms" style="box-sizing: border-box; color: #f47321; font-weight: 400; text-decoration: none;" target="_blank">Terms and Conditions</a> and <a href="https://finderscrowd.com/privacy" style="box-sizing: border-box; color: #f47321; font-weight: 400; text-decoration: none;" target="_blank">Privacy policy</a>.',
    company_invite_buttontext: 'Confirm',
    company_invite_existing_subject: '{{fromName}} has invited you to {{companyName}}\'s referral network',
    company_invite_existing_title: '{{fromName}} has invited you to {{companyName}}\'s referral network',
    company_invite_existing_text: 'Please visit FindersCrowd to view {{companyName}}\'s referral network',
    company_invite_existing_buttontext: 'Open app',
    finder_invite_subject: '{{fromName}} invited you to {{companyName}}\'s crowd',
    finder_invite_title: '{{fromName}} invited you to {{companyName}}\'s crowd {{crowdName}}',
    finder_invite_text: 'Here you use your network and your knowledge to solve different types of assignments.<br><br>By clicking on the following confirm button, you are confirming your email address and agreeing to FindersCrowd\'s <a href="https://finderscrowd.com/terms" style="box-sizing: border-box; color: #f47321; font-weight: 400; text-decoration: none;" target="_blank">Terms and Conditions</a> and <a href="https://finderscrowd.com/privacy" style="box-sizing: border-box; color: #f47321; font-weight: 400; text-decoration: none;" target="_blank">Privacy policy</a>.',
    finder_invite_buttontext: 'Confirm',
    finder_invite_existing_subject: '{{fromName}} invited you to {{companyName}}\'s crowd',
    finder_invite_existing_title: '{{fromName}} invited you to {{companyName}}\'s crowd {{crowdName}}',
    finder_invite_existing_text: 'Please visit FindersCrowd to view {{companyName}}\'s crowd',
    finder_invite_existing_buttontext: 'Open app',
    assignment_approved_title: 'Assignment approved',
    assignment_approved_body: 'Your assignment "{{title}}" is now approved and published.',
    assignment_approved_buttontext: 'Open app',
    assignment_declined_title: 'Assignment declined',
    assignment_declined_body: 'Your assignment "{{title}}" is declined. Read more in assignment details.',
    assignment_declined_buttontext: 'Open app',
    company_assignment_solved_title: 'Assignment solved',
    company_assignment_solved_body: 'Assignment "{{title}}" is solved by {{finderName}} with reward {{reward}}. Current administrator is {{userName}}.',
    company_assignment_solved_buttontext: 'Open payments',
  },
  how_to_recommend: {
    title: 'How to recommend',
    text: `
      <h3>General</h3>
      <p>As a Finder, you use your own knowledge and social network to make recommendations in order to solve different assignment. If any of your recommendations solves the assignment, you may receive a reward (if specified in the assignment). There are two ways to make recommendations and it\'s up to the client to choose if you should submit your recommendations directly to the client or send it forward to someone you think is interested.<br><br>In order to maintain high quality recommendations, initially you may only submit a limited number of recommendations per assignment. The clients will submit their rating on each of your recommendations on a scale 1-5, where 5 is the highest rating. This will ad up to your overall rating as a Finder. A higher rating can give you some benefits and allows you to submit more recommendations per assignment.  Some assignments may also be published exclusively for the Finders with the highest rating. Your current rating is displayed below in the My Assignment section.</p>
      <h3>Recommendation section</h3>
      <p>You start by clicking on the assignment you are interested in. At the end of the assignment description you\'ll find the recommendation section.</p>
      <p><b>Your recommendations</b><br>This section will be displayed in two different ways depending on the settings of the assignment. The client responsible for the assignment chooses one (or a combination) of the following settings:</p>
      <p><u>1. Submit your recommendation directly to the client</u><br>With this setting you may enter the requested information by yourself and submit the form directly to the client.</p>
      <p><u>2. Send it forward to somebody you think is interested</u><br>Depending on the settings, you may make your recommendations, either by sending e-mail or by sharing the assignment on social media.<br><br>If you make your recommendations via social media, you may choose whether you like to publish the assignment for certain selected people in your network or if you prefer to publish it to your feed.<br><br>If you make your recommendations by e-mail, enter the e-mail address of the person you think would be interested. Then follow the instructions and send your e-mail as usual through your regular e-mail program. We provide a template you can use if you prefer. It is only a suggestion and you can change the text before you send the email. The important thing is that you do not delete or change the link that we automatically place in the email. The link contains all the information about the assignment.</p>
      <p><b>Overview</b><br>This section will only be visible after you have sent your first recommendation in each assignment. The overview is a graphical summary of your earnings and the number of recommendations you have submitted.</p>
      <h3>What happens next?</h3>
      <p><b>Status</b><br>The status in FindersCrowd gives you information about what has happened with your submitted recommendations. The status is transparent to all parties and will give you a heads up when something changes. If you accept notifications, you will be notified through both email and push-notifications when something changes/happens.</p>
      <p><b>Payments and rewards</b><br>Payouts of rewards can either be handled by the client or via the FindersCrowd platform. If the client handles this, it may look different for different clients and they will inform you how it will be done. Below, we describe how it works through the FindersCrowd platform.<br><br>When the client changes the status to "Assignment solved" and confirms the agreed reward(s), we will invoice the client with the agreed amount. Once the client has paid his invoice, you will receive the reward specified in the assignment. Please visit the payment section in your profile page to decide whether you wish the reward to be paid as salary or by invoice (from your company). Under the section Earnings, you find the current statuses of all your rewards. If you choose your reward to be paid as salary, statutory social security contributions and taxes will be deducted from the specified reward.</p>
      <h3>Worth knowing</h3>
      <p><ul><li>The Chat. The client decides if and when the chat will be open for communication between you and the client.</li><li>In order to be up to date, we recommend that you use both email and push notifications. Settings for notifications are found on your profile page.</li><li>If you are interested in what is described, it\'s possible for you to recommend yourself. It\'s up to the client to decide whether it\'s allowed in each specific assignment.</li><li>The reward part is only visible to you.</li><li>The client\'s rating of your submitted recommendations are visible to you and the client.</li></ul></p>
      <p>Good luck!</p>
    `,
  },
  how_to_write_assignment: {
    title: 'How to create your assignment',
    text: `
      <h3>Overview</h3>
      <p>An assignment in FindersCrowd normally consists of two parts; <b>1) Ad section</b>, targeted either to the Finder or to the one you are looking for, and <b>2) Reward section</b>, with one or more rewards to be paid if the assignment is solved. When you click on Save and publish, the Finders will get access to your assignment. In order to keep track of all recommendations, tips, rewards and statuses, all parties will communicate exclusively on FindersCrowds platform. It\'s not recommended to use contact information in your ad description.</p>
      <h3>1) Ad section</h3>
      <p><b>Category</b><br>Choose the category that best matches your ad.</p>
      <p><b>Publish internally (only available in Enterprise Edition)</b><br>When you choose to publish internally, the assignment will only be visible to Finders invited to your Enterprise account.</p>
      <p><b>Finders is allowed to recommend each other</b><br>Select if you allow Finders within the same crowd to recommend each other.</p>
      <p><b>The Finder may recommend by:</b><br><u>1. Submitting contact information to us</u><br>With this option, the Finder will enter the requested information and send it directly to you. It may be contact information for a person recommended by the Finder, for instance, in a recruitment assignment, a new customer to contact, suggestions for improvement to your organization or something else, depending on the category selected above.<br><br><u>2. Forwarding the ad for confirmation from interested</u><br>With this option, the Finder will forward and recommend your ad to people the Finder believes are interested. It\'s then up to these people to respond if they are interested. This option may be useful if you expect a large amount of recommendations.<br><br><u>3. Finders own choice</u><br>With this option, you let the Finder choose the best way to make a recommendation.<br><br>You may also choose if the Finder should be allowed to share your ad and make recommendations via social media.</p>
      <p><b>Title and Description</b><br>Here is where you create your ad with a title and a description.</p>
      <p>If you selected option 1 above, Submitting contact information to us, you can either create your ad as a regular ad, targeted to what you are looking for, or you can create the ad as an instruction to the Finder. For example, your instruction may contain information about what kind of information you want the Finder to submit, who or what you are looking for, how you intend to use the information, or something else important. If you select this option, your ad will only be visible to the Finder.</p>
      <p>If you selected option 2 or 3 above, the persons recommended by the Finder will get access to your ad. In this case, you should write your ad as an ordinary ad, targeted to the one you are looking for. Please note that the reward will only be visible to the Finder.</p>
      <p><b>Message to interested</b><br>This can only be used in assignments where option 2 or 3 are selected. You may use triggers such as discounts, benefits or other offers to make it even more attractive to those who are recommended. If you leave this field blank, then the "Message from company" heading will not be visible in your ad.</p>
      <p><b>Attachments</b><br>This is where you attach pictures, documents and other relevant items to your ad. Visible at the both the top and bottom of the ad. The slider at the top displays your attachments in the order in which they were uploaded.</p>
      <p><b>End date</b><br>Here you specify when the ad will stop being published. Depending on your settings, other events may also stop the publishing of your assignment, for example, if you have found what you are looking for or if you choose to stop it manually.</p>
      <p><b>More options</b><br><ul><li><u>Anonymous</u> – This option hides company name as well as contact information for both the Finder and the recommended person. The information is also hidden for other companies using FindersCrowd.</li><li><u>Maximum number of submitted applications</u> – Here you can choose the maximum number of submitted recommendations you wish to receive in this assignment. Once reached, the publishing will be paused until you choose to change this restriction.</li></ul></p>
      <h3>2) Reward section</h3>
      <p>This section is for our Finders and is visible both to them and other companies using FindersCrowd, except when you publish internally (only for Enterprise Edition). Rewards may be based on a percentage or a fixed amount. Generally, it\'s advisable to use % -based rewards if you don\'t know the value of the assignment in advance, for instance, assignments in the category sales/marketing.</p>
      <p><b>Multiple recommendations can solve the assignment</b><br>Used when you are interested in more than 1 of the submitted recommendations.<br><i>Example 1: If you hire 3 developers in the same assignment, 3 recommendations will be rewarded.</i><br><i>Example 2: If the assignment is about selling your products or services, all the recommendations that solves the assignment will be rewarded, in other words, if we sell 40 products via 40 different recommendations, the reward specified inthe reward section will be paid for each of these recommendations.</i></p>
      <p><b>Reward if assignment is solved</b><br>Rewards can be specified as a fixed amount or as a percentage of the transaction value, annual salary or annual rent, depending on the selected category. Rewards can also be given in several levels or steps, which mean thatyou can specify the reward you are willing to pay for each different step in your assignment. Within each step/level you can choose whether the reward should be based on a fixed amount or on a percentage.<br><i>Example 1: Recruitment assignments. You want to split the reward into two parts; first part when signing the employment agreement (Level 1, reward: for example 15% of the agreed annual salary) and the second part after 6 months (Level 2, fixed amount, for example, 2,000 EUR in reward) if/when the person has got a permanent employment.</i><br><br><i>Please note that the reward(s) you specify in your assignment are the total amount per successful recommendation FindersCrowd AB will invoice you if theassignment is solved, which covers compensation to the Finder who has solvedthe assignment, compensation to FindersCrowd AB, third party costs and transaction costs. Normally, the Finder receives at least half of the total amount.</i><br><br><i>For customers with Enterprise Edition, another price model is valid.</i><br><br>To de able to calculate the reward in an assignment where percentage based reward is used, you have to provide an estimate of the underlying value. The estimates are visible to other users, except for estimates of salaries.<br><br>When the assignment is solved you will be asked to change your estimated value to the actual agreed value (for example the yearly salary, yearly rent or transaction value). These values are only visible to you and are used to calculate the final reward. Please note that when the agreement is solved you can\'t change the reward to a lower amount than the lowest value in your estimate.</p>
      <h3>After your assignment has been published</h3>
      <p>Two things to keep in mind when you start receiving recommendations:<ol><li>It\'s important that you rate all the recommendations, as it determines the Finder\'s rating as a Finder. The rating is only visible to the Finder. It\'s a measurement of how good and relevant recommendations a Finder submits. We use rating in our effort to ensure high quality recommendations.</li><li>We use fixed statuses to keep all parties up to date on what is happening with every recommendation the Finder has made. When you change the status, all relevant parties will be notified immediately. Status is transparent to all parties in FindersCrowd.</li></ol></p>
      <p>Good luck!</p>
    `,
  },
  network: {
    api_offline_error: 'Looks like you are offline, restore connection and try again please.',
    indicator_online: 'Online',
    indicator_offline: 'Offline',
  },
  app_updater: {
    message: 'New version of the app is avialable',
    button: 'Update',
  },
  appstore_updater: {
    message: 'There is a new version available, update?',
    message_force: 'There is a new version available',
    button_force: 'Update',
    button_yes: 'Yes',
    button_no: 'Not now',
  },
};
