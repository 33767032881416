import { locales } from '../modules/i18n';

export default function (date, language) {
  const d = new Date(date);
  const month = locales[language].calendar.month_names[d.getMonth()];
  if (!month) return '';
  const monthShort = month.substr(0, 3);
  let day = d.getDate();
  if (day < 10) day = `0${day}`;

  return `${day} ${monthShort} ${d.getFullYear()}`;
}

