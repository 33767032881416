<template>
  <f7-page @page:afterin="onPageAfterIn">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon="icon-back" :text="$t('general.back')" @click="goBack"></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{$t('candidate.title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <div class="page-cols center-content" v-if="candidate">
      <div class="page-col-left center-content-blocks">
        <f7-block strong class="candidate-details-block no-safe-area-right">
          <div class="candidate-status-badge" :data-status="candidateStatusMap(statusForLevel(candidateCurrentLevel()))">{{statusTextShort(statusForLevel(candidateCurrentLevel()))}}</div>
          <div
            class="candidate-favorite-badge"
            :class="{favorite: candidate.favorite}"
            @click="toggleFavorite"
          >
            <f7-icon
              :md="candidate.favorite ? 'material:star' : 'material:star_border'"
              :ios="candidate.favorite ? 'f7:star_fill' : 'f7:star'"
            ></f7-icon>
            <span>{{$t('candidate.mark_favorite_button')}}</span>
          </div>
          <div class="candidate-name">
            <span>{{candidate.firstName && candidate.lastName ? candidate.firstName + ' ' + candidate.lastName : candidate.email}}</span>
          </div>
          <div class="candidate-type" v-if="candidate.recommendation === candidateRecommendationList.Company">
            <f7-icon f7="business_fill" />
            <span>{{$t('candidate.recommended_directly')}}</span>
          </div>
          <div class="candidate-type" v-else-if="candidate.recommendation === candidateRecommendationList.Email">
            <f7-icon f7="email_fill" />
            <span>{{$t('candidate.recommended_email')}}</span>
          </div>
          <div class="candidate-type" v-else-if="candidate.recommendation === candidateRecommendationList.Facebook">
            <f7-icon f7="logo_facebook" />
            <span>{{$t('candidate.recommended_facebook')}}</span>
          </div>
          <div class="candidate-type" v-else-if="candidate.recommendation === candidateRecommendationList.LinkedIn">
            <f7-icon f7="logo_linkedin" />
            <span>{{$t('candidate.recommended_linkedin')}}</span>
          </div>
          <f7-list v-if="candidate.recommendation !== candidateRecommendationList.Company" no-hairlines class="no-safe-areas">
            <f7-list-item
              :link="true"
              @click="openChat(`/chat/${candidate.assignmentId}/${candidate.finderId}/${candidate.guid}/?tab=candidate`)"
            >
              <f7-icon style="margin-right:5px" color="brand-orange" material="chat" slot="title"></f7-icon>
              <span slot="title">{{$t('candidate.chat_label')}} {{candidate.firstName && candidate.lastName ? candidate.firstName + ' ' + candidate.lastName : candidate.email}}</span>
              <span class="badge color-brand-red" v-if="candidate.unreadCountCandidate" slot="after">{{candidate.unreadCountCandidate}}</span>
            </f7-list-item>
          </f7-list>
          <div class="candidate-meta">
            <div class="candidate-email">
              <f7-icon material="email"></f7-icon>
              <f7-link external target="_systembrowser" :href="`mailto:${candidate.email}`">{{candidate.email}}</f7-link>
            </div>
            <div class="candidate-phone" v-if="candidate.phone">
              <f7-icon material="phone"></f7-icon>
              <f7-link external target="_systembrowser" :href="`tel:${candidate.phone}`">{{candidate.phone}}</f7-link>
            </div>
            <div class="candidate-linkedin" v-if="candidate.linkedIn">
              <f7-icon f7="logo_linkedin"></f7-icon>
              <f7-link external :target="$externalTarget" :href="candidate.linkedIn">{{candidate.linkedIn}}</f7-link>
            </div>
          </div>
          <div class="candidate-inner-block" v-if="candidate.message">
            <div class="candidate-inner-block-content candidate-message" v-html="formatText(candidate.message)"></div>
          </div>
          <attachments
            v-if="candidate.files && candidate.files.length"
            :files="candidate.files"
            :allow-edit="false"
          ></attachments>
        </f7-block>

        <f7-block-title class="no-safe-area-right">{{$t('candidate.status_title')}}</f7-block-title>
        <template v-if="candidate.assignmentMultipleLevels">
          <div class="timeline rewards-timeline no-safe-area-right">
            <div class="timeline-item"
              v-for="reward in candidate.assignmentRewards"
              :key="reward.id"
              :class="{ active: reward.level === candidateCurrentLevel(), 'reward-received': statusForLevel(reward.level) === candidateStatusList.AgreementMade, 'rejected': statusForLevel(reward.level) === candidateStatusList.NoFurtherProcess || statusForLevel(reward.level) === candidateStatusList.HadContactWithRecommendation}"
            >
              <div class="timeline-item-divider">{{reward.level}}</div>
              <div class="timeline-item-content" style="width: 100%">
                <f7-list media-list inset class="assignment-rewards-list no-safe-areas">
                  <f7-list-item
                    :subtitle="reward.description"
                    media-item
                  >
                    <span class="assignment-rewards-list-level" slot="inner-start" v-if="reward.level === 1">{{$t('assignment.reward_level_number', { level: reward.level })}}:</span>
                    <span class="assignment-rewards-list-level" slot="inner-start" v-if="reward.level > 1">{{$t('assignment.reward_level_number', { level: reward.level })}} - {{$t('assignment.reward_after_level_reached', { level: reward.level -1 })}}:</span>
                    <span slot="title" v-html="$t('candidate.reward_level_title', {
                      amount: `<span class=text-color-brand-green>${rewardLevelAmount(reward)}</span>`,
                    })"></span>
                  </f7-list-item>

                  <template v-if="candidateInLevel(reward.level)">
                    <f7-list-item
                      v-if="statusForLevel(reward.level) === candidateStatusList.AgreementMade"
                      :footer="agreedTransactionForReward(reward.level)"
                    >
                      <span slot="title" style="font-weight: 400; white-space:normal">{{$t('candidate_status.AgreementMade')}}</span>
                    </f7-list-item>
                    <f7-list-input
                      v-else
                      type="select"
                      class="candidate-status-input"
                      :value="statusForLevel(reward.level) === candidateStatusList.Maybe ? candidate.recommendation !== candidateRecommendationList.Company ? candidateStatusList.InterestReceived : candidateStatusList.RecommendationReceived : statusForLevel(reward.level)"
                      @change="changeStatus($event, parseInt($event.target.value, 10), reward.level)"
                    >
                      <option
                        v-for="(candidateStatusId, candidateStatusKey) in candidateStatuses(reward.level)"
                        :value="candidateStatusId"
                        :key="candidateStatusKey"
                      >{{$t(`candidate_status.${candidateStatusKey}`)}}</option>
                      <div slot="content" class="candidate-status-reminder" v-if="showStatusReminder">{{$t('candidate.status_reminder')}}</div>
                    </f7-list-input>

                  </template>
                </f7-list>
              </div>
            </div>
          </div>
        </template>
        <f7-list v-else class="no-safe-area-right">
          <f7-list-item
            v-if="statusForLevel(1) === candidateStatusList.AgreementMade"
            :title="$t('candidate_status.AgreementMade')"
            :footer="agreedTransactionForReward(1)"
          ></f7-list-item>
          <f7-list-input
            v-else
            type="select"
            class="candidate-status-input"
            :value="statusForLevel(1) === candidateStatusList.Maybe ? candidate.recommendation !== candidateRecommendationList.Company ? candidateStatusList.InterestReceived : candidateStatusList.RecommendationReceived : statusForLevel(1)" @change="changeStatus($event, parseInt($event.target.value, 10), 1)"
          >
            <option
              v-for="(candidateStatusId, candidateStatusKey) in candidateStatuses(1)"
              :value="candidateStatusId"
              :key="candidateStatusKey"
            >{{$t(`candidate_status.${candidateStatusKey}`)}}</option>
          </f7-list-input>
          <div class="candidate-status-reminder" v-if="showStatusReminder">{{$t('candidate.status_reminder')}}</div>
        </f7-list>

        <f7-block-title class="no-safe-area-right">{{$t('candidate.notes_title')}}</f7-block-title>
        <f7-list>
          <f7-list-input type="textarea" ref="notesInput" :value="notes" @input="notes = $event.target.value" @change="saveCandidateNotes($event.target.value)" resizable :placeholder="$t('candidate.notes_placeholder')"></f7-list-input>
        </f7-list>
      </div>
      <div class="page-col-right center-content-blocks">
        <f7-block-title class="no-safe-area-left">{{$t('candidate.feedback_finder_title')}}</f7-block-title>
        <f7-block strong class="no-safe-area-left">
          <div class="item-label">{{$t('candidate.rate_label', {
            name: candidate.firstName && candidate.lastName ? `${candidate.firstName} ${candidate.lastName}` : `${candidate.email}`
          })}}</div>
          <f7-list no-hairlines class="no-safe-areas">
            <f7-list-item>
              <rating :value="candidate.rating || 0" @rate="rate"></rating>
            </f7-list-item>
          </f7-list>
          <div class="item-label">{{$t('candidate.chat_label')}}</div>
          <f7-list no-hairlines class="no-safe-areas">
            <f7-list-item
              :link="true"
              @click="openChat(`/chat/${candidate.assignmentId}/${candidate.finderId}/${candidate.guid}/?tab=finder`)"
            >
              <f7-icon style="margin-right:5px" color="brand-orange" material="chat" slot="title"></f7-icon>
              <span slot="title">{{candidate.finderName || 'finder'}}</span>
              <span class="badge color-brand-red" v-if="candidate.unreadCountFinder" slot="after">{{candidate.unreadCountFinder}}</span>
            </f7-list-item>
          </f7-list>
        </f7-block>
      </div>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import candidateStatusList from '../../utils/candidate-status-list';
  import candidateRecommendationList from '../../utils/candidate-recommendation-list';
  import candidateStatusMap from '../../utils/candidate-status-map';
  import currencyMap from '../../utils/currency-map';
  import formatNumber from '../../utils/format-number';
  import rewardPercentageTypeMap from '../../utils/reward-percentage-type-map';
  import assignmentStatusList from '../../utils/assignment-status-list';
  import rewardLevelAmount from '../../utils/reward-level-amount';
  import dispatchWithRetry from '../../utils/dispatch-with-retry';
  import linkify from '../../utils/linkify';

  export default {
    mixins: [dispatchWithRetry],
    props: {
      id: [String, Number],
    },
    data() {
      return {
        notes: '',
        candidateStatusList,
        candidateRecommendationList,
        assignmentStatusList,
        statusChanged: false,
      };
    },
    computed: {
      ...mapGetters({
        candidate: 'c/candidate',
        loading: 'c/candidateLoading',
        saving: 'c/candidateSaving',
      }),
      rewardDisabled() {
        return this.candidate ? this.candidate.assignmentIsInternal && this.assignment.assignmentDisableReward : false;
      },
      showStatusReminder() {
        const self = this;
        const candidate = self.candidate;


        const dontShowForStatuses = ['Declined', 'Maybe', 'AgreementMade', 'NoFurtherProcess', 'Pending', 'HadContactWithRecommendation'];
        let status;
        if (candidate.assignmentMultipleLevels) {
          let reward = candidate.rewards.filter(r => r.level === candidate.assignmentRewardLevel)[0];
          if (!reward) {
            reward = candidate.rewards[0];
          }
          status = candidateStatusMap(reward.status);
        } else {
          status = candidateStatusMap(candidate.rewards[0].status);
        }
        if (dontShowForStatuses.indexOf(status) >= 0) return false;
        if (self.statusChanged) return false;
        return true;
      },
    },
    created() {
      const self = this;
      self.dispatchWithRetry('c/getCandidate', { id: parseInt(self.id, 10) });
      self.timeout = setTimeout(() => {
        self.update();
      }, 30000);
    },
    beforeDestroy() {
      const self = this;
      self.$store.dispatch('c/unsetCandidate');
      self.destroyed = true;
      clearTimeout(self.timeout);
    },
    watch: {
      candidate() {
        const self = this;
        if (!self.candidate.level) self.candidate.level = 0;
        if (self.$refs.notesInput && document.activeElement === self.$refs.notesInput.$el.querySelector('textarea')) {
          return;
        }
        self.notes = self.candidate.notes || '';
      },
    },
    methods: {
      candidateStatusMap,
      formatNumber,
      candidateStatuses(level) {
        const self = this;
        const candidate = self.candidate;
        const excludeStatuses = ['Declined', 'Contacted', 'Maybe', 'Pending'];
        if (candidate.recommendation === candidateRecommendationList.Company) excludeStatuses.push('InterestReceived');
        if (candidate.recommendation !== candidateRecommendationList.Company) excludeStatuses.push('RecommendationReceived');
        if (level > 1) {
          excludeStatuses.push(...['InterestReceived', 'RecommendationReceived']);
        }
        const statuses = {};
        Object.keys(candidateStatusList).forEach((key) => {
          const value = candidateStatusList[key];
          if (excludeStatuses.indexOf(key) >= 0) return;
          statuses[key] = value;
        });
        return statuses;
      },
      formatText(text) {
        const formatted = linkify((text || ''), false, false).text.replace(/\n/g, '<br>');
        return formatted;
      },
      agreedTransactionForReward(level) {
        let agreedText;
        const self = this;
        self.candidate.rewards.forEach((reward) => {
          if (reward.level === level) {
            const transactionValue = reward.transactionValue;
            let currency;
            let percentageType;
            self.candidate.assignmentRewards.forEach((r) => {
              if (r.level === level) {
                currency = r.currency;
                percentageType = r.percentageType;
              }
            });
            if (transactionValue) {
              agreedText = self.$t('assignment.agreed_transaction_value', {
                transactionValue: self.formatNumber(transactionValue, currency),
                type: self.$t(`reward_percentage_type.${rewardPercentageTypeMap(percentageType)}`),
              });
            }
          }
        });
        return agreedText;
      },
      candidateCurrentLevel() {
        const self = this;
        let level = 1;
        self.candidate.rewards.forEach((reward) => {
          if (reward.level > level) level = reward.level;
        });
        return level;
      },
      candidateInLevel(level) {
        const self = this;
        let isIn = false;
        self.candidate.rewards.forEach((reward) => {
          if (reward.level === level) isIn = true;
        });
        return isIn;
      },
      rewardLevelAmount(reward) {
        const self = this;
        return rewardLevelAmount(self, { shareOut: 100 }, reward);
      },
      rewardFixedAmount(reward) {
        const { fixed, currency } = reward;
        const currencyString = currencyMap(currency);
        const amount = fixed;
        return `${formatNumber(amount)} ${currencyString}`;
      },
      rewardFromToAmount(reward) {
        const { expectedFrom, expectedTo, currency } = reward;
        const currencyString = currencyMap(currency);
        return this.$t('candidate.reward_amount_range', {
          min: formatNumber(expectedFrom),
          max: formatNumber(expectedTo),
          currency: currencyString,
        });
      },
      rewardPercentageType(reward) {
        const self = this;
        const { percentageType } = reward;
        return self.$t(`reward_percentage_type.${rewardPercentageTypeMap(percentageType)}Definite`);
      },
      statusForLevel(level) {
        const self = this;
        let status;
        self.candidate.rewards.forEach((reward) => {
          if (reward.level === level) status = reward.status;
        });
        return status;
      },
      statusTextShort(id) {
        return this.$t(`candidate_status_short.${candidateStatusMap(id)}`, { fromFinder: this.candidate.recommendation === candidateRecommendationList.Company });
      },
      moveToNextLevel() {
        const self = this;
        self.$store.dispatch('c/moveToNextLevel', { id: self.id });
      },
      toggleFavorite() {
        const self = this;
        if (self.candidate.favorite) {
          self.candidate.favorite = !self.candidate.favorite;
          self.$store.dispatch('c/unfavoriteCandidate', { id: self.id });
        } else {
          self.candidate.favorite = !self.candidate.favorite;
          self.$store.dispatch('c/favoriteCandidate', { id: self.id });
        }
      },
      rate(rating) {
        const self = this;
        self.candidate.rating = rating;
        self.$store.dispatch('c/rateCandidate', { id: self.id, rating });
      },
      requestTransaction(reward, callbackOk, callbackCancel) {
        const self = this;

        function confirmInvoice(value) {
          const confirmValue = reward.type === 1 && reward.percentage ? value * reward.percentage / 100 : value;
          if (self.candidate.assignmentIsInternal && self.candidate.rewardHandledByCompany) {
            dialogOk();
          } else {
            self.$root.confirmDialog(`
              ${self.$t('candidate.confirm_dialog_invoiced', {
                amount: self.rewardFixedAmount({
                  fixed: confirmValue,
                  currency: reward.currency,
                }),
              })}
              <div>
                <small>${self.$t('candidate.confirm_terms_link')}</small>
              </div>
              `,
              self.$t('candidate.confirm_dialog_title'),
              () => { callbackOk(value); },
              () => { callbackCancel(); }
            );
          }
        }
        const promptDialog = self.$f7.dialog.create({
          title: self.$t('candidate.confirm_dialog_title'),
          text: self.$t('candidate.confirm_dialog_expected', {
            percentageType: self.rewardPercentageType(reward),
            amount: self.rewardFromToAmount(reward),
            isSalary: reward.percentageType === 0,
          }),
          content: `
            <div class="dialog-input-field item-input no-padding">
              <div class="item-input-wrap">
                <input required type="number" class="dialog-input">
              </div>
            </div>
          `.trim(),
          buttons: [
            {
              text: self.$t('general.cancel'),
              keyCodes: [27],
              close: true,
              onClick() {
                callbackCancel();
              },
            },
            {
              text: self.$t('general.ok'),
              bold: true,
              keyCodes: [13],
              close: false,
              onClick() {
                const $inputEl = promptDialog.$el.find('input');
                self.$f7.input.validate($inputEl[0]);
                if ($inputEl.hasClass('input-invalid')) return;
                const value = parseInt($inputEl.val(), 10);
                if (Number.isNaN(value)) return;
                if (value < reward.expectedFrom) {
                  promptDialog.$el.find('.dialog-input-field').addClass('item-input-with-error-message item-input-invalid');
                  $inputEl.addClass('input-invalid');
                  const errorMessage = self.$t('candidate.confirm_dialog_less_than_expected_error');
                  if (promptDialog.$el.find('.item-input-error-message').length) {
                    promptDialog.$el.find('.item-input-error-message').text(errorMessage);
                  } else {
                    self.$$(`<div class="item-input-error-message">${errorMessage}</div>`).insertAfter($inputEl);
                  }
                  return;
                }
                promptDialog.close();
                if (value > reward.expectedTo) {
                  self.$root.confirmDialog(
                    self.$t('candidate.confirm_dialog_more_than_expected'),
                    self.$t('candidate.confirm_dialog_title'),
                    () => {
                      confirmInvoice(value);
                    },
                    () => { callbackCancel(); }
                  );
                } else {
                  confirmInvoice(value);
                }
              },
            },
          ],
        });
        promptDialog.open();
      },
      changeStatus(e, status, level) {
        const self = this;
        self.statusChanged = true;
        let reward;
        self.candidate.rewards.forEach((r) => {
          if (r.level === level) reward = r;
        });

        let originalReward;
        self.candidate.assignmentRewards.forEach((r) => {
          if (r.level === level) originalReward = r;
        });

        function sendStatus(transactionValue) {
          self.$store.dispatch('c/changeCandidateStatus', {
            id: self.id,
            status,
            rewardId: originalReward.id,
            transactionValue,
            callbackOk() {
              if (status !== 5) return;
              self.$f7.dialog.alert(
                `
                  <div class="text-align-center">
                    ${self.$theme.ios ? '<i style="font-size:28px" class="icon f7-icons color-brand-green">check_round_fill</i>' : '<i style="font-size:28px" class="icon material-icons color-brand-green">check_circle</i>'}
                    <div class="margin-top">${self.$t('candidate.agreement_made_dialog')}</div>
                  </div>
                `,
                '<div class="text-align-center">FindersCrowd</div>'
              );
            },
          });
        }

        function dialogOk(transactionValue) {
          reward.status = status;
          sendStatus(transactionValue);
        }
        function dialogCancel() {
          e.target.value = reward.status;
        }
        function proceedAgreementMade() {
          if (originalReward.type === 0) {
            // Fixed
            if (self.candidate.assignmentIsInternal && self.candidate.rewardHandledByCompany) {
              dialogOk();
            } else {
              self.$root.confirmDialog(
                `
                ${self.$t('candidate.confirm_dialog_invoiced', { amount: self.rewardFixedAmount(originalReward) })}
                <div>
                  <small>${self.$t('candidate.confirm_terms_link')}</small>
                </div>
                `,
                self.$t('candidate.confirm_dialog_title'),
                () => { dialogOk(); },
                () => { dialogCancel(); }
              );
            }
          } else {
            // Percentage
            self.requestTransaction(originalReward, (transactionValue) => {
              dialogOk(transactionValue);
            }, () => {
              dialogCancel();
            });
          }
        }
        if (status === 5) {
          if (level === self.candidate.assignmentRewards.length && !self.candidate.assignmentMultipleWinners) {
            const confirmText = self.candidate.assignmentStatus === assignmentStatusList.Closed ? 'candidate.confirm_dialog_no_accept_many_closed' : 'candidate.confirm_dialog_no_accept_many';
            self.$root.confirmDialog(
              self.$t(confirmText, {
                status: self.$t('candidate_status.NoFurtherProcess'),
              }),
              self.$t('candidate.confirm_dialog_title'),
              () => {
                proceedAgreementMade();
              },
              () => {
                dialogCancel();
              }
            );
          } else {
            proceedAgreementMade();
          }
        } else {
          sendStatus();
        }
      },
      openChat(url) {
        const self = this;
        self.candidate.unreadCountFinder = 0;
        self.candidate.unreadCountCandidate = 0;
        self.$f7router.navigate(url);
      },
      saveCandidateNotes(value) {
        const self = this;
        self.notes = value;
        self.candidate.notes = value;
        self.$store.dispatch('c/saveCandidateNotes', {
          id: self.candidate.id,
          notes: value,
        });
      },
      update() {
        const self = this;
        if (self.destroyed) return;
        self.dispatchWithRetry('c/getCandidate', {
          id: parseInt(self.id, 10),
          cache: false,
          callbackOk() {
            if (self.destroyed) return;
            self.timeout = setTimeout(() => {
              if (self.destroyed) return;
              self.update();
            }, 30000);
          },
        });
      },
      onPageAfterIn(e, page) {
        const self = this;
        if (page.direction === 'backward') {
          self.dispatchWithRetry('c/getCandidate', {
            id: parseInt(self.id, 10),
            cache: false,
          });
        }
      },
      goBack() {
        const self = this;
        if (!self.candidate || self.loading) {
          self.$f7router.back();
          return;
        }
        const needRating = self.candidate.rating === 0;
        const reward = self.candidate.rewards[self.candidate.rewards.length - 1];
        const needStatus = reward.status === candidateStatusList.InterestReceived && reward.status === candidateStatusList.RecommendationReceived;

        if (!localStorage.dontRemindRateCandidate && (needRating || needStatus)) {
          self.$f7.dialog.create({
            text: self.$t('candidate.rate_required_dialog_text'),
            verticalButtons: true,
            buttons: [
              {
                text: self.$t('candidate.rate_required_dialog_cancel'),
                bold: true,
              },
              {
                text: self.$t('candidate.rate_required_dialog_dont_remind'),
                onClick() {
                  localStorage.dontRemindRateCandidate = true;
                  self.$f7router.back();
                },
              },
              {
                text: self.$t('candidate.rate_required_dialog_continue'),
                onClick() {
                  self.$f7router.back();
                },
              },
            ],
          }).open();
        } else {
          self.$f7router.back();
        }
      },
    },
  };
</script>
