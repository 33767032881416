export default {
  Recruitment: 0,
  Sales: 1,
  Rent: 2,
  Consultant: 3,
  BusinessEducation: 4,
  Innovation: 5,
  Interim: 6,
  Finance: 7,
  Other: 99,
};