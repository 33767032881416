/* eslint no-unused-vars: off */
import $ from 'dom7';
import openExternalUrl from './open-external-url';
import Push from './push';
import UniversalLinks from './universal-links';
import events from './events';
import config from '../config';
import api from './api';

function checkVersion(self, f7) {
  f7.dialog.close();
  api.get({
    url: 'common/app/info',
    ok(response = {}) {
      const { version, linkIos, linkAndroid } = response;
      const currentArray = config.version.split('.');
      const versionArray = version.split('.');
      let hasNewVersion;
      let hasMajorUpdate;
      if (versionArray[0] > currentArray[0]) {
        hasNewVersion = true;
        hasMajorUpdate = true;
      } else if (versionArray[1] > currentArray[1]) hasNewVersion = true;
      if (!hasNewVersion) return;
      f7.dialog.create({
        title: self.$t('general.finderscrowd'),
        text: self.$t(`appstore_updater.${hasMajorUpdate ? 'messageForce' : 'message'}`),
        buttons: hasMajorUpdate
          ? [
            {
              text: self.$t('appstore_updater.button_force'),
              close: false,
              onClick() {
                openExternalUrl(f7.device.ios ? linkIos : linkAndroid, '_systembrowser');
              },
            },
          ]
          : [
            {
              text: self.$t('appstore_updater.button_no'),
            },
            {
              text: self.$t('appstore_updater.button_yes'),
              onClick() {
                openExternalUrl(f7.device.ios ? linkIos : linkAndroid, '_systembrowser');
              },
            },
          ],
      }).open();
    },
  });
}

export default function (f7) {
  const self = this;

  $('html').addClass('device-cordova');

  // External URLs
  $(document).on('click', 'a[target="_inappbrowser"], a[target="_systembrowser"]', function onClick(e) {
    e.preventDefault();
    const url = $(this).attr('href');
    const target = $(this).attr('target');
    if (!url) return;

    openExternalUrl(url, target);
  });

  /* Android back button handling */
  document.addEventListener('backbutton', (e) => {
    if (new Date().getTime() - self.backButtonTimestamp < 250) {
      self.backButtonTimestamp = new Date().getTime();
      if (window.navigator.app && window.navigator.app.exitApp) {
        window.navigator.app.exitApp();
      }
      return true;
    }
    self.backButtonTimestamp = new Date().getTime();
    e.preventDefault();
    if (!f7) return false;
    if ($('.dialog.modal-in').length) {
      f7.dialog.close('.dialog.modal-in');
      return false;
    }
    if ($('.sheet-modal.modal-in').length) {
      f7.sheet.close('.sheet-modal.modal-in');
      return false;
    }
    if ($('.popover.modal-in').length) {
      f7.popover.close('.popover.modal-in');
      return false;
    }
    if ($('.popup.modal-in').length) {
      f7.popup.close('.popup.modal-in');
      return false;
    }

    f7.views.current.router.back();
    return false;
  }, false);

  $(document).on('resume', () => {
    checkVersion(self, f7);
  });

  $(document).on('deviceready', () => {
    // Init Push
    if (self.$root.loggedIn) {
      Push.init();
    }

    // Reset badges
    if (!self.$root.loggedIn) {
      Push.setBadge(0);
    }
    $(document).on('resume', () => {
      if (!self.$root.loggedIn) {
        Push.setBadge(0);
      }
    });
    // Keyboard tweaks
    if (window.Keyboard) {
      window.Keyboard.shrinkView(false);
      window.Keyboard.disableScrollingInShrinkView(true);
      window.Keyboard.hideFormAccessoryBar(true);
      window.addEventListener('keyboardWillShow', () => {
        f7.input.scrollIntoView(document.activeElement, 0, true, true);
        events.$emit('keyboardWillShow');
      });
      window.addEventListener('keyboardDidShow', () => {
        f7.input.scrollIntoView(document.activeElement, 0, true, true);
        events.$emit('keyboardDidShow');
      });
      window.addEventListener('keyboardDidHide', () => {
        if (document.activeElement && $(document.activeElement).parents('.messagebar').length) {
          return;
        }
        window.Keyboard.hideFormAccessoryBar(false);
      });
      window.addEventListener('keyboardHeightWillChange', (e) => {
        document.body.style.height = `calc(100% - ${e.keyboardHeight}px)`;
        events.$emit('keyboardHeightWillChange');
      });
      $(document).on('touchstart', 'input, textarea, select', (e) => {
        const nodeName = e.target.nodeName.toLowerCase();
        if (nodeName === 'select') {
          window.Keyboard.hideFormAccessoryBar(false);
        } else {
          window.Keyboard.hideFormAccessoryBar(true);
        }
      }, true);
    }

    // Statusbar
    function fixAndroidStatusbar() {
      window.StatusBar.overlaysWebView(false);
      f7.statusbar.hide();
      f7.statusbar.show();
      f7.statusbar.setBackgroundColor('bf5009');
    }
    if (window.StatusBar) {
      if (f7.device.android) {
        fixAndroidStatusbar();
        $(document).on('deviceready', () => {
          fixAndroidStatusbar();
        });
      }
      window.StatusBar.styleLightContent();
    }

    // Splashscreen
    if (window.navigator.splashscreen) {
      setTimeout(() => {
        window.navigator.splashscreen.hide();
        if (f7.device.android) {
          fixAndroidStatusbar();
        }
      }, 2000);
    }
    // Setup universal links
    UniversalLinks.subscribe();

    // Check version
    checkVersion(self, f7);
  });
}
