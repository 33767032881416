<template>
  <f7-swiper :pagination="swiperActive" color-theme="white" :navigation="swiperActive" class="image-gallery" v-if="mediaFiles.length && mediaFiles.length >= 1 && showSwiper" :init="swiperActive" ref="swiper" :params="{ on: { transitionEnd: onSwiperTransitionEnd } }">
    <f7-swiper-slide v-for="(file, index) in mediaFiles" :key="index" @click.native="openPb(index)">
      <a v-if="isVideo(file) && !isVideoFrame(file)" :href="fileSrc(file)" :target="$device.cordova ? '_system' : '_blank'" class="external image-gallery-video-link">
        <f7-icon f7="play_round"></f7-icon>
      </a>
      <img v-if="isImage(file)" :src="imageSrc(file)">
      <iframe v-if="isVideoFrame(file)" :src="iframeSrc(file)" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>

      <!-- <video v-if="isVideo(file)" :src="videoSrc(file)" muted loop :autoplay="index === 0" playsinline></video> -->

      <a v-if="!isVideo(file) && !isImage(file) && !isVideoFrame(file)" :href="fileSrc(file)" :target="$device.cordova ? '_system' : '_blank'" class="external image-gallery-file-link">
        <div class="image-gallery-file-icon" :data-file-extension="fileExtension(file)"></div>
        <span>{{file.name || file.originalFileName}}</span>
      </a>
    </f7-swiper-slide>
  </f7-swiper>
</template>
<script>
  import config from '../../config';
  import getVideoDataFromUrl from '../../utils/get-video-data-from-url';

  export default {
    props: {
      files: Array,
      extraMediaFiles: Array,
      photoBrowser: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        showSwiper: false,
      };
    },
    computed: {
      swiperActive() {
        const self = this;
        return self.mediaFiles.length && self.mediaFiles.length > 1;
      },
      mediaFiles() {
        const self = this;
        const mediaFiles = [
          ...(self.extraMediaFiles || []),
          ...self.files,
        ];
        return mediaFiles;
      },
    },
    methods: {
      iframeSrc(file) {
        const self = this;

        const videFrameDataFromUrl = file.url ? self.videoFrameDataFromUrl(file.url) : {};

        if (file.yt || videFrameDataFromUrl.yt) {
          return `${self.$device.cordova ? 'https:' : ''}//www.youtube.com/embed/${file.yt || videFrameDataFromUrl.yt}`;
        }
        if (file.vimeo || videFrameDataFromUrl.vimeo) {
          return `${self.$device.cordova ? 'https:' : ''}//player.vimeo.com/video/${file.vimeo || videFrameDataFromUrl.vimeo}`;
        }
        if (file.dreamBroker || videFrameDataFromUrl.dreamBroker) {
          const db = file.dreamBroker || videFrameDataFromUrl.dreamBroker;
          return `${self.$device.cordova ? 'https:' : ''}//dreambroker.com/channel/${db[0]}/iframe/${db[1]}`;
        }
      },
      fileExtension(file) {
        const name = file.name || file.originalFileName;
        if (!name) return undefined;
        const extParts = name.split('.');
        if (extParts.length < 2) return '';
        return `.${name.split('.')[extParts.length - 1]}`;
      },
      isExternalImage(fileUrl) {
        const exts = 'jpg png jpeg gif svg'.split(' ');
        let image = false;
        exts.forEach((ext) => {
          if (fileUrl.toLowerCase().indexOf(`.${ext}`) > 0) image = true;
        });
        return image;
      },
      videoFrameDataFromUrl(url) {
        const { yt, vimeo, dreamBroker } = getVideoDataFromUrl(url);

        if (yt) {
          return {
            yt,
            imageUrl: `https://img.youtube.com/vi/${yt}/hqdefault.jpg`,
            type: 'video',
            pb: {
              html: `<iframe src="${window.cordova ? 'https:' : ''}//www.youtube.com/embed/${yt}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`,
            },
          };
        }
        if (vimeo) {
          return {
            vimeo,
            type: 'video',
            pb: {
              html: `<iframe src="${window.cordova ? 'https:' : ''}//player.vimeo.com/video/${vimeo}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`,
            },
          };
        }
        if (dreamBroker) {
          return {
            dreamBroker,
            type: 'video',
            pb: {
              html: `<iframe src="${window.cordova ? 'https:' : ''}//dreambroker.com/channel/${dreamBroker[0]}/iframe/${dreamBroker[1]}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`,
            },
          }
        }
        return null;
      },
      onSwiperTransitionEnd() {
        const self = this;
        self.$$(self.$refs.swiper.$el)
          .find('.swiper-slide-prev iframe, .swiper-slide-next iframe')
          .each((index, el) => {
            el.src = el.src;
          });
        /*
        self.$$(self.$refs.swiper.$el)
          .find('.swiper-slide-prev video, .swiper-slide-next video')
          .each((index, el) => {
            if (el.pause) el.pause();
          });
        self.$$(self.$refs.swiper.$el)
          .find('.swiper-slide-current video')
          .each((index, el) => {
            if (el.play) el.play();
          });
        */
      },
      isVideo(file) {
        const type = file.type || file.contentType;
        return file && type && type.indexOf('video') === 0;
      },
      isVideoFrame(file) {
        const self = this;
        if (file.url && !file.yt && !file.vimeo && !file.dreamBroker) {
          return !!self.videoFrameDataFromUrl(file.url);
        }
        return file.yt || file.vimeo || file.dreamBroker;
      },
      isImage(file) {
        const self = this;
        if (file && file.url && self.isExternalImage(file.url)) return true;
        const type = file.contentType || file.type;
        return file.image || (file && type && type.indexOf('image') === 0);
      },
      imageSrc(file) {
        const self = this;
        const imageMaxWidth = Math.min(self.$f7.width, 800);
        if (file.url && self.isExternalImage(file.url)) return file.url;
        if (file.imageUrl) return `${file.imageUrl}`;
        if (file.downloadFileName) {
          return `${config.path.images}${file.downloadFileName}?fit=clip&w=${imageMaxWidth}&h=400&dpr=${self.$device.pixelRatio}`;
        }
        if (file.dataUrl) {
          return `${file.dataUrl}`;
        }
        return '';
      },
      fileSrc(file) {
        if (file.url) {
          return file.url;
        }
        if (file.downloadFileName) {
          return `${config.path.files}${file.downloadFileName}`;
        }
        if (file.dataUrl) {
          return `${file.dataUrl}`;
        }
        return '';
      },
      openPb(index) {
        const self = this;
        if (!self.photoBrowser) return;
        const file = self.mediaFiles[index];
        if (self.isVideo(file)) return;
        if (!self.isImage(file) && !self.isVideoFrame(file)) return;
        self.pb.open(index);
      },
    },
    beforeDestroy() {
      const self = this;
      if (!self.photoBrowser) return;
      if (this.pb && this.pb.destroy) this.pb.destroy();
    },
    mounted() {
      const self = this;
      self.$nextTick(() => {
        self.showSwiper = true;
      });
      if (!self.photoBrowser) return;
      self.$f7ready(() => {
        const photos = self.mediaFiles
          .filter((file) => {
            return self.isImage(file) || self.isVideoFrame(file) || self.isVideo(file);
          })
          .filter(file => file.downloadFileName || file.dataUrl || file.pb || (file.url && self.isExternalImage(file.url)) || (file.url && self.videoFrameDataFromUrl(file.url)))
          .map((file) => {
            if (file.dataUrl) return file.dataUrl;
            if (file.pb) return file.pb;
            if (file.url && self.isExternalImage(file.url)) {
              return file.url;
            }
            if (file.url && self.videoFrameDataFromUrl(file.url)) {
              const { pb } = self.videoFrameDataFromUrl(file.url);
              return pb;
            }

            return `${config.path.images}${file.downloadFileName}?dpr=2`;
          });
        const pbView = self.$f7.views.main || self.$f7.views[0];

        self.pb = self.$f7.photoBrowser.create({
          theme: 'dark',
          photos,
          routableModals: !!pbView,
          view: pbView,
          backLinkText: self.$t('general.close'),
          renderNavbar() {
            const pb = this;

            let iconsColor = pb.params.iconsColor;
            if (!pb.params.iconsColor && pb.params.theme === 'dark') iconsColor = 'white';

            const backLinkText = pb.params.backLinkText;

            const isPopup = pb.params.type !== 'page';
            const navbarHtml = `
              <div class="navbar">
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a href="#" class="link ${isPopup ? 'popup-close' : ''} ${!backLinkText ? 'icon-only' : ''} ${!isPopup ? 'back' : ''}" ${isPopup ? 'data-popup=".photo-browser-popup"' : ''}>
                      <i class="icon icon-back ${iconsColor ? `color-${iconsColor}` : ''}"></i>
                      ${backLinkText ? `<span>${backLinkText}</span>` : ''}
                    </a>
                  </div>
                  <div class="title">
                    <span class="photo-browser-current"></span>
                    <span class="photo-browser-of">${pb.params.navbarOfText}</span>
                    <span class="photo-browser-total"></span>
                  </div>
                  <div class="right"></div>
                </div>
              </div>
            `.trim();
            return navbarHtml;
          },
        });
      });
    },
  };
</script>
