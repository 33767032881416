<template>
  <f7-button
    :fill="fill"
    :round="round"
    :small="small"
    :large="large"
    :outline="outline"
    :raised="raised"
    :color="color"
    :class="{ loading, disabled }"
    @click="onClick"
  >
    <slot><span>{{text}}</span></slot>
    <f7-preloader v-if="preloader" :color="preloaderColorComputed"></f7-preloader>
  </f7-button>
</template>
<script>
  export default {
    props: {
      fill: Boolean,
      round: Boolean,
      small: Boolean,
      large: Boolean,
      outline: Boolean,
      raised: Boolean,

      color: String,
      loading: Boolean,
      disabled: Boolean,
      preloader: {
        type: Boolean,
        default: true,
      },
      preloaderColor: String,
      text: String,
      fluid: Boolean,
    },
    computed: {
      preloaderColorComputed() {
        const self = this;
        if (self.preloaderColor) return self.preloaderColor;
        if (self.fluid && self.$f7.width < 768) {
          return self.color;
        }
        if (self.fill) return 'white';
        if (self.outline) {
          return self.color || 'brand-orange';
        }
      },
    },
    methods: {
      onClick(e) {
        this.$emit('click', e);
      },
    },
  };
</script>
