<template>
  <a class="url-preview external" :class="{'url-preview-placeholder': usePlaceholder}" :target="target" :href="usePlaceholder ? '' : data.url" v-if="!errored">
    <span class="url-preview-title">{{usePlaceholder ? '' : data.title}}<span v-if="usePlaceholder"></span></span>
    <span class="url-preview-description">{{usePlaceholder ? '' : data.description}}<span v-if="usePlaceholder" v-for="n in 3" :key="n"></span></span>
    <span class="url-preview-image">
      <img v-if="data && data.image" :src="data.image">
    </span>
  </a>
</template>
<script>
  import Request from '../../modules/request';



  export default {
    props: {
      url: String,
    },
    data() {
      return {
        data: null,
        errored: false,
        target: window.cordova ? '_system' : '_blank',
      };
    },
    computed: {
      usePlaceholder() {
        const self = this;
        return !self.data;
      },
    },
    mounted() {
      const self = this;
      self.getPreview(self.url, (data) => {
        self.data = data;
      });
    },
    methods: {
      getPreview(url, callback) {
        const self = this;
        const ls = window.localStorage;
        if (ls[`urlPreview:${url}`]) {
          callback(JSON.parse(ls[`urlPreview:${url}`]));
          return;
        }

        const requestUrl = `https://api.linkpreview.net/?key=5da8220e2b01af08c64512c2ce6b9642b58563e9c029b&q=${url}`;
        Request.json(requestUrl,
          (data) => {
            ls[`urlPreview:${url}`] = JSON.stringify(data);
            callback(data);
          },
          (xhr, status) => {
            self.errored = true;
          }
        );
      }
    },
  };
</script>
