<template>
  <f7-page>
    <f7-navbar :title="$t('payments.title')" large>
      <f7-link slot="nav-left" back icon="icon-back" :text="$t('general.back')"></f7-link>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <div class="center-content" v-if="payments">
      <f7-block-title class="display-flex justify-content-space-between">
        <span>{{$t('payments.title')}}</span>
        <a class="link" @click="downloadCSV" v-if="payments.transactions && payments.transactions.length && !$device.cordova">
          <i class="f7-icons" style="position: relative">document_fill<span class="text-color-white" style="position: absolute; font-size: 20px; left: 4px; top: 4px;">arrow_down</span></i>
          <span>CSV</span>
        </a>
      </f7-block-title>
      <f7-block strong class="no-padding">
        <div class="data-table no-margin" v-if="payments.transactions && payments.transactions.length">
          <c-transaction-list-table
            :transactions="payments.transactions"
          ></c-transaction-list-table>
        </div>
        <div class="text-align-center padding" v-if="payments.transactions && !payments.transactions.length">
          <p>{{$t('payments.company_no_payments')}}</p>
        </div>
      </f7-block>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';

  export default {
    created() {
      const self = this;
      self.$store.dispatch('c/getPayments');
    },
    computed: {
      ...mapGetters({
        loading: 'c/paymentsLoading',
        payments: 'c/payments',
      }),
    },
    methods: {
      downloadCSV() {
        const self = this;
        const $tableEl = self.$$(self.$el).find('table');
        let csvContent = 'data:text/csv;charset=utf-8,';
        let csvHead = [];
        $tableEl.find('thead th').each((index, el) => {
          csvHead.push(el.textContent.trim());
        });
        csvContent += `${csvHead.join(';')}\n`;

        $tableEl.find('tbody tr').each((index, rowEl) => {
          const row = [];
          self.$$(rowEl).find('td').each((cellIndex, cellEl) => {
            row.push(cellEl.textContent.trim());
          });
          csvContent += `${row.join(';')}\n`;
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('target', self.$externalTarget);
        link.setAttribute('download', `${self.$t('payments.title').toLowerCase()}.csv`);
        link.click();
      },
    },
  };
</script>
