/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';
import PWA from '../../modules/pwa';

const state = {
  user: null,
  userLoading: false,
  saving: false,
};

const getters = {
  userLoading(state) {
    return state.userLoading;
  },
  userSaving(state) {
    return state.saving;
  },
  user(state) {
    return state.user;
  },
};
const actions = {
  getUser({ commit, state }, { id } = {}) {
    if (state.user) return;
    API.get({
      url: `admin/user/details?id=${id}`,
      loading: 'a/setUserLoading',
      ok(response) {
        commit('setUser', response);
      },
    });
  },
  unsetUser({ commit }) {
    commit('unsetUser');
  },
  saveUser({ dispatch }, { user, callbackOk, callbackError } = {}) {
    API.post({
      url: 'admin/user/save',
      loading: 'a/setUserSaving',
      data: user,
      base64FileProp: 'photo',
      ok() {
        if (user && user.id && state.user && state.user.id === user.id) {
          const newUser = {
            ...state.user,
            ...user,
          };
          PWA.updateCachedApi(`admin/user/details?id=${user.id}`, newUser);
        }
        if (callbackOk) callbackOk();
      },
      error: callbackError,
    });
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setUserLoading(state, status) {
    state.userLoading = status;
  },
  unsetUser(state) {
    state.user = null;
  },
  setUserSaving(state, status) {
    state.saving = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
