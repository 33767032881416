import events from './events';

function isAuthRoute(url) {
  return url.indexOf('/signup/') === 0 ||
         url.indexOf('/forgot-password/') === 0 ||
         url.indexOf('/reset-password/') === 0;
}
const UniversalLinks = {
  onLogin() {},
  subscribe() {
    window.universalLinks.subscribe('deeplink', (eventData) => {
      events.$off('login', UniversalLinks.onLogin);

      const { path, params } = eventData;

      const query = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

      const url = `${path}/${query.length ? `?${query}` : ''}`.replace('//', '/');

      UniversalLinks.handle(url);
    });
  },
  handleLoggedIn(url) {
    // Logged in and passing to login, no sense: return
    if (isAuthRoute(url)) return;
    events.$emit('f7instance', (f7) => {
      // Otherwise load in main view
      const reloadAll = false;

      // const newUrlParts = url.split('/').filter(part => part !== '');
      // const oldUrlParts = f7.views.main.router.url.split('/').filter(part => part !== '');
      // if (newUrlParts[0] !== oldUrlParts[0] && newUrlParts[1] !== oldUrlParts[1]) {
      //   reloadAll = true;
      // }
      if (url.indexOf('?join=') >= 0) {
        events.$emit('joinCrowd', url.split('join=')[1]);
        return;
      }
      if (url.indexOf('?join_company=') >= 0) {
        events.$emit('joinCompany', url.split('join_company=')[1]);
        return;
      }

      f7.views.main.router.navigate(url, { reloadAll });
    });
  },
  handleLoggedOut(url) {
    if (!isAuthRoute(url) && url.indexOf('/confirmation/') < 0 && url.indexOf('/recommendation/') < 0) {
      // Need to be loaded after log in
      UniversalLinks.onLogin = () => {
        setTimeout(() => {
          UniversalLinks.handle(url);
        }, 1000);
      };
      events.$once('login', UniversalLinks.onLogin);
      return;
    }
    // Load in login view
    events.$emit('f7instance', (f7) => {
      f7.views.login.router.navigate(url, { reloadCurrent: true });
    });
  },
  handle(url) {
    events.$emit('isLoggedIn', (loggedIn) => {
      if (loggedIn) {
        UniversalLinks.handleLoggedIn(url);
      } else {
        UniversalLinks.handleLoggedOut(url);
      }
    });
  },
};

export default UniversalLinks;
