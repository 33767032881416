/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';

const state = {
  profile: null,
  profileLoading: false,
  saving: false,
};

const getters = {
  profileLoading(state) {
    return state.profileLoading;
  },
  profileSaving(state) {
    return state.saving;
  },
  profile(state) {
    return state.profile;
  },
};
const actions = {
  getProfile({ commit, state }, { cached } = {}) {
    if ((cached && state.profile) || state.profileLoading) return;
    API.get({
      url: 'admin/profile/details',
      loading: 'a/setProfileLoading',
      ok(res) {
        commit('setProfile', res);
      },
    });
  },
  unsetProfile({ commit }) {
    commit('unsetProfile');
  },
  saveProfile({ dispatch }, { data, callbackOk, callbackError } = {}) {
    API.post({
      url: 'admin/profile/save',
      data,
      base64FileProp: 'photo',
      loading: 'a/setProfileSaving',
      ok() {
        dispatch('getProfile');
        if (callbackOk) callbackOk();
      },
      error() {
        if (callbackError) callbackError();
      },
    });
  },
};

const mutations = {
  setProfile(state, profile) {
    state.profile = profile;
  },
  setProfileLoading(state, status) {
    state.profileLoading = status;
  },
  unsetProfile(state) {
    state.profile = null;
  },
  setProfileSaving(state, status) {
    state.saving = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
