<template>
  <f7-page
    infinite
    :infinite-preloader="false"
    @infinite="onInfinite"
  >
    <f7-navbar :title="$t('users.title')">
      <f7-link slot="nav-left" back icon="icon-back" :text="$t('general.back')"></f7-link>
    </f7-navbar>


    <offline-indicator slot="fixed"></offline-indicator>

    <div class="center-content">
      <f7-block strong class="finders-invite-block">
        <f7-block-title>{{$t('users.invite_title')}}</f7-block-title>
        <p>{{$t('users.invite_email_text')}}</p>
        <p class="text-align-center">
          <f7-button @click="inviteOpened = true" round fill style="width: 200px; display: inline-block">
            <f7-icon f7="email_fill"></f7-icon>
            <span>{{$t('users.invite_button')}}</span>
          </f7-button>
        </p>
        <div class="or-separator">
          <span>{{$t('users.invite_or')}}</span>
        </div>
        <p>{{$t('users.invite_link_text')}}</p>
        <f7-link
          no-link-class
          class="display-flex justify-content-center align-items-center"
          style="word-break: break-word;"
          :tooltip="$t('general.copy_to_clipboard')"
          @click="copyToClipboard(`${host}/?join_company=${companyGuid}`)"
        >
          <span>{{`${host}/?join_company=${companyGuid}`}}</span>
          <span class="display-flex margin-left flex-shrink-0">
            <f7-icon material="insert_drive_file"></f7-icon>
            <f7-icon material="subdirectory_arrow_left" :size="18" style="margin-left: -6px;"></f7-icon>
          </span>
        </f7-link>
      </f7-block>

      <f7-block-title class="display-flex justify-content-flex-end" v-if="hasUsers">
        <loading-button fill small round class="display-flex align-items-center" @click="resendAll()">
          <f7-icon material="send" class="no-margin" :size="20"></f7-icon>
          <span style="margin-left:4px">{{$t('users.resend_all_button')}}</span>
        </loading-button>
      </f7-block-title>

      <f7-searchbar
        v-if="hasUsers"
        class="searchbar-users"
        inline
        :custom-search="true"
        :placeholder="$t('users.search_placeholder')"
        :value="query"
        :disableButton="false"
        :disableButtonText="$t('general.cancel')"
        @searchbar:search="onSearch"
      />

      <f7-list v-if="users && users.length" media-list>
        <f7-list-item
          v-for="user in users"
          :key="user.id"
          :title="user.firstName ? `${user.firstName} ${user.lastName}` : user.email"
        >
          <avatar :file="user.photo" slot="media" :size="44"></avatar>
          <f7-link v-if="!user.active" @click="resendUser(user)" color="gray" icon-material="send" slot="content" class="padding"></f7-link>
          <f7-link @click="deleteUser(user)" color="gray" icon-material="delete" slot="content" class="padding"></f7-link>
          <div slot="text">
            <div>{{user.email}}</div>
            <div>{{$t(`users.status_${user.active ? 'active' : 'pending'}`)}}</div>
          </div>
        </f7-list-item>
      </f7-list>
      <f7-block strong class="empty-block text-align-center" v-if="users && !users.length && query">{{$t('users.search_not_found')}}</f7-block>
      <f7-preloader v-if="allowInfinite && usersHasMore" class="infinite-scroll-preloader"></f7-preloader>
    </div>

    <f7-popup :opened="inviteOpened" @popup:closed="inviteOpened = false" ref="usersPopup" v-if="renderPopups">
      <f7-view :init="false">
        <f7-page>
          <f7-navbar :title="$t('users.invite_button')">
            <f7-nav-right>
              <f7-link icon-ios="f7:close" icon-md="material:close" @click="inviteOpened = false"></f7-link>
            </f7-nav-right>
          </f7-navbar>
          <f7-list>
            <f7-block-header>{{$t('users.invite_dialog')}}</f7-block-header>
            <f7-list-input
              :label="$t('users.invite_dialog_info')"
              type="textarea"
              resizable
              :placeholder="$t('form.email')"
              defaultValue=""
            ></f7-list-input>
          </f7-list>
          <f7-list>
            <loading-list-button
              color="brand-orange"
              @click="inviteUsers"
              :loading="inviting"
            >{{$t('users.invite_dialog_button')}}</loading-list-button>
          </f7-list>
        </f7-page>
      </f7-view>
    </f7-popup>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import config from '../../config';
  import copyToClipboard from '../../utils/copy-to-clipboard';
  import events from '../../modules/events';

  export default {
    data() {
      return {
        users: null,
        allowInfinite: false,
        inviteOpened: false,
        renderPopups: true,
        query: '',
        hasUsers: false,
        host: config.host,
      };
    },
    computed: {
      ...mapGetters({
        inviting: 'c/usersInviting',
        usersLoading: 'c/usersLoading',
        currentUsers: 'c/users',
        userDeleting: 'c/userDeleting',
        usersHasMore: 'c/usersHasMore',
        usersLoadingMore: 'c/usersLoadingMore',
        profile: 'c/profile',
      }),
      companyGuid() {
        const self = this;
        if (!self.profile) return null;
        return self.profile.companyGuid;
      }
    },
    watch: {
      currentUsers() {
        const self = this;
        self.users = [...self.currentUsers];
        if (self.users.length) self.hasUsers = true;
      },
      'currentUsers.length': function watchUsers(length) {
        if (length && length % 20 === 0) {
          this.allowInfinite = true;
        } else {
          this.allowInfinite = false;
        }
      },
    },
    created() {
      const self = this;
      self.$store.dispatch('c/getUsers');
      self.$store.dispatch('c/getDetails');
      self.$store.dispatch('c/getProfile');
    },
    methods: {
      copyToClipboard(link) {
        const self = this;
        copyToClipboard(link, () => {
          events.$emit('notify', self.$t('general.copy_to_clipboard_success'));
        });
      },
      onSearch(ss, query) {
        const self = this;
        if (query.trim() === self.query) return;
        self.query = query.trim();
        clearTimeout(self.searchTimeout);
        self.searchTimeout = setTimeout(() => {
          self.$store.dispatch('c/getUsers', { cache: false, query: query.trim() });
        }, 300);
      },
      onInfinite() {
        const self = this;
        if (!self.allowInfinite || self.usersLoadingMore || !self.usersHasMore) return;
        self.$store.dispatch('c/getMoreUsers');
      },
      resendAll() {
        const self = this;
        self.$f7.dialog.confirm(self.$t('users.resend_all_confirm'), () => {
          self.$store.dispatch('c/resendAllUser');
        });
      },
      resendUser(user) {
        const self = this;
        self.$f7.dialog.confirm(self.$t('users.resend_user_confirm'), () => {
          self.$store.dispatch('c/resendUser', { userId: user.id });
        });
      },
      deleteUser(user) {
        const self = this;
        self.$f7.dialog.confirm(self.$t('users.delete_user_confirm'), () => {
          self.$store.dispatch('c/deleteUser', { userId: user.id });
        });
      },
      inviteUsers() {
        const self = this;
        function validate($inputEl) {
          self.$f7.input.validate($inputEl[0]);
          const emails = $inputEl.val().split('\n').map(e => e.trim()).filter(e => !!e);
          const hasInvalidEmail = emails.filter(e => e.indexOf('@') < 0).length > 0;
          if (!emails.length || hasInvalidEmail) {
            $inputEl.addClass('input-invalid').parents('.item-input').addClass('item-input-invalid item-input-with-error-message');
            let $errorEl = $inputEl.nextAll('.item-input-error-message');
            if ($errorEl.length === 0) {
              $inputEl.parent().append('<div class="item-input-error-message"></div>');
              $errorEl = $inputEl.nextAll('.item-input-error-message');
            }
            $errorEl.text(self.$t('users.invite_dialog_error'));
            return false;
          }
          if ($inputEl.hasClass('input-invalid')) return false;
          return true;
        }
        const $inputEl = self.$$(self.$refs.usersPopup.$el.querySelector('textarea'));
        const emails = $inputEl.val().split(/\n|\s/).map(e => e.trim()).filter(e => !!e);
        const valid = validate($inputEl);
        if (!valid) return;

        self.$store.dispatch('c/inviteUser', {
          emails,
          callbackOk() {
            self.inviteOpened = false;
            setTimeout(() => {
              self.renderPopups = false;
              setTimeout(() => {
                self.renderPopups = true;
              });
            }, 600);
          },
        });
      },
    },
  };
</script>
