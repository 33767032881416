<template>
  <f7-popup tablet-fullscreen :opened="isGuidPreview">
    <f7-view :init="false">
      <f7-page class="assignment-details assignment-preview-page">
        <f7-navbar>
          <f7-nav-left v-if="isGuidPreview && $device.cordova">
            <f7-link popup-close icon="icon-back" :text="$t('general.back')"></f7-link>
          </f7-nav-left>
          <f7-nav-title>{{$t('assignment.preview_title')}}</f7-nav-title>
          <f7-nav-right v-if="!isGuidPreview">
            <f7-link popup-close icon-ios="f7:close" icon-md="material:close"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <offline-indicator slot="fixed"></offline-indicator>
        <div class="center-content" v-if="assignment">
          <assignment-details-header
            :assignment="assignment"
            :rewardDisabled="rewardDisabled"
            :isCompany="true"
            :isPreview="true"
          />

          <template v-if="assignment.multipleLevels && !rewardDisabled">
            <f7-block-title>{{$t('assignment.rewards_title')}}</f7-block-title>
            <div class="timeline rewards-timeline">
              <div class="timeline-item"
                v-for="(reward, index) in assignment.rewards"
                :key="reward.id"
                :class="{ active: reward.level === assignment.rewardLevel}"
              >
                <div class="timeline-item-divider">{{reward.level}}</div>
                <div class="timeline-item-content" style="width: 100%">
                  <f7-list media-list inset class="assignment-rewards-list no-safe-areas">
                    <f7-list-item
                      :subtitle="reward.description"
                      media-item
                    >
                      <span class="assignment-rewards-list-level" slot="inner-start" v-if="reward.level && reward.level === 1 || index === 0">{{$t('assignment.reward_level_number', { level: reward.level || (index + 1) })}}:</span>
                      <span class="assignment-rewards-list-level" slot="inner-start" v-if="reward && reward.level > 1 || index > 0">{{$t('assignment.reward_level_number', { level: reward.level || (index + 1) })}} - {{$t('assignment.reward_after_level_reached', { level: reward.level ? reward.level -1 : index })}}:</span>
                      <span slot="title" v-html="$t('assignment.reward_to_fc_level_title', {
                        amount: `<span class=text-color-brand-green>${rewardLevelAmount(reward)}</span>`,
                      })"></span>
                      <div slot="title" v-if="assignment.multipleWinners" style="font-weight: 400; font-size: 14px; margin-bottom: 10px">- {{$t('assignment.accept_many_reward_info', { level: reward.level || (index + 1) })}}</div>
                      <span slot="text">{{rewardDetails(reward)}}</span>
                    </f7-list-item>
                  </f7-list>

                </div>
              </div>
            </div>
          </template>

          <template v-if="!isGuidPreview">
            <f7-block-title>{{$t('assignment.preview_link')}}</f7-block-title>
            <template v-if="assignment.guid">
              <f7-block strong class="display-flex justify-content-space-between align-items-center">
                <a :href="`${host}/preview/${assignment.guid}/`" :target="$device.cordova ? '_systembrowser' : '_blank'" class="clickable external flex-shrink-1" style="word-break: break-word;">{{`${host}/preview/${assignment.guid}/`}}</a>
                <f7-link :tooltip="$t('general.copy_to_clipboard')" class="margin-left flex-shrink-0 clickable" @click="copyToClipboard(`${host}/preview/${assignment.guid}/`)">
                  <f7-icon material="insert_drive_file"></f7-icon>
                  <f7-icon material="subdirectory_arrow_left" :size="18" style="margin-left: -6px;"></f7-icon>
                </f7-link>
              </f7-block>
            </template>
            <template v-else>
              <f7-block strong class="text-align-center">{{$t('assignment.preview_needs_save')}}</f7-block>
            </template>
          </template>

          <template v-if="!assignment.anonymous && moreAssignments && moreAssignments.length">
            <f7-block-title>{{$t('assignment.more_assignments_title', {companyName: assignment.companyName})}}</f7-block-title>
            <f-assignment-list :company-preview="true" :assignments="moreAssignments" :logo="assignment.logo" ></f-assignment-list>
          </template>
        </div>
        <skeleton-assignment-details v-else></skeleton-assignment-details>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
  import { mapGetters } from 'vuex';
  import rewardDetails from '../../utils/reward-details';
  import rewardLevelAmount from '../../utils/reward-level-amount';
  import config from '../../config';
  import copyToClipboard from '../../utils/copy-to-clipboard';
  import events from '../../modules/events';

  export default {
    props: {
      id: [String, Number],
      guid: String,
    },
    data() {
      return {
        largeScreen: this.$f7.width >= 768,
        host: config.host,
      };
    },
    created() {
      const self = this;
      if (self.guid) {
        self.getPreviewByGuid();
      }
    },
    mounted() {
      const self = this;
      self.onResize = function onResize() {
        if (self.$f7.width >= 768) {
          self.$set(self, 'largeScreen', true);
        } else {
          self.$set(self, 'largeScreen', false);
        }
      };
      self.$f7.on('resize', self.onResize);
    },
    beforeDestroy() {
      const self = this;
      if (self.onResize) {
        self.$f7.off('resize', self.onResize);
      }
      self.destroyed = true;
    },
    computed: {
      ...mapGetters({
        assignment: 'c/assignmentPreview',
      }),
      rewardDisabled() {
        return this.assignment ? this.assignment.disableReward : false;
      },
      isGuidPreview() {
        return !!this.guid;
      },
      moreAssignments() {
        const self = this;
        return self.assignment.companyAssignments ? self.assignment.companyAssignments.filter(({ id }) => id !== self.assignment.id) : [];
      },
    },
    methods: {
      copyToClipboard(link) {
        const self = this;
        copyToClipboard(link, () => {
          events.$emit('notify', self.$t('general.copy_to_clipboard_success'));
        });
      },
      rewardLevelAmount(reward) {
        const self = this;
        return rewardLevelAmount(self, { shareOut: 100 }, reward);
      },
      rewardDetails(reward) {
        const self = this;
        const { assignment } = self;
        return rewardDetails(self, assignment, reward);
      },
      getPreviewByGuid() {
        const self = this;
        if (!self.guid) return;
        self.$store.dispatch('c/getPreviewByGuid', {
          guid: self.guid,
          callbackOk(previewData) {
            self.$store.dispatch('c/setAssignmentPreview', previewData);
          },
        });
      },
    },
  };
</script>
