<template>
  <f7-popup>
    <f7-view :init="false">
      <f7-page>
        <f7-navbar>
          <f7-nav-title>{{$t('how_to_recommend.title')}}</f7-nav-title>
          <f7-nav-right>
            <f7-link popup-close icon-ios="f7:close" icon-md="material:close"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <offline-indicator slot="fixed"></offline-indicator>
        <f7-block strong v-html="$t('how_to_recommend.text')"></f7-block>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
  export default {};
</script>