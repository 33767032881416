<template>
  <f7-page @page:beforeremove="onPageBeforeRemove">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon="icon-back" :text="$t('general.back')" back></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{$t('admin.crowd_edit_title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <crowd-form ref="form" v-if="crowd" :crowd="crowd">
      <f7-list>
        <f7-list-button color="brand-blue" @click="save">{{$t('general.save')}}</f7-list-button>
      </f7-list>
    </crowd-form>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import events from '../../modules/events';

  export default {
    props: {
      id: [Number, String],
    },
    computed: {
      form() {
        return this.$refs.form;
      },
      ...mapGetters({
        saving: 'a/crowdSaving',
        loading: 'a/crowdLoading',
        crowd: 'a/crowd',
      }),
    },
    created() {
      const self = this;
      self.$store.dispatch('a/getCrowd', { id: parseInt(self.id, 10) });
    },

    methods: {
      save() {
        const self = this;

        const valid = self.form.validate();
        if (!valid) return;

        const crowd = self.crowd;

        const newCrowd = {
          id: crowd.id,
          ...self.form.getData(),
        };

        self.$store.dispatch('a/saveCrowd', {
          crowd: newCrowd,
          callbackOk() {
            events.$emit('store:admin:getCrowdsList');
            self.$f7router.back();
          },
        });
      },
      onPageBeforeRemove() {
        const self = this;
        self.$store.dispatch('a/unsetCrowd');
      },
    },
  };
</script>
