<template>
  <div class="rating" :class="{'no-rate': !allowRate}">
    <div
      v-for="n in 5"
      class="rating-star"
      :key="n"
      :class="{ 'rating-star-active': currentRating >= n}"
      @click="rate(n)"
      @mouseenter="onMouseEnter(n)"
      @mouseleave="onMouseLeave"
    >
      <f7-icon
        :material="currentRating >= n ? 'star' : 'star_border'"
        :color="currentRating >= n ? color : undefined"
      ></f7-icon>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      color: {
        type: String,
        default: 'brand-orange',
      },
      value: Number,
      allowRate: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        rating: 0,
      };
    },
    computed: {
      currentRating() {
        return this.rating || this.value || 0;
      },
    },
    methods: {
      onMouseEnter(n) {
        if (!this.$device.desktop || !this.allowRate) return;
        this.rating = n;
      },
      onMouseLeave() {
        if (!this.$device.desktop || !this.allowRate) return;
        this.rating = this.value;
      },
      rate(rating) {
        if (!this.allowRate) return;
        this.rating = rating;
        this.$emit('rate', this.rating);
      },
    },
  };
</script>
