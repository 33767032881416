import Request from './request';
import toFormData from '../utils/to-form-data';
import config from '../config';
import store from '../store/index';
import events from './events';
import Sync from './sync';

function setLoading(loading, value) {
  if (!loading) return;
  if (Array.isArray(loading)) {
    const [obj, prop] = loading;
    obj[prop] = value;
  } else {
    const loadingItems = typeof loading === 'string' ? loading.split(' ') : loading;
    loadingItems.forEach((item) => {
      store.commit(item, value);
    });
  }

}
const API = {
  get({ url = '', loading, cache = true, data = {}, ok, error, start, end } = {}) {
    if (start) start();
    setLoading(loading, true);

    Request({
      url: `${config.host}/api${url[0] === '/' ? '' : '/'}${url}`,
      method: 'get',
      dataType: 'json',
      data,
      cache,
      successCached(response) {
        if (response && ok) ok(response, true);
      },
      success(response) {
        if (response && ok) ok(response);
        if (end) end();
        setLoading(loading, false);
      },
      error(xhr, status) {
        if (error) error(xhr, status);
        if (end) end();
        setLoading(loading, false);
      },
      offline() {
        if (end) end();
        setLoading(loading, false);
      },
    });
  },
  post({ url = '', data = {}, base64FileProp, filesToUploadProp, loading, ok, error, start, end, sync, syncMessage } = {}) {
    if (!navigator.onLine && sync) {
      if (syncMessage) {
        setTimeout(() => {
          events.$emit('alert', syncMessage);
        }, 300);
      }
      Sync.add({
        url,
        data,
        base64FileProp,
        filesToUploadProp,
      });
      if (start) start();
      if (ok) ok();
      if (end) end();
      return;
    }
    if (start) start();
    setLoading(loading, true);
    function sendRequest() {
      const sendData = { ...data };
      delete sendData.filesToUpload;
      Request({
        url: `${config.host}/api${url[0] === '/' ? '' : '/'}${url}`,
        method: 'post',
        dataType: 'json',
        data: JSON.stringify(sendData),
        processData: false,
        contentType: 'application/json',
        successCached(response) {
          if (response && ok) ok(response, true);
        },
        success(response) {
          if (response && ok) ok(response);
          if (end) end();
          setLoading(loading, false);
        },
        error(xhr, status) {
          if (error) error(xhr, status);
          if (end) end();
          setLoading(loading, false);
        },
        offline() {
          if (end) end();
          setLoading(loading, false);
        },
      });
    }
    const needsFilesUpload =
      (base64FileProp && typeof data[base64FileProp] === 'string')
      || (data.filesToUpload && data.filesToUpload.length);

    if (!needsFilesUpload) {
      sendRequest();
      return;
    }
    if (base64FileProp) {
      const fileValue = data[base64FileProp];
      API.upload({
        data: { base64Files: fileValue },
        ok(fileRes) {
          data[base64FileProp] = Array.isArray(fileRes) ? fileRes[0] : fileRes;
          sendRequest();
        },
        error() {
          if (error) error();
        },
      });
    } else if (data.filesToUpload && filesToUploadProp) {
      const filesWithUrl = [...data.filesToUpload]
        .filter(f => f.url);
      const filesToUpload = data.filesToUpload
        .filter(f => !f.url)
        .map((f) => {
          if (f.bits) {
            return new File(f.bits, f.name, f.options);
          }
          return f;
        });

      if (!filesToUpload.length) {
        delete data.filesToUpload;
        if (!data[filesToUploadProp]) data[filesToUploadProp] = filesWithUrl;
        else data[filesToUploadProp] = [...data[filesToUploadProp], ...filesWithUrl];
        sendRequest();
        return;
      }
      API.upload({
        data: { multipartFiles: filesToUpload },
        ok(files) {
          data.filesToUpload = data.filesToUpload.map((fileToUpload) => {
            if (fileToUpload.url) return fileToUpload;
            if (fileToUpload.name) {
              const newFile = files.filter(f => f.originalFileName === fileToUpload.name && f.size === fileToUpload.size)[0];
              if (newFile) return newFile;
            }
            return fileToUpload;
          });
          if (!data[filesToUploadProp]) data[filesToUploadProp] = data.filesToUpload;
          else data[filesToUploadProp] = [...data[filesToUploadProp], ...data.filesToUpload];
          delete data.filesToUpload;
          sendRequest();
        },
        error() {
          if (error) error();
        },
      });
    }
  },
  upload({ data = {}, ok, error } = {}) {
    Request({
      url: `${config.host}/api/common/file/upload`,
      method: 'POST',
      data: toFormData(data),
      contentType: 'multipart/form-data',
      dataType: 'json',
      success(response) {
        if (ok && response && response.files) ok(response.files);
        else if (error) error(response);
      },
      error(xhr, status) {
        if (error) error(xhr, status);
      },
    });
  },
};

export default API;