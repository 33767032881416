/* eslint-disable */
import Request from '../modules/request';
import deviceId from '../utils/device-id';
import config from '../config';
import events from './events';

const Firebase = {
  app: null,
  messaging: null,
  init() {
    Firebase.app = window.firebase.initializeApp({
      apiKey: config.firebase.apiKey,
      authDomain: config.firebase.authDomain,
      databaseURL: config.firebase.databaseURL,
      projectId: config.firebase.projectId,
      storageBucket: config.firebase.storageBucket,
      messagingSenderId: config.firebase.messagingSenderId,
    });
    try {
      Firebase.messaging = window.firebase.messaging();
      Firebase.messaging.usePublicVapidKey(config.firebase.publicVapidKey);
    } catch (e) {
      // messaging not supported
    }
  },
  run() {
    let messaging = Firebase.messaging;
    if (!messaging) {
      try {
        messaging = window.firebase.messaging();
      } catch (e) {
        // messaging not supported
      }
    }
    if (!messaging) return;

    function requestPermission(success) {
      messaging.requestPermission().then(() => {
        console.log('Notification permission granted.');
        messaging.getToken().then((currentToken) => {
          console.log(currentToken);
          if (currentToken) {
            if (success) success(currentToken);
          } else {
            console.log('No Instance ID token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
      }).catch((err) => {
        console.log('Unable to get permission to notify.', err);
      });
    }

    messaging.getToken().then((currentToken) => {
      if (!currentToken) {
        // show banner
        events.$emit('requestNotificationsBanner');
        events.$on('notificationsBannerEnable', () => {
          requestPermission((token) => {
            Request.postJSON(`${config.host}/api/common/notification/token`, {
              token,
              platform: 'browser',
              deviceId,
            }, (res) => {
              //console.log(res);
            });
          });
        });
        // request permission and send token
        return;
      }
      // send token to server
      Request.postJSON(`${config.host}/api/common/notification/token`, {
        token: currentToken,
        platform: 'browser',
        deviceId,
      }, (res) => {
        //console.log(res);
      });
    }).catch((err) => {
      console.log(err);
    });


    messaging.onMessage((payload) => {
      console.log('Foreground payload', payload);
      events.$emit('pushForeground', payload.data);
    });
  }
};

export default Firebase;
