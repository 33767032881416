<template>
  <f7-page @page:afterin="onPageAfterIn">
    <f7-navbar large :title="$t('admin.users_title')">
      <f7-link slot="nav-left" class="navbar-left-panel-opener" panel-open="left" icon-ios="f7:menu" icon-md="material:menu"></f7-link>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <div class="data-table card card-outline">
      <div class="card-header no-padding-right">
        <div class="data-table-title">
          {{$t('admin.users_table_title')}}
          <div class="data-table-header-dropdown">
            <div class="input input-dropdown">
              <select :value="enterpriseFilter" @change="setEnterpriseFilter">
                <option value="all">{{$t('admin.enterprise_filter_all')}}</option>
                <option value="true">{{$t('admin.enterprise_filter_only_enterprise')}}</option>
                <option value="false">{{$t('admin.enterprise_filter_exclude_enterprise')}}</option>
              </select>
            </div>
          </div>
        </div>
        <a-table-pagination
          v-if="users && total > perPage"
          :total="total"
          :page="page"
          :per-page="perPage"
          @prevPage="prevPage"
          @nextPage="nextPage"
        />
      </div>
      <div class="card-content">
        <a-user-list-table
          v-if="showUsers && users"
          :users="users"
          :sort-by="sortBy"
          :sort-order="sortOrder"
          :filters="filters"
          @sortChange="onSortChange"
          @userLogin="onUserLogin"
          @filter="onFilter"
        />
      </div>
      <a-table-pagination
        v-if="users && total > perPage"
        :total="total"
        :page="page"
        :per-page="perPage"
        @prevPage="prevPage"
        @nextPage="nextPage"
      />
    </div>
  </f7-page>
</template>
<script>
  import API from '../../modules/api';
  import events from '../../modules/events';

  let sortBy = 'sortId';
  let sortOrder = 'desc';
  let enterpriseFilter = 'all';
  let scrollTimeout;
  let filterTimeout;

  export default {
    data() {
      return {
        showUsers: false,
        users: null,
        loading: false,
        sortBy,
        sortOrder,
        enterpriseFilter,
        total: null,
        page: 0,
        perPage: 30,
        filters: {},
      };
    },
    created() {
      const self = this;
      self.getUsersList();
      events.$on('store:admin:getUsersList', self.getUsersList);
    },
    beforeDestroy() {
      const self = this;
      events.$off('store:admin:getUsersList', self.getUsersList);
    },
    methods: {
      getUsersList({ by, order, enterprise, page } = {}) {
        const self = this;
        clearTimeout(scrollTimeout);
        if (by) {
          sortBy = by;
          sortOrder = order;
          Object.assign(self, { sortBy, sortOrder });
        }
        if (enterprise) {
          enterpriseFilter = enterprise;
          Object.assign(self, { enterpriseFilter });
        }

        let needScroll;
        if (typeof page !== 'undefined') {
          self.page = page;
          scrollTimeout = setTimeout(() => {
            needScroll = true;
          }, 300);
        }

        let query = `${sortBy}=${sortOrder}&skip=${self.perPage * self.page}&take=${self.perPage}`;
        if (enterpriseFilter !== 'all') {
          query += `&enterprise=${enterpriseFilter}`;
        }
        Object.keys(self.filters).forEach((key) => {
          if (!self.filters[key]) return;
          query += `&${key}=${self.filters[key]}`;
        });

        API.get({
          url: `admin/user/list?${query}`,
          loading: [self, 'loading'],
          ok(response) {
            self.users = response.items;
            self.total = response.total;
            if (needScroll) {
              self.$$(self.$el).find('.page-content').scrollTop(0, 500);
            }
          },
        });
      },
      prevPage() {
        const self = this;
        self.getUsersList({ page: self.page - 1 });
      },
      nextPage() {
        const self = this;
        self.getUsersList({ page: self.page + 1 });
      },
      setEnterpriseFilter(e) {
        const self = this;
        self.getUsersList({ enterprise: e.target.value, page: 0 });
      },
      onSortChange(by, order) {
        const self = this;
        self.getUsersList({ by, order, page: 0 });
      },
      onFilter(field, value) {
        const self = this;
        self.filters[field] = value;
        clearTimeout(filterTimeout);
        filterTimeout = setTimeout(() => {
          self.getUsersList();
        }, 500);
      },
      onPageAfterIn() {
        const self = this;
        setTimeout(() => {
          self.showUsers = true;
        }, 300);
      },
      onUserLogin(user) {
        const self = this;
        function login(role) {
          API.post({
            url: '/admin/user/login',
            data: {
              id: user.id,
              role,
            },
            ok(res) {
              if (!res) return;
              if (res && res.roleOptions) {
                // eslint-disable-next-line
                const buttons = res.roleOptions.map((roleId) => {
                  return {
                    text: self.$t(`roles.${['Finder', 'Company', 'Admin'][roleId]}`),
                    onClick() {
                      login(roleId);
                    },
                  };
                });
                buttons.push({
                  text: self.$t('general.cancel'),
                  color: 'brand-red',
                });
                self.$f7.dialog.create({
                  text: self.$t('auth.login_as'),
                  buttons,
                  verticalButtons: true,
                }).open();
              } else {
                self.$root.changeUser(res);
              }
            },
          });
        }
        login();
      },
    },
  };
</script>
