<template>
  <f7-page>
    <f7-navbar :title="$t('company_details.title')" :large="!isPopup">
      <f7-link back prevent-router v-if="!isPopup" slot="nav-left" @click="confirmBack" icon="icon-back" :text="$t('general.back')"></f7-link>
      <f7-link v-if="isPopup" slot="nav-right" icon-ios="f7:close" icon-md="material:close" @click="confirmBack"></f7-link>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <company-details-form ref="form" v-if="details" :details="details" :disabled="disabled" :first-edit="isFirstEdit" :show-settings="!isPopup" @formChanged="onFormChanged">
      <f7-list v-if="!disabled">
        <f7-list-button @click="save">{{$t('general.save')}}</f7-list-button>
      </f7-list>
    </company-details-form>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';

  export default {
    created() {
      const self = this;
      self.$store.dispatch('c/getDetails');
    },
    computed: {
      isPopup() {
        return !!this.$f7route.query.popup;
      },
      disabled() {
        const self = this;
        const { details, isFirstEdit } = self;
        if (isFirstEdit) return false;
        return details.locked;
      },
      isFirstEdit() {
        return !!this.$f7route.query.firstedit;
      },
      form() {
        return this.$refs.form;
      },
      ...mapGetters({
        saving: 'c/detailsSaving',
        loading: 'c/detailsLoading',
        details: 'c/details',
      }),
    },
    methods: {
      onFormChanged() {
        const self = this;
        self.formChanged = true;
      },
      confirmBack() {
        const self = this;
        function back() {
          if (self.isPopup) {
            self.$f7.popup.close();
          } else {
            self.$f7router.back();
          }
        }
        if (self.formChanged) {
          self.$root.confirmDialog(self.$t('form.back_confirm'), () => {
            back();
          });
        } else {
          back();
        }
      },
      save() {
        const self = this;

        const valid = self.form.validate();
        if (!valid) return;

        const details = self.details;

        const newDetails = {
          id: details.id,
          ...self.form.getData(),
        };

        function save() {
          self.$store.dispatch('c/saveDetails', {
            data: newDetails,
            callbackOk() {
              if (self.$f7route.query.updateAssignmentCreateData) {
                self.$store.dispatch('c/getAssignmentCreateData');
              }
              if (self.$f7route.query.updateAssignmentEditData && self.$f7route.query.id) {
                self.$store.dispatch('c/getAssignmentEditData', { id: parseInt(self.$f7route.query.id, 10) });
              }
              if (self.isPopup) {
                self.$root.closePopup();
              } else {
                self.$f7router.back();
              }
            },
          });
        }

        if (!newDetails.logo && self.$root.userRole === 'company') {
          self.$root.confirmDialog(self.$t('company_details.no_logo_confirm'), () => {
            save();
          });
        } else {
          save();
        }
      },
      logout() {
        const self = this;
        self.$root.logout();
      },
    },
  };
</script>
