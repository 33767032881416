<template>
  <div class="center-content">
    <f7-list no-hairlines>
      <f7-list-input
        label="Status"
        :class="{ disabled: formDisabled }"
        type="select"
        :value="status"
        @change="status = parseInt($event.target.value, 10)"
      >
        <option
          v-for="(statusId, statusKey) in statusList"
          :value="statusId"
          :key="statusId"
        >{{$t(`candidate_status.${statusKey}`)}}</option>
      </f7-list-input>
      <f7-list-input
        :label="$t('form.first_name')"
        required validate type="text" :value="firstName" @input="firstName = $event.target.value"
      ></f7-list-input>
      <f7-list-input
        :label="$t('form.last_name')"
        required validate type="text" :value="lastName" @input="lastName = $event.target.value"
      ></f7-list-input>
      <f7-list-input
        :label="$t('form.email')"
        required v-validate type="email" :value="email" @input="email = $event.target.value"
      ></f7-list-input>
      <f7-list-input
        :label="$t('form.phone')"
        required validate type="tel" :value="phone" @input="phone = $event.target.value"
      ></f7-list-input>
      <f7-list-input
        :label="$t('form.linkedin')"
        type="url" :value="linkedIn" @input="linkedIn = $event.target.value"></f7-input>
      </f7-list-input>
      <f7-list-input
        label="Message"
        type="textarea" resizable placeholder="Additional comments" validate required :value="message" @input="message = $event.target.value"
      ></f7-list-input>
      <f7-list-item>
        <attachments
          ref="attachments"
          :files="attachments"
          @deleteFile="deleteFile"
        ></attachments>
      </f7-list-item>
      <f7-list-input
        label="Notes"
        type="textarea"
        resizable
        placeholder="Additional comments"
        :value="notes"
        @input="notes = $event.target.value"
      ></f7-list-input>
    </f7-list>
    <slot></slot>
  </div>
</template>
<script>
  import candidateStatusList from '../../utils/candidate-status-list';
  import extend from '../../utils/extend';

  export default {
    props: {
      candidate: Object,
    },
    data() {
      const candidate = this.candidate || {};
      return extend({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        linkedIn: '',
        message: '',
        notes: '',
        status: 0,
        attachments: [],
        statusList: candidateStatusList,
      }, candidate);
    },
    watch: {
      application() {
        const self = this;
        self.$nextTick(() => {
          self.$f7.input.resizeTextarea(self.$el.querySelector('textarea'));
        });
      },
    },
    mounted() {
      const self = this;
      self.$$(self.$el).on('input change', () => {
        self.$emit('formChanged');
      });
    },
    methods: {
      deleteFile(file) {
        const self = this;
        if (self.files.indexOf(file) >= 0) {
          self.files.splice(self.files.indexOf(file), 1);
        }
      },
      validate() {
        const self = this;
        self.$f7.input.validateInputs(self.$el);
        if (self.$el.querySelectorAll('.item-input-invalid').length > 0) {
          self.$f7.input.scrollIntoView(self.$el.querySelectorAll('.item-input-invalid')[0], 300);
          return false;
        }
        return true;
      },
      getData() {
        const self = this;
        const filesToUpload = self.$refs.attachments.filesToUpload;
        const {
          firstName, lastName, email, phone, photo, message, attachments, status, linkedIn, notes,
        } = self;
        return {
          firstName, lastName, email, phone, photo, message, attachments, status, filesToUpload, linkedIn, notes,
        };
      },
    },
  };
</script>
