import currencyMap from './currency-map';
import formatNumber from './format-number';

export default function (self, assignment) {
  const { rewards = [], multipleLevels, shareOut, potentialReward, potentialEarnings } = assignment;
  const currency = rewards.length ? rewards[0].currency : 0;
  let amountMin = 0;
  let amountMax = potentialReward || potentialEarnings || 0;
  const amountMaxWasEmpty = amountMax === 0;
  rewards.forEach((reward, index) => {
    if (!multipleLevels && index > 0) return;
    const { type, percentage, expectedTo, expectedFrom } = reward;
    let { fixed } = reward;
    if (Number.isNaN(fixed)) fixed = 0;
    const rewardPart = self.$root.userRole === 'company' || self.$root.userRole === 'admin' ? 100 : shareOut;
    if (type === 0) {
      if (amountMaxWasEmpty) amountMax += fixed * rewardPart / 100;
      if (index === 0) amountMin += fixed * rewardPart / 100;
    } else {
      if (amountMaxWasEmpty) amountMax += expectedTo * (percentage * rewardPart / 10000);
      if (index === 0) amountMin += expectedFrom * (percentage * rewardPart / 10000);
    }
  });

  const currencyString = currencyMap(currency);
  let text = '';
  if (Number.isNaN(amountMin)) amountMin = 0;
  if (Number.isNaN(amountMax)) amountMax = 0;
  if (amountMax < amountMin) amountMax = amountMin;
  if (amountMin === amountMax) {
    text = `${formatNumber(amountMax)} ${currencyString}`;
  } else {
    text = self.$t('assignment.reward_amount_range', {
      min: formatNumber(amountMin),
      max: formatNumber(amountMax),
      currency: currencyString,
    });
  }

  return text;
}
