/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';
import Push from '../../modules/push';
import PWA from '../../modules/pwa';

const state = {
  list: null,
  listLoading: false,
  listMoreLoading: false,
  listSkip: 0,
  listTake: 20,
  myList: null,
  myListSkip: 0,
  myListTake: 20,
  myListLoading: false,
  myListMoreLoading: false,
  dashboardData: null,
  assignment: null,
  assignmentLoading: false,
  myAssignment: null,
  myAssignmentLoading: false,
  createData: null,
  createDataLoading: false,
  editData: null,
  editDataLoading: false,
  saving: false,
  assignmentPreview: null,
  listCategory: null,
  listHasMore: true,
  myListHasMore: true,
};

const getters = {
  assignmentPreview(state) {
    return state.assignmentPreview;
  },
  assignmentList(state) {
    return state.list;
  },
  assignmentListLoading(state) {
    return state.listLoading;
  },
  assignmentListMoreLoading(state) {
    return state.listMoreLoading;
  },
  myAssignmentList(state) {
    return state.myList;
  },
  myAssignmentListLoading(state) {
    return state.myListLoading;
  },
  myAssignmentListMoreLoading(state) {
    return state.myListMoreLoading;
  },
  dashboardData(state) {
    return state.dashboardData;
  },
  assignment(state) {
    return state.assignment;
  },
  assignmentLoading(state) {
    return state.assignmentLoading;
  },
  myAssignment(state) {
    return state.myAssignment;
  },
  myAssignmentLoading(state) {
    return state.myAssignmentLoading;
  },
  assignmentSaving(state) {
    return state.saving;
  },
  assignmentCreateDataLoading(state) {
    return state.createDataLoading;
  },
  assignmentCreateData(state) {
    return state.createData;
  },
  assignmentEditDataLoading(state) {
    return state.editDataLoading;
  },
  assignmentEditData(state) {
    return state.editData;
  },
  assignmentListCategory(state) {
    return state.listCategory;
  },
  assignmentListHasMore(state) {
    return state.listHasMore;
  },
  myAssignmentListHasMore(state) {
    return state.myListHasMore;
  },
};
const actions = {
  setAssignmentPreview({ commit }, assignment) {
    commit('setAssignmentPreview', assignment);
  },
  getAssignmentList({ commit, state }, { cache = true } = {}) {
    commit('setListSkip', 0);
    commit('setListHasMore', true);
    API.get({
      url: `company/crowd/assignmentlist?category=${state.listCategory}&skip=${state.listSkip}&take=${state.listTake}`,
      loading: 'c/setAssignmentListLoading',
      cache,
      ok(response) {
        commit('setAssignmentList', response.items);
      },
    });
  },
  updateAssignmentList({ dispatch }) {
    dispatch('getAssignmentList', { cache: false });
  },
  getMoreAssignmentList({ commit, state }) {
    commit('setListSkip', state.listSkip + state.listTake);
    API.get({
      url: `company/crowd/assignmentlist?category=${state.listCategory}&skip=${state.listSkip}&take=${state.listTake}`,
      loading: 'c/setAssignmentListLoading c/setAssignmentListMoreLoading',
      cache: false,
      ok(response) {
        if (!response || (response.items && response.items.length < 20)) {
          commit('setListHasMore', false);
          if (!response.items.length) return;
        }
        commit('addToAssignmentList', response.items);
      },
    });
  },
  unsetAssignmentList({ commit }) {
    commit('unsetAssignmentList');
  },
  getMyAssignmentList({ commit, state }, { callbackOk, callbackError, cache = true, setHasMore = true } = {}) {
    commit('setMyListSkip', 0);
    if (setHasMore) {
      commit('setMyListHasMore', true);
    }
    API.get({
      url: `company/assignment/ourlist?skip=${state.myListSkip}&take=${state.myListTake}`,
      loading: 'c/setMyAssignmentListLoading',
      cache,
      ok(response) {
        const { rating, unreadCount, items } = response;
        commit('setDashboardData', { rating, unreadCount });
        commit('setMyAssignmentList', items);
        if (callbackOk) callbackOk(items);
      },
      error: callbackError,
    });
  },
  updateMyAssignmentList({ dispatch }, { callbackOk } = {}) {
    dispatch('getMyAssignmentList', { callbackOk, cache: false, setHasMore: false });
  },
  getMoreMyAssignmentList({ commit, state }) {
    commit('setMyListSkip', state.myListSkip + state.myListTake);
    API.get({
      url: `company/assignment/ourlist?skip=${state.myListSkip}&take=${state.myListTake}`,
      loading: 'c/setMyAssignmentListLoading c/setMyAssignmentListMoreLoading',
      cache: false,
      ok(response) {
        if (!response || (response.items && response.items.length < 20)) {
          commit('setMyListHasMore', false);
          if (!response.items.length) return;
        }
        const { items } = response;
        commit('addToMyAssignmentList', items);
      },
    });
  },
  unsetMyAssignmentList({ commit }) {
    commit('unsetMyAssignmentList');
  },
  getAssignmentCreateData({ commit }, { crowdId }) {
    API.get({
      url: 'company/assignment/create',
      loading: 'c/setAssignmentCreateDataLoading',
      data: {
        crowdId,
      },
      ok(response) {
        commit('setAssignmentCreateData', response);
      },
    });
  },
  unsetAssignmentCreateData({ commit }) {
    commit('unsetAssignmentCreateData');
  },
  getAssignmentEditData({ commit }, { id } = {}) {
    API.get({
      url: `company/assignment/edit?id=${id}`,
      loading: 'c/setAssignmentEditDataLoading',
      ok(response) {
        commit('setAssignmentEditData', response);
      },
    });
  },
  unsetAssignmentEditData({ commit }) {
    commit('unsetAssignmentEditData');
  },
  getAssignment({ commit }, { id }) {
    if (state.assignment) return;
    API.get({
      url: `company/assignment/details?id=${id}`,
      loading: 'c/setAssignmentLoading',
      ok(response) {
        commit('setAssignment', response);
      },
    });
  },
  updateMyAssignment({ commit, state }, { id, callbackOk } = {}) {
    if (!id) {
      if (!state.assignment) return;
      id = state.assignment.id;
    }
    API.get({
      url: `company/assignment/ourdetails?id=${id}`,
      loading: 'c/setMyAssignmentLoading',
      cache: false,
      ok(response) {
        commit('setMyAssignment', response);
        if (callbackOk) callbackOk(response);
      },
    });
  },
  unsetAssignment({ commit }) {
    commit('unsetAssignment');
  },
  getMyAssignment({ commit }, { id, callbackOk, callbackError } = {}) {
    API.get({
      url: `company/assignment/ourdetails?id=${id}`,
      loading: 'c/setMyAssignmentLoading',
      cache: false,
      ok(response) {
        commit('setMyAssignment', response);
        if (callbackOk) callbackOk(callbackOk);
      },
      error: callbackError,
    });
  },
  unsetMyAssignment({ commit }) {
    commit('unsetMyAssignment');
  },
  saveAssignment({ dispatch, commit }, { assignment, callbackOk, callbackError } = {}) {
    API.post({
      url: 'company/assignment/save',
      loading: 'c/setAssignmentSaving',
      data: assignment,
      filesToUploadProp: 'files',
      ok() {
        if (assignment && assignment.id) {
          if (state.myAssignment && state.myAssignment.id === assignment.id) {
            const newAssignment = {
              ...state.myAssignment,
              ...assignment,
            };
            PWA.updateCachedApi(`company/assignment/edit?id=${assignment.id}`, newAssignment);
            commit('setMyAssignment', newAssignment);
          }
        } else {
          dispatch('getAssignmentList');
          dispatch('getMyAssignmentList');
        }

        if (callbackOk) callbackOk();
      },
      error: callbackError,
    });
  },
  deleteAssignment({ dispatch }, { id, callbackOk, callbackError } = {}) {
    API.post({
      url: 'company/assignment/delete',
      loading: 'c/setAssignmentSaving',
      data: { id },
      ok() {
        dispatch('getAssignmentList');
        dispatch('getMyAssignmentList');

        if (callbackOk) callbackOk();
      },
      error: callbackError,
    });
  },
  changeAssignmentStatus({ dispatch }, { id, status, callbackOk, callbackError } = {}) {
    API.post({
      url: 'company/assignment/status',
      loading: 'c/setAssignmentSaving',
      data: { id, status },
      ok() {
        dispatch('getAssignmentList');
        dispatch('getMyAssignmentList');

        dispatch('updateMyAssignment', { id });

        if (callbackOk) callbackOk();
      },
      error: callbackError,
    });
  },
  setAssignmentListCategory({ commit, dispatch }, { category }) {
    commit('setListCategory', category === null ? null : parseInt(category, 10));
    dispatch('updateAssignmentList');
  },
};

const mutations = {
  setAssignmentPreview(state, assignment) {
    state.assignmentPreview = assignment;
  },
  setAssignmentList(state, assignments) {
    state.list = assignments;
  },
  addToAssignmentList(state, assignments) {
    state.list.push(...assignments);
  },
  unsetAssignmentList(state) {
    state.listSkip = 0;
    state.list = null;
  },
  setListSkip(state, val) {
    state.listSkip = val;
  },
  setMyAssignmentList(state, assignments) {
    state.myList = assignments;
  },
  addToMyAssignmentList(state, assignments) {
    state.myList.push(...assignments);
  },
  unsetMyAssignmentList(state) {
    state.myListSkip = 0;
    state.myList = null;
  },
  setMyListSkip(state, val) {
    state.myListSkip = val;
  },
  setDashboardData(state, dashboardData) {
    state.dashboardData = dashboardData;
  },
  setAssignment(state, item) {
    state.assignment = item;
  },
  setAssignmentLoading(state, status) {
    state.assignmentLoading = status;
  },
  unsetAssignment(state) {
    state.assignment = null;
  },
  setMyAssignment(state, item) {
    state.myAssignment = item;
  },
  setMyAssignmentLoading(state, status) {
    state.myAssignmentLoading = status;
  },
  unsetMyAssignment(state) {
    state.myAssignment = null;
  },
  setAssignmentListLoading(state, status) {
    state.listLoading = status;
  },
  setAssignmentListMoreLoading(state, status) {
    state.listMoreLoading = status;
  },
  setMyAssignmentListLoading(state, status) {
    state.myListLoading = status;
  },
  setMyAssignmentListMoreLoading(state, status) {
    state.myListMoreLoading = status;
  },
  setAssignmentSaving(state, status) {
    state.saving = status;
  },
  setAssignmentCreateDataLoading(state, status) {
    state.createDataLoading = status;
  },
  setAssignmentCreateData(state, createData) {
    state.createData = createData;
  },
  unsetAssignmentCreateData(state) {
    state.createData = null;
  },
  setAssignmentEditDataLoading(state, status) {
    state.editDataLoading = status;
  },
  setAssignmentEditData(state, editData) {
    state.editData = editData;
  },
  unsetAssignmentEditData(state) {
    state.editData = null;
  },
  setListCategory(state, data) {
    state.listCategory = data;
  },
  setListHasMore(state, value) {
    state.listHasMore = value;
  },
  setMyListHasMore(state, value) {
    state.myListHasMore = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
