<template>
  <f7-page @page:beforeremove="onPageBeforeRemove">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon="icon-back" :text="$t('general.back')" back></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{$t('admin.candidate_edit_title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <candidate-form ref="form" :candidate="candidate" v-if="candidate">
      <f7-list>
        <loading-list-button
          color="brand-orange"
          :text="$t('general.save')"
          @click="save"
          :loading="saving"
        ></loading-list-button>
      </f7-list>
    </candidate-form>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import events from '../../modules/events';

  export default {
    props: {
      id: [String, Number],
    },
    created() {
      const self = this;
      self.$store.dispatch('a/getCandidate', { id: parseInt(self.id, 10) });
    },
    computed: {
      form() {
        return this.$refs.form;
      },
      ...mapGetters({
        saving: 'a/candidateSaving',
        loading: 'a/candidateLoading',
        candidate: 'a/candidate',
      }),
    },
    methods: {
      save() {
        const self = this;

        const valid = self.form.validate();
        if (!valid) return;

        const candidate = self.candidate;

        const newCandidate = {
          id: candidate.id,
          ...self.form.getData(),
        };

        self.$store.dispatch('a/saveCandidate', {
          candidate: newCandidate,
          callbackOk() {
            events.$emit('store:admin:getCandidatesList');
            self.$f7router.back();
          },
        });
      },
      onPageBeforeRemove() {
        const self = this;
        self.$store.dispatch('a/unsetCandidate');
      },
    },
  };
</script>
