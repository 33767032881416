<script>
  import config from '../../config';

  export default {
    props: {
      avatar: [String, Object],
      file: [String, Object],
      useImg: Boolean,
      size: {
        type: Number,
        required: true,
      },
      placeholder: {
        type: Boolean,
        default: true,
      },
    },
    render(c) {
      const self = this;
      if (self.useImg) {
        return c('img', {
          class: 'avatar',
          attrs: {
            src: self.avatarSrc,
          },
        });
      }

      return c('div', {
        class: 'avatar',
        attrs: {
          style: `background-image: ${self.avatarUrl}`,
        },
      }, self.$slots.default);
    },
    computed: {
      avatarUrl() {
        const src = this.avatarSrc;
        if (!src) return 'none';
        return `url(${src})`;
      },
      avatarSrc() {
        const avatar = this.avatar || this.file;
        if (typeof avatar === 'string' && avatar.indexOf('://') >= 0) {
          return avatar;
        }
        if (avatar && avatar.downloadFileName || typeof avatar === 'string') {
          const url = avatar.downloadFileName || avatar;
          if (url.indexOf('http') === 0) {
            if (url.indexOf('graph.facebook.com') >= 0) {
              const size = Math.ceil((this.size || 0) * this.$device.pixelRatio);
              return `${url.split('?')[0]}?width=${size}&height=${size}`;
            }
            return url;
          }
          return `${config.path.images}${url}?fit=crop&w=${this.size}&h=${this.size}&dpr=${this.$device.pixelRatio}`;
        }
        if (this.placeholder) return `${this.$device.cordova ? '' : '/'}i/avatar-placeholder.svg`;
        return '';
      },
    },
  };
</script>
