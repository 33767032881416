/* eslint no-param-reassign: "off" */
/* eslint consistent-return: "off" */
import { Request, Utils } from 'framework7';
import PWA from '../modules/pwa';
import events from './events';

function RequestProxy(params) {
  if (!window.cordova) {
    if (params.cache && params.url && (params.method === 'get' || params.method === 'GET')) {
      let url = params.url;
      if (params.data && Object.keys(params.data).length) {
        url += `${url.indexOf('?') >= 0 ? '&' : '?'}${Utils.serializeObject(params.data)}`;
      }
      PWA.getCachedApi(url, (response) => {
        if (response && params.successCached) {
          params.successCached(response);
        }
      });
    }
  }

  if (!navigator.onLine) {
    if (params.method === 'post' || params.method === 'POST') {
      events.$emit('showOfflineError');
    }
    if (params.offline) params.offline();
    return;
  }
  delete params.cache;
  return Request(params);
}
RequestProxy.get = Request.get;
RequestProxy.post = Request.post;
RequestProxy.postJSON = Request.postJSON;
RequestProxy.json = Request.json;
RequestProxy.setup = Request.setup;

export default RequestProxy;
