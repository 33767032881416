/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../modules/api';

const state = {
  application: null,
  applicationLoading: false,
  applicationSaving: false,
  recommendation: null,
  recommendationLoading: false,
  recommendationDeclining: false,
  recommendationInteresting: false,
};

const getters = {
  application(state) {
    return state.application;
  },
  applicationLoading(state) {
    return state.applicationLoading;
  },
  applicationSaving(state) {
    return state.applicationSaving;
  },
  recommendation(state) {
    return state.recommendation;
  },
  recommendationLoading(state) {
    return state.recommendationLoading;
  },
  recommendationDeclining(state) {
    return state.recommendationDeclining;
  },
  recommendationInteresting(state) {
    return state.recommendationInteresting;
  },
};
const actions = {
  unsetRecommendation({ commit }) {
    commit('unsetRecommendation');
  },
  getRecommendation({ commit }, { id, language, cache = false, callbackOk, callbackError } = {}) {
    API.get({
      url: `candidate/recommendation/details?guid=${id}&language=${language}`,
      loading: 'application/setRecommendationLoading',
      cache,
      ok(response) {
        commit('setRecommendation', response);
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  updateRecommendation({ commit }, { id, language, callbackOk, callbackError } = {}) {
    API.get({
      url: `candidate/recommendation/details?guid=${id}&language=${language}`,
      cache: false,
      loading: 'application/setRecommendationLoading',
      ok(response) {
        commit('setRecommendationPartial', response);
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  declineRecommendation({ dispatch }, { guid, language, recommendation, email, callbackOk, callbackError } = {}) {
    API.post({
      url: 'candidate/recommendation/decline',
      loading: 'application/setRecommendationDeclining',
      data: {
        guid,
        email,
        recommendation,
      },
      ok(response) {
        dispatch('getRecommendation', { id: guid, language });
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  interestRecommendation({ dispatch }, { guid, email, status, language, recommendation, callbackOk, callbackError } = {}) {
    API.post({
      url: 'candidate/recommendation/interest',
      loading: 'application/setRecommendationInteresting',
      data: {
        guid,
        email,
        status,
        recommendation,
      },
      ok(response) {
        // dispatch('getRecommendation', { id: guid, language });
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  getApplication({ commit }, { id, language, cache = false, callbackOk, callbackError } = {}) {
    API.get({
      url: `candidate/application/details?applicationGuid=${id}&language=${language}`,
      loading: 'application/setApplicationLoading',
      cache,
      ok(response) {
        commit('setApplication', response);
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  updateApplication({ commit }, { id, language, callbackOk, callbackError } = {}) {
    API.get({
      url: `candidate/application/details?applicationGuid=${id}&language=${language}`,
      cache: false,
      loading: 'application/setApplicationLoading',
      ok(response) {
        commit('setApplicationPartial', response);
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  saveApplication({ dispatch }, { item, callbackOk, callbackError, callbackGet } = {}) {
    API.post({
      url: 'candidate/application/save',
      loading: 'application/setApplicationSaving',
      filesToUploadProp: 'attachments',
      data: item,
      ok(response) {
        if (item.applicationGuid) {
          dispatch('getApplication', { id: item.applicationGuid, language: item.language, callbackOk: callbackGet });
        }
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
};

const mutations = {
  setApplication(state, item) {
    state.application = item;
  },
  setApplicationPartial(state, item) {
    state.application.status = item.status;
    state.application.assignmentStatus = item.assignmentStatus;
    state.application.unreadCountCompany = item.unreadCountCompany;
    state.application.unreadCountFinder = item.unreadCountFinder;
    state.application.companyHasStartedConversation = item.companyHasStartedConversation;
  },
  setApplicationLoading(state, status) {
    state.applicationLoading = status;
  },
  setApplicationSaving(state, status) {
    state.applicationSaving = status;
  },

  setRecommendation(state, item) {
    state.recommendation = item;
  },
  unsetRecommendation(state) {
    state.recommendation = null;
  },
  setRecommendationPartial(state, item) {
    state.recommendation.status = item.status;
    state.recommendation.assignmentStatus = item.assignmentStatus;
    state.recommendation.unreadCountCompany = item.unreadCountCompany;
    state.recommendation.unreadCountFinder = item.unreadCountFinder;
    state.recommendation.companyHasStartedConversation = item.companyHasStartedConversation;
  },
  setRecommendationLoading(state, status) {
    state.recommendationLoading = status;
  },
  setRecommendationSaving(state, status) {
    state.recommendationSaving = status;
  },
  setRecommendationDeclining(state, status) {
    state.recommendationDeclining = status;
  },
  setRecommendationInteresting(state, status) {
    state.recommendationInteresting = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
