<template>
  <f7-list-button :color="color" :class="{ loading, disabled }" @click="onClick">
    <slot><span>{{text}}</span></slot>
    <f7-preloader v-if="preloader" :color="color"></f7-preloader>
  </f7-list-button>
</template>
<script>
  export default {
    props: {
      color: String,
      loading: Boolean,
      disabled: Boolean,
      preloader: {
        type: Boolean,
        default: true,
      },
      text: String,
    },
    methods: {
      onClick(e) {
        this.$emit('click', e);
      },
    },
  };
</script>
