export default function (data) {
  const formData = new window.FormData();
  Object.keys(data).forEach((key) => {
    if (key === 'multipartFiles' && data.multipartFiles.length > 0) {
      const multipartFiles = data.multipartFiles;
      for (let i = 0; i < multipartFiles.length; i += 1) {
        formData.append('multipartFiles', multipartFiles[i], multipartFiles[i].name);
      }
    } else if (data[key] && typeof data[key] === 'object' && data[key].constructor && data[key].constructor === Object) {
      formData.append(key, JSON.stringify(data[key]));
    } else if (Array.isArray(data[key])) {
      formData.append(key, JSON.stringify(data[key]));
    } else {
      formData.append(key, data[key]);
    }
  });

  return formData;
}

