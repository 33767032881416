<template>
  <div class="assignment-list skeleton-text">
    <a
      v-for="n in amount"
      :key="n"
      href="#"
      class="assignment-list-item"
    >
      <div class="item-media skeleton-effect-fade">
        <logo class="company-logo" :size="60"></logo>
      </div>
      <div class="item-content skeleton-effect-fade">
        <div class="item-title">
          <span>____ _____ ____</span>
        </div>
        <div class="item-meta">
          <div class="item-date">__ ___ ____</div>
          <div class="item-category">__________</div>
        </div>
        <div class="item-text">_____________________________________________</div>
        <div class="item-footer">______ __ ___</div>
      </div>
    </a>
  </div>
</template>
<script>
  export default {
    props: {
      amount: {
        type: Number,
        default: 10,
      },
    },
  };
</script>

