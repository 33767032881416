<template>
  <f7-block strong class="attachments" v-if="wrap" ref="el">
    <div class="attachments-title-button">
      <div class="attachments-title" v-if="showTitle">{{$t('attachments.title')}}{{afterTitle ? ` ${afterTitle}` : ''}}</div>
      <template v-if="allowEdit && !disabled">
        <a slot="after" href="#" class="attachments-button button button-small button-round button-outline display-inline-block popover-open" data-popover=".attachments-popover">
          {{$t('attachments.add_files_button')}}
        </a>
        <f7-popover class="attachments-popover">
          <f7-list>
            <li>
              <label class="list-button popover-close" style="cursor: pointer">
                <input type="file" multiple @change="onFilesChange">
                {{$t('attachments.upload_button')}}
              </label>
            </li>
            <f7-list-button popover-close :text="$t('attachments.link_button')" @click="openLinkDialog" />
          </f7-list>
        </f7-popover>
      </template>
    </div>
    <div class="attachments-files" v-if="allFiles && allFiles.length">
      <div
        class="attachments-file"
        :class="{
          'attachments-image': isImage(file) || isVideoFrame(file),
          'photobrowser-link': isImage(file) || isVideoFrame(file),
          'video-preview': isVideoFrame(file),
          'attachments-document': !(isImage(file) || isVideoFrame(file) || isVideo(file)),
        }"
        v-for="(file, index) in allFiles"
        :key="index"
        :style="{backgroundImage: bgImage(file)}"
        :data-index="isImage(file) || isVideoFrame(file) ? mediaFiles.indexOf(file) : undefined"
        :data-file-extension="fileExtension(file)"
        @click="onFileClick($event, file)"
      >
        <f7-icon f7="videocam_round_fill" v-if="!isVideoFrame(file) && isVideo(file)"></f7-icon>
        <template v-if="allowEdit">
          <span v-if="inMessagebar" class="messagebar-attachment-delete" @click="onDeleteClick(file)"></span>
          <a v-else class="attachment-delete" @click="onDeleteClick(file)">
            <f7-icon ios="f7:close" md="material:close"></f7-icon>
          </a>
        </template>
        <span class="attachment-filename" v-if="fileName(file)">{{fileName(file)}}</span>
      </div>
    </div>
  </f7-block>
  <div v-else-if="allFiles && allFiles.length" class="attachments-files" ref="el">
    <div
      class="attachments-file"
      :class="{
        'attachments-image': isImage(file) || isVideoFrame(file),
        'photobrowser-link': isImage(file) || isVideoFrame(file),
        'video-preview': isVideoFrame(file),
        'attachments-document': !(isImage(file) || isVideoFrame(file) || isVideoFrame(file)),
      }"
      v-for="(file, index) in allFiles"
      :key="index"
      :style="{backgroundImage: bgImage(file)}"
      :data-index="isImage(file) || isVideoFrame(file) ? mediaFiles.indexOf(file) : undefined"
      :data-file-extension="fileExtension(file)"
      @click="onFileClick($event, file)"
      :data-file-type="file.type"
    >
      <f7-icon f7="videocam_round_fill" v-if="!isVideoFrame(file) && isVideo(file)"></f7-icon>
      <template v-if="allowEdit">
        <span v-if="inMessagebar" class="messagebar-attachment-delete" @click="onDeleteClick(file)"></span>
        <a v-else class="attachment-delete" @click="onDeleteClick(file)">
          <f7-icon ios="f7:close" md="material:close"></f7-icon>
        </a>
      </template>
      <span class="attachment-filename" v-if="fileName(file)">{{fileName(file)}}</span>
    </div>
  </div>
</template>
<script>
  import config from '../../config';
  import getVideoDataFromUrl from '../../utils/get-video-data-from-url';

  export default {
    props: {
      disabled: Boolean,
      files: Array,
      afterTitle: String,
      extraMediaFiles: Array,
      inMessagebar: Boolean,
      allowEdit: {
        type: Boolean,
        default: true,
      },
      showTitle: {
        type: Boolean,
        default: true,
      },
      wrap: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        filesToUpload: [],
      };
    },
    watch: {
      allFiles() {
        const self = this;
        self.$nextTick(() => {
          if (self.allFiles && self.allFiles.length && self.attachEventsLater && self.$refs.el) {
            const $ = self.$$;
            self.attachEventsLater = false;
            self.attachPhotoBrowserEvents();
          }
          if (!self.allFiles || self.allFiles.length === 0) {
            self.attachEventsLater = true;
          }
        });
      },
      'files.length': function watchFilesLength(newLength, oldLength) {
        const self = this;
        if (newLength <= oldLength) {
          return;
        }
        self.files.forEach((file) => {
          self.filesToUpload.forEach((fileToUpload) => {
            if (file.size === fileToUpload.size && file.originalFileName === fileToUpload.name) {
              self.filesToUpload.splice(self.filesToUpload.indexOf(fileToUpload), 1);
            }
          });
        });
      },
      mediaFiles() {
        const self = this;
        if (!self.pb) return;
        const photos = self.getPhotos();
        self.pb.photos = photos;
        self.pb.params.photos = photos;
      },
    },
    computed: {
      allFiles() {
        const self = this;
        return [
          ...(self.extraMediaFiles || []),
          ...self.files,
          ...self.filesToUpload,
        ];
      },
      mediaFiles() {
        const self = this;
        const mediaFiles = self.allFiles
          .filter((file) => {
            if (file && file.url && self.isExternalImage(file.url)) {
              return true;
            }
            if (file && file.url && self.videoFrameDataFromUrl(file.url)) {
              return true;
            }
            const type = file.contentType || file.type;
            return file.pb || file && type && type.indexOf('image') === 0;
          });
        return mediaFiles;
      },
    },
    methods: {
      fileName(file) {
        const name = file.name || file.originalFileName || file.url;
        if (!name) return null;
        if (name.length <= 30) return name;
        return `${name.substr(0, 20)}...${name.substr(name.length - 7)}`;
      },
      fileExtension(file) {
        const name = file.name || file.originalFileName;
        if (!name) return undefined;
        const extParts = name.split('.');
        if (extParts.length < 2) return '';
        return `.${name.split('.')[extParts.length - 1]}`;
      },
      fileSrc(file) {
        if (file.url) {
          return file.url;
        }
        if (file.downloadFileName) {
          return `${config.path.files}${file.downloadFileName}`;
        }
        if (file.dataUrl) {
          return `${file.dataUrl}`;
        }
        return '';
      },
      isExternalImage(fileUrl) {
        const exts = 'jpg png jpeg gif svg'.split(' ');
        let image = false;
        exts.forEach((ext) => {
          if (fileUrl.toLowerCase().indexOf(`.${ext}`) > 0) image = true;
        });
        return image;
      },
      videoFrameDataFromUrl(url) {
        const { yt, vimeo, dreamBroker } = getVideoDataFromUrl(url);
        if (yt) {
          return {
            yt,
            imageUrl: `https://img.youtube.com/vi/${yt}/hqdefault.jpg`,
            type: 'video',
            pb: {
              html: `<iframe src="${window.cordova ? 'https:' : ''}//www.youtube.com/embed/${yt}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`,
            },
          };
        }
        if (vimeo) {
          return {
            vimeo,
            type: 'video',
            pb: {
              html: `<iframe src="${window.cordova ? 'https:' : ''}//player.vimeo.com/video/${vimeo}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`,
            },
          };
        }
        if (dreamBroker) {
          return {
            dreamBroker,
            type: 'video',
            pb: {
              html: `<iframe src="${window.cordova ? 'https:' : ''}//dreambroker.com/channel/${dreamBroker[0]}/iframe/${dreamBroker[1]}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`,
            },
          }
        }
        return null;
      },
      isVideoFrame(file) {
        const self = this;
        if (file.url && !file.yt && !file.vimeo && !file.dreamBroker) {
          return !!self.videoFrameDataFromUrl(file.url);
        }
        return file.yt || file.vimeo || file.dreamBroker;
      },
      isVideo(file) {
        const type = file.type || file.contentType;
        return file && type && type.indexOf('video') === 0;
      },
      isImage(file) {
        const self = this;
        if (file.url && self.isExternalImage(file.url)) return true;
        const type = file.contentType || file.type;
        return file.image || (file && type && type.indexOf('image') === 0);
      },
      bgImage(file) {
        const self = this;
        if (file && file.url && self.isExternalImage(file.url)) return `url(${file.url})`;
        if (!self.isImage(file) && !self.isVideoFrame(file)) return '';
        if (self.isVideoFrame(file)) return '';
        if (file.imageUrl) return `url(${file.imageUrl})`;
        if (file.downloadFileName) {
          if (file.downloadFileName.indexOf('http') === 0) return `url(${file.downloadFileName})`;
          return `url(${config.path.images}${file.downloadFileName}?fit=crop&w=70&h=80&dpr=${this.$device.pixelRatio})`;
        }
        if (file.dataUrl) {
          return `url(${file.dataUrl})`;
        }
        return '';
      },
      onFilesChange(e) {
        const self = this;
        const files = e.target.files;
        for (let i = 0; i < files.length; i += 1) {
          const file = files[i];
          if (file.type && (file.type.indexOf('image') === 0)) {
            const reader = new window.FileReader();
            reader.onload = ((event) => {
              file.dataUrl = event.target.result;
              self.filesToUpload.push(file);
            });
            reader.readAsDataURL(file);
          } else {
            self.filesToUpload.push(file);
          }
        }
      },
      onFileClick(e, file) {
        const self = this;
        const $ = self.$$;
        if ($(e.target).closest('.attachment-delete, .messagebar-attachment-delete').length) return;
        if ($(e.target).closest('.photobrowser-link').length) return;
        if (!self.allowEdit && self.isImage(file)) return;
        if (file.downloadFileName) {
          let url = file.downloadFileName;
          if (url.indexOf('http') !== 0) url = `${config.path.files}${file.downloadFileName}`;
          if (window.cordova) {
            window.cordova.InAppBrowser.open(url, '_system');
          } else {
            window.open(url);
          }
        } else if (file.url) {
          if (window.cordova) {
            window.cordova.InAppBrowser.open(file.url, '_system');
          } else {
            window.open(file.url);
          }
        }
      },
      onDeleteClick(file) {
        const self = this;
        if (self.filesToUpload.indexOf(file) >= 0) {
          self.filesToUpload.splice(self.filesToUpload.indexOf(file), 1);
        }
        if (self.files.indexOf(file) >= 0) {
          self.$emit('deleteFile', file);
        }
      },
      getPhotos() {
        const self = this;
        return self.mediaFiles
          .filter(file => file.downloadFileName || file.dataUrl || file.pb || file.url)
          .map((file) => {
            if (file.dataUrl) return file.dataUrl;
            if (file.pb) return file.pb;
            if (file.downloadFileName && file.downloadFileName.indexOf('http') === 0) return file.downloadFileName;
            if (file.url && self.isExternalImage(file.url)) {
              return file.url;
            }
            if (file.url && self.videoFrameDataFromUrl(file.url)) {
              const { pb } = self.videoFrameDataFromUrl(file.url);
              return pb;
            }
            return `${config.path.files}${file.downloadFileName}?dpr=2`;
          })
          .filter(file => file);
      },
      openLinkDialog() {
        const self = this;
        self.$f7.dialog.prompt(self.$t('attachments.link_dialog_text'), (value) => {
          if (!value.trim()) return;
          self.filesToUpload.push({ url: value });
        });
      },
      attachPhotoBrowserEvents() {
        const self = this;
        const $ = self.$$;
        self.$nextTick(() => {
          $(self.$el).on('click', '.photobrowser-link', function onClick(e) {
            e.preventDefault();
            if ($(e.target).closest('.attachment-delete, .messagebar-attachment-delete').length) return;
            const clickedIndex = $(this).attr('data-index');
            self.pb.open(parseInt(clickedIndex, 10));
          });
        })
      },
    },
    beforeDestroy() {
      if (this.pb && this.pb.destroy) this.pb.destroy();
    },
    mounted() {
      const self = this;
      const $ = self.$$;
      self.$f7ready(() => {
        const photos = self.getPhotos();
        const pbView = self.$f7.views.main || self.$f7.views[0];
        self.pb = self.$f7.photoBrowser.create({
          theme: 'dark',
          photos,
          routableModals: !!pbView,
          view: pbView,
          backLinkText: self.$t('general.close'),
          renderNavbar() {
            const pb = this;

            let iconsColor = pb.params.iconsColor;
            if (!pb.params.iconsColor && pb.params.theme === 'dark') iconsColor = 'white';

            const backLinkText = pb.params.backLinkText;

            const isPopup = pb.params.type !== 'page';
            const navbarHtml = `
              <div class="navbar">
                <div class="navbar-inner sliding">
                  <div class="left">
                    <a href="#" class="link ${isPopup ? 'popup-close' : ''} ${!backLinkText ? 'icon-only' : ''} ${!isPopup ? 'back' : ''}" ${isPopup ? 'data-popup=".photo-browser-popup"' : ''}>
                      <i class="icon icon-back ${iconsColor ? `color-${iconsColor}` : ''}"></i>
                      ${backLinkText ? `<span>${backLinkText}</span>` : ''}
                    </a>
                  </div>
                  <div class="title">
                    <span class="photo-browser-current"></span>
                    <span class="photo-browser-of">${pb.params.navbarOfText}</span>
                    <span class="photo-browser-total"></span>
                  </div>
                  <div class="right"></div>
                </div>
              </div>
            `.trim();
            return navbarHtml;
          },
          on: {
            transitionEnd() {
              self.pb.$el.find('.swiper-slide-prev iframe, .swiper-slide-next iframe').each((index, el) => {
                el.src = el.src;
              });
            },
          },
        });
      });
      if (!self.$el) {
        self.attachEventsLater = true;
      } else {
        self.attachPhotoBrowserEvents();
      }
    },

  };
</script>
