<template>
  <f7-popup>
    <f7-view :init="false">
      <f7-page :page-content="false" @page:afterin="onPageAfterIn" class="chat-page">
        <f7-navbar>
          <f7-nav-title>{{$t('chat.title')}}{{assignmentTitle ? `: ${assignmentTitle}` : ''}}</f7-nav-title>
          <f7-nav-right>
            <f7-link icon-ios="f7:close" icon-md="material:close" popup-close></f7-link>
          </f7-nav-right>
        </f7-navbar>

        <div class="subnavbar" v-if="parties && hasCompanyTab && hasCandidateTab">
          <div class="subnavbar-inner">
            <f7-segmented round color-theme="white" class="subnavbar-chat">
              <f7-button outline round tab-link="#chat-candidate-tab" :tab-link-active="activeTab === 'candidate'">
                <span class="text">{{parties.candidateName}}<span v-if="parties.unreadCountCandidate && showCandidateCount" class="badge color-brand-red">{{parties.unreadCountCandidate}}</span></span>
              </f7-button>
              <f7-button outline round tab-link="#chat-company-tab" :tab-link-active="activeTab === 'company'" v-if="parties.companyHasStartedConversation">
                <span class="text">{{parties.companyName}}<span v-if="parties.unreadCountCompany && showCompanyCount" class="badge color-brand-red">{{parties.unreadCountCompany}}</span></span>
              </f7-button>
            </f7-segmented>
          </div>
        </div>

        <offline-indicator></offline-indicator>

        <div class="tabs tabs-full-height" v-if="showChat">
          <f7-tab id="chat-candidate-tab"
            @tab:show="onTabShow('candidate')"
            :tab-active="activeTab === 'candidate' || !hasCompanyTab"
            v-if="hasCandidateTab"
          >
            <chat
              v-if="candidateChatLoaded || !hasCompanyTab"
              :assignmentId="assignmentId"
              :guid="guid"
              from="Finder"
              to="Candidate"
              :party-name="parties && parties.candidateName"
            ></chat>
          </f7-tab>
          <f7-tab
            id="chat-company-tab"
            @tab:show="onTabShow('company')"
            :tab-active="activeTab === 'company'"
            v-if="hasCompanyTab"
          >
            <chat
              v-if="companyChatLoaded"
              :assignmentId="assignmentId"
              :companyId="companyId"
              :guid="guid"
              from="Finder"
              to="Company"
              :party-name="parties && parties.companyName"
            ></chat>
          </f7-tab>
        </div>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>

<script>
  import { mapGetters } from 'vuex';
  import candidateRecommendationList from '../../utils/candidate-recommendation-list';

  export default {
    props: {
      assignmentId: [String, Number],
      guid: [String, Number],
      companyId: [String, Number],
    },
    data() {
      const activeTab = this.$f7route.query.tab || 'candidate';
      return {
        showChat: false,
        pageIn: false,
        activeTab,
        candidateChatLoaded: activeTab === 'candidate',
        companyChatLoaded: activeTab === 'company',
        showCandidateCount: activeTab !== 'candidate',
        showCompanyCount: activeTab !== 'company',
      };
    },
    mounted() {
      const self = this;
      setTimeout(() => {
        self.showChat = true;
      }, 500);
    },
    watch: {
      parties() {
        if (this.hasCompanyTab && this.hasCandidateTab && this.pageIn) {
          this.$$(this.$el.querySelector('.page')).addClass('page-with-subnavbar');
        }
      },
    },
    computed: {
      ...mapGetters({
        parties: 'm/messagesPartiesFinder',
        partiesLoading: 'm/messagesPartiesLoadingFinder',
      }),
      isPopup() {
        return !!this.$f7route.query.popup;
      },
      hasCandidateTab() {
        const self = this;
        return self.parties && self.parties.candidateRecommendation !== candidateRecommendationList.Company;
      },
      hasCompanyTab() {
        const self = this;
        return self.parties && self.parties.companyHasStartedConversation;
      },
      assignmentTitle() {
        const self = this;
        if (self.parties && self.parties.assignmentTitle) return self.parties.assignmentTitle;
        if (self.$f7route.query.assignmentTitle) return self.$f7route.query.assignmentTitle;
        return '';
      },
    },
    created() {
      const self = this;
      self.$store.dispatch('m/getMessagesParties', {
        guid: self.guid,
        assignmentId: self.assignmentId,
        from: 'Finder',
      });
    },
    methods: {
      onTabShow(tab) {
        if (tab === 'candidate') {
          this.activeTab = 'candidate';
          this.candidateChatLoaded = true;
          this.showCandidateCount = false;
        } else {
          this.activeTab = 'company';
          this.companyChatLoaded = true;
          this.showCompanyCount = false;
        }
      },
      onPageAfterIn() {
        this.pageIn = true;
        if (this.hasCompanyTab) {
          this.$$(this.$el.querySelector('.page')).addClass('page-with-subnavbar');
        }
      },
    },
  };
</script>