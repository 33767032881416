<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link back icon="icon-back" :text="$t('general.back')"></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{$t('assignment_form.edit_title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <assignment-form ref="form" class="center-content" v-if="assignment" :assignment="assignment" :crowd="crowd"  @formChanged="onFormChanged">
      <f7-block strong v-if="assignment.needsCompanyDetails" class="text-align-center">
        <p>{{$t('assignment_form.need_company_details')}}</p>
        <p>
          <f7-button small round fill class="display-inline-block" @click="editCompany">{{$t('assignment_form.edit_company_details_button')}}</f7-button>
        </p>
      </f7-block>
      <div class="buttons-row mobile-buttons-row">
        <loading-button
          class="button-left button-desktop-only"
          :class="{ disabled: saving }"
          v-if="status === assignmentStatusList.Published"
          large
          fill
          round
          color="brand-orange"
          :text="$t('assignment_form.close_button')"
          @click="closeAssignment"
          :loading="action === 'close'"
        ></loading-button>
        <loading-button
          class="button-left button-desktop-only"
          :class="{ disabled: saving }"
          v-if="assignment.isDeletable"
          large
          fill
          round
          color="brand-red"
          :text="$t('assignment_form.delete_button')"
          @click="deleteAssignment"
          :loading="action === 'delete'"
        ></loading-button>
        <loading-button
          class="button-right"
          :class="{ disabled: saving }"
          large
          fill
          round
          fluid
          color="gray"
          :text="$t('assignment_form.preview_link')"
          @click="previewAssignment()"
        ></loading-button>
        <loading-button
          :class="{ disabled: saving }"
          v-if="status !== assignmentStatusList.Closed"
          large
          fill
          round
          fluid
          color="brand-blue"
          :text="$t('general.save')"
          @click="() => {saveAssignment()}"
          :loading="action === 'save'"
        ></loading-button>
        <loading-button
          :class="{disabled: assignment.needsCompanyDetails || saving}"
          v-if="status === assignmentStatusList.WaitingForConfirm || status === assignmentStatusList.Draft || status === assignmentStatusList.Declined"
          large
          fill
          round
          fluid
          color="brand-green"
          :text="$t('assignment_form.save_publish_button')"
          @click="() => {saveAssignment(true)}"
          :loading="action === 'publish'"
        ></loading-button>
      </div>

      <f7-block-footer class="desktop-text-align-right mobile-text-align-center" v-html="$t('assignment_form.terms_link')" v-if="status === assignmentStatusList.WaitingForConfirm || status === assignmentStatusList.Draft || status === assignmentStatusList.Declined"></f7-block-footer>

      <div class="buttons-row buttons-mobile-only mobile-buttons-row">
        <loading-button
          class="button-left button-mobile-only"
          :class="{ disabled: saving }"
          v-if="status === assignmentStatusList.Published"
          large
          fill
          round
          fluid
          color="brand-orange"
          :text="$t('assignment_form.close_button')"
          @click="closeAssignment"
          :loading="action === 'close'"
        ></loading-button>
        <loading-button
          class="button-left button-mobile-only"
          :class="{ disabled: saving }"
          v-if="assignment.isDeletable"
          large
          fill
          round
          fluid
          color="brand-red"
          :text="$t('assignment_form.delete_button')"
          @click="deleteAssignment"
          :loading="action === 'delete'"
        ></loading-button>
      </div>
    </assignment-form>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import assignmentStatusList from '../../utils/assignment-status-list';
  import currencyMap from '../../utils/currency-map';
  import formatNumber from '../../utils/format-number';
  import events from '../../modules/events';

  export default {
    props: {
      id: [String, Number],
    },
    data() {
      return {
        assignmentStatusList,
        action: null,
        originalRewards: null,
        crowdId: null,
      };
    },
    created() {
      const self = this;
      self.$store.dispatch('c/getAssignmentEditData', { id: parseInt(self.id, 10) });
    },
    mounted() {
      const self = this;
      events.$on('routesBeforeLeave', self.onRouteLeave);
    },
    beforeDestroy() {
      const self = this;
      self.$store.dispatch('c/unsetAssignmentEditData');
      events.$off('routesBeforeLeave', self.onRouteLeave);
    },
    computed: {
      status() {
        return this.assignment.status;
      },
      form() {
        return this.$refs.form;
      },
      ...mapGetters({
        saving: 'c/assignmentSaving',
        loading: 'c/assignmentEditDataLoading',
        assignment: 'c/assignmentEditData',
        crowd: 'c/crowd',
      }),
    },
    watch: {
      assignment() {
        const self = this;
        if (self.originalRewards) return;
        self.originalRewards = self.assignment.rewards.map(r => Object.assign({}, r));
        if (self.assignment.crowdId && !self.crowdId) {
          self.crowdId = self.assignment.crowdId;
          self.$store.dispatch('c/getCrowd', { id: self.crowdId });
        }
      },
    },
    methods: {
      editCompany() {
        const self = this;
        self.$root.openPopup(`/profile/details/?firstedit=true&popup=true&updateAssignmentEditData=true&id=${self.id}`, 0, false);
      },
      getInvoiceAmount() {
        const self = this;
        const assignment = self.form.getData();

        const { rewards = [], multipleLevels, shareOut } = assignment;
        const currency = rewards.length ? rewards[0].currency : 0;
        let amountMin = 0;
        let amountMax = 0;
        rewards.forEach((reward, index) => {
          if (!multipleLevels && index > 0) return;
          const { type, fixed, percentage, expectedTo, expectedFrom } = reward;
          const rewardPart = this.$root.userRole === 'finder' ? shareOut : 100;
          if (type === 0) {
            amountMax += fixed * rewardPart / 100;
            if (index === 0) amountMin += fixed * rewardPart / 100;
          } else {
            amountMax += expectedTo * (percentage * rewardPart / 10000);
            if (index === 0) amountMin += expectedFrom * (percentage * rewardPart / 10000);
          }
        });

        const currencyString = currencyMap(currency);
        let text = '';
        if (amountMin === amountMax) {
          text = `${formatNumber(amountMax)} ${currencyString}`;
        } else {
          text = self.$t('assignment.reward_amount_range', {
            min: formatNumber(amountMin),
            max: formatNumber(amountMax),
            currency: currencyString,
          });
        }

        return text;
      },
      onFormChanged() {
        const self = this;
        self.formChanged = true;
      },
      onRouteLeave({ to, from, prevent, resolve, reject }) {
        const self = this;
        if (self.formChanged && from && from.route && from.route.path === '/assignment/:id/edit') {
          if (to && (to.url === '/how-to-write-assignment/' || to.url.indexOf('/data/') >= 0 || to.url.indexOf('/photos/') >= 0)) {
            return;
          }
          prevent();
          self.$root.confirmDialog(self.$t('form.back_confirm'), () => {
            resolve();
          }, () => {
            reject();
          });
        }
      },
      confirmProfileLink(url) {
        const self = this;
        if (self.formChanged) {
          self.$root.confirmDialog(self.$t('form.back_confirm'), () => {
            self.$f7router.navigate(url);
          });
        } else {
          self.$f7router.navigate(url);
        }
      },
      confirmBack() {
        const self = this;
        if (self.formChanged) {
          self.$root.confirmDialog(self.$t('form.back_confirm'), () => {
            self.$f7router.back();
          });
        } else {
          self.$f7router.back();
        }
      },
      changeStatus(status, callbackOk) {
        const self = this;
        self.$store.dispatch(
          'c/changeAssignmentStatus',
          {
            id: self.assignment.id,
            status,
            callbackOk,
          }
        );
      },
      closeAssignment() {
        const self = this;
        self.$root.confirmDialog(self.$t('assignment_form.close_confirm'), () => {
          self.action = 'close';
          self.changeStatus(assignmentStatusList.Closed, () => {
            self.action = null;
            self.$f7router.back();
          });
        });
      },
      deleteAssignment() {
        const self = this;
        self.$root.confirmDialog(self.$t('assignment_form.delete_confirm'), () => {
          self.action = 'delete';
          self.$store.dispatch('c/deleteAssignment', {
            id: self.assignment.id,
            callbackOk() {
              self.action = null;
              self.$f7router.navigate('/my-assignments/', { reloadAll: true });
            },
          });
        });
      },
      saveAssignment(publish) {
        const self = this;

        const valid = self.form.validate();
        if (!valid) return;

        const assignment = self.assignment;

        self.action = publish ? 'publish' : 'save';

        const newData = self.form.getData();
        delete newData.status;

        const newAssignment = {
          id: assignment.id,
          status: publish ? assignmentStatusList.Published : assignment.status,
          ...newData,
        };
        self.formChanged = false;
        function save() {
          self.$store.dispatch('c/saveAssignment', {
            assignment: newAssignment,
            callbackOk() {
              if (self.action === 'publish' && !self.$root.userConfirmed) {
                self.$f7.dialog.alert(self.$t('assignment_form.need_confirm_dialog'));
              } else if (self.action === 'publish') {
                self.$f7.dialog.alert(self.$t('assignment_form.need_review_dialog'));
              }
              self.action = null;
              self.$f7router.back();
              if (newAssignment.crowdId) {
                self.$store.dispatch('c/getCrowdAssignmentList', { crowdId: newAssignment.crowdId });
              }
            },
            callbackError() {
              self.action = null;
            },
          });
        }

        function confirmPublish(callback) {
          if (!newAssignment.disableReward && newAssignment.status === assignmentStatusList.Published && !(assignment.isInternal && assignment.rewardHandledByCompany)) {
            const amount = self.getInvoiceAmount();
            const crowdName = self.assignment.crowdName;
            self.$f7.dialog.confirm(self.$t('assignment_form.publish_confirm', { amount, crowdName }), () => {
              callback();
            }, () => {
              self.action = null;
            });
          } else {
            callback();
          }
        }

        function confirmIncrease(callback) {
          if (!self.originalRewards || !self.originalRewards.length) {
            callback();
            return;
          }
          let rewardIncreased;
          if (assignment.status === assignmentStatusList.Published) {
            self.originalRewards.forEach((reward, rIndex) => {
              const newReward = newAssignment.rewards[rIndex];
              if (reward.type === 0 && reward.fixed < newReward.fixed) {
                rewardIncreased = true;
              } else if (reward.type === 1) {
                if (
                  (reward.expectedFrom * reward.percentage < newReward.expectedFrom * newReward.percentage)
                  || (reward.expectedTo * reward.percentage < newReward.expectedTo * newReward.percentage)
                ) {
                  rewardIncreased = true;
                }
              }
            });
          }
          if (rewardIncreased) {
            self.$f7.dialog.confirm(self.$t('assignment_form.reward_increase_confirm'), () => {
              callback();
            }, () => {
              self.action = null;
            });
          } else {
            callback();
          }
        }

        confirmIncrease(() => {
          confirmPublish(() => {
            save();
          });
        });
      },
      previewAssignment() {
        const self = this;
        if (!self.assignment || self.previewLoading) return;
        const preview = self.form.getData();
        self.previewLoading = true;
        self.formChanged = false;
        self.$store.dispatch('c/getPreview', {
          id: self.assignment.id,
          callbackOk(company) {
            self.$store.dispatch('c/setAssignmentPreview', {
              companyName: company.companyName,
              logo: company.logo,
              companyId: company.id,
              guid: company.assignmentGuid,
              potentialReward: self.assignment.potentialReward,
              ...preview,
              files: [...preview.files, ...preview.filesToUpload],
            });
            self.$f7router.navigate('/assignment-preview/');
            self.previewLoading = false;
          },
        });
      },
    },
  };
</script>
