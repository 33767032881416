/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';
import PWA from '../../modules/pwa';

const state = {
  crowd: null,
  crowdLoading: false,
  saving: false,
};

const getters = {
  crowdLoading(state) {
    return state.crowdLoading;
  },
  crowdSaving(state) {
    return state.saving;
  },
  crowd(state) {
    return state.crowd;
  },
};
const actions = {
  getCrowd({ commit, state }, { id } = {}) {
    if (state.crowd) return;
    API.get({
      url: `admin/crowd/details?id=${id}`,
      loading: 'a/setCrowdLoading',
      ok(response) {
        commit('setCrowd', response);
      },
    });
  },
  unsetCrowd({ commit }) {
    commit('unsetCrowd');
  },
  saveCrowd(ctx, { crowd, callbackOk, callbackError } = {}) {
    API.post({
      url: 'admin/crowd/save',
      loading: 'a/setCrowdSaving',
      data: crowd,
      base64FileProp: 'logo',
      ok() {
        if (crowd && crowd.id && state.crowd && state.crowd.id === crowd.id) {
          const newCrowd = {
            ...state.crowd,
            ...crowd,
          };
          PWA.updateCachedApi(`admin/crowd/details?id=${crowd.id}`, newCrowd);
        }
        if (callbackOk) callbackOk();
      },
      error: callbackError,
    });
  },
};

const mutations = {
  setCrowd(state, crowd) {
    state.crowd = crowd;
  },
  setCrowdLoading(state, status) {
    state.crowdLoading = status;
  },
  unsetCrowd(state) {
    state.crowd = null;
  },
  setCrowdSaving(state, status) {
    state.saving = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
