<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <back-link />
      </f7-nav-left>
      <f7-nav-title>{{$t('assignment_form.edit_title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <assignment-form ref="form" v-if="assignment" :assignment="assignment" :crowd="crowd">
      <f7-block-title v-if="needsReview">{{$t('assignment_form.need_review_title')}}</f7-block-title>
      <f7-list>
        <f7-list-input
          :label="$t('assignment_form.advertisement_order')"
          :value="advertisementOrder"
          @input="advertisementOrder = $event.target.value"
        ></f7-list-input>
        <li class="assignment-share-out-input">
          <div class="item-content">
            <div class="item-inner item-cell">
              <shareout-slider
                :value="shareOut"
                @change="setTempShareout($event)"
                @changed="changeShareOut($event)"
              ></shareout-slider>
            </div>
          </div>
        </li>
      </f7-list>
      <f7-list v-if="needsReview || (!needsReview && showDeclineReason)">
        <f7-list-item
          v-if="needsReview"
          checkbox
          :title="$t('assignment_form.approve_label')"
          name="approval"
          :checked="approved === true"
          @change="approved = $event.target.checked ? true : null"
        ></f7-list-item>
        <f7-list-item
          v-if="needsReview"
          checkbox
          :title="$t('assignment_form.decline_label')"
          name="approval"
          :checked="approved === false"
          @change="approved = $event.target.checked ? false : null"
        ></f7-list-item>
        <f7-list-input
          v-if="approved === false || (!needsReview && showDeclineReason)"
          :label="$t('assignment_form.decline_reason_label')"
          type="textarea"
          :value="declineReason"
          @input="declineReason = $event.target.value"
          validate
          required
        ></f7-list-input>
      </f7-list>
      <div class="buttons-row mobile-buttons-row">
        <loading-button
          class="button-left"
          large
          fill
          round
          fluid
          color="gray"
          :text="$t('assignment_form.preview_link')"
          @click="previewAssignment()"
        ></loading-button>
        <loading-button
          class="button-right"
          large
          fill
          round
          color="brand-blue"
          fluid
          :text="$t('general.save')"
          @click="saveAssignment"
          :loading="saving"
        ></loading-button>
      </div>
    </assignment-form>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import events from '../../modules/events';
  import assignmentStatusList from '../../utils/assignment-status-list';

  export default {
    props: {
      id: [Number, String],
    },
    data() {
      return {
        approved: null,
        declineReason: '',
        advertisementOrder: null,
        shareOut: 50,
        originalShareout: null,
        showDeclineReason: false,
        crowdId: null,
      };
    },
    created() {
      const self = this;
      self.$store.dispatch('a/getAssignment', { id: parseInt(self.id, 10) });
    },
    watch: {
      assignment() {
        const self = this;
        if (!self.assignment) return;

        self.shareOut = self.assignment.shareOut === null
          ? self.assignment.defaultShareOut
          : self.assignment.shareOut;

        self.advertisementOrder = self.assignment.advertisementOrder;
        if (self.assignment.declineReason) {
          self.showDeclineReason = true;
          self.declineReason = self.assignment.declineReason || '';
        }
        if (self.assignment.crowdId && !self.crowdId) {
          self.crowdId = self.assignment.crowdId;
          self.$store.dispatch('a/getCrowd', { id: self.crowdId });
        }
      },
    },
    computed: {
      needsReview() {
        return this.assignment && this.assignment.status === assignmentStatusList.InReview;
      },
      status() {
        return this.assignment.status;
      },
      form() {
        return this.$refs.form;
      },
      ...mapGetters({
        saving: 'a/assignmentSaving',
        loading: 'a/assignmentLoading',
        assignment: 'a/assignment',
        crowd: 'a/crowd',
      }),
    },
    beforeDestroy() {
      const self = this;
      self.$store.dispatch('a/unsetAssignment');
    },
    methods: {
      previewAssignment() {
        const self = this;
        if (!self.assignment || self.previewLoading) return;
        const preview = self.form.getData();
        self.previewLoading = true;
        self.$store.dispatch('a/getPreview', {
          id: self.assignment.id,
          callbackOk(company) {
            self.$store.dispatch('a/setAssignmentPreview', {
              companyName: company.companyName,
              logo: company.logo,
              companyId: company.id,
              guid: company.assignmentGuid,
              potentialReward: self.assignment.potentialReward,
              ...preview,
            });
            self.$f7router.navigate('/assignment-preview/');
            self.previewLoading = false;
          },
        });
      },
      setTempShareout(value) {
        const self = this;
        if (self.originalShareout === null) {
          self.originalShareout = self.shareOut;
        }
        self.shareOut = value;
      },
      changeShareOut(value) {
        const self = this;
        if (value === self.originalShareout) return;
        if (self.originalShareout === null) return;
        self.$f7.dialog.confirm(self.$t('general.are_you_sure'), () => {
          self.shareOut = value;
          self.originalShareout = null;
        }, () => {
          self.shareOut = self.originalShareout;
          self.originalShareout = null;
          const range = self.$f7.range.get(self.$el.querySelector('.range-slider'));
          if (range) range.setValue(self.shareOut);
        });
      },
      saveAssignment() {
        const self = this;

        const valid = self.form.validate();
        if (!valid) return;

        self.$f7.dialog.confirm(self.$t('general.are_you_sure'), () => {
          const { assignment, shareOut, advertisementOrder } = self;

          const newAssignment = {
            id: assignment.id,
            shareOut,
            advertisementOrder: advertisementOrder ? parseInt(advertisementOrder, 10) : null,
            ...self.form.getData(),
          };

          if (self.needsReview && self.approved === true) {
            newAssignment.status = assignmentStatusList.Published;
          } else if (self.needsReview && self.approved === false) {
            if (!self.declineReason) return;
            newAssignment.status = assignmentStatusList.Declined;
            newAssignment.declineReason = self.declineReason;
          } else if (!self.needsReview && self.declineReason) {
            newAssignment.declineReason = self.declineReason;
          }

          self.$store.dispatch('a/saveAssignment', {
            assignment: newAssignment,
            callbackOk() {
              events.$emit('store:admin:getAssignmentsList');
              self.$f7router.back();
            },
          });
        });
      },
    },
  };
</script>
