<template>
  <f7-popup :close-by-backdrop-click="false" class="assignment-recommendations-popup">
    <f7-view :init="false">
      <f7-page>
        <f7-navbar>
          <f7-nav-title>{{$t('assignment_recommend.title')}}</f7-nav-title>
          <f7-nav-right>
            <f7-link popup-close icon-ios="f7:close" icon-md="material:close"></f7-link>
          </f7-nav-right>
        </f7-navbar>
        <offline-indicator slot="fixed"></offline-indicator>

        <template v-if="assignment && (!guid || (guid && recommendation))">
          <f7-block class="assignment-details-block">
            <div class="assignment-recommendations-info">
              {{$t(`assignment_recommend.${isInnovation ? 'info_innovation' : 'info'}`)}}
            </div>
          </f7-block>

          <f7-list>
            <template v-if="!isInnovation">
              <f7-list-input
                :label="$t('form.email')"
                type="email" :value="email" @input="email = $event.target.value" validate validateOnBlur :placeholder="$t('assignment_recommend.email_placeholder')"
              ></f7-list-input>
              <f7-list-input
                :label="$t('form.first_name')"
                type="text" :value="firstName" @input="firstName = $event.target.value" :placeholder="$t('assignment_recommend.first_name_placeholder')"
              ></f7-list-input>
              <f7-list-input
                :label="$t('form.last_name')"
                type="text" :value="lastName" @input="lastName = $event.target.value" :placeholder="$t('assignment_recommend.last_name_placeholder')"
              ></f7-list-input>
              <f7-list-input
                :label="$t('form.phone')"
                type="tel" :value="phone" @input="phone = $event.target.value" :placeholder="$t('assignment_recommend.phone_placeholder')"
              ></f7-list-input>
              <f7-list-input
                v-if="isRecruitment"
                :label="$t('form.linkedin')"
                type="url" :value="linkedIn" @input="linkedIn = $event.target.value" :placeholder="$t('assignment_recommend.linkedin_placeholder')"
              ></f7-list-input>
            </template>

            <f7-list-input
              :label="$t('form.message')"
              type="textarea" :value="message" @input="message = $event.target.value" resizable :placeholder="$t('assignment_recommend.message_placeholder', { companyName: assignment.companyName })"
            ></f7-list-input>

            <f7-list-item>
              <attachments
                ref="attachments"
                :files="attachments"
                @deleteFile="deleteFile"
              ></attachments>
            </f7-list-item>

          </f7-list>
          <div class="buttons-row margin-horizontal no-padding-horizontal">
            <loading-button
              class="button-right"
              large
              fill
              round
              color="brand-blue"
              :text="guid ? $t('assignment_recommend.update_button') : $t('assignment_recommend.send_button', { companyName: assignment.companyName })"
              @click="sendRecommendation"
              :loading="sending"
            ></loading-button>
          </div>
        </template>

      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<script>
  import { mapGetters } from 'vuex';
  import assignmentCategoryList from '../../utils/assignment-category-list';
  import extend from '../../utils/extend';
  import events from '../../modules/events';
  import candidateStatusList from '../../utils/candidate-status-list';
  import candidateRecommendationList from '../../utils/candidate-recommendation-list';

  export default {
    props: {
      id: [String, Number],
      guid: [String, Number],
    },
    data() {
      return {
        assignmentCategoryList,
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        message: '',
        linkedIn: '',
        attachments: [],
      };
    },
    computed: {
      ...mapGetters({
        loading: 'f/assignmentLoading',
        recommendationLoading: 'f/recommendationLoading',
        sending: 'f/recommendationSending',
        assignment: 'f/assignment',
        recommendation: 'f/recommendation',
      }),
      isInnovation() {
        return this.assignment && this.assignment.category === assignmentCategoryList.Innovation;
      },
      isRecruitment() {
        return this.assignment && this.assignment.category === assignmentCategoryList.Recruitment;
      },
    },
    created() {
      const self = this;
      self.$store.dispatch('f/getAssignment', { id: parseInt(self.id, 10) });
      self.$store.dispatch('f/unsetRecommendation');
      if (self.guid) {
        self.$store.dispatch('f/getRecommendation', { guid: self.guid });
      }
    },
    watch: {
      recommendation() {
        const self = this;
        if (!self.recommendation) return;
        const { email, firstName, lastName, phone, message, linkedIn, attachments } = self.recommendation;
        extend(self, {
          email,
          firstName,
          lastName,
          phone,
          message,
          linkedIn,
          attachments,
        });
      },
    },
    methods: {
      deleteFile(file) {
        const self = this;
        if (self.attachments.indexOf(file) >= 0) {
          self.attachments.splice(self.attachments.indexOf(file), 1);
        }
      },
      sendRecommendation() {
        const self = this;

        if (self.$el.querySelectorAll('.item-input-invalid').length > 0) {
          self.$f7.input.scrollIntoView(self.$el.querySelectorAll('.item-input-invalid')[0], 300);
          return;
        }

        self.$f7.dialog.confirm(self.$t('assignment_recommend.send_to_company', { companyName: self.assignment.companyName }), () => {
          const filesToUpload = self.$refs.attachments.filesToUpload;
          const { email, firstName, lastName, phone, message, attachments, guid } = self;

          let linkedIn = self.linkedIn;
          if (linkedIn && linkedIn.indexOf('//') < 0) linkedIn = `http://${linkedIn}`;

          const data = {
            assignmentId: self.id,
            message,
            filesToUpload,
            recommendation: candidateRecommendationList.Company,
          };

          if (!self.isInnovation) {
            Object.assign(data, {
              email,
              firstName,
              lastName,
              phone,
            });
            if (self.isRecruitment) {
              data.linkedIn = linkedIn;
            }
          } else if (!message && filesToUpload.length === 0 && attachments.length === 0) {
            events.$emit('notify', self.$t('assignment_recommend.message_attachments_required'));
            return;
            // Add finder email
          }

          if (guid) {
            data.guid = guid;
            data.attachments = attachments;
          }

          self.$store.dispatch('f/sendRecommendation', {
            data,
            sync: true,
            syncMessage: self.$t('assignment.recommendation_sync_message'),
            callbackOk(res) {
              if (res && res.status === candidateStatusList.Pending && !self.$root.userConfirmed) {
                self.$f7.dialog.alert(self.$t('assignment.recommendation_need_confirm'));
              }
              self.$f7router.back();
            },
          });
        });
      },
    },
  };
</script>