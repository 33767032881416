import AdminDashboard from './admin/dashboard.vue';
import AdminUserList from './admin/user-list.vue';
import AdminUserEdit from './admin/user-edit.vue';
import AdminCandidateList from './admin/candidate-list.vue';
import AdminCandidateEdit from './admin/candidate-edit.vue';
import AdminCompanyList from './admin/company-list.vue';
import AdminCompanyCreate from './admin/company-create.vue';
import AdminCompanyEdit from './admin/company-edit.vue';
import AdminCrowdList from './admin/crowd-list.vue';
import AdminCrowdEdit from './admin/crowd-edit.vue';
import AdminAssignmentList from './admin/assignment-list.vue';
import AdminAssignmentEdit from './admin/assignment-edit.vue';
import AdminAssignmentPreview from './admin/assignment-preview.vue';

import CommonNotFound from './common/not-found.vue';
import CommonAbout from './common/about.vue';
import CommonCompanyProfile from './common/company-profile.vue';
import CommonNotifications from './common/notifications.vue';
import CommonProfile from './common/profile.vue';
import CommonNotificationsSettings from './common/notifications-settings.vue';
import CommonAssignmentDetailsLanding from './common/assignment-details-landing.vue';

import CompanyMyAssignments from './company/my-assignments.vue';
import CompanyPublicAssignments from './company/public-assignments.vue';
import CompanyDetails from './company/details.vue';
import CompanyUsers from './company/users.vue';
import CompanyAssignmentCreate from './company/assignment-create.vue';
import CompanyAssignmentEdit from './company/assignment-edit.vue';
import CompanyAssignmentDetails from './company/assignment-details.vue';
import CompanyMyAssignmentDetails from './company/assignment-details-my.vue';
import CompanyAssignmentPreview from './company/assignment-preview.vue';
import CompanyCandidateDetails from './company/candidate-details.vue';
import CompanyChat from './company/chat.vue';
import CompanyPayments from './company/payments.vue';
import CompanyHowToWriteAssignment from './company/how-to-write-assignment.vue';
import CompanyCrowdCreate from './company/crowd-create.vue';
import CompanyCrowdEdit from './company/crowd-edit.vue';
import CompanyCrowdAssignments from './company/crowd-assignments.vue';
import CompanyCrowdFinders from './company/crowd-finders.vue';
import CompanyCrowdAdmins from './company/crowd-admins.vue';

import FinderMyAssignments from './finder/my-assignments.vue';
import FinderPublicAssignments from './finder/public-assignments.vue';
import FinderAssignmentDetails from './finder/assignment-details.vue';
import FinderAssignmentRecommend from './finder/assignment-recommend.vue';
import FinderPayments from './finder/payments.vue';
import FinderChat from './finder/chat.vue';
import FinderHowToRecommend from './finder/how-to-recommend.vue';
import FinderCrowdAssignments from './finder/crowd-assignments.vue';
import FinderCrowdJoin from './finder/crowd-join.vue';

import AuthLogin from './auth/login.vue';
import AuthSignup from './auth/signup.vue';
import AuthForgotPassword from './auth/forgot-password.vue';
import AuthConfirmation from './auth/confirmation.vue';
import AuthResetPassword from './auth/reset-password.vue';
import AuthCompanyJoin from './auth/company-join.vue';

import CandidateRecommendation from './candidate/recommendation.vue';
import CandidateApplication from './candidate/application.vue';
import CandidateChat from './candidate/chat.vue';

import CrowdChat from './common/crowd-chat.vue';

function routablePopup(component) {
  return {
    async(to, from, resolve, reject) {
      const router = this;
      if (to.url === from.url) {
        reject();
        return;
      }
      let beforePage = '/';

      if (router.history.length) {
        beforePage = router.history[router.history.length - 1];
        if (beforePage === to.url) {
          beforePage = router.history[router.history.length - 2];
          router.history.splice(router.history.length - 1, 1);
          router.saveHistory();
        }
        if (!beforePage) beforePage = '/';
      }
      if (router.$el.find('.page').length === 0) {
        reject();
        router.navigate(beforePage, {
          reloadCurrent: true,
          pushState: false,
          once: {
            pageAfterIn() {
              setTimeout(() => {
                router.navigate(to.url, { pushState: false });
              }, 0);
            },
          },
        });
        return;
      }
      resolve({
        popup: {
          component,
        },
      });
    },
  };
}

const commonRoutes = [
  {
    path: '/recommendation/:type/:guid/',
    component: CandidateRecommendation,
  },
  {
    path: '/recommendation/:type/:guid',
    component: CandidateRecommendation,
  },
  {
    path: '/recommendation/:guid/',
    component: CandidateRecommendation,
  },
  {
    path: '/recommendation/:guid',
    component: CandidateRecommendation,
  },
  {
    path: '/application/:guid/',
    component: CandidateApplication,
  },
  {
    path: '/application/:guid',
    component: CandidateApplication,
  },
  {
    path: '/application/chat/:guid/:assignmentId/:finderId/:companyId',
    ...routablePopup(CandidateChat),
  },
  {
    path: '/preview/:guid',
    component: CompanyAssignmentPreview,
  },
];
const authRoutes = [
  {
    path: '/',
    component: AuthLogin,
  },
  {
    path: '/signup/:role?/',
    alias: '/signup/:role?',
    component: AuthSignup,
  },
  {
    path: '/forgot-password',
    component: AuthForgotPassword,
  },
  {
    path: '/reset-password/:guid/',
    component: AuthResetPassword,
  },
  {
    path: '/reset-password/:guid',
    component: AuthResetPassword,
  },
  {
    path: '/assignment/:id',
    component: CommonAssignmentDetailsLanding,
    options: {
      props: {
        checkAuth: true,
      },
    },
  },
  {
    path: '/assignment/my/:id',
    component: CommonAssignmentDetailsLanding,
    options: {
      props: {
        checkAuth: true,
      },
    },
  },
  {
    path: '/company-join/:companyGuid',
    component: AuthCompanyJoin,
  },
];
const authRedirect = [
  {
    path: '/signup/:role?/',
    alias: '/signup/:role?',
    redirect: '/',
  },
  {
    path: '/forgot-password',
    redirect: '/',
  },
  {
    path: '/reset-password/:guid/',
    redirect: '/',
  },
  {
    path: '/reset-password/:guid',
    redirect: '/',
  },
];
const adminRoutes = [
  {
    path: '/',
    component: AdminDashboard,
  },
  {
    path: '/companies',
    component: AdminCompanyList,
  },
  {
    path: '/companies/new',
    component: AdminCompanyCreate,
  },
  {
    path: '/companies/edit/:id',
    component: AdminCompanyEdit,
  },
  {
    path: '/crowds',
    component: AdminCrowdList,
  },
  {
    path: '/crowds/edit/:id',
    component: AdminCrowdEdit,
  },
  {
    path: '/assignments',
    component: AdminAssignmentList,
  },
  {
    path: '/assignments/edit/:id',
    component: AdminAssignmentEdit,
  },
  {
    path: '/assignment-preview',
    ...routablePopup(AdminAssignmentPreview),
  },
  {
    path: '/users',
    component: AdminUserList,
  },
  {
    path: '/users/edit/:id',
    component: AdminUserEdit,
  },
  {
    path: '/candidates',
    component: AdminCandidateList,
  },
  {
    path: '/candidates/edit/:id',
    component: AdminCandidateEdit,
  },
];
const companyRoutes = [
  {
    path: '/',
    name: 'assignment-list',
    component: CompanyPublicAssignments,
  },
  {
    path: '/my-assignments',
    component: CompanyMyAssignments,
  },
  {
    path: '/crowd/new',
    component: CompanyCrowdCreate,
  },
  {
    path: '/crowd/:crowdId',
    component: CompanyCrowdAssignments,
  },
  {
    path: '/crowd/:crowdId/edit',
    component: CompanyCrowdEdit,
  },
  {
    path: '/crowd/:crowdId/finders',
    component: CompanyCrowdFinders,
  },
  {
    path: '/crowd/:crowdId/administrators',
    component: CompanyCrowdAdmins,
  },
  {
    path: '/crowd/:crowdId/chat',
    ...routablePopup(CrowdChat),
  },
  {
    path: '/company/:id',
    component: CommonCompanyProfile,
  },
  {
    path: '/notifications',
    component: CommonNotifications,
  },
  {
    path: '/profile',
    component: CommonProfile,
  },
  {
    path: '/profile/details',
    component: CompanyDetails,
  },
  {
    path: '/profile/users',
    component: CompanyUsers,
  },
  {
    path: '/profile/notifications',
    component: CommonNotificationsSettings,
  },
  {
    path: '/profile/about',
    component: CommonAbout,
  },
  {
    path: '/candidate/:id',
    component: CompanyCandidateDetails,
  },
  {
    path: '/assignment/new',
    component: CompanyAssignmentCreate,
  },
  {
    path: '/assignment/my/:id',
    component: CompanyMyAssignmentDetails,
  },
  {
    path: '/assignment-preview',
    ...routablePopup(CompanyAssignmentPreview),
  },
  {
    path: '/assignment/:id',
    component: CompanyAssignmentDetails,
  },
  {
    path: '/assignment/:id/edit',
    name: 'assignment-edit',
    component: CompanyAssignmentEdit,
  },
  {
    path: '/chat/:assignmentId/:finderId/:guid',
    ...routablePopup(CompanyChat),
  },
  {
    path: '/profile/payments',
    component: CompanyPayments,
  },
  {
    path: '/how-to-write-assignment',
    ...routablePopup(CompanyHowToWriteAssignment),
  },
];

const finderRoutes = [
  {
    path: '/',
    name: 'assignment-list',
    component: FinderPublicAssignments,
  },
  {
    path: '/my-assignments',
    component: FinderMyAssignments,
  },
  {
    path: '/crowd/join',
    component: FinderCrowdJoin,
  },
  {
    path: '/crowd/:crowdId',
    component: FinderCrowdAssignments,
  },
  {
    path: '/crowd/:crowdId/chat',
    ...routablePopup(CrowdChat),
  },
  {
    path: '/assignment/:id',
    component: FinderAssignmentDetails,
  },
  {
    path: '/assignment/:id/recommend',
    ...routablePopup(FinderAssignmentRecommend),
  },
  {
    path: '/assignment/:id/recommend/:guid',
    ...routablePopup(FinderAssignmentRecommend),
  },
  {
    path: '/notifications',
    component: CommonNotifications,
  },
  {
    path: '/profile',
    component: CommonProfile,
  },
  {
    path: '/profile/notifications',
    component: CommonNotificationsSettings,
  },
  {
    path: '/profile/payments',
    component: FinderPayments,
  },
  {
    path: '/profile/about',
    component: CommonAbout,
  },
  {
    path: '/company/:id',
    component: CommonCompanyProfile,
  },
  {
    path: '/chat/:assignmentId/:companyId/:guid',
    ...routablePopup(FinderChat),
  },
  {
    path: '/how-to-recommend',
    ...routablePopup(FinderHowToRecommend),
  },
];

const systemRoutes = [
  {
    path: '/confirmation/:confirmationId/',
    component: AuthConfirmation,
  },
  {
    path: '/confirmation/:confirmationId',
    component: AuthConfirmation,
  },
  {
    path: '/confirmation/:confirmationId/:assignmentId/',
    component: AuthConfirmation,
  },
  {
    path: '/confirmation/:confirmationId/:assignmentId',
    component: AuthConfirmation,
  },
  {
    path: '(.*)',
    async(to, from, resolve, reject) {
      const router = this;
      if (to.url.indexOf('/assignment/') === 0 && to.query && to.query.email) {
        reject();
        setTimeout(() => {
          router.navigate(`/?email=${to.query.email}`, { reloadCurrent: true });
        }, 0);
        return;
      }
      const userRole = window.localStorage.userRole || null;
      const loggedIn = !!window.localStorage.accessToken && userRole;
      if (loggedIn) {
        resolve({
          component: CommonNotFound,
        });
        return;
      }
      resolve({
        component: AuthLogin,
      });
    },
  },
];

function getRoutes(role) {
  const routes = [...commonRoutes];
  if (role === 'company') routes.push(...companyRoutes, ...authRedirect);
  if (role === 'finder') routes.push(...finderRoutes, ...authRedirect);
  if (role === 'admin') routes.push(...adminRoutes, ...authRedirect);
  if (!role) routes.unshift(...authRoutes);
  routes.push(...systemRoutes);
  return routes;
}

export { systemRoutes, finderRoutes, companyRoutes, adminRoutes, authRoutes, commonRoutes };
export default getRoutes;
