import assignmentCategoryList from './assignment-category-list';

export default function (statusId) {
  let statusText;
  Object.keys(assignmentCategoryList).forEach((key) => {
    if (assignmentCategoryList[key] === statusId) statusText = key;
  });
  return statusText;
}

