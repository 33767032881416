<template>
  <f7-page @page:beforeremove="onPageBeforeRemove">
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon="icon-back" :text="$t('general.back')" back></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{$t('admin.user_edit_title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <user-profile-form ref="form" v-if="finder" :user="finder">
      <f7-list>
        <loading-list-button
          color="brand-orange"
          :text="$t('general.save')"
          @click="save"
          :loading="saving"
        ></loading-list-button>
      </f7-list>
    </user-profile-form>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import events from '../../modules/events';

  export default {
    props: {
      id: [String, Number],
    },
    created() {
      const self = this;
      self.$store.dispatch('a/getUser', { id: parseInt(self.id, 10) });
    },
    computed: {
      form() {
        return this.$refs.form;
      },
      ...mapGetters({
        saving: 'a/userSaving',
        loading: 'a/userLoading',
        finder: 'a/user',
      }),
    },
    methods: {
      save() {
        const self = this;

        const valid = self.form.validate();
        if (!valid) return;

        const finder = self.finder;

        const newFinder = {
          id: finder.id || self.id,
          ...self.form.getData(),
        };

        self.$store.dispatch('a/saveUser', {
          user: newFinder,
          callbackOk() {
            events.$emit('store:admin:getUsersList');
            self.$f7router.back();
          },
        });
      },
      onPageBeforeRemove() {
        const self = this;
        self.$store.dispatch('a/unsetUser');
      },
    },
  };
</script>
