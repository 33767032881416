<template>
  <table>
    <thead>
      <tr>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('sortId')"
          @click="sort('sortId')"
        >
          <span>ID</span>
          <!-- <data-table-filter max-width="90" type="number" :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th>
          <span>{{$t('admin.table_role')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('sortName')"
        >
          <span @click="sort('sortName')">{{$t('general.name')}}</span>
          <data-table-filter :min-width="150" :placeholder="$t('general.filter')" @input="filter('queryName', $event.target.value)" :value="filters.name" />
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('sortEmail')"
        >
          <span @click="sort('sortEmail')">{{$t('general.email')}}</span>
          <data-table-filter :placeholder="$t('general.filter')" @input="filter('queryEmail', $event.target.value)" :value="filters.email" />
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('sortAffiliateCount')"
          @click="sort('sortAffiliateCount')"
        >
          <span>{{$t('admin.table_user_affiliates')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('sortIsConfirmed')"
          @click="sort('sortIsConfirmed')"
        >
          <span>{{$t('admin.table_user_confirmed')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('sortIsFacebook')"
          @click="sort('sortIsFacebook')"
        >
          <span>{{$t('admin.table_user_facebook')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('sortIsGoogle')"
          @click="sort('sortIsGoogle')"
        >
          <span>{{$t('admin.table_user_google')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th>
          <span>{{$t('admin.table_user_platform')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('sortCreatedDate')"
          @click="sort('sortCreatedDate')"
        >
          <span>{{$t('general.created')}}</span>
          <!-- <data-table-filter max-width="140" :placeholder="$t('general.filter')" calendar></data-table-filter> -->
        </th>
        <th
          class="sortable-cell no-ripple"
          :class="sortableClasses('sortLastActive')"
          @click="sort('sortLastActive')"
        >
          <span>{{$t('general.active')}}</span>
          <!-- <data-table-filter max-width="140" :placeholder="$t('general.filter')" calendar></data-table-filter> -->
        </th>
        <th class="actions-cell"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="user in users" :key="user.id">
        <td>{{user.id}}</td>
        <td>{{user.roles.map(role => $t(`roles.${role}`)).join(', ')}}</td>
        <td class="nowrap">{{user.firstName}} {{user.lastName}}</td>
        <td>{{user.email}}</td>
        <td>{{user.affiliateCount || ''}}</td>
        <td>
          <f7-icon v-if="user.isConfirmed" material="check"></f7-icon>
        </td>
        <td>
          <f7-icon v-if="user.isFacebook" material="check"></f7-icon>
        </td>
        <td>
          <f7-icon v-if="user.isGoogle" material="check"></f7-icon>
        </td>
        <td v-html="platform(user)"></td>
        <td class="nowrap">{{formatDate(user.createdDate)}}</td>
        <td class="nowrap">{{formatDate(user.lastActive)}} {{formatTime(user.lastActive)}}</td>
        <td class="actions-cell">
          <f7-link :href="`/users/edit/${user.id}/`" text-color="brand-green" icon-ios="f7:edit" icon-md="material:mode_edit"></f7-link>
          <f7-link text-color="brand-orange" icon-ios="f7:enter" icon-md="material:exit_to_app" @click="userLogin(user)"></f7-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
  import formatDate from '../../utils/format-date';
  import formatTime from '../../utils/format-time';
  import tableMixin from './table-mixin';

  export default {
    mixins: [tableMixin],
    props: {
      users: Array,
      sortBy: String,
      sortOrder: String,
      filters: Object,
    },
    methods: {
      platform(user) {
        const { hasPlatformBrowser, hasPlatformIos, hasPlatformAndroid } = user;
        return [
          hasPlatformBrowser && 'Browser',
          hasPlatformIos && 'iOS',
          hasPlatformAndroid && 'Android',
        ].filter(el => el).join('<br>');
      },
      sortableClasses(by) {
        const self = this;
        const { sortBy, sortOrder } = self;
        return {
          'sortable-cell-active': sortBy === by,
          'sortable-desc': sortBy === by && sortOrder === 'desc',
          'sortable-asc': sortBy === by && sortOrder === 'asc' || sortBy !== by,
        };
      },
      formatDate(date) {
        if (!date) return '';
        return formatDate(date, this.$root.language);
      },
      formatTime(date) {
        if (!date) return '';
        return formatTime(date);
      },
      sort(by) {
        const self = this;
        let order = self.sortOrder;
        if (self.sortBy === by) {
          if (order === 'desc') order = 'asc';
          else order = 'desc';
        } else {
          order = 'asc';
        }
        self.$emit('sortChange', by, order);
      },
      userLogin(user) {
        const self = this;
        self.$emit('userLogin', user);
      },
      filter(field, value) {
        const self = this;
        self.$emit('filter', field, value);
      },
    },
  };
</script>
