<template>
  <table>
    <thead>
      <tr>
        <th class="sortable-cell sortable-cell-active sortable-asc no-ripple">
          <span>ID</span>
          <!-- <data-table-filter max-width="90" type="number" :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th v-if="showOrder">
          <span>Order</span>
          <!-- <data-table-filter max-width="90" type="number" :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th>
          <span>{{$t('general.title')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th>
          <span>{{$t('general.company_name')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th>
          <span>{{$t('general.crowd')}}</span>
        </th>
        <th v-if="showUser">
          <span>{{$t('admin.table_user_name')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th>
          <span>{{$t('general.status')}}</span>
          <!-- <data-table-filter type="select" :clear-button="false">
            <option :value="null">{{$t(`assignment_status.All`)}}</option>
            <option
              v-for="(id, key) in statusList"
              :key="id"
              :value="id"
            >{{$t(`assignment_status.${key}`)}}</option>
          </data-table-filter> -->
        </th>
        <th class="sortable-cell sortable-desc no-ripple">
          <span>{{$t('general.created')}}</span>
          <!-- <data-table-filter max-width="140" :placeholder="$t('general.filter')" calendar></data-table-filter> -->
        </th>
        <th class="sortable-cell sortable-desc no-ripple">
          <span>{{$t('assignment_form.end_date_label')}}</span>
          <!-- <data-table-filter max-width="140" :placeholder="$t('general.filter')" calendar></data-table-filter> -->
        </th>
        <th class="actions-cell"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="assignment in assignments" :key="assignment.id">
        <td>{{assignment.id}}</td>
        <td v-if="showOrder">{{assignment.advertisementOrder}}</td>
        <td>{{assignment.title}}</td>
        <td><f7-link :href="`/companies/edit/${assignment.companyId}/`">{{assignment.companyName}}</f7-link></td>
        <td><f7-link :href="`/crowds/edit/${assignment.crowdId}/`">{{assignment.crowdName}}</f7-link></td>
        <td v-if="showUser" class="nowrap">
          <f7-link :href="`/users/edit/${assignment.userId}/`">{{assignment.firstName}} {{assignment.lastName}}</f7-link>
        </td>
        <td>{{$t(`assignment_status.${statusMap(assignment.status)}`)}}</td>
        <td class="nowrap">{{formatDate(assignment.createdDate)}}</td>
        <td class="nowrap">{{formatDate(assignment.endDate)}}</td>
        <td class="actions-cell">
          <f7-link :href="`/assignments/edit/${assignment.id}/`" text-color="brand-green" icon-ios="f7:edit" icon-md="material:mode_edit"></f7-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import statusList from '../../utils/assignment-status-list';
  import statusMap from '../../utils/assignment-status-map';
  import formatDate from '../../utils/format-date';
  import tableMixin from './table-mixin';

  export default {
    mixins: [tableMixin],
    props: {
      assignments: Array,
      showUser: Boolean,
      showOrder: Boolean,
    },
    data() {
      return {
        statusList,
      };
    },
    methods: {
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
      statusMap,
    },
  };
</script>
