<template>
  <div>
    <f7-block strong class="no-margin margin-top enterprise-tab-links" v-if="isEnterprise">
      <f7-link tab-link :tab-link-active="isEnterprise && activeEnterpriseTab === 'internal'" @click="changeEnterpriseTab('internal')">
        <template v-if="isEnterprise">
          <logo use-img v-if="companyLogo" :file="companyLogo" :size="300"></logo>
          <span v-else-if="companyName">{{companyName}}</span>
        </template>
      </f7-link>
      <f7-link tab-link :tab-link-active="isEnterprise && activeEnterpriseTab === 'public'" @click="changeEnterpriseTab('public')">{{$t('assignments.public_tab_title')}}</f7-link>
    </f7-block>

    <div v-if="isEnterprise" v-show="activeEnterpriseTab === 'internal'">
      <template v-if="!internalAssignments">
        <f7-block strong class="assignment-list-filter">
          <div class="input input-dropdown skeleton-text skeleton-effect-fade">
            <select disabled readonly>
              <option>____ ____ ____</option>
            </select>
          </div>
        </f7-block>
        <skeleton-assignment-list></skeleton-assignment-list>
      </template>
      <f7-block strong v-if="hasInternalAssignments || categoryInternal !== null" class="assignment-list-filter">
        <div class="input input-dropdown">
          <select :value="categoryInternal" @change="changeCategoryFilter('internal', $event.target.value)">
            <option :value="null">{{$t('assignment_category.All')}}</option>
            <option
              v-for="(category, key) in assignmentCategoryList"
              :value="category"
              :data-key="key"
              :key="key"
            >{{$t(`assignment_category.${key}`)}}</option>
          </select>
        </div>
      </f7-block>
      <f-assignment-list v-if="internalAssignments && internalAssignments.length" :assignments="internalAssignments"></f-assignment-list>
      <f7-block strong v-if="internalAssignments && !internalAssignments.length" class="text-align-center">
        {{$t('assignments.no_internal_assignments')}}
      </f7-block>
      <f7-preloader v-if="allowInfiniteInternal && internalListHasMore" class="infinite-scroll-preloader"></f7-preloader>
    </div>

    <div v-show="(!isEnterprise || isEnterprise && activeEnterpriseTab === 'public')">
      <template v-if="!assignments">
        <f7-block strong class="assignment-list-filter">
          <div class="input input-dropdown skeleton-text skeleton-effect-fade">
            <select disabled readonly>
              <option>____ ____ ____</option>
            </select>
          </div>
        </f7-block>
        <skeleton-assignment-list></skeleton-assignment-list>
      </template>
      <f7-block strong v-if="hasPublicAssignments || categoryPublic !== null" class="assignment-list-filter">
        <div class="input input-dropdown">
          <select :value="categoryPublic" @change="changeCategoryFilter('public', $event.target.value)">
            <option :value="null">{{$t('assignment_category.All')}}</option>
            <option
              v-for="(category, key) in assignmentCategoryList"
              :value="category"
              :data-key="key"
              :key="key"
            >{{$t(`assignment_category.${key}`)}}</option>
          </select>
        </div>
      </f7-block>
      <f-assignment-list v-if="assignments && assignments.length" :assignments="assignments"></f-assignment-list>
      <f7-block strong v-if="assignments && !assignments.length" class="text-align-center">
        {{$t('assignments.no_published_assignments')}}
      </f7-block>
      <f7-preloader v-if="allowInfiniteAll && listHasMore" class="infinite-scroll-preloader"></f7-preloader>
    </div>
  </div>
</template>
<script>
  import assignmentCategoryList from '../../utils/assignment-category-list';

  export default {
    props: {
      isEnterprise: Boolean,
      companyLogo: [String, Object],
      companyName: String,
      activeEnterpriseTab: String,
      hasInternalAssignments: Boolean,
      hasPublicAssignments: Boolean,
      categoryInternal: Number,
      categoryPublic: Number,
      allowInfiniteAll: Boolean,
      allowInfiniteInternal: Boolean,
      assignments: Array,
      internalAssignments: Array,
      listHasMore: Boolean,
      internalListHasMore: Boolean,
    },
    data() {
      return {
        assignmentCategoryList,
      };
    },
    methods: {
      changeCategoryFilter(type, value) {
        this.$emit('changeCategoryFilter', type, value);
      },
      changeEnterpriseTab(value) {
        this.$emit('changeEnterpriseTab', value);
      },
    },
  };
</script>

