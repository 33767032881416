<template>
  <f7-page class="assignment-details">
    <f7-navbar>
      <f7-nav-left>
        <back-link />
      </f7-nav-left>
      <f7-nav-title>{{$t('assignment.title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>

    <share-fab
      v-if="assignment && !assignment.isPrivate"
      :title="assignment.title"
      :message="assignment.description"
      :url="`${host}/assignment/${assignment.id}/${profile && profile.guid ? `?fid=${profile.guid}` : ''}`"
    />

    <div v-if="assignment" class="center-content">
      <assignment-details-header
        :assignment="assignment"
        :isCompany="true"
      />

      <template v-if="assignment.multipleLevels">
        <f7-block-title>{{$t('assignment.rewards_title')}}</f7-block-title>
        <div class="timeline rewards-timeline">
          <div class="timeline-item"
            v-for="(reward, index) in assignment.rewards"
            :key="reward.id"
            :class="{ active: reward.level === assignment.rewardLevel}"
          >
            <div class="timeline-item-divider">{{reward.level}}</div>
            <div class="timeline-item-content" style="width: 100%">
              <f7-list media-list inset class="assignment-rewards-list no-safe-areas">
                <f7-list-item
                  :subtitle="reward.description"
                  media-item
                >
                  <span class="assignment-rewards-list-level" slot="inner-start" v-if="reward.level === 1">{{$t('assignment.reward_level_number', { level: reward.level })}}:</span>
                  <span class="assignment-rewards-list-level" slot="inner-start" v-if="reward.level > 1">{{$t('assignment.reward_level_number', { level: reward.level })}} - {{$t('assignment.reward_after_level_reached', { level: reward.level -1 })}}:</span>
                  <span slot="title" v-html="$t('assignment.reward_to_fc_level_title', {
                    amount: `<span class=text-color-brand-green>${rewardLevelAmount(reward)}</span>`,
                  })"></span>
                  <div slot="title" v-if="assignment.multipleWinners" style="font-weight: 400; font-size: 14px; margin-bottom: 10px">- {{$t('assignment.accept_many_reward_info', { level: reward.level || (index + 1) })}}</div>
                  <span slot="text">{{rewardDetails(reward)}}</span>
                </f7-list-item>
              </f7-list>
            </div>
          </div>
        </div>
      </template>

      <template v-if="!assignment.anonymous && moreAssignments && moreAssignments.length">
        <f7-block-title v-if="moreAssignments && moreAssignments.length">{{$t('assignment.more_assignments_title', {companyName: assignment.companyName})}}</f7-block-title>
        <f-assignment-list v-if="moreAssignments" :assignments="moreAssignments" :logo="assignment.logo"></f-assignment-list>
      </template>
    </div>
    <skeleton-assignment-details v-else></skeleton-assignment-details>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import config from '../../config';
  import rewardDetails from '../../utils/reward-details';
  import rewardLevelAmount from '../../utils/reward-level-amount';
  import dispatchWithRetry from '../../utils/dispatch-with-retry';
  import assignmentCategoryList from '../../utils/assignment-category-list';
  import assignmentStatusList from '../../utils/assignment-status-list';

  export default {
    mixins: [dispatchWithRetry],
    props: {
      id: [String, Number],
    },
    data() {
      return {
        assignmentCategoryList,
        assignment: null,
        host: config.host,
      };
    },
    created() {
      const self = this;
      self.$store.dispatch('c/unsetAssignment');
      self.dispatchWithRetry('c/getAssignment', { id: parseInt(self.id, 10) });
    },
    watch: {
      currentAssignment(newValue) {
        const self = this;
        if (!newValue) return;
        if (newValue && newValue.id !== parseInt(self.id, 10)) {
          return;
        }
        self.assignment = newValue;
        if (window.cordova) {
          window.ga('send', {
            hitType: 'screenview',
            screenName: self.$f7route.url,
            appName: 'FindersCrowd',
            dimension1: localStorage.userRole,
            dimension2: self.assignment.title,
          });
        } else {
          window.ga('send', 'pageview', {
            page: self.$f7route.url,
            dimension1: localStorage.userRole,
            dimension2: self.assignment.title,
          });
        }
      },
    },
    computed: {
      isClosed() {
        return this.assignment && this.assignment.status === assignmentStatusList.Closed;
      },
      moreAssignments() {
        return this.assignment.companyAssignments;
      },
      ...mapGetters({
        currentAssignment: 'c/assignment',
        loading: 'c/assignmentLoading',
        profile: 'c/profile',
      }),
    },
    methods: {
      rewardLevelAmount(reward) {
        const self = this;
        const { assignment } = self;
        return rewardLevelAmount(self, assignment, reward);
      },
      rewardDetails(reward) {
        const self = this;
        const { assignment } = self;
        return rewardDetails(self, assignment, reward);
      },
    },
  };
</script>
