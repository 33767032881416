import { Template7 } from 'framework7';
import en from '../locales/en';
import sv from '../locales/sv';

const locales = {
  en,
  sv,
};

const templatesCache = {};

function i18n(message, data = {}, forceLocale) {
  const self = this;
  const lang = self.$root.language;
  if (!templatesCache[lang]) templatesCache[lang] = {};

  let locale = forceLocale || locales[lang];
  if (!locale) locale = locales.en;

  let text = locale;
  let errored;
  message.split('.').forEach((part) => {
    if (text && text[part]) {
      text = text[part];
    } else {
      errored = true;
    }
  });

  if (errored && locale !== locales.en) {
    return i18n.call(self, message, data, locales.en);
  }

  if (!text || errored) return '';
  if (text.indexOf('{{') >= 0 && text.indexOf('}}') >= 0) {
    if (!templatesCache[lang][message]) {
      templatesCache[lang][message] = Template7.compile(text);
    }
    text = templatesCache[lang][message](data);
  }
  return text;
}

export { locales };
export default i18n;
