import openExternalUrl from './open-external-url';

export default {
  cordova({ message, subject, url, rect } = {}) {
    if (rect) {
      window.plugins.socialsharing.setIPadPopupCoordinates(`${rect.left},${rect.top},${rect.width},${rect.height}`);
    }
    window.plugins.socialsharing.shareWithOptions({
      message,
      subject,
      url,
    }, () => {}, () => {});
  },
  facebookCordova({ url } = {}) {
    if (window.facebookConnectPlugin) {
      window.facebookConnectPlugin.showDialog({
        method: 'share',
        href: url,
      });
    }
  },
  facebook({ url } = {}) {
    if (window.FB) {
      window.FB.ui({
        method: 'share',
        href: url,
      });
    } else if (window.cordova && window.facebookConnectPlugin) {
      window.facebookConnectPlugin.showDialog({
        method: 'share',
        href: url,
      });
    }
  },
  linkedin({ url, title, summary } = {}) {
    const shareUrl = `https://www.linkedin.com/shareArticle?url=${url}&title=${title}&summary=${summary}&source=${encodeURI('FindersCrowd')}&mini=true`;
    if (window.cordova) {
      openExternalUrl(shareUrl, '_system');
    } else {
      window.open(shareUrl, '_blank', 'width=520,height=570');
    }
  },
};
