/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';
import PWA from '../../modules/pwa';

const state = {
  candidate: null,
  loading: false,
  saving: false,
};

const getters = {
  candidateLoading(state) {
    return state.loading;
  },
  candidateSaving(state) {
    return state.saving;
  },
  candidate(state) {
    return state.candidate;
  },
};
const actions = {
  getCandidate({ commit }, { id, callbackOk, callbackError, cache = true } = {}) {
    API.get({
      url: `company/candidate/details?id=${id}`,
      loading: 'c/setCandidateLoading',
      cache,
      ok(response) {
        commit('setCandidate', response);
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  unsetCandidate({ commit }) {
    commit('unsetCandidate');
  },
  rateCandidate({ dispatch }, { id, rating, callbackOk, callbackError } = {}) {
    API.post({
      url: 'company/candidate/rate',
      data: { id, rating },
      loading: 'c/setCandidateSaving',
      ok(response) {
        dispatch('getCandidate', { id, cache: false });
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  favoriteCandidate({ dispatch }, { id, callbackOk, callbackError } = {}) {
    API.post({
      url: 'company/candidate/favorite',
      data: { id, favorite: true },
      loading: 'c/setCandidateSaving',
      ok(response) {
        dispatch('getCandidate', { id, cache: false });
        dispatch('updateMyAssignment');
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  unfavoriteCandidate({ dispatch }, { id, callbackOk, callbackError } = {}) {
    API.post({
      url: 'company/candidate/favorite',
      data: { id, favorite: false, cache: false },
      loading: 'c/setCandidateSaving',
      ok(response) {
        dispatch('getCandidate', { id });
        dispatch('updateMyAssignment');
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  changeCandidateStatus({ dispatch }, { id, status, rewardId, transactionValue, callbackOk, callbackError }) {
    API.post({
      url: 'company/candidate/status',
      data: { id, status, rewardId, transactionValue },
      loading: 'c/setCandidateSaving',
      ok(response) {
        dispatch('getCandidate', { id, cache: false });
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  moveToNextLevel({ dispatch }, { id, callbackOk, callbackError } = {}) {
    API.post({
      url: 'company/candidate/level',
      data: { id },
      loading: 'c/setCandidateSaving',
      ok(response) {
        dispatch('getCandidate', { id, cache: false });
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  saveCandidateNotes({ dispatch }, { id, notes, callbackOk, callbackError } = {}) {
    API.post({
      url: 'company/candidate/notes',
      data: { id, notes },
      loading: 'c/setCandidateSaving',
      ok(response) {
        if (state.candidate) {
          const newCandidate = {
            ...state.candidate,
            notes,
          };
          state.candidate.notes = notes;
          PWA.updateCachedApi(`company/candidate/details?id=${id}`, newCandidate);
        }
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
};

const mutations = {
  setCandidate(state, item) {
    state.candidate = item;
  },
  setCandidateLoading(state, status) {
    state.loading = status;
  },
  unsetCandidate(state) {
    state.candidate = null;
  },
  setCandidateSaving(state, status) {
    state.saving = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
