<template>
  <table>
    <thead>
      <tr>
        <th class="sortable-cell sortable-cell-active sortable-asc no-ripple">
          <span>ID</span>
          <!-- <data-table-filter max-width="90" type="number" :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th>
          <span>{{$t('general.company_name')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th>
          <span>{{$t('general.email')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th>
          <span>{{$t('general.user')}}</span>
          <!-- <data-table-filter :placeholder="$t('general.filter')"></data-table-filter> -->
        </th>
        <th class="sortable-cell sortable-desc no-ripple">
          <span>{{$t('general.created')}}</span>
          <!-- <data-table-filter max-width="140" :placeholder="$t('general.filter')" calendar></data-table-filter> -->
        </th>
        <th class="actions-cell"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="company in companies"
        :key="company.id"
      >
        <td>{{company.id}}</td>
        <td class="nowrap">{{company.companyName}}</td>
        <td>{{company.email}}</td>
        <td class="nowrap">
          <f7-link v-if="company.user" :href="`/users/edit/${company.user.id}/`">{{company.user.firstName}} {{company.user.lastName}}</f7-link>
        </td>
        <td class="nowrap">{{formatDate(company.createdDate)}}</td>
        <td class="actions-cell">
          <f7-link :href="`/companies/edit/${company.id}/`" text-color="brand-green" icon-ios="f7:edit" icon-md="material:mode_edit"></f7-link>
          <f7-link @click="userLogin(company.user)" text-color="brand-orange" icon-ios="f7:enter" icon-md="material:exit_to_app"></f7-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
  import formatDate from '../../utils/format-date';
  import tableMixin from './table-mixin';

  export default {
    mixins: [tableMixin],
    props: {
      companies: Array,
    },
    methods: {
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
      userLogin(user) {
        const self = this;
        self.$emit('userLogin', user);
      },
    },
  };
</script>
