// eslint-disable-next-line
let language = window.localStorage.finderscrowd_lang;

if (!language) {
  const lang = window.navigator.language;
  if (lang.indexOf('sv') === 0) {
    language = 'sv';
  } else {
    language = 'en';
  }
  window.localStorage.finderscrowd_lang = language;
}

export default language;
