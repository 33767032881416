<template>
  <f7-page>
    <f7-list no-hairlines class="no-margin">
      <f7-list-item
        :class="{ 'admin-menu-active-link': active === 'dashboard' }"
        panel-close
        reload-current
        link="/"
        view=".view-main"
        :title="$t('admin.menu_dashboard')"
      >
        <f7-icon ios="f7:data_fill" slot="media" md="material:dashboard"></f7-icon>
      </f7-list-item>
      <f7-list-item
        :class="{ 'admin-menu-active-link': active === 'crowds' }"
        panel-close
        reload-current
        link="/crowds/"
        view=".view-main"
        :title="$t('admin.menu_crowds')"
      >
        <f7-icon ios="f7:world" slot="media" md="material:language"></f7-icon>
      </f7-list-item>
      <f7-list-item
        :class="{ 'admin-menu-active-link': active === 'companies' }"
        panel-close
        reload-current
        link="/companies/"
        view=".view-main"
        :title="$t('admin.menu_companies')"
      >
        <f7-icon ios="f7:briefcase_fill" slot="media" md="material:work"></f7-icon>
      </f7-list-item>
      <f7-list-item
        :class="{ 'admin-menu-active-link': active === 'assignments' }"
        panel-close
        reload-current
        link="/assignments/"
        view=".view-main"
        :title="$t('admin.menu_assignments')"
      >
        <f7-icon ios="f7:document_text_fill" slot="media" md="material:assignment"></f7-icon>
      </f7-list-item>
      <f7-list-item
        :class="{ 'admin-menu-active-link': active === 'users' }"
        panel-close
        reload-current
        link="/users/"
        view=".view-main"
        :title="$t('admin.menu_users')"
      >
        <f7-icon ios="f7:persons_fill" slot="media" md="material:people"></f7-icon>
      </f7-list-item>
      <f7-list-item
        :class="{ 'admin-menu-active-link': active === 'candidates' }"
        panel-close
        reload-current
        link="/candidates/"
        view=".view-main"
        :title="$t('admin.menu_candidates')"
      >
        <f7-icon ios="f7:document_check_fill" slot="media" md="material:assignment_turned_in"></f7-icon>
      </f7-list-item>
    </f7-list>
    <f7-list v-if="user && user.roleOptions && user.roleOptions.length >= 2">
      <f7-list-item
        v-if="currentRole !== role"
        v-for="(role, index) in user.roleOptions"
        :key="index"
        link="#"
        @click="changeRole(role)"
      >
        <f7-icon ios="f7:refresh" slot="media" md="material:refresh">
          <sup v-if="$theme.ios">{{role === 0 ? 'person_round_fill' : 'briefcase_fill'}}</sup>
          <sup v-else>{{role === 0 ? 'person' : 'work'}}</sup>
        </f7-icon>
        <span style="white-space: normal">{{$t('profile.switch_account_to', { type: rolesMap(role) })}}</span>
      </f7-list-item>
    </f7-list>
    <f7-list no-hairlines class="no-margin-bottom">
      <f7-list-item
        :title="$t('admin.menu_logout')"
        link="#"
        @click="logout"
      >
        <f7-icon ios="f7:exit_fill" slot="media" md="material:exit_to_app"></f7-icon>
      </f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    data() {
      return {
        active: null,
      };
    },
    computed: {
      currentRole() {
        return ['finder', 'company', 'admin'].indexOf(this.$root.userRole);
      },
      ...mapGetters({
        user: 'a/profile',
      }),
    },
    mounted() {
      const self = this;
      self.$store.dispatch('a/getProfile');
      self.$f7ready((f7) => {
        f7.on('routeChange', (to, from, router) => {
          if (!router.view || !router.view.main) return;
          if (to.path.indexOf('/finders/') >= 0) self.active = 'finders';
          else if (to.path.indexOf('/users/') >= 0) self.active = 'users';
          else if (to.path.indexOf('/crowds/') >= 0) self.active = 'crowds';
          else if (to.path.indexOf('/candidates/') >= 0) self.active = 'candidates';
          else if (to.path.indexOf('/assignments/') >= 0) self.active = 'assignments';
          else if (to.path.indexOf('/companies/') >= 0) self.active = 'companies';
          else if (to.path.indexOf('/') >= 0) self.active = 'dashboard';
          else self.active = null;
        });
      });
    },
    methods: {
      changeRole(role) {
        this.$root.changeRole(['finder', 'company', 'admin'][role]);
      },
      rolesMap(role) {
        return this.$t(`roles.${['Finder', 'CompanyPossesionForm', 'Admin'][role]}`).toLowerCase();
      },
      logout() {
        this.$root.logout();
      },
    },
  };
</script>
