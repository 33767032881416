export default function (url) {
  let yt;
  let vimeo;
  let dreamBroker;
  // YouTube
  if (url.indexOf('youtube.com') >= 0) {
    try {
      url.split('?')[1].split('&').forEach((part) => {
        if (part.indexOf('v=') === 0) yt = part.split('=')[1];
      });
    } catch (e) {
      // no video ID
    }
  }
  if (url.indexOf('youtu.be') >= 0) {
    try {
      yt = url.split('youtu.be/')[1].replace(/\./, '');
    } catch (e) {
      // no video ID
    }
  }
  // Vimeo
  if (url.indexOf('vimeo.com') >= 0) {
    try {
      vimeo = url.split('/')[url.split('/').length - 1];
    } catch (e) {
      // no video ID
    }
  }

  // dreamBroker
  if (url.indexOf('dreambroker.com') >= 0) {
    try {
      dreamBroker = url.split('/channel/')[1].split('/');
    } catch (e) {
      // no vide ID
    }
  }
  if (dreamBroker && dreamBroker.length === 1) dreamBroker = undefined;

  return {
    yt,
    vimeo,
    dreamBroker,
  };
}
