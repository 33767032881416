import config from '../config';
import Request from './request';
import events from './events';

const Push = {
  push: null,
  registerDevice(data) {
    const { device } = window;
    const dataToSend = {
      accessToken: window.localStorage.getItem('accessToken'),
      token: data.registrationId,
      platform: device.platform.toLowerCase(),
      deviceId: device.uuid,
    };
    Request({
      url: `${config.host}/api/common/notification/token`,
      method: 'POST',
      dataType: 'json',
      processData: false,
      contentType: 'application/json',
      crossDomain: true,
      data: JSON.stringify(dataToSend),
      success() {
      },
      error(err) { // eslint-disable-line
        // send error?
      },
    });
  },
  setBadge(count) {
    if (Push.push) {
      Push.push.setApplicationIconBadgeNumber(() => {}, () => {}, count);
    }
  },
  getBadge(callback) {
    if (Push.push) {
      Push.push.getApplicationIconBadgeNumber((n) => {
        callback(n);
      }, () => {});
    }
  },
  handleRegistration(data) {
    Push.registerDevice(data);
  },
  handleNotification(data) {
    if (Push.push && Push.push.finish) {
      Push.push.finish(() => { }, () => { }, data.additionalData.notId);
    }
    let newBadgeNumber = 1;
    if (typeof data.count !== 'undefined') newBadgeNumber = data.count;
    Push.setBadge(newBadgeNumber);
    if (data && data.additionalData && data.additionalData.foreground) {
      return;
    }
    if (!data.additionalData) {
      return;
    }
    const url = data.additionalData.url;
    if (!url) return;
    events.$emit('pushNavigate', url);
  },
  handleError() {
    // Send error
  },
  init() {
    function pushInit() {
      Push.push = window.PushNotification.init({
        android: {
          icon: 'notification',
          iconColor: '#f47321',
        },
        ios: {
          alert: 'true',
          badge: 'true',
          sound: 'true',
        },
        windows: {},
      });
      Push.push.on('registration', Push.handleRegistration);
      Push.push.on('notification', Push.handleNotification);
      Push.push.on('error', Push.handleError);
    }
    window.PushNotification.hasPermission((data) => {
      if (data.isEnabled) {
        pushInit();
        return;
      }
      events.$emit('requestNotificationsBanner');
      events.$on('notificationsBannerEnable', () => {
        pushInit();
      });
    });
  },
};

export default Push;
