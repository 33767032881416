import $ from 'dom7';
import config from '../config';
import Request from './request';
import Framework7 from '../libs/framework7';

const Activity = {
  endpoint: 'common/activity/track',
  track() {
    const userRole = window.localStorage.userRole || null;
    const loggedIn = !!window.localStorage.accessToken && userRole;
    if (!loggedIn) {
      return;
    }
    let platform = 0;
    if (window.cordova) {
      platform = Framework7.device.android ? 2 : 1;
    }
    Request({
      url: `${config.host}/api/${Activity.endpoint}`,
      method: 'GET',
      data: {
        platform,
      },
      cache: false,
    });
  },
  init() {
    if (window.cordova) {
      $(document).on('resume', () => {
        Activity.track();
      });
    } else {
      $(document).on('visibilitychange', () => {
        if (document.visibilityState === 'visible') {
          Activity.track();
        }
      });
    }
  },
};

export default Activity;
