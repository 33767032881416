<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <f7-link icon="icon-back" :text="$t('general.back')" back></f7-link>
      </f7-nav-left>
      <f7-nav-title>{{$t('admin.company_create_title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <user-profile-form ref="form">
      <f7-list>
        <loading-list-button color="brand-orange" :text="$t('general.create')" @click="create" :loading="saving" :disabled="created"></loading-list-button>
      </f7-list>
    </user-profile-form>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import events from '../../modules/events';

  export default {
    data() {
      return {
        created: false,
      };
    },
    computed: {
      form() {
        return this.$refs.form;
      },
      ...mapGetters({
        saving: 'a/companySaving',
      }),
    },
    methods: {
      create() {
        const self = this;

        const valid = self.form.validate();
        if (!valid) return;

        const newCompany = {
          id: 0,
          ...self.form.getData(),
        };

        self.$store.dispatch('a/saveCompany', {
          company: newCompany,
          callbackOk() {
            self.created = true;
            events.$emit('store:admin:getCompaniesList');
            self.$f7router.back();
          },
        });
      },
    },
  };
</script>
