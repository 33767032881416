/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';
import Push from '../../modules/push';
import PWA from '../../modules/pwa';

const state = {
  list: null,
  listLoading: false,
  listMoreLoading: false,
  listSkip: 0,
  listTake: 20,
  myList: null,
  myListLoading: false,
  myListMoreLoading: false,
  myListSkip: 0,
  myListTake: 20,
  dashboardData: null,
  assignment: null,
  assignmentLoading: false,
  candidateSending: false,
  recommendation: null,
  recommendationSending: false,
  recommendationLoading: false,
  listCategory: null,
  listHasMore: true,
  myListHasMore: true,
};

const getters = {
  assignmentList(state) {
    return state.list;
  },
  assignmentListLoading(state) {
    return state.listLoading;
  },
  assignmentListMoreLoading(state) {
    return state.listMoreLoading;
  },
  myAssignmentList(state) {
    return state.myList;
  },
  myAssignmentListLoading(state) {
    return state.myListLoading;
  },
  myAssignmentListMoreLoading(state) {
    return state.myListMoreLoading;
  },
  dashboardData(state) {
    return state.dashboardData;
  },
  assignmentLoading(state) {
    return state.assignmentLoading;
  },
  assignment(state) {
    return state.assignment;
  },
  candidateSending(state) {
    return state.candidateSending;
  },
  recommendation(state) {
    return state.recommendation;
  },
  recommendationSending(state) {
    return state.recommendationSending;
  },
  recommendationLoading(state) {
    return state.recommendationLoading;
  },
  assignmentListCategory(state) {
    return state.listCategory;
  },
  assignmentListHasMore(state) {
    return state.listHasMore;
  },
  myAssignmentListHasMore(state) {
    return state.myListHasMore;
  },
};
const actions = {
  getAssignmentList({ commit }) {
    commit('setListSkip', 0);
    commit('setListHasMore', true);
    API.get({
      url: `finder/assignment/list?category=${state.listCategory}&skip=${state.listSkip}&take=${state.listTake}`,
      loading: 'f/setAssignmentListLoading',
      ok(response) {
        commit('setAssignmentList', response.items);
      },
    });
  },
  updateAssignmentList({ dispatch }, { callbackOk } = {}) {
    dispatch('getAssignmentList', { callbackOk, cache: false });
  },
  getMoreAssignmentList({ commit, state }) {
    commit('setListSkip', state.listSkip + state.listTake);
    API.get({
      url: `finder/assignment/list?category=${state.listCategory}&skip=${state.listSkip}&take=${state.listTake}`,
      loading: 'f/setAssignmentListLoading f/setAssignmentListMoreLoading',
      cache: false,
      ok(response) {
        if (!response || (response.items && response.items.length < 20)) {
          commit('setListHasMore', false);
          if (!response.items.length) return;
        }
        commit('addToAssignmentList', response.items);
      },
    });
  },
  unsetAssignmentList({ commit }) {
    commit('unsetAssignmentList');
  },
  getMyAssignmentList({ commit, state }, { callbackOk, callbackError, cache = true, setHasMore = true } = {}) {
    commit('setMyListSkip', 0);
    if (setHasMore) {
      commit('setMyListHasMore', true);
    }
    API.get({
      url: `finder/assignment/mylist?skip=${state.myListSkip}&take=${state.myListTake}`,
      loading: 'f/setMyAssignmentListLoading',
      cache,
      ok(response) {
        const {
          rating,
          earnings,
          potentialEarnings,
          recommendations,
          potentialRecommendations,
          unreadCount,
          pendingInvite,
          items,
        } = response;

        const dashboardData = {
          rating,
          earnings,
          potentialEarnings,
          recommendations,
          potentialRecommendations,
          unreadCount,
          pendingInvite,
        };
        commit('setDashboardData', dashboardData);
        commit('setMyAssignmentList', items);
        if (callbackOk) callbackOk(items);
      },
      error() {
        if (callbackError) callbackError();
      },
    });
  },
  updateMyAssignmentList({ dispatch }, { callbackOk } = {}) {
    dispatch('getMyAssignmentList', { callbackOk, cache: false, setHasMore: false });
  },
  getMoreMyAssignmentList({ commit, state }) {
    commit('setMyListSkip', state.myListSkip + state.myListTake);
    API.get({
      url: `finder/assignment/mylist?skip=${state.myListSkip}&take=${state.myListTake}`,
      loading: 'f/setMyAssignmentListLoading f/setMyAssignmentListMoreLoading',
      cache: false,
      ok(response) {
        if (!response || (response.items && response.items.length < 20)) {
          commit('setMyListHasMore', false);
          if (!response.items.length) return;
        }
        commit('addToMyAssignmentList', response.items);
      },
    });
  },
  unsetMyAssignmentList({ commit }) {
    commit('unsetMyAssignmentList');
  },
  getAssignment({ commit }, { id, callbackOk, callbackError } = {}) {
    API.get({
      url: `finder/assignment/details?id=${id}`,
      loading: 'f/setAssignmentLoading',
      ok(response) {
        commit('setAssignment', response);
        if (callbackOk) callbackOk(response);
      },
      error() {
        if (callbackError) callbackError();
      },
    });
  },
  updateAssignment({ commit }, { id, callbackOk, callbackError } = {}) {
    API.get({
      url: `finder/assignment/details?id=${id}`,
      loading: 'f/setAssignmentLoading',
      cache: false,
      ok(response) {
        commit('setAssignment', response);
        if (callbackOk) callbackOk(response);
      },
      error() {
        if (callbackError) callbackError();
      },
    });
  },
  unsetAssignment({ commit }) {
    commit('unsetAssignment');
  },
  sendCandidate({ dispatch }, { assignmentId, email, guid, recommendation, callbackOk, callbackError } = {}) {
    API.post({
      url: 'finder/candidate/recommend',
      data: { assignmentId, email, guid, recommendation },
      loading: 'f/setCandidateSending',
      ok(response) {
        dispatch('getAssignmentList');
        dispatch('getMyAssignmentList', { cache: false });
        if (callbackOk) callbackOk(response);
      },
      error() {
        if (callbackError) callbackError();
      },
    });
  },
  getRecommendation({ commit }, { guid, callbackOk }) {
    API.get({
      url: `/finder/candidate/details?guid=${guid}`,
      loading: 'f/setRecommendationLoading',
      cache: false,
      ok(response) {
        commit('setRecommendation', response);
        if (callbackOk) callbackOk(response);
      },
    });
  },
  unsetRecommendation({ commit }) {
    commit('unsetRecommendation');
  },
  sendRecommendation({ dispatch }, { data, forceRecommend, sync, syncMessage, callbackOk, callbackError } = {}) {
    API.post({
      url: data.guid && !forceRecommend ? 'finder/candidate/update' : 'finder/candidate/directly',
      data,
      filesToUploadProp: 'attachments',
      loading: 'f/setRecommendationSending',
      sync,
      syncMessage,
      ok(response) {
        PWA.updateCachedApi(`/finder/candidate/details?guid=${data.guid}`, { ...(state.recommendation || {}), ...data });
        dispatch('updateAssignment', { id: parseInt(data.assignmentId, 10) });
        if (callbackOk) callbackOk(response);
      },
      error() {
        if (callbackError) callbackError();
      },
    });
  },
  setFinderAnonymous({ dispatch }, { assignmentId, finderAnonymous, callbackOk, callbackError }) {
    API.post({
      url: 'finder/assignment/anonymous',
      data: {
        assignmentId,
        finderAnonymous,
      },
      loading: 'f/setAssignmentLoading',
      ok(response) {
        PWA.updateCachedApi(`finder/assignment/details?id=${assignmentId}`, { ...(state.assignment || {}), ...{ finderAnonymous } });
        dispatch('updateAssignment', { id: parseInt(assignmentId, 10) });
        if (callbackOk) callbackOk(response);
      },
      error() {
        if (callbackError) callbackError();
      },
    });
  },
  setAssignmentListCategory({ commit, dispatch }, { category }) {
    commit('setListCategory', category === null ? null : parseInt(category, 10));
    dispatch('updateAssignmentList');
  },
};

const mutations = {
  setAssignmentList(state, assignments) {
    state.list = assignments;
  },
  addToAssignmentList(state, assignments) {
    state.list.push(...assignments);
  },
  unsetAssignmentList(state) {
    state.listSkip = 0;
    state.list = null;
  },
  setListSkip(state, val) {
    state.listSkip = val;
  },
  setMyAssignmentList(state, assignments) {
    state.myList = assignments;
  },
  addToMyAssignmentList(state, assignments) {
    state.myList.push(...assignments);
  },
  unsetMyAssignmentList(state) {
    state.myListSkip = 0;
    state.myList = null;
  },
  setMyListSkip(state, val) {
    state.myListSkip = val;
  },
  setDashboardData(state, dashboardData) {
    state.dashboardData = dashboardData;
  },
  setAssignment(state, item) {
    state.assignment = item;
  },
  setAssignmentLoading(state, status) {
    state.assignmentLoading = status;
  },
  unsetAssignment(state) {
    state.assignment = null;
  },
  setAssignmentListLoading(state, status) {
    state.listLoading = status;
  },
  setAssignmentListMoreLoading(state, status) {
    state.listMoreLoading = status;
  },
  setMyAssignmentListLoading(state, status) {
    state.myListLoading = status;
  },
  setMyAssignmentListMoreLoading(state, status) {
    state.myListMoreLoading = status;
  },
  setCandidateSending(state, status) {
    state.candidateSending = status;
  },
  setRecommendation(state, data) {
    state.recommendation = data;
  },
  setRecommendationLoading(state, status) {
    state.recommendationLoading = status;
  },
  setRecommendationSending(state, status) {
    state.recommendationSending = status;
  },
  unsetRecommendation(state) {
    state.recommendation = null;
  },
  setListCategory(state, data) {
    state.listCategory = data;
  },
  setListHasMore(state, value) {
    state.listHasMore = value;
  },
  setMyListHasMore(state, value) {
    state.myListHasMore = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
