import events from './events';

const notificationsQueue = [];
let currentNotification;
function processNotificationsQueue() {
  if (currentNotification) return;
  currentNotification = notificationsQueue.shift();
  if (currentNotification) currentNotification();
}

const Notifications = {
  handlePushNavigate(url) {
    setTimeout(() => {
      events.$emit('f7instance', (f7) => {
        if (url.indexOf('userRole=') >= 0) {
          const requiredRole = url.split('userRole=')[1];
          const loggedIn = !!window.localStorage.accessToken;
          if (requiredRole !== localStorage.userRole && loggedIn) {
            let redirect = url.split('userRole=')[0];
            redirect = redirect.substr(0, redirect.length - 1);
            events.$emit('changeRole', requiredRole, null, redirect);
          } else {
            f7.views.main.router.navigate(url);
          }
        } else {
          f7.views.main.router.navigate(url);
        }
      });
    }, 1000);
  },
  handlePushForeground(data) {
    setTimeout(() => {
      events.$emit('f7instance', (f7) => {
        notificationsQueue.push(() => {
          const notification = f7.notification.create({
            title: data.title,
            text: data.body,
            closeOnClick: true,
            closeButton: true,
            closeTimeout: 4 * 1000,
            on: {
              closed() {
                setTimeout(() => {
                  notification.destroy();
                  currentNotification = undefined;
                  processNotificationsQueue();
                }, 0);
              },
              click() {
                const userRole = window.localStorage.userRole || null;
                const loggedIn = !!window.localStorage.accessToken && userRole;
                if (data.url && loggedIn && f7 && f7.views.main) {
                  f7.views.main.router.navigate(data.url);
                }
              },
            },
          });
          notification.open();
        });
        processNotificationsQueue();
      });
    }, 1000);
  },
  handleShowNotificationsBanner(success) {
    if (window.localStorage.notificationsBannerShown) return;
    events.$emit('showNotificationsBanner', () => {
      if (success) success();
    });
  },
  init() {
    events.$on('pushNavigate', (url) => {
      Notifications.handlePushNavigate(url);
    });
    events.$on('pushForeground', (data) => {
      Notifications.handlePushForeground(data);
    });
    events.$on('requestNotificationsBanner', (success) => {
      Notifications.handleShowNotificationsBanner(success);
    });
  },
};

export default Notifications;
