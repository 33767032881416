// Common components
import App from './app.vue';
import AppMenu from './common/app-menu.vue';
import Rating from './common/rating.vue';
import Attachments from './common/attachments.vue';
import Logo from './common/logo.vue';
import Avatar from './common/avatar.vue';
import ShareoutSlider from './common/shareout-slider.vue';
import Slider from './common/slider.vue';
import LoadingListButton from './common/loading-list-button.vue';
import LoadingButton from './common/loading-button.vue';
import DataTableFilter from './common/data-table-filter.vue';
import Gauge from './common/gauge.vue';
import Chat from './common/chat.vue';
import Reward from './common/reward.vue';
import RichText from './common/rich-text.vue';
import UrlPreview from './common/url-preview.vue';
import ProfileLink from './common/profile-link.vue';
import PieChart from './common/pie-chart.vue';
import ImageGallery from './common/image-gallery.vue';
import OfflineIndicator from './common/offline-indicator.vue';
import PublishedAssignments from './common/published-assignments.vue';
import BackLink from './common/back-link.vue';
import SkeletonAssignmentList from './common/skeleton-assignment-list.vue';
import SkeletonAssignmentDetails from './common/skeleton-assignment-details.vue';
import SkeletonCompanyDetails from './common/skeleton-company-details.vue';
import SkeletonApplicationDetails from './common/skeleton-application-details.vue';
import ShareFab from './common/share-fab.vue';
import PanelLink from './common/panel-link.vue';
import ChatButton from './common/chat-button.vue';
import AssignmentDetailsHeader from './common/assignment-details-header.vue';

// Forms
import UserProfileForm from './forms/user-profile-form.vue';
import CompanyDetailsForm from './forms/company-details-form.vue';
import AssignmentForm from './forms/assignment-form.vue';
import CandidateForm from './forms/candidate-form.vue';
import ApplicationForm from './forms/application-form.vue';
import CrowdForm from './forms/crowd-form.vue';

// Admin components
import AFinderRewardListTable from './admin/finder-reward-list-table.vue';
import ACrowdListTable from './admin/crowd-list-table.vue';
import ACompanyListTable from './admin/company-list-table.vue';
import AAssignmentListTable from './admin/assignment-list-table.vue';
import AUserListTable from './admin/user-list-table.vue';
import ACandidateListTable from './admin/candidate-list-table.vue';
import ATablePagination from './admin/table-pagination.vue';

// Company components
import CAssignmentList from './company/assignment-list.vue';
import CTransactionListTable from './company/transaction-list-table.vue';

// Finder components
import FAssignmentList from './finder/assignment-list.vue';
import FTransactionListTable from './finder/transaction-list-table.vue';
import FContactedCandidates from './finder/contacted-candidates.vue';
import FContactedCandidatesList from './finder/contacted-candidates-list.vue';

export default {
  // Common components
  App,
  AppMenu,
  Rating,
  Attachments,
  Logo,
  Avatar,
  ShareoutSlider,
  Slider,
  LoadingListButton,
  LoadingButton,
  DataTableFilter,
  Gauge,
  Chat,
  Reward,
  RichText,
  UrlPreview,
  ProfileLink,
  PieChart,
  ImageGallery,
  OfflineIndicator,
  PublishedAssignments,
  BackLink,
  SkeletonAssignmentList,
  SkeletonAssignmentDetails,
  SkeletonCompanyDetails,
  SkeletonApplicationDetails,
  ShareFab,
  PanelLink,
  ChatButton,
  AssignmentDetailsHeader,

  // Shared forms
  AssignmentForm,
  UserProfileForm,
  CompanyDetailsForm,
  CandidateForm,
  ApplicationForm,
  CrowdForm,

  // Admin
  AFinderRewardListTable,
  ACrowdListTable,
  ACompanyListTable,
  AAssignmentListTable,
  AUserListTable,
  ACandidateListTable,
  ATablePagination,
  // Company
  CAssignmentList,
  CTransactionListTable,
  // Finder
  FAssignmentList,
  FTransactionListTable,
  FContactedCandidates,
  FContactedCandidatesList,
};
