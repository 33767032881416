<template>
  <f7-page infinite @infinite="onInfinite" :infinite-preloader="false">
    <f7-navbar large>
      <panel-link slot="nav-left" />
      <f7-nav-title>
        <f7-link href="/profile/notifications/">
          <span>{{$t('notifications_page.title')}}</span>
          <f7-icon ios="f7:gear_fill" md="material:settings" :size="24" />
        </f7-link>
      </f7-nav-title>
      <f7-nav-right>
        <profile-link />
      </f7-nav-right>
      <f7-nav-title-large>
        <f7-link href="/profile/notifications/" class="display-inline-flex">
          <span>{{$t('notifications_page.title')}}</span>
          <f7-icon ios="f7:gear_fill" md="material:settings" :size="24" />
        </f7-link>
      </f7-nav-title-large>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <div class="center-content">
      <f7-list class="notification-list" media v-if="notifications && notifications.length">
        <f7-list-item
          media-item
          v-for="item in notifications"
          :key="item.id"
          :class="{'notification-unread': isUnread(item)}"
          :title="item.title"
          :subtitle="item.subtitle"
          :link="item.link"
          :text="item.text"
          :after="formatDate(item.createdDate)"
          @click="markAsRead(item)"
        ></f7-list-item>
      </f7-list>
      <f7-list class="notification-list skeleton-text skeleton-effect-fade" media v-if="!notifications">
        <f7-list-item
          media-item
          v-for="n in 10"
          :key="n"
          title="_____"
          link
          text="____________ ____"
          after="__ ___ ____"
        ></f7-list-item>
      </f7-list>
      <f7-block strong class="empty-block" v-if="notifications && !notifications.length">
        {{$t('notifications_page.no_notifications')}}
      </f7-block>
      <f7-preloader v-if="allowInfinite" class="infinite-scroll-preloader"></f7-preloader>
    </div>
  </f7-page>
</template>
<script>
  import mapRoleGetters from '../../utils/map-role-getters';
  import formatDate from '../../utils/format-date';
  import Push from '../../modules/push';
  import events from '../../modules/events';

  export default {
    data() {
      return {
        allowInfinite: false,
      };
    },
    created() {
      const self = this;
      self.$store.dispatch(`${self.$root.storeRolePrefix}/getNotificationList`, {
        callbackOk() {
          events.$emit('updateNotificationsCount');
        },
      });
    },
    computed: {
      ...mapRoleGetters({
        loading: 'notificationListLoading',
        loadingMore: 'notificationListMoreLoading',
        notifications: 'notificationList',
      }),
    },
    mounted() {
      Push.setBadge(0);
      events.$emit('setActiveMenu', 'notifications');
    },
    watch: {
      'notifications.length': function watchNotifications(length) {
        if (length && length % 20 === 0) {
          this.allowInfinite = true;
        } else {
          this.allowInfinite = false;
        }
      },
    },
    methods: {
      onInfinite() {
        const self = this;
        if (!self.allowInfinite || self.loadingMore) return;
        self.$store.dispatch(`${self.$root.storeRolePrefix}/getMoreNotificationList`, {
          callbackOk() {
            events.$emit('updateNotificationsCount');
          },
        });
      },
      isUnread(item) {
        if (!item.unread) return false;
        let readCache;
        try {
          readCache = JSON.parse(window.localStorage.notificationsRead);
        } catch (e) {
          readCache = [];
        }
        if (readCache.indexOf(item.id) >= 0) return false;
        return true;
      },
      markAsRead(item) {
        item.unread = false;
        let readCache;
        try {
          readCache = JSON.parse(window.localStorage.notificationsRead);
        } catch (e) {
          readCache = [];
        }
        if (readCache.indexOf(item.id) < 0) readCache.push(item.id);
        window.localStorage.notificationsRead = JSON.stringify(readCache);
      },
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
    },
  };
</script>
