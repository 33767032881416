/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';

const state = {
  crowd: null,
  crowdLoading: false,
  crowdSaving: false,
  crowdDeleting: false,
  crowdList: null,
  crowdListLoading: false,

  crowdJoinList: null,
  crowdJoinListSkip: 0,
  crowdJoinListTake: 20,
  crowdJoinListLoading: false,
  crowdJoinListMoreLoading: false,
  crowdJoinListHasMore: true,
  crowdJoining: false,
  crowdLeaving: false,

  crowdAssignmentList: null,
  crowdAssignmentListSkip: 0,
  crowdAssignmentListTake: 20,
  crowdAssignmentListLoading: false,
  crowdAssignmentListMoreLoading: false,
  crowdAssignmentListHasMore: true,
  crowdAssignmentListCategory: null,
};
const getters = {};
Object.keys(state).forEach((key) => {
  getters[key] = state => state[key];
});

const actions = {
  acceptInvite(context, { crowdId, callbackOk, callbackError }) {
    API.post({
      url: 'finder/invite/accept',
      data: { crowdId },
      ok() {
        if (callbackOk) callbackOk();
      },
      error() {
        if (callbackError) callbackError();
      },
    });
  },
  declineInvite(context, { crowdId, callbackOk, callbackError }) {
    API.post({
      url: 'finder/invite/decline',
      data: { crowdId },
      ok() {
        if (callbackOk) callbackOk();
      },
      error() {
        if (callbackError) callbackError();
      },
    });
  },
  getCrowdAssignmentList({ commit, state }, { crowdId, cache = true } = {}) {
    commit('setCrowdAssignmentListSkip', 0);
    commit('setCrowdAssignmentListHasMore', true);
    API.get({
      url: `finder/assignment/list?crowdId=${crowdId}&category=${state.crowdAssignmentListCategory}&skip=${state.crowdAssignmentListSkip}&take=${state.crowdAssignmentListTake}`,
      loading: 'f/setCrowdAssignmentListLoading',
      cache,
      ok(response) {
        commit('setCrowdAssignmentList', { crowdId: parseInt(crowdId, 10), items: response.items });
      },
    });
  },
  updateCrowdAssignmentList({ dispatch }, { crowdId }) {
    dispatch('getCrowdAssignmentList', { cache: false, crowdId });
  },
  getMoreCrowdAssignmentList({ commit, state }, { crowdId }) {
    commit('setCrowdAssignmentListSkip', state.crowdAssignmentListSkip + state.crowdAssignmentListTake);
    API.get({
      url: `finder/assignment/list?id=${crowdId}&category=${state.crowdAssignmentListCategory}&skip=${state.crowdAssignmentListSkip}&take=${state.crowdAssignmentListTake}`,
      loading: 'f/setCrowdAssignmentListLoading f/setCrowdAssignmentListMoreLoading',
      cache: false,
      ok(response) {
        if (!response || (response.items && response.items.length < 20)) {
          commit('setCrowdAssignmentListHasMore', false);
          if (!response.items.length) return;
        }
        commit('addToCrowdAssignmentList', response.items);
      },
    });
  },
  unsetCrowdAssignmentList({ commit }) {
    commit('unsetCrowdAssignmentList');
  },
  getMyCrowdList({ commit }, { cache = false, callbackOk } = {}) {
    API.get({
      url: 'finder/crowd/mylist',
      loading: 'f/setCrowdListLoading',
      cache,
      ok(response) {
        commit('setCrowdList', response.items);
        if (callbackOk) callbackOk();
      },
    });
  },
  getCrowd({ commit }, { id }) {
    API.get({
      url: `finder/crowd/details?id=${id}`,
      loading: 'f/setCrowdLoading',
      ok(response) {
        commit('setCrowd', response);
      },
    });
  },
  unsetCrowd({ commit }) {
    commit('unsetCrowd');
  },
  setCrowdAssignmentListCategory({ commit, dispatch }, { category, crowdId }) {
    commit('setCrowdAssignmentListCategory', category === null ? null : parseInt(category, 10));
    dispatch('updateCrowdAssignmentList', { crowdId });
  },
  unsetCrowdAssignmentListCategory({ commit }) {
    commit('unsetCrowdAssignmentListCategory');
  },
  getCrowdJoinList({ commit }) {
    commit('setCrowdJoinListSkip', 0);
    commit('setCrowdJoinListHasMore', true);
    API.get({
      url: `finder/crowd/joinlist?skip=${state.crowdJoinListSkip}&take=${state.crowdJoinListTake}`,
      loading: 'f/setCrowdJoinListLoading',
      ok(response) {
        commit('setCrowdJoinList', response.items);
      },
    });
  },
  getMoreCrowdJoinList({ commit, state }) {
    commit('setCrowdJoinListSkip', state.crowdJoinListSkip + state.crowdJoinListTake);
    API.get({
      url: `finder/crowd/joinlist?skip=${state.crowdJoinListSkip}&take=${state.crowdJoinListTake}`,
      loading: 'f/setCrowdJoinListLoading f/setCrowdJoinListMoreLoading',
      cache: false,
      ok(response) {
        if (!response || (response.items && response.items.length < 20)) {
          commit('setCrowdJoinListHasMore', false);
          if (!response.items.length) return;
        }
        commit('addToCrowdJoinList', response.items);
      },
    });
  },
  unsetCrowdJoinList({ commit }) {
    commit('unsetCrowdJoinList');
  },
  joinCrowd(ctx, { crowdId, crowdGuid, callbackOk }) {
    API.post({
      url: 'finder/crowd/join',
      loading: 'f/setCrowdJoining',
      data: {
        crowdId,
        crowdGuid,
      },
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
    });
  },
  leaveCrowd(ctx, { crowdId, callbackOk }) {
    API.post({
      url: 'finder/crowd/leave',
      loading: 'f/setCrowdLeaving',
      data: {
        crowdId,
      },
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
    });
  },
};

const mutations = {};
Object.keys(state).forEach((key) => {
  const upperKey = `${key[0].toUpperCase()}${key.substr(1)}`;
  mutations[`set${upperKey}`] = (state, data) => {
    state[key] = data;
  };
  mutations[`unset${upperKey}`] = (state) => {
    state[key] = null;
  };
});
Object.assign(mutations, {
  addToCrowdAssignmentList(state, assignments) {
    state.crowdAssignmentList.items.push(...assignments);
  },
  addToCrowdJoinList(state, items) {
    state.crowdJoinList.push(...items);
  },
});

export default {
  state,
  getters,
  actions,
  mutations,
};
