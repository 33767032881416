<template>
  <f7-page class="assignment-details">
    <f7-navbar>
      <f7-nav-title>{{$t('assignment.title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <div class="content-not-found-block" v-if="!assignment && checkAuth && unauthorized">{{$t('assignment.unauthorized_text')}}</div>
    <div class="center-content" v-if="assignment" :class="{'assignment-closed': isClosed}">
      <assignment-details-header
        :assignment="assignment"
        :isLanding="true"
      />
      <f7-block strong class="display-flex justify-content-center">
        <f7-button :route-props="{ assignmentRedirectId: assignment.id }" class="assignment-details-signup-button" big fill round href="/signup/finder/" reload-current>{{$t('assignment.signup_button')}}</f7-button>
      </f7-block>
    </div>
  </f7-page>
</template>
<script>
  import assignmentStatusList from '../../utils/assignment-status-list';
  import api from '../../modules/api';
  import events from '../../modules/events';

  export default {
    props: {
      id: [String, Number],
      checkAuth: Boolean,
    },
    data() {
      return {
        assignmentStatusList,
        assignment: null,
        unauthorized: false,
      };
    },
    created() {
      const self = this;
      api.get({
        url: `common/assignment/details?id=${self.id}&language=${self.$root.language}`,
        ok(res) {
          self.assignment = res;
          self.loading = false;

          if (window.cordova) {
            window.ga('send', {
              hitType: 'screenview',
              screenName: self.$f7route.url,
              appName: 'FindersCrowd',
              dimension1: localStorage.userRole,
              dimension2: self.assignment.title,
            });
          } else {
            window.ga('send', 'pageview', {
              page: self.$f7route.url,
              dimension1: localStorage.userRole,
              dimension2: self.assignment.title,
            });
          }
        },
        error() {
          self.loading = false;
        },
      });
    },
    computed: {
      isClosed() {
        return this.assignment && this.assignment.status === this.assignmentStatusList.Closed;
      },
    },
    mounted() {
      const self = this;
      if (!self.checkAuth) return;
      events.$on('unauthorized-error', (prevent) => {
        prevent();
        self.unauthorized = true;
      });
    },
  };
</script>
