<template>
  <div class="center-content">
    <f7-block strong class="company-profile-block">
      <div class="company-header">
        <div class="blur-bg"></div>
      </div>
      <div class="company-content-wrap">
        <logo class="company-logo skeleton-effect-fade" :size="120"></logo>
        <div class="company-content">
          <div class="company-name skeleton-text skeleton-effect-fade">______</div>
          <div class="company-address skeleton-effect-fade">
            <f7-icon material="business"></f7-icon>
            <span class="skeleton-text">______ _______ ___________ ___</span>
          </div>
          <div class="company-homepage skeleton-effect-fade">
            <f7-icon material="language"></f7-icon>
            <a href="#" class="skeleton-text">_________________</a>
          </div>
        </div>
      </div>
    </f7-block>
    <f7-block-title class="skeleton-text skeleton-effect-fade">_________ ___________</f7-block-title>
    <skeleton-assignment-list :amount="3"></skeleton-assignment-list>
  </div>
</template>
<script>
  export default {};
</script>
