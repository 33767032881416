<template>
  <f7-page>
    <f7-navbar>
      <f7-nav-left>
        <back-link />
      </f7-nav-left>
      <f7-nav-title>{{$t('company.title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <div class="center-content" v-if="company">
      <f7-block strong class="company-profile-block">
        <div class="company-header">
          <div
            class="blur-bg"
            :style="`background-image: url(${headerBgSrc(company.logo)})`"
          ></div>
        </div>
        <div class="company-content-wrap">
          <logo :file="company.logo" class="company-logo" :size="120"></logo>
          <div class="company-content">
            <div class="company-name">{{company.companyName}}</div>
            <div class="company-address" v-if="computedAddress">
              <f7-icon material="business"></f7-icon>
              <span>{{computedAddress}}</span>
            </div>
            <div class="company-homepage" v-if="company.homepage">
              <f7-icon material="language"></f7-icon>
              <a :href="company.homepage" class="external" :target="$externalTarget">{{company.homepage}}</a>
            </div>
          </div>
        </div>
      </f7-block>
      <f7-block-title v-if="company.assignments && company.assignments.length">{{$t('company.published_assignments_title')}}</f7-block-title>
      <f-assignment-list v-if="company.assignments && company.assignments.length" :assignments="company.assignments" :logo="company.logo"></f-assignment-list>
    </div>
    <skeleton-company-details v-else></skeleton-company-details>
  </f7-page>
</template>
<script>
  import striptags from 'striptags';
  import mapRoleGetters from '../../utils/map-role-getters';
  import config from '../../config';

  export default {
    props: {
      id: [String, Number],
    },
    data() {
      return {
        company: null,
      };
    },
    created() {
      const self = this;
      self.$store.dispatch(`${self.$root.storeRolePrefix}/unsetCompany`);
      self.$store.dispatch(`${self.$root.storeRolePrefix}/getCompany`, { id: parseInt(self.id, 10) });
    },
    watch: {
      currentCompany(company) {
        const self = this;
        if (!company) return;
        if (company && company.id !== parseInt(self.id, 10)) {
          return;
        }
        self.company = company;
      },
    },
    methods: {
      formatText(text) {
        return striptags(text || '').replace(/\n/g, '<br>');
      },
      headerBgSrc(logo) {
        if (typeof logo === 'string') return logo;
        if (logo && logo.downloadFileName) {
          if (logo.downloadFileName.indexOf('http') === 0) return logo.downloadFileName;
          return `${config.path.images}${logo.downloadFileName}?fit=crop&w=900&h=140&blur=250`;
        }
        return '';
      },
    },
    computed: {
      computedAddress() {
        const self = this;
        const company = self.company;
        return [company.address, company.city, company.country]
          .filter(el => el)
          .join(', ');
      },
      ...mapRoleGetters({
        loading: 'companyLoading',
        currentCompany: 'company',
      }),
    },
  };
</script>
