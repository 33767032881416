/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';

const state = {
  company: null,
  companyLoading: false,
};

const getters = {
  companyLoading(state) {
    return state.companyLoading;
  },
  company(state) {
    return state.company;
  },
};
const actions = {
  getCompany({ commit }, { id } = {}) {
    if (state.company) return;
    API.get({
      url: `finder/company/details?id=${id}`,
      loading: 'f/setCompanyLoading',
      ok(res) {
        commit('setCompany', res);
      },
    });
  },
  unsetCompany({ commit }) {
    commit('unsetCompany');
  },
  joinCompany(ctx, { companyGuid, callbackOk }) {
    API.post({
      url: 'finder/company/join',
      data: {
        companyGuid,
      },
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
    });
  },
};

const mutations = {
  setCompany(state, item) {
    state.company = item;
  },
  setCompanyLoading(state, status) {
    state.companyLoading = status;
  },
  unsetCompany(state) {
    state.company = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
