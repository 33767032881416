<template>
  <f7-block strong class="assignment-details-block">
    <div class="assignment-details-content-wrap">
      <div class="assignment-details-logo" v-if="isLanding">
        <logo class="company-logo" :file="assignment.logo" :size="120"></logo>
      </div>
      <div class="assignment-details-logo" v-else>
        <logo v-if="assignment.anonymous" class="company-logo" :file="assignment.logo" :size="120"></logo>
        <a v-else :href="isPreview ? '#' : `/company/${assignment.companyId}/`" :style="isPreview ? 'cursor: auto' : ''">
          <logo class="company-logo" :file="assignment.logo" :size="120"></logo>
        </a>
      </div>
      <div class="assignment-details-content">
        <div class="assignment-title">{{assignment.title}}</div>

        <div class="assignment-closed-badge" v-if="isClosed && !isPreview">{{$t('assignment.closed_title')}}</div>

        <image-gallery
          v-if="mediaFilesFromText.length || assignment.files && assignment.files.length"
          :files="assignment.files"
          :extra-media-files="mediaFilesFromText"
          :allow-edit="false"
        ></image-gallery>

        <div class="assignment-meta">
          <div class="company-name" v-if="assignment.anonymous">
            <f7-icon material="business"></f7-icon>
            <span>{{assignment.companyName}}</span>
          </div>
          <div class="company-name" v-else>
            <template v-if="isLanding">
              <f7-icon material="business"></f7-icon>
              <span>{{assignment.companyName}}</span>
            </template>
            <template v-else>
              <a :href="`/company/${assignment.companyId}/`">
                <f7-icon material="business"></f7-icon>
                <span>{{assignment.companyName}}</span>
                <f7-icon f7="chevron_right"></f7-icon>
              </a>
            </template>
          </div>
          <div class="assignment-category" v-if="typeof assignment.category !== 'undefined'">
            <f7-icon material="local_offer"></f7-icon>
            <span>{{$t(`assignment_category.${assignmentCategoryMap(assignment.category)}`)}}</span>
          </div>
          <div class="assignment-date" v-if="assignment.publishedDate">
            <f7-icon material="date_range"></f7-icon>
            <span>{{$t('assignment.published_date_range', { from: formatDate(assignment.publishedDate), to: formatDate(assignment.endDate) })}}</span>
          </div>
        </div>

        <div class="assignment-reward" v-if="!rewardDisabled">
          <template v-if="isCompany">
            <div class="assignment-reward-finder-info-title">
              <f7-icon material="person"></f7-icon>
              <span>
                <span>{{$t('assignment.accept_info_to_finder')}}:</span>
              </span>
            </div>
            {{$t('assignment.reward_to_fc_label')}}
          </template>
          <template v-else>
            {{$t('assignment.earn_label')}}
          </template>
          <span class="assignment-reward-number text-color-brand-green">{{rewardText}}</span>

          <div v-if="assignment.multipleWinners && isCompany" style="font-weight: 400; font-size: 14px">- {{$t('assignment.accept_many_reward_info', { level: null })}}</div>
          <template v-else>
            {{$t('assignment.reward_amount_end')}}
          </template>

          <div class="assignment-reward-details" v-if="rewardDetails() && !assignment.multipleLevels">{{rewardDetails()}}</div>
          <div class="assignment-reward-description" v-if="!assignment.multipleLevels && assignment.rewards[0] && assignment.rewards[0].description">
            <f7-icon material="assignment"></f7-icon>
            <span v-html="formatText(assignment.rewards[0].description)"></span>
          </div>
          <div class="assignment-accept-many">
            <f7-icon :material="assignment.multipleWinners ? 'supervisor_account' : 'person'"></f7-icon>
            <span v-if="assignment.multipleWinners" v-html="$t('assignment.accept_many_info')"></span>
            <span v-else v-html="$t('assignment.accept_one_info')"></span>
          </div>
        </div>

        <div class="assignment-description" v-if="assignment.description">
          <rich-text :text="assignment.description" :show-previews="false"></rich-text>
        </div>

        <div class="assignment-message-for-interested" v-if="!isFinder && assignment.messageForInterested && assignment.category !== assignmentCategoryList.Innovation">
          <div class="assignment-message-for-interested-title">{{$t('assignment.interested_message_title')}}</div>
          <div class="assignment-message-for-interested-content">
            <div v-html="formatText(assignment.messageForInterested)"></div>
          </div>
        </div>

        <div class="assignment-message-for-finder" v-if="(isFinder || isPreview) && assignment.messageForFinder">
          <div class="assignment-message-for-finder-title">{{$t('assignment.interested_message_title')}}</div>
          <div class="assignment-message-for-finder-content">
            <div v-html="formatText(assignment.messageForFinder)"></div>
          </div>
        </div>

        <attachments
          v-if="mediaFilesFromText.length || assignment.files && assignment.files.length"
          :files="assignment.files || []"
          :extra-media-files="mediaFilesFromText"
          :allow-edit="false"
        ></attachments>

        <div v-if="!isCompany && assignment.category !== assignmentCategoryList.Innovation" class="assignment-inner-block assignment-recommendations-info">
          <b>{{$t(`assignment.${isFinder ? 'recommendations_info' : 'recommendations_landing_info'}`)}}</b>
        </div>
      </div>
    </div>
  </f7-block>
</template>
<script>
  import striptags from 'striptags';
  import formatDate from '../../utils/format-date';
  import assignmentCategoryMap from '../../utils/assignment-category-map';
  import assignmentCategoryList from '../../utils/assignment-category-list';
  import mediaFilesFromText from '../../utils/media-files-from-text';
  import rewardDetails from '../../utils/reward-details';
  import rewardText from '../../utils/reward-text';
  import assignmentStatusList from '../../utils/assignment-status-list';

  export default {
    props: {
      assignment: Object,
      isLanding: Boolean,
      isCompany: Boolean,
      isFinder: Boolean,
      isPreview: Boolean,
      rewardDisabled: Boolean,
    },
    data() {
      return {
        assignmentCategoryList,
      };
    },
    computed: {
      mediaFilesFromText() {
        return this.assignment ? mediaFilesFromText(this.assignment.description) : [];
      },
      rewardText() {
        const self = this;
        const { assignment } = self;
        return rewardText(self, assignment);
      },
      isClosed() {
        return this.assignment && this.assignment.status === assignmentStatusList.Closed;
      },
    },
    methods: {
      assignmentCategoryMap,
      formatText(text) {
        return striptags(text || '').replace(/\n/g, '<br>');
      },
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
      rewardDetails(reward) {
        const self = this;
        const { assignment } = self;
        return rewardDetails(self, assignment, reward);
      },
    },
  };
</script>
