/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';
import PWA from '../../modules/pwa';

const state = {
  profile: null,
  profileLoading: false,
  saving: false,
  notificationsSettings: null,
  notificationsSettingsLoading: false,
  notificationsSettingsSaving: false,
  payments: null,
  paymentsLoading: false,
  paymentsSaving: false,
};

const getters = {
  profileLoading(state) {
    return state.profileLoading;
  },
  profileSaving(state) {
    return state.saving;
  },
  profile(state) {
    return state.profile;
  },
  notificationsSettings(state) {
    return state.notificationsSettings;
  },
  notificationsSettingsLoading(state) {
    return state.notificationsSettingsLoading;
  },
  notificationsSettingsSaving(state) {
    return state.notificationsSettingsSaving;
  },
  payments(state) {
    return state.payments;
  },
  paymentsLoading(state) {
    return state.paymentsLoading;
  },
  paymentsSaving(state) {
    return state.paymentsSaving;
  },
};
const actions = {
  getProfile({ commit, state }, { cached, callbackOk } = {}) {
    if ((cached && state.profile) || state.profileLoading) return;
    API.get({
      url: 'finder/profile/details',
      loading: 'f/setProfileLoading',
      ok(res) {
        const user = res;
        window.localStorage.userProfile = JSON.stringify(user);
        window.BriefUser = { firstName: user.firstName, lastName: user.lastName, email: user.email, avatarUrl: '' };
        commit('setProfile', res);
        if (callbackOk) callbackOk();
      },
    });
  },
  unsetProfile({ commit }) {
    commit('unsetProfile');
  },
  saveProfile({ dispatch, commit }, { data, callbackOk, callbackError } = {}) {
    API.post({
      url: 'finder/profile/save',
      data,
      base64FileProp: 'photo',
      loading: 'f/setProfileSaving',
      ok() {
        commit('setProfile', { ...state.profile, ...data });
        PWA.updateCachedApi('finder/profile/details', { ...state.profile, ...data }, () => {
          dispatch('getProfile');
          if (callbackOk) callbackOk();
        });
      },
      error() {
        if (callbackError) callbackError();
      },
    });
  },
  getNotificationsSettings({ commit }) {
    API.get({
      url: 'finder/notification/details',
      loading: 'f/setNotificationsSettingsLoading',
      ok(res) {
        commit('setNotificationsSettings', res);
      },
    });
  },
  saveNotificationsSettings({ commit }, { data, callbackOk, callbackError } = {}) {
    API.post({
      url: 'finder/notification/save',
      data,
      loading: 'f/setNotificationsSettingsSaving',
      ok() {
        commit('setNotificationsSettings', { ...state.notificationsSettings, ...data });
        PWA.updateCachedApi('finder/notification/details', { ...state.notificationsSettings, ...data });
        if (callbackOk) callbackOk();
      },
      error() {
        if (callbackError) callbackError();
      },
    });
  },
  getPayments({ commit }) {
    API.get({
      url: 'finder/payment/details',
      loading: 'f/setPaymentsLoading',
      ok(res) {
        commit('setPayments', res);
      },
    });
  },
  savePayments({ commit }, { data, callbackOk, callbackError } = {}) {
    API.post({
      url: 'finder/payment/save',
      data,
      loading: 'f/setPaymentsSaving',
      ok() {
        commit('setPayments', { ...state.payments, ...data });
        PWA.updateCachedApi('finder/payment/details', { ...state.payments, ...data });
        if (callbackOk) callbackOk();
      },
      error() {
        if (callbackError) callbackError();
      },
    });
  },
};

const mutations = {
  setProfile(state, profile) {
    state.profile = profile;
  },
  setProfileLoading(state, status) {
    state.profileLoading = status;
  },
  unsetProfile(state) {
    state.profile = null;
  },
  setProfileSaving(state, status) {
    state.saving = status;
  },
  setNotificationsSettings(state, data) {
    state.notificationsSettings = data;
  },
  setNotificationsSettingsLoading(state, status) {
    state.notificationsSettingsLoading = status;
  },
  setNotificationsSettingsSaving(state, status) {
    state.notificationsSettingsSaving = status;
  },
  setPayments(state, data) {
    state.payments = data;
  },
  setPaymentsLoading(state, status) {
    state.paymentsLoading = status;
  },
  setPaymentsSaving(state, status) {
    state.paymentsSaving = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
