/* globals */
let __fbSdkReady = false;
const __fbCallbacks = [];
/* */

function getLoginStatus(s, f) {
  if (!__fbSdkReady) {
    return __fbCallbacks.push(() => {
      getLoginStatus(s, f);
    });
  }

  FB.getLoginStatus((response, e) => {
    s(response);
  });
}

function showDialog(options, s, f) {
  if (!__fbSdkReady) {
    return __fbCallbacks.push(() => {
      showDialog(options, s, f);
    });
  }

  options.name = options.name || '';
  options.message = options.message || '';
  options.caption = options.caption || '';
  options.description = options.description || '';
  options.href = options.href || '';
  options.picture = options.picture || '';
  options.quote = options.quote || '';

  FB.ui(options, (response) => {
    if (response && (response.request || !response.error_code)) {
      s(response);
      return;
    }
    f(response.message);
  });
}
// Attach this to a UI element, this requires user interaction.
function login(permissions, s, f) {
  if (!__fbSdkReady) {
    return __fbCallbacks.push(() => {
      login(permissions, s, f);
    });
  }
  // JS SDK takes an object here but the native SDKs use array.
  const options = {};
  if (permissions && permissions.length > 0) {
    const index = permissions.indexOf('rerequest');
    if (index > -1) {
      permissions.splice(index, 1);
      options.auth_type = 'rerequest';
    }
    options.scope = permissions.join(',');
  }

  /**
   * Functions that resolves or rejects a Promise depending on response.
   *
   * Cases:
   * 1. Resolve/Success: If authResponse exists in response, that means that login is successful.
   *    In that case resolve (success) function will be invoked with authResponse value.
   * 2. Reject/Failure: In any other case (no response or response.authResponse) reject (failure) is invoked.
   *  a. response exists and response.status exists, rejected with response.status.message.
   *  b. response exists and response.status does not exist, rejected with response.
   *  c. response does not exist, rejected with 'no response' message.
   */
  FB.login((response) => {
    if (response.authResponse) {
      s(response);
    } else if (response) { // Previously this was just an else statement.
      if (response.status) { // When status is undefined this would throw an error, and rejection function would never be invoked.
        f(response.status.message);
      } else {
        f(response);
      }
    } else { // In case that no response is available (e.g. popup dismissed)
      f('No response');
    }
  }, options);
}

function getAccessToken(s, f) {
  const response = FB.getAccessToken();
  if (response) {
    s(response);
    return;
  }
  f('NO_TOKEN');
}

function logEvent(eventName, params, valueToSum, s, f) {
  if (!__fbSdkReady) {
    return __fbCallbacks.push(() => {
      logEvent(eventName, params, valueToSum, s, f);
    });
  }

  FB.AppEvents.logEvent(eventName, valueToSum, params);

  if (s) s();
}

function logPurchase(value, currency, s, f) {
  if (!__fbSdkReady) {
    return __fbCallbacks.push(() => {
      logPurchase(value, currency, s, f);
    });
  }

  FB.AppEvents.logPurchase(value, currency);

  if (s) s();
}

function logout(s, f) {
  if (!__fbSdkReady) {
    return __fbCallbacks.push(() => {
      logout(s, f);
    });
  }

  FB.logout((response) => {
    s(response);
  });
}

function api(graphPath, permissions, s, f) {
  if (!__fbSdkReady) {
    return __fbCallbacks.push(() => {
      api(graphPath, permissions, s, f);
    });
  }

  // JS API does not take additional permissions
  FB.api(graphPath, (response) => {
    if (response.error) {
      f(response);
    } else {
      s(response);
    }
  });
}

function browserInit() {
  window.fbAsyncInit = function fbAsyncInit() {
    FB.init({
      appId: '2188205938120228', // APP_ID is populated by the cordova after_prepare hook
      xfbml: true,
      version: 'v2.7',
    });

    __fbSdkReady = true;

    for (let i = 0; i < __fbCallbacks.length; i += 1) {
      __fbCallbacks[i]();
    }
  };

  ((d, s, id) => {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    const js = d.createElement(s); js.id = id;
    js.src = '//connect.facebook.net/en_US/sdk.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
}

export default {
  browserInit,
  api,
  getAccessToken,
  getLoginStatus,
  login,
  logout,
  logEvent,
  logPurchase,
  showDialog,
};

