/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';
import PWA from '../../modules/pwa';

const state = {
  users: null,
  usersLoading: null,
  usersInviting: null,
  userDeleting: null,
  usersLoadingMore: null,
  usersHasMore: true,
  usersSkip: 0,
  usersTake: 20,
  payments: null,
  paymentsLoading: false,
};
const getters = {
  users(state) {
    return state.users;
  },
  usersLoading(state) {
    return state.usersLoading;
  },
  usersInviting(state) {
    return state.usersInviting;
  },
  userDeleting(state) {
    return state.userDeleting;
  },
  usersLoadingMore(state) {
    return state.usersLoadingMore;
  },
  usersHasMore(state) {
    return state.usersHasMore;
  },
  usersSkip(state) {
    return state.usersSkip;
  },
  usersTake(state) {
    return state.usersTake;
  },
  payments(state) {
    return state.payments;
  },
  paymentsLoading(state) {
    return state.paymentsLoading;
  },
};
const actions = {
  getUsers({ commit }, { cache = true, query } = {}) {
    commit('setUsersSkip', 0);
    commit('setUsersHasMore', true);
    API.get({
      url: `company/user/list${query ? `?search=${query}` : ''}`,
      loading: 'c/setUsersLoading',
      cache,
      ok(response) {
        commit('setUsers', response.users);
      },
    });
  },
  getMoreUsers({ commit, state }) {
    commit('setUsersSkip', state.usersSkip + state.usersTake);
    API.get({
      url: `company/user/list?skip=${state.usersSkip}&take=${state.usersTake}`,
      loading: 'c/setUsersLoading c/setUsersLoadingMore',
      cache: false,
      ok(response) {
        if (!response || (response.users && response.users.length < 20)) {
          commit('setUsersHasMore', false);
          if (!response.users.length) return;
        }
        const { users } = response;
        commit('addToUsers', users);
      },
    });
  },
  getPreview(store, { id, callbackOk, callbackError } = {}) {
    API.get({
      url: `company/info/preview?assignmentId=${id}`,
      cache: false,
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  getPreviewByGuid(store, { guid, callbackOk, callbackError } = {}) {
    API.get({
      url: `company/assignment/preview?guid=${guid}`,
      ok(response) {
        if (callbackOk) callbackOk(response);
      },
      error: callbackError,
    });
  },
  inviteUser({ dispatch }, { emails, callbackOk } = {}) {
    API.post({
      url: 'company/user/invite',
      loading: 'c/setUsersInviting',
      data: { emails },
      ok() {
        if (callbackOk) callbackOk();
        dispatch('getUsers');
      },
    });
  },
  getPayments({ commit }) {
    API.get({
      url: 'company/payment/details',
      loading: 'c/setPaymentsLoading',
      ok(response) {
        commit('setPayments', response);
      },
    });
  },
  resendAllUser() {
    API.post({
      url: 'company/user/resendall',
      loading: 'c/setUsersInviting',
    });
  },
  resendUser({ dispatch }, { userId }) {
    API.post({
      url: 'company/user/resend',
      loading: 'c/setUsersInviting',
      data: { userId },
      ok() {
        dispatch('getUsers', { cache: false });
      },
    });
  },
  deleteUser({ dispatch }, { userId }) {
    API.post({
      url: 'company/user/delete',
      loading: 'c/setUserDeleting',
      data: { userId },
      ok() {
        dispatch('getUsers', { cache: false });
      },
    });
  },
  sendEnterpriseUpgradeRequest(ctx, { callbackOk } = {}) {
    API.post({
      url: 'company/account/upgrade',
      ok() {
        if (callbackOk) callbackOk();
      },
    });
  },
};
const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  setUsersLoading(state, status) {
    state.usersLoading = status;
  },
  setUsersInviting(state, status) {
    state.usersInviting = status;
  },
  setUserDeleting(state, data) {
    state.userDeleting = data;
  },
  setUsersSkip(state, skip) {
    state.usersSkip = skip;
  },
  setUsersLoadingMore(state, status) {
    state.usersLoadingMore = status;
  },
  setUsersHasMore(state, value) {
    state.usersHasMore = value;
  },
  addToUsers(state, items) {
    state.users.push(...items);
  },
  setPaymentsLoading(state, status) {
    state.paymentsLoading = status;
  },
  setPayments(state, data) {
    state.payments = data;
  },
};

('profile details notificationsSettings').split(' ').forEach((key) => {
  const keyUpper = key[0].toUpperCase() + key.substr(1);

  let getUrl;
  switch (key) {
    case 'profile':
      getUrl = 'company/profile/details';
      break;
    case 'details':
      getUrl = 'company/info/details';
      break;
    case 'notificationsSettings':
      getUrl = 'company/notification/details';
      break;
    default:
      break;
  }

  Object.assign(state, {
    [key]: null,
    [`${key}Loading`]: false,
    [`${key}Saving`]: false,
  });

  Object.assign(getters, {
    [key](state) {
      return state[key];
    },
    [`${key}Loading`](state) {
      return state[`${key}Loading`];
    },
    [`${key}Saving`](state) {
      return state[`${key}Saving`];
    },
  });

  Object.assign(actions, {
    [`get${keyUpper}`]({ commit }, { cached, callbackOk, callbackError } = {}) {
      if ((cached && state[key]) || state[`${key}Loading`]) return;
      API.get({
        url: getUrl,
        loading: `c/set${keyUpper}Loading`,
        ok(response) {
          if (key === 'profile') {
            const user = response;
            window.localStorage.userProfile = JSON.stringify(response);
            window.BriefUser = { firstName: user.firstName, lastName: user.lastName, email: user.email, avatarUrl: '' };
          }
          commit(`set${keyUpper}`, response);
          if (callbackOk) callbackOk(response);
        },
        error: callbackError,
      });
    },
    [`save${keyUpper}`]({ commit }, { data, callbackOk, callbackError } = {}) {
      let url;
      let fileProp;
      switch (key) {
        case 'profile':
          url = 'company/profile/save';
          fileProp = 'photo';
          break;
        case 'details':
          url = 'company/info/save';
          fileProp = 'logo';
          break;
        case 'notificationsSettings':
          url = 'company/notification/save';
          break;
        default:
          break;
      }
      API.post({
        url,
        data,
        loading: `c/set${keyUpper}Saving`,
        base64FileProp: fileProp,
        ok(response) {
          commit(`set${keyUpper}`, { ...state[key], ...data });
          PWA.updateCachedApi(getUrl, { ...state[key], ...data });
          if (callbackOk) callbackOk(response);
        },
        error: callbackError,
      });
    },
  });

  Object.assign(mutations, {
    [`set${keyUpper}`](state, data) {
      state[key] = data;
    },
    [`set${keyUpper}Loading`](state, status) {
      state[`${key}Loading`] = status;
    },
    [`set${keyUpper}Saving`](state, status) {
      state[`${key}Saving`] = status;
    },
  });
});

export default {
  state,
  getters,
  actions,
  mutations,
};
