<template>
  <f7-page
    infinite
    :infinite-preloader="false"
    @infinite="onInfinite"
    @page:beforein="onPageBeforeIn"
    @page:afterin="onPageAfterIn"
  >
    <f7-navbar>
      <panel-link slot="nav-left" />
      <f7-nav-title>
        <span>{{$t('assignments.my_assignments_finder')}}</span>
      </f7-nav-title>
      <f7-nav-right>
        <profile-link />
      </f7-nav-right>
    </f7-navbar>

    <offline-indicator></offline-indicator>
    <div class="center-content center-content-blocks">
      <f7-list class="pending-invite" v-if="dashboardData && dashboardData.pendingInvite" media-list>
        <f7-list-item
          :title="$t('assignments.pending_invite_text', {companyName: dashboardData.pendingInvite.companyName})"
        >
          <logo class="company-logo" :logo="dashboardData.pendingInvite.companyLogo" :size="60" slot="media"></logo>
          <div slot="inner" class="pending-invite-buttons">
            <loading-button :loading="inviteInProcess === 'accept'" :disabled="inviteInProcess === 'decline'" small fill @click="acceptInvite" preloaderColor="white" :text="$t('assignments.pending_invite_accept')"></loading-button>
            <loading-button :loading="inviteInProcess === 'decline'" :disabled="inviteInProcess === 'accept'" small @click="declineInvite" preloaderColor="brand-orange" :text="$t('assignments.pending_invite_decline')"></loading-button>
          </div>
        </f7-list-item>
      </f7-list>
      <f7-block strong v-if="dashboardData">
        <h3 class="text-align-center">
          {{$t('assignments.rating_title')}}
          <f7-icon
            :tooltip="$t('assignments.rating_info_finder')"
            style="margin-left: 5px; cursor: pointer"
            :size="20"
            material="info"
          ></f7-icon>
        </h3>
        <rating
          :value="dashboardData.rating"
          :allowRate="false"
        ></rating>
        <p class="text-align-center no-margin-bottom margin-top" style="font-weight: bold; font-size: 16px">
          <f7-link href="/how-to-recommend/">{{$t('assignment.how_to_recommend_link')}}<f7-icon material="info" :size="20"></f7-icon></f7-link>
        </p>
        <template v-if="!isEnterprise">
          <template v-if="!$device.cordova">
            <p class="text-align-center"><b>{{$t('general.share_finderscrowd')}}</b></p>
            <p class="text-align-center">
              <f7-button fill @click="shareFacebook" small-md class="display-inline-flex align-items-center">
                <f7-icon f7="logo_facebook" :size="20"></f7-icon>
                <span>Facebook</span>
              </f7-button>
              <f7-button fill @click="shareLinkedIn" small-md class="display-inline-flex align-items-center">
                <f7-icon f7="logo_linkedin" :size="20"></f7-icon>
                <span>LinkedIn</span>
              </f7-button>
            </p>
          </template>
          <template v-else>
            <p class="text-align-center">
              <f7-button id="share-button" fill @click="shareCordova" small-md class="display-inline-flex align-items-center">
                <f7-icon ios="f7:share" md="material:share" :size="20"></f7-icon>
                <span>{{$t('general.share_finderscrowd')}}</span>
              </f7-button>
            </p>
          </template>
        </template>
      </f7-block>

      <template v-if="useSwiper">
        <f7-block strong v-if="myAssignments && myAssignments.length" class="no-padding-left no-padding-right gauges-swiper">
          <f7-swiper :navigation="$device.desktop" pagination :params="swiperParams">
            <f7-swiper-slide v-for="(gauge, index) in earningGauges" :key="index">
              <gauge
                :size="200"
                :progress="gauge.progress"
                :numberText="gauge.number"
                :numberFontSize="30"
                :labelBeforeText="gauge.labelBefore"
                :labelText="gauge.label"
                :labelAfterText="gauge.labelAfter"
                :labelFontSize="12"
                labelColor="#555"
                strokeFillColor="#ddd"
                strokeColor="#3FD77A"
                :strokeWidth="12"
                :visible="true"
              ></gauge>
            </f7-swiper-slide>
            <f7-swiper-slide>
              <gauge
                :size="200"
                :progress="dashboardData.recommendations/dashboardData.potentialRecommendations"
                :numberText="`${dashboardData.recommendations}/${dashboardData.potentialRecommendations}`"
                :numberFontSize="30"
                :labelBeforeText="$t('assignments.gauge_recommendations_before_label')"
                :labelText="$t('assignments.gauge_recommendations_label')"
                :labelAfterText="$t('assignments.gauge_recommendations_label_after')"
                :labelFontSize="12"
                labelColor="#555"
                strokeFillColor="#ddd"
                strokeColor="#f47321"
                :strokeWidth="12"
                :visible="true"
              ></gauge>
            </f7-swiper-slide>
          </f7-swiper>
        </f7-block>
      </template>
      <template v-else>
        <f7-block strong class="row gauges-row" v-if="myAssignments && myAssignments.length">
          <f7-col width="50" :tablet-width="earningGauges.length > 1 ? '33' : '50'" v-for="(gauge, index) in earningGauges" :key="index">
            <gauge
              :size="200"
              :progress="gauge.progress"
              :numberText="gauge.number"
              :numberFontSize="30"
              :labelBeforeText="gauge.labelBefore"
              :labelText="gauge.label"
              :labelAfterText="gauge.labelAfter"
              :labelFontSize="12"
              labelColor="#555"
              strokeFillColor="#ddd"
              strokeColor="#3FD77A"
              :strokeWidth="12"
              :visible="true"
            ></gauge>
          </f7-col>
          <f7-col width="50" :tablet-width="earningGauges.length > 1 ? '33' : '50'">
            <gauge
              :size="200"
              :progress="dashboardData.recommendations/dashboardData.potentialRecommendations"
              :numberText="`${dashboardData.recommendations}/${dashboardData.potentialRecommendations}`"
              :numberFontSize="30"
              :labelBeforeText="$t('assignments.gauge_recommendations_before_label')"
              :labelText="$t('assignments.gauge_recommendations_label')"
              :labelAfterText="$t('assignments.gauge_recommendations_label_after')"
              :labelFontSize="12"
              labelColor="#555"
              strokeFillColor="#ddd"
              strokeColor="#f47321"
              :strokeWidth="12"
              :visible="true"
            ></gauge>
          </f7-col>
        </f7-block>
      </template>

      <f-assignment-list v-if="myAssignments && myAssignments.length" :show-badge="true" :assignments="myAssignments"></f-assignment-list>
      <skeleton-assignment-list v-if="!myAssignments"></skeleton-assignment-list>
      <f7-block strong v-if="myAssignments && !myAssignments.length">
        <p class="finder-intro-title">{{$t('assignments.finder_intro_title')}}</p>
        <div class="finder-intro-text" v-html="$t('assignments.finder_intro_text')"></div>
      </f7-block>
      <f7-preloader v-if="allowInfinite && myListHasMore" class="infinite-scroll-preloader"></f7-preloader>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import config from '../../config';
  import formatNumber from '../../utils/format-number';
  import dispatchWithRetry from '../../utils/dispatch-with-retry';
  import events from '../../modules/events';
  import share from '../../modules/share';

  export default {
    mixins: [dispatchWithRetry],
    data() {
      return {
        allowInfinite: false,
        largeScreen: this.$f7.width >= 768,
        inviteInProcess: false,
      };
    },
    created() {
      const self = this;
      self.$store.dispatch('f/unsetMyAssignmentList');
      self.dispatchWithRetry('f/getMyAssignmentList');
      if (!self.profile) {
        self.dispatchWithRetry('f/getProfile');
      }
      self.timeout = setTimeout(() => {
        self.update();
      }, 30000);
    },
    mounted() {
      const self = this;
      self.setScrollOnUpdate = true;
      events.$emit('setActiveMenu', 'my-assignments');
      self.onResize = function onResize() {
        if (self.$f7.width >= 768) {
          self.$set(self, 'largeScreen', true);
        } else {
          self.$set(self, 'largeScreen', false);
        }
      };
      self.$f7.on('resize', self.onResize);
      events.$on('pushForeground', self.updateDashboard);
      self.$$(document).on('resume', self.updateDashboard);
    },
    beforeDestroy() {
      const self = this;
      if (self.onResize) {
        self.$f7.off('resize', self.onResize);
      }
      self.destroyed = true;
      clearTimeout(self.timeout);
      events.$off('pushForeground', self.updateDashboard);
      self.$$(document).off('resume', self.updateDashboard);
    },
    watch: {
      'myAssignments.length': function watchAssignments(length) {
        const self = this;
        if (length && self.setScrollOnUpdate && self.$f7route.url === '/my-assignments/') {
          self.restoreScroll();
        }
        if (length && length % 20 === 0) {
          this.allowInfinite = true;
        } else {
          this.allowInfinite = false;
        }
      },
    },

    computed: {
      isEnterprise() {
        return this.profile && this.profile.isEnterprise;
      },
      companyLogo() {
        return this.profile ? this.profile.companyLogo : null;
      },
      companyName() {
        return this.profile ? this.profile.companyName : null;
      },
      useSwiper() {
        return this.earningGauges.length > 1;
      },
      swiperParams() {
        const self = this;
        const isDesktop = self.$device.desktop;
        return {
          slidesPerView: 'auto',
          centeredSlides: true,
          watchOverflow: true,
          simulateTouch: false,
          on: {
            init() {
              const swiper = this;
              swiper.$el.parents('.tab').on('tab:show', () => {
                swiper.update();
                if (isDesktop) {
                  self.resizeSwiper(swiper);
                }
              });
              if (!isDesktop) return;
              self.resizeSwiper(swiper);
            },
            resize() {
              if (!isDesktop) return;
              self.resizeSwiper(this);
            },
          },
        };
      },
      earningGauges() {
        const self = this;
        const g = [];
        if (!self.dashboardData) return g;

        const { earnings, potentialEarnings } = self.dashboardData;
        potentialEarnings.forEach((potential) => {
          if (potential.amount === 0) return;
          const currency = potential.currency;
          const earn = {
            amount: 0,
            currency,
          };
          earnings.forEach((earning) => {
            if (earning.currency === potential.currency) earn.amount = earning.amount;
          });
          g.push({
            progress: earn.amount / potential.amount,
            number: formatNumber(earn.amount, earn.currency, 100000),
            labelBefore: self.$t('assignments.gauge_earnings_before_label'),
            label: self.$t('assignments.gauge_earnings_label', { earnings: formatNumber(potential.amount, potential.currency, 100000) }),
            labelAfter: self.$t('assignments.gauge_earnings_after_label'),
          });
        });

        return g;
      },
      ...mapGetters({
        dashboardData: 'f/dashboardData',
        myAssignments: 'f/myAssignmentList',
        myLoading: 'f/myAssignmentListLoading',
        myLoadingMore: 'f/myAssignmentListMoreLoading',
        profile: 'f/profile',
        myListHasMore: 'f/myAssignmentListHasMore',
      }),
    },
    methods: {
      acceptInvite() {
        const self = this;
        self.inviteInProcess = 'accept';
        self.$store.dispatch('f/acceptInvite', {
          callbackOk() {
            self.$store.dispatch('f/getProfile', {
              callbackOk() {
                // self.$f7router.navigate('/');
                // self.$store.dispatch('f/getInternalAssignmentList', {
                //   callbackOk() {
                //     self.activeEnterpriseTab = 'internal';
                //   },
                // });
                // self.$store.dispatch('f/getAssignmentList');
              },
            });
            self.updateDashboard();
          },
          callbackError() {
            self.inviteInProcess = false;
          },
        });
      },
      declineInvite() {
        const self = this;
        self.inviteInProcess = 'decline';
        self.$store.dispatch('f/declineInvite', {
          callbackOk() {
            self.updateDashboard();
          },
          callbackError() {
            self.inviteInProcess = false;
          },
        });
      },
      shareCordova() {
        const self = this;
        let rect;
        if (self.$device.ios) {
          rect = self.$el.querySelector('#share-button').getBoundingClientRect();
        }
        share.cordova({
          message: `FindersCrowd - ${self.$t('general.share_finderscrowd_summary')}`,
          subject: 'FindersCrowd',
          url: `${config.host}/?fid=${self.profile && self.profile.guid ? self.profile.guid : ''}`,
          rect,
        }, () => {}, () => {});
      },
      shareFacebook() {
        const self = this;
        share.facebook({
          url: `${config.host}/?fid=${self.profile && self.profile.guid ? self.profile.guid : ''}`,
        });
      },
      shareLinkedIn() {
        const self = this;
        const url = encodeURIComponent(`${config.host}/?fid=${self.profile && self.profile.guid ? self.profile.guid : ''}`);
        const title = encodeURI('FindersCrowd');
        const summary = encodeURI((self.$t('general.share_finderscrowd_summary') || '').substr(0, 256));

        share.linkedin({
          url,
          title,
          summary,
        });
      },
      restoreScroll() {
        const self = this;
        self.setScrollOnUpdate = false;
        if (!self.$device.cordova) return;
        const scrollTop = self.$f7router.scrollHistory[self.$f7route.url];
        if (scrollTop) {
          self.$nextTick(() => {
            self.$$('.tab-active').scrollTop(scrollTop);
          });
        }
      },
      onPageBeforeIn(e, page) {
        const self = this;
        if (page.direction === 'backward') {
          clearTimeout(self.timeout);
          self.update();
        }
      },
      onPageAfterIn() {},
      resizeSwiper(swiper) {
        const self = this;
        self.$nextTick(() => {
          let slidesSize = 0;
          const swiperSize = swiper.size;
          if (!swiperSize) return;
          swiper.slidesSizesGrid.forEach((el) => {
            slidesSize += el;
          });
          if (swiper.size > slidesSize) {
            swiper.$el.addClass('centered');
          } else {
            swiper.$el.removeClass('centered');
          }
        });
      },
      onInfinite() {
        const self = this;
        if (!self.allowInfinite || self.myLoadingMore || !self.myListHasMore) return;
        self.$store.dispatch('f/getMoreMyAssignmentList');
      },
      updateDashboard() {
        const self = this;
        if (self.destroyed) return;
        self.$store.dispatch('f/updateMyAssignmentList');
      },
      update() {
        const self = this;
        if (self.destroyed) return;
        self.dispatchWithRetry('f/updateMyAssignmentList', {
          callbackOk() {
            if (self.destroyed) return;
            self.timeout = setTimeout(() => {
              if (self.destroyed) return;
              self.update();
            }, 30000);
          },
        });
      },

    },
  };
</script>
