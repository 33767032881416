import Framework7, { Template7 } from 'framework7';

import Dialog from 'framework7/components/dialog/dialog';
import Popup from 'framework7/components/popup/popup';
import LoginScreen from 'framework7/components/login-screen/login-screen';
import Popover from 'framework7/components/popover/popover';
import Actions from 'framework7/components/actions/actions';
import Sheet from 'framework7/components/sheet/sheet';
import Toast from 'framework7/components/toast/toast';
import Preloader from 'framework7/components/preloader/preloader';
import Progressbar from 'framework7/components/progressbar/progressbar';
import Swipeout from 'framework7/components/swipeout/swipeout';
import VirtualList from 'framework7/components/virtual-list/virtual-list';
import Tabs from 'framework7/components/tabs/tabs';
import Panel from 'framework7/components/panel/panel';
import Card from 'framework7/components/card/card';
import Form from 'framework7/components/form/form';
import Input from 'framework7/components/input/input';
import Checkbox from 'framework7/components/checkbox/checkbox';
import Range from 'framework7/components/range/range';
import Radio from 'framework7/components/radio/radio';
import Toggle from 'framework7/components/toggle/toggle';
import SmartSelect from 'framework7/components/smart-select/smart-select';
import InfiniteScroll from 'framework7/components/infinite-scroll/infinite-scroll';
import Lazy from 'framework7/components/lazy/lazy';
import Searchbar from 'framework7/components/searchbar/searchbar';
import Calendar from 'framework7/components/calendar/calendar';
import Accordion from 'framework7/components/accordion/accordion';
import Messages from 'framework7/components/messages/messages';
import Messagebar from 'framework7/components/messagebar/messagebar';
import Swiper from 'framework7/components/swiper/swiper';
import PhotoBrowser from 'framework7/components/photo-browser/photo-browser';
import Notification from 'framework7/components/notification/notification';
import Tooltip from 'framework7/components/tooltip/tooltip';
import Fab from 'framework7/components/fab/fab';
import Skeleton from 'framework7/components/skeleton/skeleton';
import Stepper from 'framework7/components/stepper/stepper';

Framework7.use([
  Dialog,
  Popup,
  LoginScreen,
  Popover,
  Actions,
  Sheet,
  Toast,
  Preloader,
  Progressbar,
  Swipeout,
  VirtualList,
  Tabs,
  Panel,
  Card,
  Form,
  Input,
  Checkbox,
  Range,
  Radio,
  Toggle,
  SmartSelect,
  InfiniteScroll,
  Lazy,
  Searchbar,
  Calendar,
  Accordion,
  Messages,
  Messagebar,
  Swiper,
  PhotoBrowser,
  Notification,
  Tooltip,
  Fab,
  Skeleton,
  Stepper,
]);

export { Template7 };
export default Framework7;

