<template>
  <div class="slider" :class="{ 'slider-disabled': disabled }">
    <div class="slider-cell slider-label" v-if="leftLabel">
      <div class="label">{{leftLabel}}</div>
    </div>
    <div class="slider-cell slider-range">
      <f7-range :min="min" :max="max" :value="value" :step="step" :label="label" @range:change="onChange" @range:changed="onChanged"></f7-range>
    </div>
    <div class="slider-cell slider-label" v-if="rightLabel">
      <div class="label">{{rightLabel}}</div>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      min: Number,
      max: Number,
      leftLabel: String,
      rightLabel: String,
      step: Number,
      value: Number,
      label: Boolean,
      disabled: Boolean,
    },
    methods: {
      onChange(value) {
        this.$emit('change', parseInt(value, 10));
      },
      onChanged(value) {
        this.$emit('changed', parseInt(value, 10));
      },
    },
  };
</script>
