<template>
  <f7-page class="application-page" @page:afterin="onPageAfterIn">
    <f7-navbar>
      <f7-nav-left v-if="$device.cordova">
        <f7-link href="/" back icon="icon-back" :text="$t('general.back')"></f7-link>
      </f7-nav-left>
      <f7-nav-title>
        <span class="navbar-logo"></span>
        <span>FindersCrowd</span>
      </f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <div class="center-content" v-if="application">
      <f7-block strong class="assignment-details-block">
        <div class="assignment-details-content-wrap">
          <div class="assignment-details-logo">
            <logo v-if="application.anonymous || !application.homepage" class="company-logo" :file="application.logo" :size="120"></logo>
            <a v-else class="external" :target="$externalTarget" :href="application.homepage">
              <logo class="company-logo" :file="application.logo" :size="120"></logo>
            </a>
          </div>
          <div class="assignment-details-content">
            <div class="assignment-title">{{application.title}}</div>
            <div class="assignment-closed-badge" v-if="isClosed">{{$t('application.closed_title')}}</div>
            <image-gallery
              v-if="mediaFilesFromText.length || application.files && application.files.length"
              :files="application.files"
              :extra-media-files="mediaFilesFromText"
              :allow-edit="false"
            ></image-gallery>
            <div class="company-name" v-if="application.anonymous || !application.homepage">
              <f7-icon material="business"></f7-icon>
              <span>{{application.companyName}}</span>
            </div>
            <div class="company-name" v-else>
              <a class="external" :target="$externalTarget" :href="application.homepage">
                <f7-icon material="business"></f7-icon>
                <span>{{application.companyName}}</span>
                <f7-icon f7="chevron_right"></f7-icon>
              </a>
            </div>
            <div class="assignment-limit-message" v-if="isLimit">{{$t('application.reached_limit')}}</div>
            <template v-if="application.status === candidateStatusList.Contacted || application.status === candidateStatusList.Declined">

              <div v-if="application.status === candidateStatusList.Declined" class="assignment-inner-block assignment-application-status" :data-status="candidateStatusMap(application.status)">
                <div class="assignment-inner-block-content">
                  <div>{{$t('application.status_title')}}:</div>
                  <b>{{$t(`candidate_status.${candidateStatusMap(application.status)}`)}}</b>
                </div>
              </div>

              <div class="assignment-description" v-if="application.description">
                <rich-text :text="application.description" :show-previews="false"></rich-text>
              </div>

              <attachments
                v-if="application.files && application.files.length"
                :allow-edit="false"
                :media-files-source="application.description"
                :files="application.files"
              ></attachments>

              <div class="assignment-message-for-interested" v-if="application.messageForInterested">
                <div class="assignment-message-for-interested-title">{{$t('application.interested_message_title')}}</div>
                <div class="assignment-message-for-interested-content">
                  <div v-html="formatText(application.messageForInterested)"></div>
                </div>
              </div>
            </template>
            <template v-else>
              <div v-if="application.rewardLevel === 1" class="assignment-inner-block assignment-application-status" :data-status="candidateStatusMap(application.status)">
                <div class="assignment-inner-block-content">
                  <div>{{$t('application.status_title')}}:</div>
                  <b>{{$t(`candidate_status.${candidateStatusMap(application.status)}`)}}</b>
                </div>
              </div>
              <div v-else class="assignment-inner-block assignment-application-status assignment-application-status-multiple">
                <div class="assignment-inner-block-title">{{$t('application.status_title')}}</div>
                <div class="assignment-inner-block-content">
                  <div class="timeline rewards-timeline no-safe-areas">
                    <div class="timeline-item"
                      v-for="n in 100"
                      :key="n"
                      v-if="n <= application.rewardLevel"
                      :class="{
                        active: application.rewardLevel === n,
                        'reward-received': (n < application.rewardLevel || (n === application.rewardLevel && application.status === candidateStatusList.AgreementMade)),
                        'rejected': application.rewardLevel === n && (application.status === candidateStatusList.NoFurtherProcess || application.status === candidateStatusList.HadContactWithRecommendation)
                      }"
                    >
                      <div class="timeline-item-divider">{{n}}</div>
                      <div class="timeline-item-content" style="width: 100%">
                        <div>{{$t('application.assignment_level', { level: n })}}:</div>
                        <div v-if="n < application.rewardLevel">
                          <b>{{$t('candidate_status.AgreementMade')}}</b>
                        </div>
                        <div v-else>
                          <b>{{$t(`candidate_status.${candidateStatusMap(application.status)}`)}}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <f7-accordion-item class="assignment-accordion-details">
                <f7-accordion-toggle>
                  <span>{{$t('application.assignment_details_title')}}</span>
                  <f7-icon f7="chevron_down"></f7-icon>
                </f7-accordion-toggle>
                <f7-accordion-content>
                  <div class="assignment-description" v-if="application.description">
                    <rich-text :text="application.description" :show-previews="false"></rich-text>
                  </div>

                  <attachments
                    v-if="mediaFilesFromText.length || application.files && application.files.length"
                    :files="application.files || []"
                    :extra-media-files="mediaFilesFromText"
                    :allow-edit="false"
                  ></attachments>

                  <div class="assignment-message-for-interested" v-if="application.messageForInterested">
                    <div class="assignment-message-for-interested-title">{{$t('application.interested_message_title')}}</div>
                    <div class="assignment-message-for-interested-content">
                      <div v-html="formatText(application.messageForInterested)"></div>
                    </div>
                  </div>
                </f7-accordion-content>
              </f7-accordion-item>
            </template>
          </div>
        </div>
      </f7-block>

      <!-- Chat links -->
      <template v-if="application.status !== candidateStatusList.Contacted && application.status !== candidateStatusList.Declined && (application.enableFinderConversation || application.companyHasStartedConversation)">
        <f7-block-title>{{$t('application.chat_block_title')}}</f7-block-title>
        <f7-list>
          <f7-list-item
            v-if="application.enableFinderConversation"
            :link="true"
            @click="openChat(`/application/chat/${guid}/${application.assignmentId}/${application.finderId}/${application.companyId}/?tab=finder`)"
          >
            <f7-icon style="margin-right:5px" color="brand-orange" material="chat" slot="title"></f7-icon>
            <span slot="title">{{$t('application.chat_with_link', { name: (application.finderName || 'finder') })}}</span>
            <span class="badge color-brand-red" slot="after" v-if="application.unreadCountFinder">{{application.unreadCountFinder}}</span>
          </f7-list-item>
          <f7-list-item
            v-if="application.companyHasStartedConversation"
            :link="true"
            @click="openChat(`/application/chat/${guid}/${application.assignmentId}/${application.finderId}/${application.companyId}/?tab=company`)"
          >
            <f7-icon style="margin-right:5px" color="brand-orange" material="chat" slot="title"></f7-icon>
            <span slot="title">{{$t('application.chat_with_link', { name: (application.companyName) })}}</span>
            <span class="badge color-brand-red" slot="after" v-if="application.unreadCountCompany">{{application.unreadCountCompany}}</span>
          </f7-list-item>
        </f7-list>
      </template>

      <!-- Edit application -->
      <template v-if="application.status !== candidateStatusList.Contacted && application.status !== candidateStatusList.Declined">
        <f7-block-title class="block-title-with-subtitle" ref="formTitle">
          <f7-icon material="check_circle" color="brand-green"></f7-icon>
          <div class="block-title-content">
            <div>{{$t('application.intereset_received_title')}}</div>
            <div class="block-title-subtitle">{{$t('application.intereset_received_subtitle')}}</div>
          </div>
        </f7-block-title>
        <application-form ref="form" :application="application" :disabled="(isClosed || isLimit)"></application-form>
        <f7-block>
          <div class="buttons-row" v-if="!(isClosed || isLimit || isAgreementMade)">
            <loading-button
              class="button-right"
              :class="{disabled: (isClosed || isLimit)}"
              large
              fill
              round
              color="brand-blue"
              preloader-color="white"
              :text="$t('general.save')"
              @click="save()"
              :loading="saving"
            ></loading-button>
          </div>
        </f7-block>
      </template>
    </div>
    <skeleton-application-details v-if="!application"></skeleton-application-details>
  </f7-page>
</template>
<script>
  import striptags from 'striptags';
  import { mapGetters } from 'vuex';
  import candidateStatusList from '../../utils/candidate-status-list';
  import candidateStatusMap from '../../utils/candidate-status-map';
  import assignmentStatusList from '../../utils/assignment-status-list';
  import dispatchWithRetry from '../../utils/dispatch-with-retry';
  import mediaFilesFromText from '../../utils/media-files-from-text';

  export default {
    mixins: [dispatchWithRetry],
    props: {
      guid: [String, Number],
    },
    data() {
      return {
        candidateStatusList,
        savingButton: null,
      };
    },
    created() {
      const self = this;
      self.dispatchWithRetry('application/getApplication', { id: self.guid, language: self.$root.language });

      self.timeout = setTimeout(() => {
        self.update();
      }, 30000);
    },
    beforeDestroy() {
      const self = this;
      self.destroyed = true;
      clearTimeout(self.timeout);
    },
    computed: {
      ...mapGetters({
        application: 'application/application',
        loading: 'application/applicationLoading',
        saving: 'application/applicationSaving',
      }),
      mediaFilesFromText() {
        return this.application ? mediaFilesFromText(this.application.description) : [];
      },
      isClosed() {
        const self = this;
        return self.application && (
          (self.application.assignmentStatus === assignmentStatusList.Closed)
          || (new Date(self.application.endDate).getTime() < new Date().getTime())
        );
      },
      isLimit() {
        const self = this;
        return self.application && self.application.interestLimitReachedForAssignment;
      },
      isAgreementMade() {
        const self = this;
        return self.application.status === candidateStatusList.AgreementMade;
      },
    },
    methods: {
      candidateStatusMap,
      scrollToForm() {
        const self = this;
        const $$ = self.$$;
        self.$nextTick(() => {
          const formTitleEl = self.$refs.formTitle && self.$refs.formTitle.$el;
          if (!formTitleEl) return;
          const scrollTop = $$(formTitleEl).parents('.page-content').scrollTop() + $$(formTitleEl).offset().top;
          $$(formTitleEl).parents('.page-content').scrollTop(scrollTop - 60, 300);
        });
      },
      formatText(text) {
        return striptags(text || '').replace(/\n/g, '<br>');
      },
      resizeTextarea() {
        const self = this;

        self.$$(self.$el).find('textarea').each((index, textareaEl) => {
          self.$f7.input.resizeTextarea(textareaEl);
        });
      },
      openChat(url) {
        const self = this;
        self.application.unreadCountCompany = 0;
        self.application.unreadCountFinder = 0;
        self.$f7router.navigate(url);
      },
      save() {
        const self = this;

        const hasForm = self.$refs.form;
        let valid;
        let newData;
        if (hasForm) {
          valid = self.$refs.form.validate();
          if (!valid) return;
          newData = self.$refs.form.getData();
        }

        const newApplication = {
          language: self.$root.language,
          guid: self.application.guid,
          applicationGuid: self.application.applicationGuid,
          email: self.application.email,
          status: self.application.status,
        };

        if (newData) {
          Object.assign(newApplication, newData);
        }

        self.$store.dispatch('application/saveApplication', {
          item: newApplication,
          callbackOk() {
            self.$f7.dialog.alert(
              `
                <div class="text-align-center">
                  ${self.$theme.ios ? '<i style="font-size:28px" class="icon f7-icons color-brand-green">check_round_fill</i>' : '<i style="font-size:28px" class="icon material-icons color-brand-green">check_circle</i>'}
                  <div class="margin-top">${self.$t('application.updated')}</div>
                </div>
              `,
              '<div class="text-align-center">FindersCrowd</div>'
            );
          },
        });
      },
      update() {
        const self = this;
        if (self.destroyed) return;
        if (self.isTaken) return;
        self.dispatchWithRetry('application/updateApplication', {
          id: self.guid,
          language: self.$root.language,
          callbackOk() {
            if (self.destroyed) return;
            self.timeout = setTimeout(() => {
              if (self.destroyed) return;
              self.update();
            }, 30000);
          },
        });
      },
      onPageAfterIn(e, page) {
        const self = this;
        if (page.direction === 'backward') {
          self.dispatchWithRetry('application/updateApplication', { id: self.guid, language: self.$root.language });
        }
      },
    },
  };
</script>
