<template>
  <f7-page class="assignment-details" @page:afterin="onPageAfterIn">
    <f7-navbar>
      <f7-nav-left>
        <back-link />
      </f7-nav-left>
      <f7-nav-title>{{$t('assignment.title')}}</f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>

    <div v-if="assignment" class="center-content" :class="{'assignment-closed': isClosed}">
      <assignment-details-header
        :assignment="assignment"
        :isFinder="true"
        :rewardDisabled="rewardDisabled"
      />
      <template v-if="assignment && assignment.contactedCandidates.length">
        <f7-block-title>{{$t('assignment.overview_title')}}</f7-block-title>
        <f7-block strong class="row gauges-row">
          <f7-col v-if="!rewardDisabled">
            <gauge
              :size="200"
              :progress="!isUnlimited ? (assignment.earnings || 0)/(assignment.potentialEarnings || 1) : (assignment.earnings > 0 ? 1 : 0)"
              :numberText="`${formatNumber(assignment.earnings || 0, assignment.rewards[0].currency, 100000)}`"
              :numberFontSize="30"
              :labelBeforeText="$t('assignments.gauge_earnings_before_label')"
              :labelText="!isUnlimited ? $t('assignments.gauge_earnings_label', { earnings: formatNumber(assignment.potentialEarnings || 1, assignment.rewards[0].currency, 100000) }): ''"
              :labelAfterText="!isUnlimited ? $t('assignments.gauge_earnings_after_label') : ''"
              :labelFontSize="12"
              labelColor="#555"
              strokeFillColor="#ddd"
              strokeColor="#3FD77A"
              :strokeWidth="12"
              :visible="true"
            ></gauge>
          </f7-col>
          <f7-col>
            <gauge
              :size="200"
              :progress="!isUnlimited ? assignment.contactedCandidates.length/maxRecommendations : (assignment.contactedCandidates.length > 0 ? 1 : 0)"
              :numberText="!isUnlimited ? `${assignment.contactedCandidates.length}/${maxRecommendations}`: `${assignment.contactedCandidates.length}`"
              :numberFontSize="30"
              :labelBeforeText="$t('assignments.gauge_recommendations_before_label')"
              :labelText="$t('assignments.gauge_recommendations_label')"
              :labelAfterText="$t('assignments.gauge_recommendations_label_after_single')"
              :labelFontSize="12"
              labelColor="#555"
              strokeFillColor="#ddd"
              strokeColor="#f47321"
              :strokeWidth="12"
              :visible="true"
            ></gauge>
          </f7-col>
        </f7-block>
      </template>

      <template v-if="assignment.transactions && assignment.transactions.length && !rewardDisabled">
        <f7-block-title>{{$t('assignment.earnings_title')}}</f7-block-title>
        <f7-block
          v-for="transaction in assignment.transactions"
          :key="transaction.id"
          class="assignment-reward-received"
          no-hairlines
          strong
        >
          <p v-html="$t('assignment.earning_details', {
            amount: `${transaction.amount} ${currencyMap(transaction.currency)}`,
            level: assignment.multipleLevels ? transaction.rewardLevel : 0,
            multipleLevels: assignment.multipleLevels,
            isNotInnovation: assignment.category != assignmentCategoryList.Innovation,
            candidateName: transactionCandidateName(transaction),
            date: formatDate(transaction.createdDate),
          })"></p>
          <p v-if="assignment.shareOut !== 100" v-html="$t('assignment.payment_status', {
            status: $t(`finder_transaction_status.${transactionStatusMap(transaction.status)}`)
          })"></p>
        </f7-block>
        <f7-block v-if="showPaymentInfo" strong no-hairlines class="assignment-reward-received assignment-reward-received-yellow">
          <p v-if="assignment.shareOut !== 100" v-html="$t('assignment.payment_info', {
            isPayroll: assignment.paymentMethod === finderPaymentMethodList.CoolCompany,
            paymentMethod: $t(`finder_payment_method.${finderPaymentMethodMap(assignment.paymentMethod)}`),
            url: '/profile/payments/',
          })"></p>
          <p v-else v-html="$t('assignment.payment_info_company', {
            companyName: assignment.companyName,
          })"></p>
        </f7-block>
      </template>

      <f7-block-title class="display-flex justify-content-space-between" style="flex-wrap: wrap">
        <span v-if="!rewardDisabled">{{$t('assignment.recommendations_title')}} {{assignment.multipleLevels ? '' : assignment.rewards[0].received}}</span>
        <span v-else>{{$t('assignment.recommendations_title')}}</span>
        <f7-link href="/how-to-recommend/">{{$t('assignment.how_to_recommend_link')}}<f7-icon material="info" :size="20"></f7-icon></f7-link>
      </f7-block-title>

      <template v-if="assignment.multipleLevels && !rewardDisabled">
        <div class="timeline rewards-timeline">
          <div class="timeline-item"
            v-for="reward in assignment.rewards"
            :key="reward.id"
            :class="{ active: reward.level === assignment.rewardLevel, 'reward-received': receivedForLevel(reward.level) }"
          >
            <div class="timeline-item-divider">{{reward.level}}</div>
            <div class="timeline-item-content" style="width: 100%">
              <f7-list media-list inset class="assignment-rewards-list no-safe-areas">
                <f7-list-item
                  :subtitle="reward.description"
                  media-item
                >
                  <span class="assignment-rewards-list-level" slot="inner-start" v-if="reward.level === 1">{{$t('assignment.reward_level_number', { level: reward.level })}}:</span>
                  <span class="assignment-rewards-list-level" slot="inner-start" v-if="reward.level > 1">{{$t('assignment.reward_level_number', { level: reward.level })}} - {{$t('assignment.reward_after_level_reached', { level: reward.level -1 })}}:</span>
                  <span slot="title" v-html="$t('assignment.reward_level_title', {
                    amount: `<span class=text-color-brand-green>${rewardLevelAmount(reward)}</span>`,
                  })"></span>
                  <div slot="title" v-if="assignment.multipleWinners" style="font-weight: 400; font-size: 14px; margin-bottom: 10px">- {{$t('assignment.accept_many_reward_info', { level: reward.level })}}</div>
                  <span slot="text">{{rewardDetails(reward)}}</span>
                </f7-list-item>
              </f7-list>

              <f7-block class="assignment-candidates-block no-safe-areas" strong inset v-if="reward.level === 1 || candidatesForLevel(reward.level).length > 0">
                <template v-if="userDisabled">
                  <p><b>{{$t('assignment.user_disabled_info')}}</b></p>
                </template>
                <template v-else>
                  <p v-if="!isUnlimited && reward.level === 1 && reward.level === assignment.rewardLevel && candidatesForLevel(reward.level).length >= maxRecommendations"><b>{{$t('assignment.recommendations_maximum', {max: maxRecommendations})}}</b></p>
                  <p v-else><b>{{$t(`assignment.recommendation_info_${assignmentHowToRecommendMap(assignment.howToRecommend)}`, { company: assignment.anonymous ? $t('assignment.company_anonymous') : assignment.companyName })}}</b></p>
                </template>

                <p v-if="(isUnlimited && !isClosed) || (contactedCandidates.length < maxRecommendations && !isClosed && reward.level === 1 && !userDisabled)" class="text-align-center">
                  <loading-button
                    :loading="candidateLoading"
                    style="width: 240px"
                    class="display-inline-flex"
                    round
                    fill
                    :text="$t('assignment.recommend_button')"
                    @click="sendCandidate()"
                  ></loading-button>
                </p>
                <f-contacted-candidates
                  v-if="contactedCandidates.length"
                  :rewardLevel="reward.level"
                  :assignment="assignment"
                  :isMulti="true"
                  @resendCandidate="resendCandidate"
                />
              </f7-block>
              <f7-block-footer v-if="!!isUnlimited && contactedCandidates.length < maxRecommendations && !isClosed && reward.level === 1 && !userDisabled" style="margin-top: 10px; margin-bottom: 0">{{$t('assignment.recommendations_amount', {amount: maxRecommendations})}}</f7-block-footer>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <f7-block strong>
          <template v-if="userDisabled">
            <p><b>{{$t('assignment.user_disabled_info')}}</b></p>
          </template>
          <template v-else>
            <p v-if="!isUnlimited && maxRecommendations === contactedCandidates.length"><b>{{$t('assignment.recommendations_maximum', {max: maxRecommendations})}}</b></p>
            <p v-else><b>{{$t(`assignment.recommendation_info_${assignmentHowToRecommendMap(assignment.howToRecommend)}`, { company: assignment.anonymous ? $t('assignment.company_anonymous') : assignment.companyName })}}</b></p>
          </template>
          <p v-if="allowToSendRecommendation" class="text-align-center">
            <loading-button
              :loading="candidateLoading || sending"
              style="width: 240px"
              class="display-inline-flex"
              round
              fill
              :text="$t('assignment.recommend_button')"
              @click="sendCandidate()"
            ></loading-button>
          </p>
          <f-contacted-candidates
            v-if="contactedCandidates.length"
            :rewardLevel="1"
            :assignment="assignment"
            :isMulti="false"
            @resendCandidate="resendCandidate"
          />
        </f7-block>
        <f7-block-footer v-if="!isUnlimited && (contactedCandidates.length < maxRecommendations) && !userDisabled">{{$t('assignment.recommendations_amount', {amount: maxRecommendations})}}</f7-block-footer>
      </template>

      <f7-link href="" external :target="emailLinkTarget" ref="dummyLink" style="display:none"></f7-link>

      <template v-if="!assignment.anonymous && moreAssignments && moreAssignments.length">
        <f7-block-title>{{$t('assignment.more_assignments_title', {companyName: assignment.companyName})}}</f7-block-title>
        <f-assignment-list
          :class="{'assignments-more-wrap': showMoreAssignmentsWrap && moreAssignments.length > 1}"
          :assignments="moreAssignments"
          :logo="assignment.logo"
        >
          <a v-if="showMoreAssignmentsWrap && moreAssignments.length > 1" class="assignments-more-show-link" href="#" @click="showMoreAssignmentsWrap = false">
            <span>{{$t('assignment.show_more_assignments_button')}}</span>
            <f7-icon f7="chevron_down"></f7-icon>
          </a>
        </f-assignment-list>
      </template>
    </div>
    <skeleton-assignment-details v-else></skeleton-assignment-details>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import config from '../../config';
  import formatDate from '../../utils/format-date';
  import formatNumber from '../../utils/format-number';
  import currencyMap from '../../utils/currency-map';
  import transactionStatusList from '../../utils/finder-transaction-status-list';
  import transactionStatusMap from '../../utils/finder-transaction-status-map';
  import candidateStatusList from '../../utils/candidate-status-list';
  import candidateStatusMap from '../../utils/candidate-status-map';
  import finderPaymentMethodList from '../../utils/finder-payment-method-list';
  import finderPaymentMethodMap from '../../utils/finder-payment-method-map';
  import assignmentCategoryMap from '../../utils/assignment-category-map';
  import assignmentStatusList from '../../utils/assignment-status-list';
  import rewardDetails from '../../utils/reward-details';
  import rewardLevelAmount from '../../utils/reward-level-amount';
  import dispatchWithRetry from '../../utils/dispatch-with-retry';
  import assignmentCategoryList from '../../utils/assignment-category-list';
  import candidateRecommendationList from '../../utils/candidate-recommendation-list';
  import share from '../../modules/share';
  import events from '../../modules/events';
  import assignmentHowToRecommendMap from '../../utils/assignment-how-to-recommend-map';
  import assignmentHowToRecommendList from '../../utils/assignment-how-to-recommend-list';
  import assignmentHowToLimitList from '../../utils/assignment-how-to-limit-list';

  export default {
    mixins: [dispatchWithRetry],
    props: {
      id: [String, Number],
      scrollToRecommendations: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        assignmentCategoryList,
        assignmentStatusList,
        candidateRecommendationList,
        assignment: null,
        candidateStatusList,
        largeScreen: this.$f7.width >= 768,
        showMoreAssignmentsWrap: true,
        finderAnonymous: null,
        finderPaymentMethodList,
        candidateLoading: false,
      };
    },
    computed: {
      allowToSendRecommendation() {
        return (this.isUnlimited && !this.isClosed) || (this.contactedCandidates.length < this.maxRecommendations && !this.isClosed);
      },
      rewardDisabled() {
        return this.assignment ? this.assignment.disableReward : false;
      },
      userDisabled() {
        return this.assignment && this.assignment.userDisabled;
      },
      contactedCandidates() {
        return this.assignment ? this.assignment.contactedCandidates : [];
      },
      maxRecommendations() {
        if (this.assignment) {
          if (typeof this.assignment.howToLimitRecommendation === 'undefined') {
            return this.assignment.finderRating;
          }
          if (this.assignment.howToLimitRecommendation === assignmentHowToLimitList.OnRating) {
            return this.assignment.finderRating;
          }
          if (this.assignment.howToLimitRecommendation === assignmentHowToLimitList.Limited) {
            return this.assignment.howToLimitRecommendationAmount;
          }
          return null;
        }
        return 0;
      },
      isUnlimited() {
        return this.maxRecommendations === null;
      },
      isClosed() {
        return this.assignment && this.assignment.status === this.assignmentStatusList.Closed;
      },
      emailLinkTarget() {
        if (window.cordova) return '_system';
        return '_self';
      },
      showPaymentInfo() {
        const self = this;
        const { assignment } = self;
        const { transactions = [] } = assignment;
        let show = true;
        transactions.forEach((t) => {
          if (t.status !== transactionStatusList.WaitingForCompany) {
            show = false;
          }
        });
        return show;
      },
      moreAssignments() {
        const self = this;
        return self.assignment.companyAssignments.filter(({ id }) => id !== self.assignment.id);
      },
      ...mapGetters({
        currentAssignment: 'f/assignment',
        loading: 'f/assignmentLoading',
        sending: 'f/candidateSending',
        profile: 'f/profile',
      }),
    },
    watch: {
      assignment() {
        const self = this;
        const assignment = self.assignment;
        if (!assignment) return;
        self.finderAnonymous = assignment.finderAnonymous;
        if (!self.valuesArraysCreated) {
          self.valuesArraysCreated = true;
        }

        if (!assignment.contactedCandidates) assignment.contactedCandidates = [];

        if (window.cordova) {
          window.ga('send', {
            hitType: 'screenview',
            screenName: self.$f7route.url,
            appName: 'FindersCrowd',
            dimension1: localStorage.userRole,
            dimension2: assignment.title,
          });
        } else {
          window.ga('send', 'pageview', {
            page: self.$f7route.url,
            dimension1: localStorage.userRole,
            dimension2: assignment.title,
          });
        }
        if (assignment && self.scrollToRecommendations) {
          self.scrollPageToRecommendations();
        }
      },
      currentAssignment(newValue) {
        const self = this;
        if (!newValue) return;
        if (newValue && newValue.id !== parseInt(self.id, 10)) {
          return;
        }
        self.assignment = newValue;
      },
    },
    created() {
      const self = this;
      self.$store.dispatch('f/unsetAssignment');
      self.dispatchWithRetry('f/getAssignment', { id: parseInt(self.id, 10) });
      self.timeout = setTimeout(() => {
        self.update();
      }, 30000);
    },
    mounted() {
      const self = this;
      self.onResize = function onResize() {
        if (self.$f7.width >= 768) {
          self.$set(self, 'largeScreen', true);
        } else {
          self.$set(self, 'largeScreen', false);
        }
      };
      self.$f7.on('resize', self.onResize);
    },
    beforeDestroy() {
      const self = this;
      if (self.onResize) {
        self.$f7.off('resize', self.onResize);
      }
      self.destroyed = true;
      clearTimeout(self.timeout);
    },
    methods: {
      allowToSendRecommendationForLevel(level) {
        return (this.isUnlimited && !this.isClosed) || (this.contactedCandidates.length < this.maxRecommendations && !this.isClosed && level === 1 && !this.userDisabled);
      },
      contactedIconText(contacted) {
        const r = contacted.recommendation;
        if (r === candidateRecommendationList.Company) return 'document_text_fill';
        if (r === candidateRecommendationList.Facebook) return 'logo_facebook';
        if (r === candidateRecommendationList.LinkedIn) return 'logo_linkedin';
        return 'email_fill';
      },
      contactedName(contacted, status) {
        const self = this;
        if (contacted.firstName && contacted.lastName) {
          return `${contacted.firstName} ${contacted.lastName}`;
        }
        if (contacted.email) {
          if (status === candidateStatusList.Contacted) {
            if (contacted.recommendation === candidateRecommendationList.Company) {
              return self.$t('assignment.recommendation_sent_to', { to: self.assignment.companyName });
            }
            return self.$t('assignment.recommendation_sent_to', { to: contacted.email });
          }
          return contacted.email;
        }
        if (status === candidateStatusList.Contacted) {
          if (contacted.recommendation === candidateRecommendationList.Facebook) {
            return self.$t('assignment.recommendation_sent_to', { to: 'Facebook' });
          }
          if (contacted.recommendation === candidateRecommendationList.LinkedIn) {
            return self.$t('assignment.recommendation_sent_to', { to: 'LinkedIn' });
          }
        }

        return self.$t('general.candidate');
      },
      scrollPageToRecommendations() {
        const self = this;
        if (self.scrolledPageToRecommendations) return;
        self.$nextTick(() => {
          self.scrolledPageToRecommendations = true;
          const $el = self.$$(self.$el);
          $el.find('.page-content').scrollTop($el.find('.candidates-send-list').offset().top - 160, 400);
        });
      },
      trackCreateEmail() {
        if (window.ga) {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'Button',
            eventAction: 'click',
            eventLabel: 'Create Email',
          });
        }
      },
      trackShareCandidate() {
        if (window.ga) {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'Button',
            eventAction: 'click',
            eventLabel: 'Share Candidate',
          });
        }
      },
      trackRecommendation() {
        if (window.ga) {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'Button',
            eventAction: 'click',
            eventLabel: 'Make Recommendation',
          });
        }
      },
      formatNumber,
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
      currencyMap,
      transactionStatusMap,
      finderPaymentMethodMap,
      candidateStatusMap,
      assignmentCategoryMap,
      assignmentHowToRecommendMap,
      statusText(id) {
        return this.$t(`candidate_status.${candidateStatusMap(id)}`);
      },
      statusTextShort(id) {
        return this.$t(`candidate_status_short.${candidateStatusMap(id)}`);
      },
      transactionCandidateName(transaction) {
        return transaction.candidate.firstName && transaction.candidate.lastName ? `${transaction.candidate.firstName} ${transaction.candidate.lastName}` : `${transaction.candidate.email}`;
      },
      receivedForCandidateLevel(contacted, level) {
        let received = 0;
        contacted.rewards.forEach((reward) => {
          if (reward.level === level && reward.received) received += reward.received;
        });
        return received;
      },
      receivedForLevel(level) {
        const self = this;
        let received = 0;
        self.assignment.contactedCandidates.forEach((candidate) => {
          candidate.rewards.forEach((reward) => {
            if (reward.level === level && reward.received) {
              received += reward.received;
            }
          });
        });
        return received;
      },
      candidateStatusForLevel(candidate, level) {
        let status;
        candidate.rewards.forEach((reward) => {
          if (reward.level === level) status = reward.status;
        });
        return status;
      },
      candidatesForLevel(level = 0) {
        const self = this;
        return self.assignment.contactedCandidates.filter((candidate) => {
          let match = false;
          const candidateLevel = candidate.rewardLevel || 0;
          if (candidateLevel === level) match = true;
          if (!match) {
            candidate.rewards.forEach((reward) => {
              if (reward.level === level) match = true;
            });
          }
          return match;
        });
      },
      rewardLevelAmount(reward) {
        const self = this;
        const { assignment } = self;
        return rewardLevelAmount(self, assignment, reward);
      },
      rewardDetails(reward) {
        const self = this;
        const { assignment } = self;
        return rewardDetails(self, assignment, reward);
      },
      emailSubject() {
        return this.$t('assignment.recommendation_email_subject');
      },
      emailBody(guid) {
        const self = this;
        let description = self.assignment.description;
        if (description && description.length > 100) {
          description = `${description.substr(0, 100)}...`;
        }
        return encodeURIComponent(self.$t('assignment.recommendation_email_body', {
          title: self.assignment.title,
          companyName: self.assignment.anonymous ? '' : self.assignment.companyName,
          description,
          url: `${config.host}/recommendation/email/${guid}/`,
        }).trim());
      },

      openChat(contacted, url) {
        const self = this;
        contacted.unreadCountCompany = 0;
        contacted.unreadCountCandidate = 0;
        self.$f7router.navigate(url);
      },

      resendUrl(candidate, status) {
        const self = this;
        if (!candidate.guid || status === candidateStatusList.Pending || !candidate.email) {
          return '#';
        }
        if (candidate.recommendation !== candidateRecommendationList.Email) {
          return '#';
        }
        return `mailto:${candidate.email}?subject=${self.emailSubject()}&body=${self.emailBody(candidate.guid)}`;
      },
      resendCandidate(candidate, status) {
        const self = this;
        if (status === candidateStatusList.Pending && !self.$root.userConfirmed) {
          self.$f7.dialog.alert(self.$t('assignment.recommendation_need_confirm'));
          return;
        }
        if (candidate.recommendation === candidateRecommendationList.Company) {
          self.candidateLoading = true;
          self.$store.dispatch('f/getRecommendation', {
            guid: candidate.guid,
            callbackOk(response) {
              self.$store.dispatch('f/sendRecommendation', {
                forceRecommend: true,
                data: {
                  ...response,
                  recommendation: candidateRecommendationList.Company,
                },
                callbackOk() {
                  self.candidateLoading = false;
                },
              });
            },
          });
          return;
        }

        if (status === candidateStatusList.Contacted) {
          if (candidate.recommendation) {
            const stringType = candidate.recommendation === candidateRecommendationList.Facebook ? 'facebook' : 'linkedin';
            const url = `${config.host}/recommendation/${stringType}/${candidate.guid}/`;
            self.trackShareCandidate();
            if (candidate.recommendation === candidateRecommendationList.Facebook) {
              share.facebook({ url });
            } else if (candidate.recommendation === candidateRecommendationList.LinkedIn) {
              share.linkedin({ url });
            }
          }
          return;
        }
        const { email } = candidate;
        self.trackCreateEmail();
        self.$store.dispatch('f/sendCandidate', {
          assignmentId: self.assignment.id,
          email,
          guid: candidate.guid,
          callbackOk({ finderGuid }) {
            self.$store.dispatch('f/updateAssignment', { id: parseInt(self.id, 10) });
            // Emulate link click assignment data
            if (finderGuid) {
              const url = `mailto:${email}?subject=${self.emailSubject()}&body=${self.emailBody(finderGuid)}`;
              self.$nextTick(() => {
                if (window.cordova && window.cordova.InAppBrowser) {
                  window.cordova.InAppBrowser.open(url, '_system');
                } else {
                  window.open(url, '_self');
                }
              });
            } else {
              self.$f7.dialog.alert(self.$t('assignment.recommendation_need_confirm'));
            }
          },
        });
      },
      sendCandidate(sendType) {
        const self = this;
        const { howToRecommend, allowSocialMediaRecommendation } = self.assignment;

        function recommendToCompany() {
          self.trackRecommendation();
          self.$f7router.navigate(`/assignment/${self.assignment.id}/recommend/`);
        }
        function proceedCandidate({ finderGuid }) {
          self.$store.dispatch('f/updateAssignment', { id: self.assignment.id });

          if (!(finderGuid && self.$root.userConfirmed) || !self.$root.userConfirmed) {
            self.$f7.dialog.alert(self.$t('assignment.recommendation_need_confirm'));
          }
        }

        function createEmail() {
          self.trackCreateEmail();
          self.candidateLoading = true;
          self.$store.dispatch('f/sendCandidate', {
            assignmentId: self.assignment.id,
            recommendation: candidateRecommendationList.Email,
            callbackOk({ finderGuid, status }) {
              self.candidateLoading = false;
              proceedCandidate({ finderGuid, status, type: 'email' });
              // Emulate link click assignment data
              if (finderGuid && self.$root.userConfirmed) {
                const url = `mailto:?subject=${self.emailSubject()}&body=${self.emailBody(finderGuid)}`;
                self.$nextTick(() => {
                  if (window.cordova && window.cordova.InAppBrowser) {
                    window.cordova.InAppBrowser.open(url, '_system');
                  } else {
                    window.open(url, '_self');
                  }
                });
              }
            },
            callbackError() {
              self.candidateLoading = false;
            },
          });
        }

        function shareCandidate(type) {
          self.trackShareCandidate();

          if (window.cordova) {
            self.candidateLoading = true;
            self.$store.dispatch('f/sendCandidate', {
              assignmentId: self.assignment.id,
              recommendation: type,
              callbackOk({ finderGuid, status }) {
                self.candidateLoading = false;
                proceedCandidate({ finderGuid, status });

                if (finderGuid && self.$root.userConfirmed) {
                  const stringType = type === candidateRecommendationList.Facebook ? 'facebook' : 'linkedin';
                  const url = `${config.host}/recommendation/${stringType}/${finderGuid}/`;
                  if (type === candidateRecommendationList.Facebook) {
                    share.facebook({ url });
                  } else if (type === candidateRecommendationList.LinkedIn) {
                    share.linkedin({ url });
                  }
                }
              },
              callbackError() {
                self.candidateLoading = false;
              },
            });
          } else {
            if (!navigator.onLine) {
              events.$emit('showOfflineError');
              return;
            }
            const guid = self.assignment.finderGuid || self.$utils.id('xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx');
            if (self.$root.userConfirmed) {
              const stringType = type === candidateRecommendationList.Facebook ? 'facebook' : 'linkedin';
              const url = `${config.host}/recommendation/${stringType}/${guid}/`;
              if (type === candidateRecommendationList.Facebook) {
                share.facebook({ url });
              } else if (type === candidateRecommendationList.LinkedIn) {
                share.linkedin({ url });
              }
            }
            self.candidateLoading = true;
            self.$store.dispatch('f/sendCandidate', {
              guid,
              assignmentId: self.assignment.id,
              recommendation: type,
              callbackOk({ finderGuid, status }) {
                self.candidateLoading = false;
                proceedCandidate({ finderGuid, status });
              },
              callbackError() {
                self.candidateLoading = false;
              },
            });
          }
        }

        if (howToRecommend === assignmentHowToRecommendList.ToUs) {
          // open recommendation page
          recommendToCompany();
          return;
        }

        if (sendType) {
          if (sendType === 'email') {
            createEmail(candidateRecommendationList.Email);
          }
          if (sendType === 'facebook') {
            shareCandidate(candidateRecommendationList.Facebook);
          }
          if (sendType === 'linkedin') {
            shareCandidate(candidateRecommendationList.LinkedIn);
          }
          return;
        }

        const onlyEmail = howToRecommend !== assignmentHowToRecommendList.FindersChoice && !allowSocialMediaRecommendation;
        let buttons;
        if (onlyEmail) {
          buttons = `
            <div class="assignment-recommend-buttons">
              <a href="#" class="button button-round button-fill recommend-buttons-email">
                <i class="f7-icons">email_fill</i>
                <span>${self.$t('assignment.create_email_button')}</span>
              </a>
            </div>
          `;
        } else {
          buttons = `
            <div class="assignment-recommend-buttons">
              ${howToRecommend === assignmentHowToRecommendList.FindersChoice ? `
                <p>${self.$t('assignment.recommendation_dialog_text_to_company')}</p>
                <a href="#" class="button button-round button-fill recommend-buttons-company">
                  <span>${self.$t('assignment.recommendation_dialog_company_button')}</span>
                </a>
                <div class="assignment-recommend-buttons-divider"><span>${self.$t('general.or')}</span></div>
              ` : ''}
              <p>${self.$t('assignment.recommendation_dialog_text_to_candidates')}</p>
              <a href="#" class="button button-round button-fill recommend-buttons-email">
                <i class="f7-icons">email_fill</i>
                <span>${self.$t('assignment.recommendation_dialog_email_button')}</span>
              </a>
              ${allowSocialMediaRecommendation === true ? `
                <a href="#" class="button button-round button-fill recommend-buttons-facebook">
                  <i class="f7-icons">logo_facebook</i>
                  <span>${self.$t('assignment.recommendation_dialog_facebook_button')}</span>
                </a>
                <a href="#" class="button button-round button-fill recommend-buttons-linkedin">
                  <i class="f7-icons">logo_linkedin</i>
                  <span>${self.$t('assignment.recommendation_dialog_linkedin_button')}</span>
                </a>
              ` : ''}
            </div>
          `;
        }

        const dialog = self.$f7.dialog.create({
          title: onlyEmail ? self.$t('assignment.recommendation_dialog_title_email_only') : self.$t('assignment.recommendation_dialog_title'),
          text: `${self.$t('assignment.recommendation_dialog_text')}${buttons}`,
          verticalButtons: true,
          buttons: [
            {
              text: self.$t('general.cancel'),
              color: 'gray',
            },
          ],
        }).open();
        dialog.$el.find('.recommend-buttons-company').on('click', () => {
          dialog.close();
          recommendToCompany();
        });
        dialog.$el.find('.recommend-buttons-email').on('click', () => {
          dialog.close();
          createEmail(candidateRecommendationList.Email);
        });
        dialog.$el.find('.recommend-buttons-facebook').on('click', () => {
          dialog.close();
          shareCandidate(candidateRecommendationList.Facebook);
        });
        dialog.$el.find('.recommend-buttons-linkedin').on('click', () => {
          dialog.close();
          shareCandidate(candidateRecommendationList.LinkedIn);
        });
      },

      setFinderAnonymous(value) {
        const self = this;
        self.finderAnonymous = value;
        self.assignment.finderAnonymous = value;
        self.$store.dispatch('f/setFinderAnonymous', {
          assignmentId: self.assignment.id,
          finderAnonymous: value,
        });
      },
      update() {
        const self = this;
        if (self.destroyed) return;
        self.dispatchWithRetry('f/updateAssignment', {
          id: parseInt(self.id, 10),
          callbackOk() {
            if (self.destroyed) return;
            self.timeout = setTimeout(() => {
              if (self.destroyed) return;
              self.update();
            }, 30000);
          },
        });
      },
      onPageAfterIn(e, page) {
        const self = this;
        if (page.direction === 'backward') {
          self.$store.dispatch('f/updateAssignment', { id: parseInt(self.id, 10) });
        }
      },
    },
  };
</script>
