<template>
  <f7-popup @popup:opened="onOpened" class="chat-popup">
    <f7-view>
      <f7-page
        class="chat-page"
        infinite
        infinite-top
        :infinite-preloader="false"
        @infinite="onInfinite"
      >
        <f7-navbar>
          <f7-nav-title>
            {{$t('crowd_chat.title')}} - {{crowdName || (crowd ? crowd.name : '')}}
            <span class="subtitle" v-if="presence">{{$t('crowd_chat.presence', presence)}}</span>
            <span class="subtitle" v-else>&nbsp;</span>
          </f7-nav-title>
          <f7-link slot="nav-right" icon-ios="f7:close" icon-md="material:close" popup-close></f7-link>
        </f7-navbar>

        <offline-indicator slot="fixed"></offline-indicator>

        <f7-messagebar
          ref="messagebar"
          :placeholder="$t('chat.placeholder')"
          :value="text"
          :attachments-visible="attachments.length > 0"
          @input="text = $event.target.value"
        >
          <label slot="inner-start" class="link" :class="{ disabled: !$root.online }">
            <f7-icon ios="f7:attachment" md="material:attach_file" />
            <input type="file" multiple @change="onFilesChange">
          </label>
          <f7-link
            :class="{ disabled: !$root.online }"
            icon-ios="f7:arrow_up_round_fill"
            icon-md="material:send"
            slot="inner-end"
            @click="sendMessage"
          ></f7-link>
          <f7-messagebar-attachments>
            <attachments
              :files="attachments || []"
              :allow-edit="true"
              :show-title="false"
              :wrap="false"
              :in-messagebar="true"
              @deleteFile="deleteAttachment"
            ></attachments>
          </f7-messagebar-attachments>
        </f7-messagebar>

        <f7-messages ref="messages" :init="false">
          <template v-for="(message, index) in messagesSorted">
            <f7-messages-title
              v-if="isDifferentDate(message)"
              :key="`${message.id}_date`"
            >{{getMessagesDate(message.createdDate)}}</f7-messages-title>
            <f7-message
              :type="message.myMessage ? 'sent' : 'received'"
              :key="message.clientId || message.id"
              :header="`${message.user.firstName || ''} ${message.user.lastName || ''} ${messageTime(message.createdDate)}`"
              :last="true"
              :first="true"
              :tail="!message.urlPreview"
              :class="{
                'message-sending': message.sending,
                'message-appear-from-bottom': index >= animateMessageIndex && (animatedIds.indexOf(message.id) < 0 || animatedIds.indexOf(message.clientId) < 0),
                'message-with-attachments': message.attachments && message.attachments.length > 0,
                'message-with-url-preview': message.urlPreview,
              }"
              @animationend.native="addAnimatedId(message)"
            >
              <avatar slot="avatar" :size="34" :file="message.user.avatar" />
              <div slot="text" v-if="message.attachments && message.attachments.length" class="message-attachments">
                <attachments
                  :files="message.attachments || []"
                  :allow-edit="false"
                  :show-title="false"
                  :wrap="false"
                ></attachments>
              </div>
              <div slot="text" v-if="message.text" v-html="message.textFormatted"></div>
              <url-preview
                v-if="message.urlPreview"
                slot="text"
                :url="message.urlPreview"
              />
            </f7-message>
          </template>
          <f7-message
            v-if="messagesSorted && !messagesSorted.length"
            type="sent"
            :last="true"
            :first="true"
            :tail="true"
            class="intro-message"
          >
            <div slot="text" v-html="$t('crowd_chat.intro_message')"></div>
          </f7-message>
        </f7-messages>
      </f7-page>
    </f7-view>
  </f7-popup>
  <!--  -->
</template>

<script>
  import { mapGetters } from 'vuex';
  import striptags from 'striptags';
  import events from '../../modules/events';
  import formatDate from '../../utils/format-date';
  import dispatchWithRetry from '../../utils/dispatch-with-retry';
  import mapRoleGetters from '../../utils/map-role-getters';
  import socket from '../../modules/socket';
  import Sync from '../../modules/sync';
  import API from '../../modules/api';
  import config from '../../config';
  import getVideoDataFromUrl from '../../utils/get-video-data-from-url';
  import urlRegex from '../../utils/url-regex';
  import linkify from '../../utils/linkify';

  export default {
    mixins: [dispatchWithRetry],
    props: {
      crowdId: [Number, String],
      crowdName: String,
    },
    data() {
      return {
        crowd: null,
        interval: null,
        animateMessageIndex: 100,
        text: '',
        animatedIds: [],
        allowScrollOnKeyboardOpen: true,
        allowInfinite: false,
        messages: [],
        skip: 0,
        take: 30,
        presence: null,
        attachments: [],
      };
    },
    computed: {
      ...mapRoleGetters({
        profile: 'profile',
      }),
      ...mapGetters({
        storeCrowd: 'c/crowd',
      }),
      messagesSorted() {
        const self = this;
        if (!self.messages) return null;
        const sorted = self.messages.sort((a, b) => {
          return new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime();
        });
        sorted.forEach((m) => {
          const formatted = self.formatText(m.text);
          m.textFormatted = formatted.text;
          m.urlPreview = formatted.urlPreview;
        });
        return sorted;
      },
      moreLoading() {
        const self = this;
        return self[`moreLoading${self.from}${self.to}`]
      },
      loading() {
        const self = this;
        return self[`loading${self.from}${self.to}`];
      },
      sending() {
        const self = this;
        return self[`sending${self.from}${self.to}`];
      },
    },
    watch: {
      profile(profile, oldProfile) {
        const self = this;
        if (profile && !oldProfile) {
          self.getMessages();
          if (!self.crowdName) self.dispatchWithRetry('c/getCrowd', { id: self.crowdId });
        }
      },
      storeCrowd() {
        if (this.storeCrowd && parseInt(this.crowdId, 10) === this.storeCrowd.id) {
          this.crowd = this.storeCrowd;
        }
      },
      'messages.length': function onMessagesChange(newLength, oldLength) {
        const self = this;
        if (!oldLength) {
          if (newLength >= 20) {
            self.allowInfinite = true;
          }
          self.$nextTick(() => {
            self.f7Messages.scroll(0);
          });
          self.animateMessageIndex = newLength;
          if (self.messages) {
            self.messages.forEach((m) => {
              self.addAnimatedId(m);
            });
          }
          return;
        }
        if (newLength > oldLength) {
          const pageContentEl = self.pageContentEl;
          const scrollHeightBefore = pageContentEl.scrollHeight;
          const heightBefore = pageContentEl.offsetHeight;
          const scrollBefore = pageContentEl.scrollTop;
          self.$nextTick(() => {
            let onEdge = false;
            if (scrollBefore - (scrollHeightBefore - heightBefore) >= -10) {
              onEdge = true;
            }
            if (onEdge) self.f7Messages.scroll();
          });
        }
      },
    },
    created() {
      const self = this;
      if (self.profile) {
        self.getMessages();
        if (!self.crowdName) {
          self.dispatchWithRetry('c/getCrowd', { id: self.crowdId });
        }
      }
    },
    mounted() {
      const self = this;
      self.f7Messages = self.$f7.messages.create({
        el: self.$refs.messages.$el,
        autoLayout: false,
      });
      self.$refs.messagebar.f7Messagebar.$textareaEl.on('keypress', self.onKeyPress);

      events.$on('keyboardWillShow', self.onKeyboardWillShow);
      self.$nextTick(() => {
        self.$f7.popup.open(self.$el);
      });
      self.pageContentEl = self.$el.querySelector('.page-content');
      events.$on('chat:message', self.receiveMessage);
      self.getPresence();
      document.addEventListener('resume', self.getMessages);
      events.$on('online', self.getMessages);
    },
    beforeDestroy() {
      const self = this;
      self.$refs.messagebar.f7Messagebar.$textareaEl.off('keypress', self.onKeyPress);
      self.f7Messages.destroy();
      self.destroyed = true;
      events.$off('keyboardWillShow', self.onKeyboardWillShow);
      events.$off('chat:message', self.receiveMessage);
      clearTimeout(self.timeout);
      document.removeEventListener('resume', self.getMessages);
      events.$off('online', self.getMessages);
    },
    methods: {
      deleteAttachment(attachment) {
        const self = this;
        self.attachments.splice(self.attachments.indexOf(attachment), 1);
      },
      onFilesChange(e) {
        const self = this;
        const files = e.target.files;
        for (let i = 0; i < files.length; i += 1) {
          const file = files[i];
          if (file.type && (file.type.indexOf('image') === 0)) {
            const reader = new window.FileReader();
            reader.onload = ((event) => {
              file.dataUrl = event.target.result;
              self.attachments.push(file);
            });
            reader.readAsDataURL(file);
          } else {
            self.attachments.push(file);
          }
        }
      },
      getPresence() {
        const self = this;
        if (self.destroyed) return;
        API.get({
          url: `/${self.$root.userRole}/crowd/chatmembers?crowdId=${self.crowdId}`,
          cache: false,
          ok(presence) {
            self.presence = presence || { finders:0, admins: 0 };
          },
        });
      },
      onOpened() {
        const self = this;
        if (self.$device.desktop) {
          self.$nextTick(() => {
            self.$refs.messagebar.focus();
          });
        }
      },
      onClosed() {
        const self = this;
        self.$emit('closed');
      },
      needToScroll() {
        const self = this;
        const pageContentEl = self.pageContentEl;
        const onBottom = pageContentEl.scrollTop >= (pageContentEl.scrollHeight - pageContentEl.offsetHeight) - pageContentEl.offsetHeight;
        return onBottom;
      },
      onKeyboardWillShow() {
        const self = this;
        if (!self.allowScrollOnKeyboardOpen) return;
        const needToScroll = self.needToScroll();
        if (needToScroll) {
          self.f7Messages.scroll(0);
        }
      },
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
      formatText(text) {
        let urlPreview;
        let formatted = striptags(text || '').replace(/\n/g, '<br>');
        formatted = formatted.replace(urlRegex, (url) => {
          const { yt, vimeo, dreamBroker } = getVideoDataFromUrl(url);

          if (yt) {
            return `<iframe class="message-video" src="${window.cordova ? 'https:' : ''}//www.youtube.com/embed/${yt}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`;
          }
          if (vimeo) {
            return `<iframe class="message-video" src="${window.cordova ? 'https:' : ''}//player.vimeo.com/video/${vimeo}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`;
          }
          if (dreamBroker) {
            return `<iframe class="message-video" src="${window.cordova ? 'https:' : ''}//dreambroker.com/channel/${dreamBroker[0]}/iframe/${dreamBroker[1]}" frameborder="0" allowfullscreen webkitallowfullscreen></iframe>`;
          }

          let linkTarget = window.cordova ? '_inappbrowser' : '_blank';
          if (url.indexOf('@') >= 0 && url.indexOf('//') < 0 && url.indexOf('mailto:') < 0) {
            linkTarget = '_systembrowser';
            // eslint-disable-next-line
            url = `mailto:${url}`;
          }
          let href = url;
          // Add http
          if (!href.match(/(http:|ftp:|file:|https:)/) && href.match(/[a-z0-9]/).index === 0) {
            href = `http://${href}`;
          }

          if (href.indexOf('http') === 0 && !urlPreview) {
            urlPreview = href;
          }

          return `<a class="external" href="${href}" target="${linkTarget}">${url}</a>`;
        });

        return {
          text: formatted,
          urlPreview,
        };
      },
      addAnimatedId(message) {
        if (message.id && this.animatedIds.indexOf(message.id) < 0) {
          this.animatedIds.push(message.id);
        }
        if (message.clientId && this.animatedIds.indexOf(message.clientId) < 0) {
          this.animatedIds.push(message.clientId);
        }
      },
      isDifferentDate(message) {
        const self = this;
        const previousMessage = self.messagesSorted[self.messagesSorted.indexOf(message) - 1];
        if (previousMessage) {
          const previousDate = new Date(previousMessage.createdDate || new Date());
          const currentDate = new Date(message.createdDate || new Date());
          if (previousDate.getFullYear() !== currentDate.getFullYear() || previousDate.getMonth() !== currentDate.getMonth() || previousDate.getDate() !== currentDate.getDate()) {
            return true;
          }
          return false;
        }
        return true;
      },
      getMessagesDate(date) {
        return this.formatDate(date);
      },
      messageTime(date) {
        const d = new Date(date);
        let hours = d.getHours();
        let minutes = d.getMinutes();
        if (hours < 10) hours = `0${hours}`;
        if (minutes < 10) minutes = `0${minutes}`;
        return `${hours}:${minutes}`;
      },

      onKeyPress(e) {
        const self = this;
        if (!self.$device.desktop) return;
        if (e.keyCode === 13 && !e.shiftKey) {
          e.preventDefault();
          self.sendMessage();
        }
      },
      onInfinite() {
        const self = this;
        if (!self.allowInfinite || self.moreLoading) return;
        const {
          crowdId,
        } = self;
        const pageContentEl = self.pageContentEl;
        const scrollHeightBefore = pageContentEl.scrollHeight;
        const messagesBefore = self.messages.length;
        self.skip += self.take;

        self.getMessagesAPI(self.skip, self.take).then((messages) => {
          self.messages.push(...messages);
          self.animateMessageIndex = self.messages.length;
          if (self.messages.length - messagesBefore < 20) {
            self.allowInfinite = false;
          }
          self.$nextTick(() => {
            const { scrollHeight } = pageContentEl;
            pageContentEl.scrollTop = (scrollHeight - scrollHeightBefore);
          });
        });
      },
      receiveMessage(message) {
        const self = this;
        const sameMessage = self.messages.filter(m => m.clientId === message.clientId)[0];
        if (sameMessage) {
          if (sameMessage.sending) {
            sameMessage.sending = false;
          }
          return;
        }
        if (parseInt(message.crowdId, 10) !== parseInt(self.crowdId, 10)) return;
        if (!message.user) {
          message.user = {
            firstName: '',
            lastName: '',
          };
        }
        self.messages.push(message);
      },
      sendMessageAPI(role, message) {
        if (!navigator.onLine) {
          Sync.add({
            socket: true,
            eventName: `${role}SaveMessage`,
            data: message,
          });
          return;
        }
        if (message.attachments && message.attachments.length > 0) {
          API.upload({
            data: { multipartFiles: message.attachments },
            ok(files) {
              const newMessage = Object.assign({}, message, { attachments: files });
              socket.connection.send(`${role}SaveMessage`, newMessage)
                .catch(() => {
                  setTimeout(() => {
                    self.sendMessageAPI(role, message);
                  }, 5000);
                });
            },
          });
          return;
        }
        socket.connection.send(`${role}SaveMessage`, message)
          .catch(() => {
            setTimeout(() => {
              self.sendMessageAPI(role, message);
            }, 5000);
          });
      },
      sendMessage() {
        const self = this;
        const {
          crowdId,
        } = self;

        const text = self.text.trim();
        const attachments = [...self.attachments];
        if (!text && !attachments.length) return;

        clearTimeout(self.timeout);
        setTimeout(() => {
          self.allowScrollOnKeyboardOpen = false;
          setTimeout(() => {
            self.allowScrollOnKeyboardOpen = true;
          }, 300);
          self.$refs.messagebar.focus();
        }, 0);

        const message = {
          crowdId,
          clientId: self.$f7.utils.id(),
          text,
          sending: true,
          createdDate: new Date(),
          myMessage: true,
          attachments,
          user: {
            firstName: self.profile.firstName ? self.profile.firstName : self.profile.email,
            lastName: self.profile.firstName ? self.profile.lastName : '',
            avatar: self.profile.photo || undefined,
          },
        };
        self.text = '';
        self.attachments = [];

        self.messages.push(message);

        self.sendMessageAPI(self.$root.userRole, message);
      },
      getMessagesAPI(skip = 0, take = 30) {
        const self = this;
        const { crowdId } = self;
        return new Promise((resolve, reject) => {
          API.get({
            url: `/${self.$root.userRole}/crowd/chatmessagelist?crowdId=${crowdId}&skip=${skip}&take=${take}`,
            cache: false,
            ok(res) {
              resolve(res.messages || []);
            },
            error(err) {
              reject(err);
            },
          });
        });
      },
      getMessages() {
        const self = this;
        if (self.destroyed) return;
        const { crowdId } = self;
        self
          .getMessagesAPI()
          .then((messages) => {
            if (self.destroyed) return;
            self.messages = [];
            self.messages.push(...messages);
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
</script>
