<template>
  <div class="profile-form center-content">
    <f7-block strong class="profile-form-logo-inputs" v-if="!skeleton">
      <avatar class="profile-form-logo" :avatar="photo" :placeholder="false" :size="150">
        <label v-if="!readonly">
          <input type="file" ref="logo" @change="proceedPhoto">
          <f7-icon ios="f7:camera_fill" md="material:camera_alt"></f7-icon>
        </label>
      </avatar>
      <f7-list class="profile-form-inputs no-safe-areas" no-hairlines>
        <f7-list-input
          :label="$t('form.first_name')"
          :required="$root.userRole !== 'admin'"
          validate
          type="text"
          :value="firstName"
          @input="firstName = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          :label="$t('form.last_name')"
          :required="$root.userRole !== 'admin'"
          validate
          type="text"
          :value="lastName"
          @input="lastName = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          :label="$t('form.email')"
          required
          validate
          validateOnBlur
          type="email"
          :value="email"
          @input="email = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          :label="$t('form.phone')"
          validate
          type="tel"
          :value="phone"
          @input="phone = $event.target.value"
        ></f7-list-input>
        <f7-list-input
          :label="$t('form.language')"
          type="select"
          :value="language"
          @change="language = $event.target.value"
        >
          <option value="en">{{$t('language.en')}}</option>
          <option value="sv">{{$t('language.sv')}}</option>
        </f7-list-input>
        <f7-list-item
          v-if="$root.userRole === 'admin'"
          :title="$t('admin.user_disabled')"
        >
          <f7-toggle slot="after" :checked="disabled" @change="disabled = $event.target.checked"></f7-toggle>
        </f7-list-item>
        <slot name="list"></slot>
      </f7-list>
    </f7-block>
    <f7-block strong class="profile-form-logo-inputs skeleton-text" v-if="skeleton">
      <avatar class="profile-form-logo skeleton-effect-fade" :placeholder="true" :size="150"></avatar>
      <f7-list class="profile-form-inputs no-safe-areas" no-hairlines>
        <f7-list-input
          class="skeleton-effect-fade"
          v-for="n in 5"
          :key="n"
          label="_______"
          type="text"
          value="_______________"
        ></f7-list-input>
      </f7-list>
    </f7-block>
    <slot></slot>
  </div>
</template>
<script>
  import Croppie from 'croppie';
  import extend from '../../utils/extend';

  export default {
    props: {
      user: Object,
      readonly: Boolean,
      skeleton: Boolean,
    },
    data() {
      const user = this.user || {};
      return extend({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        photo: null,
        language: this.$root.language,
        disabled: false,
      }, user);
    },
    watch: {
      user() {
        if (!this.user) return;
        const { firstName, lastName, email, phone, photo, language = this.$root.language, disabled } = this.user;
        extend(this, { firstName, lastName, email, phone, photo, language, disabled });
      },
    },
    mounted() {
      const self = this;
      self.$$(self.$el).on('input change', () => {
        setTimeout(() => {
          self.$emit('formChanged');
        }, 0);
      });
    },
    methods: {
      openCropper(src) {
        const self = this;
        let croppie;

        function onResize() {
          croppie.setZoom(croppie._currentZoom); // eslint-disable-line
        }

        const popup = self.$f7.popup.create({
          backdrop: self.$$(self.$el).parents('.popup').length === 0,
          content: `
            <div class="popup popup-image-crop popup-tablet-fullscreen">
              <div class="view view-init" data-dynamic-navbar="false">
                <div class="page">
                  <div class="image-crop"></div>
                  <div class="croppie-toolbar toolbar tabbar toolbar-bottom no-hairline no-shadow">
                    <div class="toolbar-inner">
                      <a href="#" class="link popup-close" data-popup=".popup-image-crop">${self.$t('general.cancel')}</a>
                      <a href="#" class="link crop-image">${self.$t('general.done')}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          `.trim(),
          on: {
            open() {
              let CroppieConstructor = Croppie;
              if (CroppieConstructor.Croppie) CroppieConstructor = CroppieConstructor.Croppie;
              croppie = new CroppieConstructor(popup.$el.find('.image-crop')[0], {
                enableZoom: true,
                enforceBoundary: true,
                showZoomer: true,
                enableExif: true,
                viewport: {
                  width: 300,
                  height: 300,
                  type: 'square',
                },
              });
              croppie.bind({ url: src, zoom: 0 });
              popup.$el.find('a.crop-image').on('click', () => {
                croppie.result({
                  type: 'base64',
                  size: {
                    width: 800,
                    height: 800,
                  },
                  format: 'png',
                }).then((result) => {
                  self.photo = result;
                });
                popup.close();
              });
              self.$f7.on('resize', onResize);
            },
            closed() {
              self.$f7.off('resize', onResize);
              setTimeout(() => {
                popup.destroy();
                croppie.destroy();
              }, 100);
            },
          },
        });
        popup.open();
      },
      proceedPhoto(event) {
        const self = this;
        const input = event.target;
        const file = input.files[0];
        if (file.type.indexOf('image') === 0) {
          const reader = new window.FileReader();
          reader.onload = ((e) => {
            self.openCropper(e.target.result);
            input.value = null;
          });
          reader.readAsDataURL(file);
        }
      },
      validate() {
        const self = this;
        self.$f7.input.validateInputs(self.$el);
        if (self.$el.querySelectorAll('.item-input-invalid').length > 0) {
          self.$f7.input.scrollIntoView(self.$el.querySelectorAll('.item-input-invalid')[0], 300);
          return false;
        }
        return true;
      },
      getData() {
        const self = this;
        const {
          firstName, lastName, email, phone, photo, language, disabled,
        } = self;
        return {
          firstName, lastName, email, phone, photo, language, disabled,
        };
      },
    },
  };
</script>
