import currencyMap from './currency-map';
import formatNumber from './format-number';
import rewardPercentageTypeList from './reward-percentage-type-list';
import rewardPercentageTypeMap from './reward-percentage-type-map';

export default function (self, assignment, reward) {
  const { rewards = [{}], multipleLevels, shareOut } = assignment;

  const currency = rewards.length ? rewards[0].currency : 0;
  if (!reward && multipleLevels) return null;
  // eslint-disable-next-line
  if (!reward) reward = rewards[0];

  const { type, percentage, percentageType } = reward;
  let { expectedFrom, expectedTo } = reward;
  const rewardPart = self.$root.userRole === 'company' || self.$root.userRole === 'admin' ? 100 : shareOut;

  if (Number.isNaN(expectedFrom) || !expectedFrom) expectedFrom = 0;
  if (Number.isNaN(expectedTo) || !expectedTo) expectedTo = 0;

  let text = null;
  const currencyString = currencyMap(currency);
  if (type === 1) {
    const loc = percentageType === rewardPercentageTypeList.AnnualSalary ? 'assignment.reward_details_salary' : 'assignment.reward_details';
    text = self.$t(loc, {
      percentage: (percentage * rewardPart) / 100,
      percentageType: self.$t(`reward_percentage_type.${rewardPercentageTypeMap(percentageType)}Definite`),
      min: formatNumber(expectedFrom),
      max: formatNumber(expectedTo),
      currency: currencyString,
    });
  }
  return text;
}
