<template>
  <f7-list no-hairlines :inset="isMulti" class="candidates-send-list no-safe-areas">
    <f7-list-item
      v-for="contacted in candidates"
      :key="contacted.id"
      media-item
      class="candidates-contacted-item"
    >
      <div class="candidate-status-badge" :data-status="candidateStatusMap(candidateStatusForLevel(contacted, rewardLevel))">{{statusTextShort(candidateStatusForLevel(contacted, rewardLevel))}}</div>

      <span slot="title">{{ contactedName(contacted, candidateStatusForLevel(contacted, rewardLevel)) }}</span>

      <template v-if="candidateStatusForLevel(contacted, rewardLevel) !== candidateStatusList.Contacted && candidateStatusForLevel(contacted, rewardLevel) !== candidateStatusList.Declined">
        <div class="chat-buttons" slot="subtitle">
          <f7-link v-if="contacted.recommendation === candidateRecommendationList.Company" :href="`/assignment/${assignment.id}/recommend/${contacted.guid}/`">
            <f7-icon material="edit"></f7-icon>
            <span class="text">{{$t('assignment.recommendation_edit_link')}}</span>
          </f7-link>
          <f7-link
            v-if="contacted.recommendation !== candidateRecommendationList.Company"
            @click="openChat(contacted, `/chat/${assignment.id}/${assignment.companyId}/${contacted.guid}/?tab=candidate`)"
          >
            <f7-icon material="chat"></f7-icon>
            <span class="badge color-brand-red" v-if="contacted.unreadCountCandidate">{{contacted.unreadCountCandidate}}</span>
            <span class="text">{{$t('assignment.chat_with_link', { name: contacted.firstName && contacted.lastName ? `${contacted.firstName} ${contacted.lastName}` : `${contacted.email}` })}}</span>
          </f7-link>
          <f7-link
            v-if="contacted.companyHasStartedConversation"
            @click="openChat(contacted, `/chat/${assignment.id}/${assignment.companyId}/${contacted.guid}/?tab=company`)"
          >
            <f7-icon material="chat"></f7-icon>
            <span class="badge color-brand-red" v-if="contacted.unreadCountCompany">{{contacted.unreadCountCompany}}</span>
            <span class="text">{{$t('assignment.chat_with_link', { name: assignment.anonymous ? $t('assignment.company_anonymous') : assignment.companyName })}}</span>
          </f7-link>
        </div>
        <div slot="subtitle">{{$t('assignment.feedback_label', { companyName: assignment.anonymous ? $t('assignment.company_anonymous') : assignment.companyName })}}:</div>
        <rating slot="subtitle" :allow-rate="false" :value="contacted.rating || 0"></rating>
      </template>

      <template v-if="!contacted.guid || candidateStatusForLevel(contacted, rewardLevel) === candidateStatusList.Pending">
        <f7-icon material="warning" color="brand-yellow" class="warning-icon" slot="after"></f7-icon>
        <span slot="after" class="warning-text">{{$t('assignment.no_recommendation_sent')}}</span>
      </template>

      <loading-button
        v-if="candidateStatusForLevel(contacted, rewardLevel) === candidateStatusList.Pending && !isClosed && contacted.recommendation === candidateRecommendationList.Company"
        small
        round
        fill
        slot="after"
        :text="$t('assignment.recommend_button')"
        @click="resendCandidate(contacted, candidateStatusForLevel(contacted, rewardLevel))"
      ></loading-button>
    </f7-list-item>

  </f7-list>
</template>

<script>
  import config from '../../config';
  import candidateStatusList from '../../utils/candidate-status-list';
  import candidateRecommendationList from '../../utils/candidate-recommendation-list';
  import candidateStatusMap from '../../utils/candidate-status-map';
  import assignmentStatusList from '../../utils/assignment-status-list';

  export default {
    props: {
      rewardLevel: Number,
      candidates: Array,
      assignment: Object,
      isMulti: Boolean
    },
    data() {
      return {
        candidateStatusList,
        candidateRecommendationList,
      };
    },
    computed: {
      emailLinkTarget() {
        if (window.cordova) return '_system';
        return '_self';
      },
      isClosed() {
        return this.assignment && this.assignment.status === assignmentStatusList.Closed;
      },
    },
    methods: {
      candidateStatusMap,
      contactedName(contacted, status) {
        const self = this;
        if (contacted.firstName && contacted.lastName) {
          return `${contacted.firstName} ${contacted.lastName}`;
        }
        if (contacted.email) {
          if (status === candidateStatusList.Contacted) {
            if (contacted.recommendation === candidateRecommendationList.Company) {
              return self.$t('assignment.recommendation_sent_to', { to: self.assignment.companyName });
            }
            return self.$t('assignment.recommendation_sent_to', { to: contacted.email });
          }
          return contacted.email;
        }
        if (status === candidateStatusList.Contacted) {
          if (contacted.recommendation === candidateRecommendationList.Facebook) {
            return self.$t('assignment.recommendation_sent_to', { to: 'Facebook' });
          }
          if (contacted.recommendation === candidateRecommendationList.LinkedIn) {
            return self.$t('assignment.recommendation_sent_to', { to: 'LinkedIn' });
          }
        }

        return self.$t('general.candidate');
      },
      candidateStatusForLevel(candidate, level) {
        let status;
        candidate.rewards.forEach((reward) => {
          if (reward.level === level) status = reward.status;
        });
        return status;
      },
      statusTextShort(id) {
        return this.$t(`candidate_status_short.${candidateStatusMap(id)}`);
      },
      openChat(contacted, url) {
        const self = this;
        contacted.unreadCountCompany = 0;
        contacted.unreadCountCandidate = 0;
        self.$f7router.navigate(url);
      },
      emailSubject() {
        return this.$t('assignment.recommendation_email_subject');
      },
      emailBody(guid) {
        const self = this;
        let description = self.assignment.description;
        if (description && description.length > 100) {
          description = `${description.substr(0, 100)}...`;
        }
        return encodeURIComponent(self.$t('assignment.recommendation_email_body', {
          title: self.assignment.title,
          companyName: self.assignment.anonymous ? '' : self.assignment.companyName,
          description,
          url: `${config.host}/recommendation/${guid}/`,
        }).trim());
      },
      resendUrl(candidate, status) {
        const self = this;
        if (!candidate.guid || status === candidateStatusList.Pending || !candidate.email) {
          return '#';
        }
        if (candidate.recommendation !== candidateRecommendationList.Email) {
          return '#';
        }
        return `mailto:${candidate.email}?subject=${self.emailSubject()}&body=${self.emailBody(candidate.guid)}`;
      },
      resendCandidate(candidate, status) {
        this.$emit('resendCandidate', candidate, status);
      },
    },
  };
</script>
