/* eslint no-shadow: "off" */
/* eslint no-param-reassign: "off" */
import API from '../../modules/api';
import PWA from '../../modules/pwa';

const state = {
  candidate: null,
  candidateLoading: false,
  saving: false,
};

const getters = {
  candidateLoading(state) {
    return state.candidateLoading;
  },
  candidateSaving(state) {
    return state.saving;
  },
  candidate(state) {
    return state.candidate;
  },
};
const actions = {
  getCandidate({ commit, state }, { id } = {}) {
    if (state.candidate) return;
    API.get({
      url: `admin/candidate/details?id=${id}`,
      loading: 'a/setCandidateLoading',
      ok(response) {
        commit('setCandidate', response);
      },
    });
  },
  unsetCandidate({ commit }) {
    commit('unsetCandidate');
  },
  saveCandidate({ dispatch }, { candidate, callbackOk, callbackError } = {}) {
    API.post({
      url: 'admin/candidate/save',
      loading: 'a/setCandidateSaving',
      data: candidate,
      base64FileProp: 'logo',
      ok() {
        if (candidate && candidate.id && state.candidate && state.candidate.id === candidate.id) {
          const newCandidate = {
            ...state.candidate,
            ...candidate,
          };
          PWA.updateCachedApi(`admin/candidate/details?id=${candidate.id}`, newCandidate);
        }
        if (callbackOk) callbackOk();
      },
      error: callbackError,
    });
  },
};

const mutations = {
  setCandidate(state, candidate) {
    state.candidate = candidate;
  },
  setCandidateLoading(state, status) {
    state.candidateLoading = status;
  },
  unsetCandidate(state) {
    state.candidate = null;
  },
  setCandidateSaving(state, status) {
    state.saving = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
