<template>
  <f7-fab
    class="chat-button"
    position="center-bottom"
    :text="$t('chat.chat_button')"
    @click="openChat"
  >
    <f7-icon f7="chat_fill" />
    <f7-badge slot="root" color="red" v-if="count > 0">{{count}}</f7-badge>
  </f7-fab>
</template>
<script>
  import API from '../../modules/api';
  import events from '../../modules/events';

  export default {
    props: {
      crowdId: [String, Number],
      crowdName: String,
    },
    data() {
      return {
        count: 0,
      };
    },
    mounted() {
      const self = this;
      self.getCount();
      events.$on('chat:message', self.onMessage);
      events.$on('online', self.getCount);
      document.addEventListener('resume', self.getCount);
    },
    beforeDestroy() {
      const self = this;
      events.$off('chat:message', self.onMessage);
      events.$off('online', self.getCount);
      document.removeEventListener('resume', self.getCount);
      self.destroyed = true;
    },
    methods: {
      openChat() {
        const self = this;
        const { crowdId, crowdName } = self;
        self.$f7router.navigate(`/crowd/${crowdId}/chat/`, { props: { crowdName } })
        self.count = 0;
      },
      onMessage({ crowdId }) {
        const self = this;
        const chatOpened = self.$f7router && self.$f7router.currentRoute && self.$f7router.currentRoute.url.indexOf(`crowd/${crowdId}/chat`) >= 0;
        if (parseInt(self.crowdId, 10) !== crowdId || chatOpened) return;
        self.count += 1;
      },
      getCount() {
        const self = this;
        if (self.destroyed) return;
        API.get({
          url: `/${self.$root.userRole}/crowd/chatmessagecount?crowdId=${self.crowdId}`,
          cache: false,
          ok(res) {
            self.count = res.count || 0;
          },
        });
      },
    },
  };
</script>
