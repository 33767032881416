<template>
  <f7-page
    infinite
    :infinite-preloader="false"
    @infinite="onInfinite"
    @page:beforein="onPageBeforeIn"
    @page:afterin="onPageAfterIn"
  >
    <f7-navbar>
      <panel-link slot="nav-left" />
      <f7-nav-title>
        <span>{{$t('assignments.my_assignments_company')}}</span>
      </f7-nav-title>
      <f7-nav-right>
        <profile-link />
      </f7-nav-right>
    </f7-navbar>

    <offline-indicator slot="fixed"></offline-indicator>

    <div class="center-content center-content-blocks">
      <f7-block strong v-if="dashboardData">
        <h3 class="text-align-center">
          {{$t('assignments.rating_title')}}
          <f7-icon
            :tooltip="$t('assignments.rating_info_company')"
            style="margin-left: 5px; cursor: pointer"
            :size="20"
            material="info"
          ></f7-icon>
        </h3>
        <rating
          :value="dashboardData.rating"
          :allowRate="false"
        ></rating>
        <p class="text-align-center no-margin-bottom margin-top" style="font-weight: bold; font-size: 16px">
          <f7-link href="/how-to-write-assignment/">{{$t('assignment_form.how_to_write_link')}}<f7-icon material="info" :size="20"></f7-icon></f7-link>
        </p>
      </f7-block>

      <c-assignment-list v-if="myAssignments && myAssignments.length" :assignments="myAssignments"></c-assignment-list>
      <skeleton-assignment-list v-if="!myAssignments"></skeleton-assignment-list>
      <f7-block strong v-if="myAssignments && !myAssignments.length">
        <p class="finder-intro-title">{{$t('assignments.company_intro_title')}}</p>
        <div class="finder-intro-text" v-html="$t('assignments.company_intro_text')"></div>
      </f7-block>
      <f7-preloader v-if="allowInfinite && myListHasMore" class="infinite-scroll-preloader"></f7-preloader>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';
  import events from '../../modules/events';
  import dispatchWithRetry from '../../utils/dispatch-with-retry';
  import assignmentCategoryList from '../../utils/assignment-category-list';

  export default {
    mixins: [dispatchWithRetry],
    data() {
      return {
        assignmentCategoryList,
        allowInfinite: false,
      };
    },
    created() {
      const self = this;

      self.$store.dispatch('c/unsetMyAssignmentList');
      self.dispatchWithRetry('c/getMyAssignmentList');

      if (!self.profile) {
        self.dispatchWithRetry('c/getProfile');
      }

      self.timeout = setTimeout(() => {
        self.update();
      }, 30000);
    },
    beforeDestroy() {
      const self = this;
      self.destroyed = true;
      clearTimeout(self.timeout);
      events.$off('pushForeground', self.updateDashboard);
      self.$$(document).off('resume', self.updateDashboard);
    },
    watch: {
      'myAssignments.length': function watchAssignments(length) {
        const self = this;
        if (length && self.setScrollOnUpdate && self.$f7route.url === '/my-assignments/') {
          self.restoreScroll();
        }
        if (length && length % 20 === 0) {
          this.allowInfinite = true;
        } else {
          this.allowInfinite = false;
        }
      },
    },
    computed: {
      isEnterprise() {
        return this.profile && this.profile.isEnterprise;
      },
      companyLogo() {
        return this.profile ? this.profile.companyLogo : null;
      },
      companyName() {
        return this.profile ? this.profile.companyName : null;
      },
      userConfirmed() {
        return this.$root.userConfirmed;
      },
      ...mapGetters({
        dashboardData: 'c/dashboardData',
        myAssignments: 'c/myAssignmentList',
        myLoading: 'c/myAssignmentListLoading',
        myLoadingMore: 'c/myAssignmentListMoreLoading',
        profile: 'c/profile',
        myListHasMore: 'c/myAssignmentListHasMore',
      }),
    },
    mounted() {
      const self = this;
      self.setScrollOnUpdate = true;
      events.$emit('setActiveMenu', 'my-assignments');
      events.$on('assignment-created', () => {
        self.onAssignmentCreated = true;
      });
      events.$on('pushForeground', self.updateDashboard);
      self.$$(document).on('resume', self.updateDashboard);
    },
    methods: {
      restoreScroll() {
        const self = this;
        self.setScrollOnUpdate = false;
        if (!self.$device.cordova) return;
        const scrollTop = self.$f7router.scrollHistory[self.$f7route.url];
        if (scrollTop) {
          self.$nextTick(() => {
            self.$$(self.$el).find('.page-content').scrollTop(scrollTop);
          });
        }
      },
      onInfinite() {
        const self = this;
        if (!self.allowInfinite || self.myLoadingMore || !self.myListHasMore) return;
        self.$store.dispatch('c/getMoreMyAssignmentList');
      },
      onPageBeforeIn(e, page) {
        const self = this;
        if (page.direction === 'backward') {
          clearTimeout(self.timeout);
          self.update();
        }
      },
      onPageAfterIn(e, page) {
        const self = this;
        if (page.direction === 'backward') {
          if (self.onAssignmentCreated) {
            self.onAssignmentCreated = false;
          }
          self.$store.dispatch('c/unsetAssignment');
          self.$store.dispatch('c/unsetMyAssignment');
        } else {
          setTimeout(() => {
            self.$root.justLoggedIn = false;
          }, 100);
        }
      },
      updateDashboard() {
        const self = this;
        if (self.destroyed) return;
        self.$store.dispatch('c/updateMyAssignmentList');
      },
      update() {
        const self = this;
        if (self.destroyed) return;
        self.dispatchWithRetry('c/updateMyAssignmentList', {
          callbackOk() {
            if (self.destroyed) return;
            self.timeout = setTimeout(() => {
              if (self.destroyed) return;
              self.update();
            }, 30000);
          },
        });
      },
    },
  };
</script>
