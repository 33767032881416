<template>
  <f7-page class="application-page">
    <f7-navbar>
      <f7-nav-left v-if="$device.cordova">
        <f7-link href="/" back icon="icon-back" :text="$t('general.back')"></f7-link>
      </f7-nav-left>
      <f7-nav-title>
        <span class="navbar-logo"></span>
        <span>FindersCrowd</span>
      </f7-nav-title>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <div class="center-content" v-if="recommendation">
      <f7-block strong class="assignment-details-block">
        <div class="assignment-welcome-message" v-if="!isShared && recommendation.finderName && recommendation.finderName.trim()">{{$t('application.intro', { name: recommendation.finderName })}}</div>
        <div class="assignment-details-content-wrap">
          <div class="assignment-details-logo">
            <logo v-if="recommendation.anonymous || !recommendation.homepage" class="company-logo" :file="recommendation.logo" :size="120"></logo>
            <a v-else class="external" :target="$externalTarget" :href="recommendation.homepage">
              <logo class="company-logo" :file="recommendation.logo" :size="120"></logo>
            </a>
          </div>
          <div class="assignment-details-content">
            <div class="assignment-title">{{recommendation.title}}</div>
            <div class="assignment-closed-badge" v-if="isClosed">{{$t('application.closed_title')}}</div>
            <image-gallery
              v-if="mediaFilesFromText.length || recommendation.files && recommendation.files.length"
              :files="recommendation.files"
              :extra-media-files="mediaFilesFromText"
              :allow-edit="false"
            ></image-gallery>
            <div class="company-name" v-if="recommendation.anonymous || !recommendation.homepage">
              <f7-icon material="business"></f7-icon>
              <span>{{recommendation.companyName}}</span>
            </div>
            <div class="company-name" v-else>
              <a class="external" :target="$externalTarget" :href="recommendation.homepage">
                <f7-icon material="business"></f7-icon>
                <span>{{recommendation.companyName}}</span>
                <f7-icon f7="chevron_right"></f7-icon>
              </a>
            </div>
            <div class="assignment-limit-message" v-if="isLimit">{{$t('application.reached_limit')}}</div>

            <div v-if="isDeclined" class="assignment-inner-block assignment-application-status" :data-status="candidateStatusMap(candidateStatusList.Declined)">
              <div class="assignment-inner-block-content">
                <div>{{$t('application.status_title')}}:</div>
                <b>{{$t(`candidate_status.${candidateStatusMap(candidateStatusList.Declined)}`)}}</b>
              </div>
            </div>

            <div class="assignment-description" v-if="recommendation.description">
              <rich-text :text="recommendation.description" :show-previews="false"></rich-text>
            </div>

            <attachments
              v-if="recommendation.files && recommendation.files.length"
              :allow-edit="false"
              :media-files-source="recommendation.description"
              :files="recommendation.files"
            ></attachments>

            <div class="assignment-message-for-interested" v-if="recommendation.messageForInterested">
              <div class="assignment-message-for-interested-title">{{$t('application.interested_message_title')}}</div>
              <div class="assignment-message-for-interested-content">
                <div v-html="formatText(recommendation.messageForInterested)"></div>
              </div>
            </div>
          </div>
        </div>
      </f7-block>

      <!-- Initial Application -->
      <template>
        <f7-block-title>{{$t('application.interested_title')}}</f7-block-title>
        <div class="block block-strong">
          <div class="buttons-row" style="padding-left: 0; padding-right: 0; margin-bottom: 0" v-if="!(isClosed || isLimit)">
            <loading-button
              :class="{disabled: (isClosed || isLimit)}"
              large
              fill
              round
              fluid
              color="brand-green"
              preloader-color="white"
              :text="$t('application.interested_button')"
              @click="saveInterest()"
              :loading="interesting && savingButton === 'interest'"
            ></loading-button>
            <loading-button
              :class="{disabled: (isClosed || isLimit)}"
              large
              fill
              round
              fluid
              color="brand-yellow"
              preloader-color="white"
              :text="$t('application.maybe_button')"
              @click="saveInterest(true)"
              :loading="interesting && savingButton === 'maybe'"
            ></loading-button>
            <loading-button
              v-if="!isDeclined"
              class="button-right"
              :class="{disabled: (isClosed || isLimit)}"
              large
              fill
              round
              color="brand-red"
              preloader-color="white"
              :text="$t('application.not_interested_button')"
              @click="decline()"
              :loading="declining"
            ></loading-button>
          </div>
        </div>
        <div v-if="!(isClosed || isLimit)" class="block-footer" v-html="$t('application.privacy_link')"></div>
      </template>
    </div>
    <skeleton-application-details v-if="!recommendation"></skeleton-application-details>
  </f7-page>
</template>
<script>
  import striptags from 'striptags';
  import { mapGetters } from 'vuex';
  import candidateStatusList from '../../utils/candidate-status-list';
  import candidateStatusMap from '../../utils/candidate-status-map';
  import assignmentStatusList from '../../utils/assignment-status-list';
  import rewardText from '../../utils/reward-text';
  import dispatchWithRetry from '../../utils/dispatch-with-retry';
  import mediaFilesFromText from '../../utils/media-files-from-text';
  import candidateRecommendationList from '../../utils/candidate-recommendation-list';

  export default {
    mixins: [dispatchWithRetry],
    props: {
      guid: [String, Number],
      type: String,
    },
    data() {
      const type = this.type;
      let recommendationType;
      if (type === 'email') recommendationType = candidateRecommendationList.Email;
      else if (type === 'facebook') recommendationType = candidateRecommendationList.Facebook;
      else if (type === 'linkedin') recommendationType = candidateRecommendationList.LinkedIn;
      return {
        candidateStatusList,
        savingButton: null,
        isShared: false,
        isDeclined: false,
        recommendationType,
      };
    },
    created() {
      const self = this;
      self.$store.dispatch('application/unsetRecommendation');
      self.dispatchWithRetry('application/getRecommendation', { id: self.guid, language: self.$root.language });

      self.timeout = setTimeout(() => {
        self.update();
      }, 30000);
    },
    beforeDestroy() {
      const self = this;
      self.destroyed = true;
      clearTimeout(self.timeout);
    },
    watch: {
      recommendation(recommendation, prevRecommendation) {
        const self = this;
        if (!prevRecommendation && recommendation) {
          self.isShared = !recommendation.email;
        }
      },
    },
    computed: {
      ...mapGetters({
        recommendation: 'application/recommendation',
        loading: 'application/recommendationLoading',
        declining: 'application/recommendationDeclining',
        interesting: 'application/recommendationInteresting',
      }),
      mediaFilesFromText() {
        return this.recommendation ? mediaFilesFromText(this.recommendation.description) : [];
      },
      isClosed() {
        const self = this;
        return self.recommendation && (
          (self.recommendation.assignmentStatus === assignmentStatusList.Closed)
          || (new Date(self.recommendation.endDate).getTime() < new Date().getTime())
        );
      },
      isLimit() {
        const self = this;
        return self.recommendation && self.recommendation.interestLimitReachedForAssignment;
      },
    },
    methods: {
      candidateStatusMap,
      formatText(text) {
        return striptags(text || '').replace(/\n/g, '<br>');
      },
      getEmail(status, callback) {
        const self = this;
        let title = self.$t('application.email_title_declined');
        if (status === candidateStatusList.InterestReceived) title = self.$t('application.email_title_interested');
        if (status === candidateStatusList.Maybe) title = self.$t('application.email_title_maybe');
        const emailDialog = self.$f7.dialog.create({
          title: title,
          content: `
            <div class="dialog-input-field input">
              <input type="email" placeholder="${self.$t('application.email_placeholder')}" class="dialog-input" required validate data-validate-on-blur="true">
            </div>
          `,
          buttons: [
            {
              text: self.$t('general.cancel'),
              color: 'gray',
            },
            {
              text: self.$t('general.send'),
              close: false,
              onClick() {
                const $emailInput = emailDialog.$el.find('input');
                const email = $emailInput.val();
                self.$f7.input.validate($emailInput);
                if ($emailInput.hasClass('input-invalid')) return;
                emailDialog.close();
                if (callback) callback(email);
              },
            },
          ],
        }).open();
      },
      saveInterest(maybe) {
        const self = this;
        const status = maybe ? candidateStatusList.Maybe : candidateStatusList.InterestReceived;
        if (maybe) {
          self.savingButton = 'maybe';
        } else {
          self.savingButton = 'interest';
        }
        function send() {
          self.$store.dispatch('application/interestRecommendation', {
            guid: self.recommendation.guid,
            email: self.recommendation.email,
            language: self.$root.language,
            status,
            recommendation: self.recommendationType,
            callbackOk(res) {
              let interval;
              let seconds = 5;

              const dialog = self.$f7.dialog.create({
                title: '',
                text: `
                  <div class="text-align-center">
                    ${self.$theme.ios ? '<i style="font-size:28px" class="icon f7-icons color-brand-green">check_round_fill</i>' : '<i style="font-size:28px" class="icon material-icons color-brand-green">check_circle</i>'}
                    <div class="margin-top">${self.$t('application.saved')}</div>
                    ${res.applicationGuid ? `
                    <div class="margin-top text-align-center redirecting-text">${self.$t('application.redirecting_to_application', { n: seconds })}</div>
                    ` : ''}
                  </div>
                `,
              });
              dialog.open();
              if (!res.applicationGuid) return;

              setTimeout(() => {
                clearInterval(interval);
                dialog.close();
                self.$f7router.navigate(`/application/${res.applicationGuid}`, { reloadCurrent: true });
              }, seconds * 1000);

              interval = setInterval(() => {
                seconds -= 1;
                dialog.$el.find('.redirecting-text').text(self.$t('application.redirecting_to_application', { n: seconds }));
              }, 1000);
            },
          });
          if (window.ga) {
            window.ga('send', {
              hitType: 'event',
              eventCategory: 'Button',
              eventAction: 'click',
              eventLabel: maybe ? 'Maybe Recommendation' : 'Interesting Recommendation',
            });
          }
        }
        if (!self.recommendation.email) {
          self.getEmail(status, (email) => {
            self.recommendation.email = email;
            send();
          });
        } else {
          send();
        }
      },
      decline() {
        const self = this;
        const { rewards = [], multipleLevels, shareOut, potentialReward, potentialEarnings } = self.recommendation;
        const assignment = {
          rewards,
          multipleLevels,
          shareOut,
          potentialReward,
          potentialEarnings,
        };

        self.$root.confirmDialog(self.$t('application.decline_confirm'), () => {
          self.getEmail(candidateStatusList.Declined, (email) => {
            self.recommendation.email = email;
            self.$store.dispatch('application/declineRecommendation', {
              guid: self.recommendation.guid,
              language: self.$root.language,
              recommendation: self.recommendationType,
              email: self.recommendation.email,
              callbackOk() {
                if (!self.$root.loggedIn) {
                  self.$f7.dialog.alert(self.$t('application.after_decline_message', {
                    reward: rewardText(self, assignment),
                    email: self.recommendation.email || '',
                  }));
                }
                self.isDeclined = true;
              },
            });
            if (window.ga) {
              window.ga('send', {
                hitType: 'event',
                eventCategory: 'Button',
                eventAction: 'click',
                eventLabel: 'Decline Recommendation',
              });
            }
          });
        });
      },
      update() {
        const self = this;
        if (self.destroyed) return;
        if (self.isShared) return;

        self.dispatchWithRetry('application/updateRecommendation', {
          id: self.guid,
          language: self.$root.language,
          callbackOk() {
            if (self.destroyed) return;
            self.timeout = setTimeout(() => {
              if (self.destroyed) return;
              self.update();
            }, 30000);
          },
        });
      },
    },
  };
</script>
