<template>
  <f7-list form ref="form">
    <f7-list-input
      :label="$t('form.first_name')"
      :class="{ disabled }" type="text" :placeholder="$t('form.first_name_placeholder')" :value="firstName" @input="firstName = $event.target.value"
    ></f7-list-input>
    <f7-list-input
      :label="$t('form.last_name')"
      :class="{ disabled }" type="text" :placeholder="$t('form.last_name_placeholder')" :value="lastName" @input="lastName = $event.target.value"
    ></f7-list-input>
    <f7-list-input
      :label="$t('form.email')"
      :class="{ disabled }" type="email" :placeholder="$t('form.email_placeholder')" required validate validateOnBlur :value="application.email" @input="email = $event.target.value"
    ></f7-list-input>
    <f7-list-input
      :label="$t('form.phone')"
      :class="{ disabled }" type="tel" :placeholder="$t('form.phone_placeholder')" :value="phone" @input="phone = $event.target.value"
    ></f7-list-input>
    <f7-list-input
      v-if="application.category === categoryList.Recruitment"
      :label="$t('form.linkedin')"
      :class="{ disabled }" type="url" :placeholder="$t('form.linkedin_placeholder')" :value="linkedIn" @input="linkedIn = $event.target.value"
    ></f7-list-input>
    <f7-list-input
      :label="$t('form.message')"
      :class="{ disabled }" type="textarea" resizable :placeholder="$t('form.message_placeholder')" :value="message" @input="message = $event.target.value"
    ></f7-list-input>
    <f7-list-item>
      <attachments
        ref="attachments"
        :files="attachments"
        :disabled="disabled"
        @deleteFile="deleteFile"
      ></attachments>
    </f7-list-item>
    <f7-list-item v-if="application && application.status > 0" :title="$t('application.email_notifications_label')">
      <f7-toggle color="brand-orange" slot="after" :checked="enabledEmail" @change="enabledEmail = $event.target.checked" :class="{ disabled }" ></f7-toggle>
    </f7-list-item>
    <slot></slot>
  </f7-list>
</template>

<script>
  import extend from '../../utils/extend';
  import assignmentCategoryList from '../../utils/assignment-category-list';

  export default {
    props: {
      application: Object,
      disabled: Boolean,
    },
    data() {
      const application = this.application || {};
      const data = extend({
        categoryList: assignmentCategoryList,
        guid: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        linkedIn: '',
        attachments: [],
        enabledEmail: true,
      }, application);
      if (data.attachments === null || !data.attachments) data.attachments = [];
      return data;
    },
    watch: {
      application() {
        const self = this;
        if (self.application.status > 0) {
          self.firstName = self.application.firstName;
          self.lastName = self.application.lastName;
          self.email = self.application.email;
          self.phone = self.application.phone;
          self.message = self.application.message;
          self.linkedIn = self.application.linkedIn;
          self.attachments = self.application.attachments;
          self.enabledEmail = self.application.enabledEmail;
        }

        self.$nextTick(() => {
          self.$f7.input.resizeTextarea(self.$el.querySelector('textarea'));
        });
      },
    },
    methods: {
      deleteFile(file) {
        const self = this;
        if (self.attachments.indexOf(file) >= 0) {
          self.attachments.splice(self.attachments.indexOf(file), 1);
        }
      },
      validate() {
        const self = this;
        self.$$(self.$el).find('input[required]').each((index, el) => {
          self.$f7.input.validate(el);
        });
        if (self.$el.querySelectorAll('.item-input-invalid').length > 0) {
          self.$f7.input.scrollIntoView(self.$el.querySelectorAll('.item-input-invalid')[0], 300);
          return false;
        }
        const {
          email,
        } = self;

        if (!email) {
          return false;
        }
        return true;
      },
      getData() {
        const self = this;
        const filesToUpload = self.$refs.attachments.filesToUpload;
        const {
          guid,
          firstName,
          lastName,
          email,
          phone,
          message,
          attachments,
          enabledEmail,
        } = self;

        let linkedIn = self.linkedIn;
        if (linkedIn && linkedIn.indexOf('//') < 0) linkedIn = `http://${linkedIn}`;

        return {
          guid,
          firstName,
          lastName,
          email,
          phone,
          linkedIn,
          message,
          attachments,
          enabledEmail,
          filesToUpload,
        };
      },
    },
  };
</script>
