<template>
  <f7-page>
    <f7-navbar large :title="$t('admin.dashboard_title')">
      <f7-link slot="nav-left" class="navbar-left-panel-opener" panel-open="left" icon-ios="f7:menu" icon-md="material:menu"></f7-link>
    </f7-navbar>
    <offline-indicator slot="fixed"></offline-indicator>
    <div class="data-table card card-outline" v-if="assignmentsReview && assignmentsReview.length">
      <div class="card-header">
        <div class="data-table-title">{{$t('admin.dashboard_table_needs_review_title')}}</div>
      </div>
      <div class="card-content">
        <a-assignment-list-table
          :assignments="assignmentsReview"
          :showUser="true"
        ></a-assignment-list-table>
      </div>
    </div>
    <div class="data-table card card-outline" v-if="finderRewardList && finderRewardList.length">
      <div class="card-header">
        <div class="data-table-title">{{$t('admin.dashboard_table_rewards_title')}}</div>
      </div>
      <div class="card-content">
        <a-finder-reward-list-table
          :items="finderRewardList"
          :update-lists="true"
          :change-status="true"
        ></a-finder-reward-list-table>
      </div>
    </div>
    <div class="data-table card card-outline" v-if="finderPaidRewardList && finderPaidRewardList.length">
      <div class="card-header">
        <div class="data-table-title">{{$t('admin.dashboard_table_paid_rewards_title')}}</div>
      </div>
      <div class="card-content">
        <a-finder-reward-list-table
          :items="finderPaidRewardList"
          :change-status="false"
        ></a-finder-reward-list-table>
      </div>
    </div>
  </f7-page>
</template>
<script>
  import { mapGetters } from 'vuex';

  export default {
    created() {
      const self = this;
      self.$store.dispatch('a/getAssignmentReviewList');
      self.$store.dispatch('a/getFinderRewardList');
      self.$store.dispatch('a/getFinderPaidRewardList');
    },
    computed: {
      ...mapGetters({
        finderRewardList: 'a/finderRewardList',
        finderPaidRewardList: 'a/finderPaidRewardList',
        assignmentsReview: 'a/assignmentReviewList',
      }),
    },
  };
</script>