<template>
  <div class="assignment-form">
    <f7-block-title v-if="$root.userRole === 'admin'">
      <span>{{$t('assignment_form.description_block_title')}}</span>
    </f7-block-title>
    <f7-block-title v-else class="display-flex justify-content-space-between" style="flex-wrap: wrap">
      <span>{{$t('assignment_form.description_block_title')}}</span>
      <f7-link href="/how-to-write-assignment/">{{$t('assignment_form.how_to_write_link')}}<f7-icon material="info" :size="20"></f7-icon></f7-link>
    </f7-block-title>
    <f7-list>
      <li class="assignment-form-crowd">
        <div class="item-content item-input">
          <div class="item-inner">
            <div class="item-title item-label">{{$t('general.crowd')}}</div>
            <div class="item-crowd" v-if="assignment.crowdId">
              <template v-if="crowd">
                <logo :size="60" :file="crowd.logo" />
                <span>{{crowd.name}}</span>
              </template>
            </div>
            <div class="item-crowd" v-else>
              <logo :size="60" :file="`${$device.cordova ? '' : '/'}i/logo.svg`" />
              <span>{{$t('assignments.public_crowd_title')}}</span>
            </div>
          </div>
        </div>
      </li>
      <f7-list-input v-if="$root.userRole === 'admin'"
        :label="$t('assignment_form.status_label')"
        :class="{ disabled: formDisabled }" type="select" :value="status"  @change="status = parseInt($event.target.value, 10)"
      >
        <option
          v-for="(id, key) in statusList"
          :value="id"
          :key="id"
        >{{$t(`assignment_status.${key}`)}}</option>
      </f7-list-input>
      <f7-list-input
        :label="$t('assignment_form.category_label')"
        :class="{ disabled: formDisabled || isPublishedDisabled }" type="select" :value="category" @change="setCategory(parseInt($event.target.value, 10))"
      >
        <option
          v-for="(id, key) in categoryList"
          :value="id"
          :key="key"
        >{{$t(`assignment_category.${key}`)}}</option>
      </f7-list-input>

      <!-- <f7-list-item v-if="assignment.isEnterprise" :title="$t('assignment_form.internal_label')">
        <f7-icon
          slot="title"
          :tooltip="$t('assignment_form.internal_info')"
          style="margin-left: 5px; cursor: pointer"
          :size="20"
          material="info"
        ></f7-icon>
        <f7-toggle color="brand-orange" :class="{ disabled: formDisabled || isPublishedDisabled }" slot="after" :checked="isInternal" @change="isInternal = $event.target.checked"></f7-toggle>
      </f7-list-item> -->
      <f7-list-item v-if="isInternal && category !== categoryList.Innovation" :title="$t('assignment_form.internal_finders_label')">
        <f7-toggle color="brand-orange" :class="{ disabled: formDisabled || isPublishedDisabled }" slot="after" :checked="internalRecommendationAllowed" @change="internalRecommendationAllowed = $event.target.checked"></f7-toggle>
      </f7-list-item>
      <f7-list-item :title="$t('assignment_form.accept_many_label')">
        <f7-icon
          slot="title"
          :tooltip="$t('assignment_form.accept_many_info')"
          style="margin-left: 5px; cursor: pointer"
          :size="20"
          material="info"
        ></f7-icon>
        <f7-toggle color="brand-orange" slot="after" :checked="multipleWinners" @change="multipleWinners = $event.target.checked" :class="{ disabled: formDisabled || isPublishedDisabled }"></f7-toggle>
      </f7-list-item>
      <li v-if="category !== categoryList.Innovation" class="how-to-recommend-radios">
        <div class="item-header padding-top padding-left padding-right" :class="{'padding-top': $theme.ios}">{{$t('assignment_form.want_interested_title')}}</div>
        <ul class="no-margin no-padding padding-bottom" :class="{ disabled: formDisabled || rewardDisabled || isPublishedDisabled }">
          <li
            v-for="(wiId, wiKey) in howToRecommendList"
            :key="wiId"
          >
            <label class="item-content item-radio">
              <input type="radio" :checked="howToRecommend === wiId" @change="howToRecommend = wiId">
              <i class="icon icon-radio"></i>
              <div class="item-inner">
                <div class="item-title">
                  <span slot="title" style="white-space: normal">{{$t(`assignment_form.want_interested_${wiKey}`)}}</span>
                </div>
              </div>
            </label>
          </li>
          <li
            v-if="howToRecommend === 1 || howToRecommend === 2"
          >
            <label class="item-content item-checkbox">
              <input type="checkbox" :checked="allowSocialMediaRecommendation === true" @change="allowSocialMediaRecommendation = $event.target.checked">
              <i class="icon icon-checkbox"></i>
              <div class="item-inner">
                <div class="item-title">
                  <span slot="title" style="white-space: normal">{{$t('assignment_form.allow_social_media_label')}}</span>
                </div>
              </div>
            </label>
          </li>
        </ul>
      </li>
      <li class="how-to-limit-radios">
        <div class="item-header padding-top padding-left padding-right" :class="{'padding-top': $theme.ios}">{{$t('assignment_form.limit_title')}}</div>
        <ul class="no-margin no-padding padding-bottom" :class="{ disabled: formDisabled || rewardDisabled || isPublishedDisabled }">
          <li>
            <label class="item-content item-radio">
              <input type="radio" :checked="howToLimitRecommendation === 0" @change="howToLimitRecommendation = 0">
              <i class="icon icon-radio"></i>
              <div class="item-inner">
                <div class="item-title">
                  <span slot="title" style="white-space: normal">{{$t('assignment_form.limit_OnRating')}}</span>
                </div>
              </div>
            </label>
          </li>
          <li>
            <label class="item-content item-radio">
              <input type="radio" :checked="howToLimitRecommendation === 1" @change="howToLimitRecommendation = 1">
              <i class="icon icon-radio"></i>
              <div class="item-inner">
                <div class="item-title">
                  <span slot="title" style="white-space: normal">{{$t('assignment_form.limit_Unlimited')}}</span>
                </div>
              </div>
            </label>
          </li>
          <li>
            <label class="item-content item-radio">
              <input type="radio" :checked="howToLimitRecommendation === 2" @change="howToLimitRecommendation = 2">
              <i class="icon icon-radio"></i>
              <div class="item-inner">
                <div class="item-title">
                  <span v-html="$t('assignment_form.limit_Limited', { value: howToLimitRecommendationAmount })"></span>
                </div>
                <div class="item-after">
                  <div
                    class="stepper stepper-small stepper-raised"
                  >
                    <div
                      class="stepper-button-minus prevent-active-state-propagation"
                      @click.stop.prevent="howToLimitRecommendationAmount = Math.max(1, howToLimitRecommendationAmount - 1)"
                    ></div>
                    <div
                      class="stepper-button-plus prevent-active-state-propagation"
                      @click.stop.prevent="howToLimitRecommendationAmount = Math.min(50, howToLimitRecommendationAmount + 1)"
                    ></div>
                  </div>
                </div>
              </div>
            </label>
          </li>
        </ul>
      </li>
      <f7-list-input
        :label="$t('assignment_form.title_label')"
        :class="{ disabled: formDisabled }" type="text" :placeholder="$t('assignment_form.title_placeholder')" validate required :value="title" @input="title = $event.target.value"
      ></f7-list-input>
      <f7-list-input
        :label="$t('assignment_form.description_label')"
        :class="{ disabled: formDisabled }" type="textarea" resizable :placeholder="$t('assignment_form.description_placeholder')" validate required :value="description" @input="description = $event.target.value"
      ></f7-list-input>
      <f7-list-input
        v-if="category !== categoryList.Innovation && howToRecommend !== howToRecommendList.ToUs"
        :label="$t('assignment_form.interested_message_label')"
        :class="{ disabled: formDisabled || rewardDisabled || isPublishedDisabled }"
        type="textarea"
        resizable
        :placeholder="$t('assignment_form.interested_message_placeholder')"
        :value="messageForInterested"
        @input="messageForInterested = $event.target.value"
      ></f7-list-input>
      <f7-list-input
        :label="$t('assignment_form.finder_message_label')"
        :class="{ disabled: formDisabled || rewardDisabled || isPublishedDisabled }"
        type="textarea"
        resizable
        :placeholder="$t('assignment_form.finder_message_placeholder')"
        :value="messageForFinder"
        @input="messageForFinder = $event.target.value"
      ></f7-list-input>
      <f7-list-input
        :label="$t('assignment_form.end_date_label')"
        :class="{ disabled: formDisabled }" type="text" ref="endDate" :placeholder="$t('assignment_form.end_date_placeholder')" validate required :value="endDate ? formatDate(endDate) : endDate"
      ></f7-list-input>
      <f7-list-item>
        <attachments
          ref="attachments"
          :disabled="formDisabled"
          :files="files"
          @deleteFile="deleteFile"
        ></attachments>
      </f7-list-item>
      <f7-list-item accordion-item :title="$t('assignment_form.more_options_link')">
        <f7-accordion-content>
          <f7-list no-hairlines>
            <f7-list-item :title="$t('assignment_form.anonymous_label')">
              <f7-icon
                slot="title"
                :tooltip="$t('assignment_form.anonymous_info')"
                style="margin-left: 5px; cursor: pointer"
                :size="20"
                material="info"
              ></f7-icon>
              <f7-toggle color="brand-orange" :class="{ disabled: formDisabled }" slot="after" :checked="anonymous" @change="anonymous = $event.target.checked"></f7-toggle>
            </f7-list-item>
            <f7-list-input
              :label="$t('assignment_form.max_number_interest_label')"
              :class="{ disabled: formDisabled }" type="number" :value="interestLimit" @input="interestLimit = $event.target.value" min="1" validate required="required"
            ></f7-list-input>
          </f7-list>
        </f7-accordion-content>
      </f7-list-item>
    </f7-list>
    <f7-block-title class="assignment-form-reward-title">
      <span>{{$t('assignment_form.reward_block_title')}}</span>
      <div class="toggle-wrap">
        <f7-toggle
          v-if="isInternal"
          color="brand-orange"
          slot="after"
          :checked="!disableReward"
          @change="disableReward = !$event.target.checked"
          :class="{ disabled: formDisabled || rewardDisabled || isPublishedDisabled }"
        />
      </div>
    </f7-block-title>

    <f7-block strong v-if="disableReward" class="text-align-center">{{$t('assignment_form.disable_reward_info')}}</f7-block>

    <f7-block strong v-if="!disableReward">
      <reward
        v-for="(reward, index) in rewards"
        :key="index"
        :disabled="formDisabled || rewardDisabled || isPublishedDisabled"
        :allowEditRewardAmount="allowEditRewardAmount"
        :noHairlines="true"
        :showDescription="true"
        :showDeleteButton="index > 0 && !isPublishedDisabled"
        :disableCurrency="index > 0"
        :level="multipleLevelsComputed ? index + 1 : null"
        class="list-bordered"

        :percentageTypeList="percentageTypeList"
        :currency="reward.currency"
        :type="reward.type"
        :fixed="reward.fixed"
        :percentage="reward.percentage"
        :percentageType="reward.percentageType"
        :expectedFrom="reward.expectedFrom"
        :expectedTo="reward.expectedTo"
        :description="reward.description"
        :descriptionRequired="multipleLevelsComputed"

        @deleteClick="rewards.splice(index, 1)"
        @change="(prop, value) => {setRewardField(reward, prop, value)}"
      >
        <f7-list-item v-if="index === 0" slot="reward-start">
          <span slot="title" style="white-space: normal">{{$t('assignment_form.reward_title')}} *</span>
        </f7-list-item>
      </reward>
      <p v-if="!isPublishedDisabled && !disableReward" class="text-align-center">
        <f7-button small outline round class="display-inline-block" @click="addRewardLevel">{{$t('assignment_form.add_reward_button')}}</f7-button>
      </p>
    </f7-block>
    <div class="block-footer" v-if="!disableReward">* {{$t('assignment_form.reward_info')}}</div>
    <slot></slot>
  </div>
</template>
<script>
  import assignmentStatusList from '../../utils/assignment-status-list';
  import assignmentCategoryList from '../../utils/assignment-category-list';
  import defaultCurrency from '../../utils/default-currency';
  import currencyList from '../../utils/currency-list';
  import formatDate from '../../utils/format-date';
  import percentageTypeList from '../../utils/reward-percentage-type-list';
  import assigmentHowToRecommendList from '../../utils/assignment-how-to-recommend-list';
  import assigmentHowToLimitList from '../../utils/assignment-how-to-limit-list';

  export default {
    props: {
      assignment: Object,
      crowd: Object,
    },
    data() {
      const assignment = this.assignment || {};
      const defaultReward = {
        currency: assignment.rewards && assignment.rewards[0] ? assignment.rewards[0].currency : defaultCurrency,
        type: 0,
        fixed: '',
        percentage: 10,
        percentageType: 0,
        expectedFrom: '',
        expectedTo: '',
        description: '',
      };
      const data = {
        // Helpers
        currencyList,
        statusList: assignmentStatusList,
        categoryList: assignmentCategoryList,
        howToRecommendList: assigmentHowToRecommendList,
        howToLimitList: assigmentHowToLimitList,
        needsCompanyDetails: false,
        defaultReward,

        // Data
        howToRecommend: null,
        howToLimitRecommendation: assignment.isEnterprise ? 1 : 0,
        howToLimitRecommendationAmount: 3,
        title: '',
        category: 0,
        description: '',
        messageForInterested: '',
        messageForFinder: '',
        endDate: new Date().setMonth(new Date().getMonth() + 6),
        files: [],
        allowSocialMediaRecommendation: true,
        internalRecommendationAllowed: true,
        disableReward: false,
        anonymous: false,
        interestLimit: 100,
        status: 0,
        multipleLevels: false,
        isInternal: false,
        rewards: [
          {
            ...defaultReward,
          },
        ],
        multipleWinners: false,
        ...assignment,
      };
      if (assignment.endDate) data.endDateOriginal = assignment.endDate;
      if (data.files === null || !data.files) data.files = [];
      if (data.needsCompanyDetails && !assignment.id) {
        data.rewards.forEach((reward) => {
          // eslint-disable-next-line
          reward.currency = defaultCurrency;
        });
      }
      if (assignment.crowdId) data.isInternal = true;
      if (!data.rewards.length) data.rewards.push(defaultReward);
      return data;
    },
    computed: {
      percentageTypeList() {
        const self = this;
        if (self.category === assignmentCategoryList.Recruitment) {
          return [percentageTypeList.AnnualSalary];
        }
        if (self.category === assignmentCategoryList.Rent) {
          return [percentageTypeList.AnnualRent, percentageTypeList.TransactionValue];
        }
        return [percentageTypeList.TransactionValue];
      },
      formDisabled() {
        if (this.$root.userRole === 'admin') return false;
        return !!(this.assignment && this.assignment.status === this.statusList.Closed);
      },
      rewardDisabled() {
        if (this.$root.userRole === 'admin') return false;
        return !!(this.assignment && this.assignment.status === this.statusList.Published);
      },
      allowEditRewardAmount() {
        return this.$root.userRole === 'company' && this.assignment && this.assignment.status === this.statusList.Published;
      },
      isPublished() {
        return this.assignment && this.assignment.status === this.statusList.Published;
      },
      isPublishedDisabled() {
        if (this.$root.userRole === 'admin') return false;
        return this.isPublished;
      },
      multipleLevelsComputed() {
        return this.rewards.length > 1;
      },
    },
    watch: {
      // isInternal(isInternal) {
      //   const self = this;
      //   if (!isInternal) self.disableReward = false;
      // },
      assignment() {
        const self = this;
        const { assignment } = self;
        Object.assign(self, assignment);
        if (assignment.endDate) self.endDateOriginal = assignment.endDate;
        if (assignment.crowdId) self.isInternal = true;
        if (self.files === null || !self.files) self.files = [];
        if (self.needsCompanyDetails) {
          self.rewards.forEach((reward) => {
            // eslint-disable-next-line
            reward.currency = defaultCurrency;
          });
        }
      },
    },
    mounted() {
      const self = this;
      self.$f7ready((f7) => {
        const dateInputEl = self.$refs.endDate.$el.children[0];
        const today = new Date();
        const ua = window.navigator.userAgent;
        const isIE = ua.indexOf('MSIE ') >= 0 || ua.indexOf('Trident/') >= 0 || ua.indexOf('Edge/') >= 0;
        let initialValue;
        if (self.endDateOriginal) initialValue = [new Date(self.endDateOriginal)];
        else if (self.endDate) initialValue = [new Date(self.endDate)];
        else initialValue = [];

        self.calendar = f7.calendar.create({
          inputEl: dateInputEl,
          closeOnSelect: true,
          footer: true,
          minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
          value: initialValue,
          routableModals: !isIE,
          formatValue(values) {
            self.endDateOriginal = values[0];
            return formatDate(values[0], self.$root.language);
          },
          on: {
            change(calendar, value) {
              if (typeof value[0] === 'undefined') return;
              self.endDate = value[0];
              self.endDateOriginal = value[0];
            },
          },
        });
        self.$$(self.$el).on('focus', 'input, textarea, select', (e) => {
          if (e.target !== dateInputEl) self.calendar.close();
        }, true);
      });
      self.$$(self.$el).on('input change', () => {
        self.$emit('formChanged');
      });
      self.$nextTick(() => {
        self.$$(self.$el).find('textarea').each((index, textareaEl) => {
          self.$f7.input.resizeTextarea(textareaEl);
        });
      });
    },
    methods: {
      formatDate(date) {
        return formatDate(date, this.$root.language);
      },
      addRewardLevel() {
        const newReward = {
          ...this.defaultReward,
          percentageType: this.rewards[this.rewards.length - 1].percentageType,
        };
        this.rewards.push(newReward);
      },
      setRewardField(obj, prop, value) {
        const self = this;
        self.$set(obj, prop, value);
        if (prop === 'currency') {
          self.rewards.forEach((reward) => {
            self.$set(reward, 'currency', value);
          });
          self.$set(self.defaultReward, 'currency', value);
        }
      },
      setCategory(category) {
        const self = this;
        self.prevCategory = self.category;
        self.category = category;
        self.rewards.forEach((reward) => {
          reward.percentageType = self.percentageTypeList[0];
        });
        if (category === assignmentCategoryList.Sales || category === assignmentCategoryList.Innovation) {
          self.multipleWinners = true;
        }
        if (category === assignmentCategoryList.Innovation) {
          self.prevHowToRecommend = self.howToRecommend;
          self.howToRecommend = 0;
          self.internalRecommendationAllowed = false;
        } else if (self.prevCategory === assignmentCategoryList.Innovation) {
          self.howToRecommend = self.prevHowToRecommend;
        }
      },
      validate() {
        const self = this;
        const {
          title, description, endDate, multipleLevelsComputed, rewards, howToRecommend, isInternal, disableReward,
        } = self;
        const dateInputEl = self.$refs.endDate.$el.children[0];
        dateInputEl.readOnly = false;
        self.$f7.input.validateInputs(self.$el);
        dateInputEl.readOnly = true;

        if (howToRecommend === null) {
          self.$$('.how-to-recommend-radios .item-header').addClass('text-color-red');
        } else {
          self.$$('.how-to-recommend-radios .item-header').removeClass('text-color-red');
        }

        if (self.$el.querySelectorAll('.item-input-invalid').length > 0) {
          self.$f7.input.scrollIntoView(self.$el.querySelectorAll('.item-input-invalid')[0], 300);
          return false;
        }
        if (howToRecommend === null) {
          self.$f7.input.scrollIntoView(self.$el.querySelectorAll('.how-to-recommend-radios')[0], 300);
          return false;
        }

        const {
          type, fixed, percentage, expectedFrom, expectedTo,
        } = rewards[0];
        if (!title || !description || !endDate) return false;

        if (disableReward) return true;
        if (!multipleLevelsComputed && ((type === 0 && !fixed) || (type === 1 && !percentage && !expectedFrom && !expectedTo))) {
          return false;
        }
        return true;
      },
      getData() {
        const self = this;
        const filesToUpload = self.$refs.attachments.filesToUpload;
        const {
          howToRecommend, howToLimitRecommendation, howToLimitRecommendationAmount, title, description, category, messageForFinder, internalRecommendationAllowed, anonymous, files, endDate, interestLimit, status, endDateOriginal, multipleWinners, isInternal, allowSocialMediaRecommendation, disableReward,
        } = self;
        let { messageForInterested } = self;
        let rewards = [];
        if (!disableReward) {
          rewards = self.rewards;
        } else {
          rewards = [{
            currency: defaultCurrency,
            type: 0,
            fixed: 0,
            percentage: 10,
            percentageType: 0,
            description: '',
            level: 1,
          }];
        }
        rewards.forEach((reward, index) => {
          if (reward.type === 0) {
            delete reward.expectedFrom; // eslint-disable-line
            delete reward.expectedTo; // eslint-disable-line
          } else {
            delete reward.fixed; // eslint-disable-line
          }
          if (typeof reward.level === 'undefined') {
            reward.level = index + 1;
          }
        });

        if (howToRecommend === self.howToRecommendList.ToUs) {
          messageForInterested = '';
        }
        return {
          howToRecommend,
          howToLimitRecommendation,
          howToLimitRecommendationAmount,
          title,
          description,
          messageForInterested,
          messageForFinder,
          multipleLevels: rewards.length > 1,
          rewards,
          category,
          internalRecommendationAllowed,
          allowSocialMediaRecommendation,
          disableReward,
          anonymous,
          files,
          endDate: endDateOriginal || endDate,
          interestLimit,
          filesToUpload,
          status,
          multipleWinners,
          isInternal,
        };
      },
      deleteFile(file) {
        const self = this;
        if (self.files.indexOf(file) >= 0) {
          self.files.splice(self.files.indexOf(file), 1);
        }
      },
    },
    beforeDestroy() {
      const self = this;
      if (self.calendar) self.calendar.destroy();
    },
  };
</script>
